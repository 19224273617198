<template>
  <div>
      <div class="row mt-3">
        <div class="col-12">
          <b-form-checkbox v-model="graphOptions.fullSize" name="fullSize" switch size="lg" @change="selectedGraphOptionsChanged">
            {{  $t('indoorClimate.graphConfig.graphOptions.fullSize') }}
          </b-form-checkbox>
          <b-form-checkbox v-model="graphOptions.disableThresholds" name="disableThresholds" switch size="lg" @change="selectedGraphOptionsChanged">
            {{  $t('indoorClimate.graphConfig.graphOptions.disableThresholds') }}
          </b-form-checkbox>
        </div>
      </div>
  </div>
</template>

<script>
import {mapState} from "vuex"

export default {
  data() {
    return {
      info: null,
      graphOptions: {
        fullSize: false,
        disableThresholds: false
      }
    }
  },
  created(){
    console.log('MapConfig $route.name: ' + this.$route.name)
    this.graphOptions.fullSize = this.graphConfig.fullSize
    this.graphOptions.disableThresholds = this.graphConfig.disableThresholds
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      graphConfig: state => state.liveViewShare.graphConfig,
    })
  },
  methods: {
    selectedGraphOptionsChanged() {
      console.log('graphOptions changed: ' + this.graphOptions)
      this.$store.dispatch('liveViewShare/graphConfigGraphOptionsChanged', this.graphOptions)
    },
  }
}
</script>

<style>
.custom-switch.b-custom-control-lg .custom-control-label, .input-group-lg .custom-switch .custom-control-label {
  font-size: 1rem;
  padding-top: 3px;
  line-height: 1.5;
}
</style>