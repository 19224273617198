import Vue from "vue"
import * as commons from '@/commons'
import router from '@/router'
import moment from 'moment-timezone'
import i18n from "@/i18n";

// default state
const getDefaultState = () => {

  return {
    selectedLocation: null,
    availableSensorAttributes: [],
    graphConfig: {
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedMeanDuration: "P1D",
      selectedSensorAttributes: [],
      disableThresholds: false,
      enableWeekends: true,
      enableMinMax: true,
      enableMean: true
    }
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  graphConfigDateTimeRangeFormatted: state => {
    if(state.graphConfig.dateRange.startDate && state.graphConfig.dateRange.endDate && state.selectedLocation && state.selectedLocation.map && state.selectedLocation.map.building) {
      return moment(state.graphConfig.dateRange.startDate).format(i18n.t('dateTimeShortFormatMoment'))
          + ' - '
          + moment(state.graphConfig.dateRange.endDate).format(i18n.t('dateTimeShortFormatMoment'))
    } else {
      return ''
    }
  }
}

// action
const actions = {

  appInit({ commit, state, dispatch, rootState }) {
    console.log('graphLocation/appInit')
    dispatch('resetGraphConfig')
  },

  resetGraphConfig({ commit, state }, data) {
    console.log('graphLocation/resetGraphConfig')

    return new Promise((resolve) => {
      let startDate = new Date()
      startDate.setDate(startDate.getDate() - 1)
      startDate.setHours(0, 0, 0, 0)
      let endDate = new Date()
      //endDate.setHours(23, 59, 59, 999)
      commit('UPDATE_GRAPH_CONFIG', {
        dateRange: {startDate: startDate, endDate: endDate},
        selectedSensorAttributes: []
      })
      resolve()
    })
  },

  loadLocationFromRouteQueryParam({ commit, state, dispatch }){
    if (router.currentRoute.query.locationId) {
      if (state.selectedLocation && state.selectedLocation.id ==router.currentRoute.query.locationId) {
        dispatch('loadSelectedLocationSensorValues')
      } else {
        //load location from server
        Vue.axios.get('/locations/' + router.currentRoute.query.locationId).then(response => {
          commit('UPDATE_SELECTED_LOCATION', response.data)
          commit('UPDATE_GRAPH_CONFIG', { selectedSensorAttributes: [] })
          dispatch('loadSelectedLocationSensorValues')
        }).catch(error => {
          commons.processRestError(error)
        })
      }
    }else{
      commit('UPDATE_SELECTED_LOCATION', null)
      dispatch('resetGraphConfig')
    }
  },

  loadSelectedLocationSensorValues({ commit, state, dispatch, rootState }){

    console.log('loadSelectedLocationSensorValues dateRange ' + JSON.stringify(state.graphConfig.dateRange))

    let params = {}
    if(state.graphConfig.dateRange){
      params.dateTimeFrom = state.graphConfig.dateRange.startDate
      params.dateTimeFrom.setHours(0, 0, 0, 0)
      params.dateTimeFrom = moment(state.graphConfig.dateRange.startDate).format('YYYY-MM-DDTHH:mm:ss')
      let nowInBuildingTimezone = moment.tz(moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), state.selectedLocation.map.building.timeZone)
      let endDateInBuildingTimezone = moment.tz(moment(state.graphConfig.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss'), state.selectedLocation.map.building.timeZone)
      if(!endDateInBuildingTimezone.isSame(nowInBuildingTimezone,'day')) {
        params.dateTimeTo = state.graphConfig.dateRange.endDate
        params.dateTimeTo.setHours(23, 59, 59, 999)
        params.dateTimeTo = moment(state.graphConfig.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss')
      }

      const diffMillis = Math.abs(state.graphConfig.dateRange.endDate - state.graphConfig.dateRange.startDate)
      if(diffMillis < (1000 * 60 * 60 * 24 * 5)){
        params.aggregateWindowDuration = "PT15M"
      } else if(diffMillis < (1000 * 60 * 60 * 24 * 30)){
        params.aggregateWindowDuration = "PT1H"
      } else if(diffMillis < (1000 * 60 * 60 * 24 * 90)){
        params.aggregateWindowDuration = "PT6H"
      } else {
        params.aggregateWindowDuration = "P1D"
      }
    }

    let promiseSensorValues = new Promise((resolve, reject) => {

      Vue.axios.get('/locations/' + state.selectedLocation.id + '/sensor-values', { params: params }).then(response => {
        resolve(response.data)
      }).catch(error => {
        commons.processRestError(error)
        reject(error)
      })
    })

    let promiseSensorValuesMin = new Promise((resolve, reject) => {

      params.minValue = true
      params.maxValue = false
      params.meanValue = false

      Vue.axios.get('/locations/' + state.selectedLocation.id + '/sensor-values', { params: params }).then(response => {
        resolve(response.data)
      }).catch(error => {
        commons.processRestError(error)
        reject(error)
      })
    })

    let promiseSensorValuesMax = new Promise((resolve, reject) => {

      params.minValue = false
      params.maxValue = true
      params.meanValue = false

      Vue.axios.get('/locations/' + state.selectedLocation.id + '/sensor-values', { params: params }).then(response => {
        resolve(response.data)
      }).catch(error => {
        commons.processRestError(error)
        reject(error)
      })
    })

    let promiseSensorValuesMean = new Promise((resolve, reject) => {

      // for mean to have whole graph add as start date 1 day before selected date
      //params.dateTimeFrom = moment(state.graphConfig.dateRange.startDate).subtract({hours: 24}).format('YYYY-MM-DDTHH:mm:ss')
      params.aggregateWindowDuration = state.graphConfig.selectedMeanDuration
      params.minValue = false
      params.maxValue = false
      params.meanValue = true

      Vue.axios.get('/locations/' + state.selectedLocation.id + '/sensor-values', { params: params }).then(response => {
        resolve(response.data)
      }).catch(error => {
        commons.processRestError(error)
        reject(error)
      })
    })

    Promise.all([promiseSensorValues, promiseSensorValuesMean, promiseSensorValuesMin, promiseSensorValuesMax]).then(function(responsesData) {
      commit('UPDATE_LOCATION_SENSOR_VALUES', responsesData)
    }).catch((error) => {
      commons.processRestError(error)
    })
  },

  graphConfigDateRangeSelected({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  { dateRange: data.dateRange })
    dispatch('loadSelectedLocationSensorValues')
  },

  graphConfigSensorAttributesChanged({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  { selectedSensorAttributes:  data })
  },

  selectedMeanDurationSelected({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  { selectedMeanDuration: data })
    dispatch('loadSelectedLocationSensorValues')
  },

  graphConfigOptionsChanged({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  { graphOptions: data })
  }

}


// mutations
const mutations = {

  ['GLOBAL_RESET']: (state, data) => {
    Object.assign(state, getDefaultState())
  },

  ['UPDATE_SELECTED_LOCATION']: (state, data) => {
    state.selectedLocation = data
  },

  ['UPDATE_GRAPH_CONFIG']: (state, data) => {
    if(data.dateRange){
      state.graphConfig.dateRange = data.dateRange
    }
    if(data.selectedSensorAttributes){
      state.graphConfig.selectedSensorAttributes = data.selectedSensorAttributes
    }
    if(data.selectedMeanDuration){
      state.graphConfig.selectedMeanDuration = data.selectedMeanDuration
    }
    if(data.graphOptions){
      // state.graphConfig.graphOptions = data.graphOptions
      state.graphConfig = Object.assign({}, state.graphConfig, data.graphOptions)
    }
  },

  ['UPDATE_LOCATION_SENSOR_VALUES']: (state, data) => {
    state.availableSensorAttributes = commons.getAvailableSensorAttributesFromSensorData(data[0], null, true)
    if(state.availableSensorAttributes.length > 0 && state.graphConfig.selectedSensorAttributes.length == 0){
      // select first attribute
      Vue.set(state.graphConfig, 'selectedSensorAttributes', [state.availableSensorAttributes[0]])
    }
    Vue.set(state.selectedLocation, 'sensorValues', data[0])
    Vue.set(state.selectedLocation, 'sensorValuesMap', commons.processSensorData(data[0]))
    Vue.set(state.selectedLocation, 'sensorMeanValues', data[1])
    Vue.set(state.selectedLocation, 'sensorMeanValuesMap', commons.processSensorData(data[1]))
    Vue.set(state.selectedLocation, 'sensorMinValues', data[2])
    Vue.set(state.selectedLocation, 'sensorMinValuesMap', commons.processSensorData(data[2]))
    Vue.set(state.selectedLocation, 'sensorMaxValues', data[3])
    Vue.set(state.selectedLocation, 'sensorMaxValuesMap', commons.processSensorData(data[3]))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}