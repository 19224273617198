<template>
  <div class="live-share" v-if="shareLink">
    <div class="middle-box animated fadeInDown">
      <div>
          <div class="clearfix">
              <div class="mb-5 float-right">
                  <img :src="require('@/assets/custom/img/CLIMIFY.svg')" width="200px"/>
              </div>
              <div class="float-left">
                <h3>{{ $t('liveViewShare.title')}}</h3>
                <p>{{ $t('liveViewShare.subtitle') }}</p>
              </div>
          </div>
          <div class="alert alert-danger alert-dismissible" v-show="error">
            {{ error }}
          </div>

          <div class="row equal-height justify-content-center">

            <div class="col-xxl-9 col-xl-8 col-lg-6">
              <div class="ibox">
                <div class="ibox-title">
                  <h5>{{ $t('indoorClimate.selectedMap.title') }}<template v-if="selectedMap"> - {{ selectedMap.name }} </template></h5>
                  <span v-if="selectedMap" class="_label _label-primary float-right">{{ $t('indoorClimate.dateTimeInfo', { 0: selectedBuilding.timeZone }) }} <strong>{{ $store.getters["liveViewShare/mapConfigDateTimeFormatted"] }}</strong></span>
                </div>
                <div class="ibox-content">
                  <div class="pl-2 pr-2">
                    <map-view :map="selectedMap" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xxl-3 col-xl-4 col-lg-6">
              <div class="ibox">
                <div class="ibox-title">
                  <h5><template v-if="selectedMap">{{ $t('indoorClimate.mapConfig.titleMapSelected') }}</template>
                    <template v-else>{{ $t('indoorClimate.mapConfig.titleMapNotSelected') }}</template>
                  </h5>
                </div>
                <div class="ibox-content">
                    <map-config v-if="selectedMap"/>
                </div>
              </div>
            </div>

          </div>

          <div class="row equal-height m-t-sm" v-if="selectedLocation && graphData && graphData.size > 0">
            <div class="col-xxl-9 col-lg-8">
              <div class="ibox">
                <div class="ibox-title">
                  <h5>{{ $t('indoorClimate.graph.title') }}</h5>
                </div>
                <div class="ibox-content">
                  <graph-view />
                </div>
              </div>
            </div>

            <div class="col-xxl-3 col-lg-4">
              <div class="ibox">
                <div class="ibox-title">
                  <h5>{{ $t('indoorClimate.graphConfig.title') }}</h5>
                </div>
                <div class="ibox-content">
                  <graph-config />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>


<script>
import {mapState} from "vuex"
import MapView from "@/components/pages/liveViewShare/MapView"
import MapConfig from "@/components/pages/liveViewShare/MapConfig"
import GraphView from "@/components/pages/liveViewShare/GraphView"
import GraphConfig from "@/components/pages/liveViewShare/GraphConfig"

export default {
  components:{
    MapView,
    MapConfig,
    GraphView,
    GraphConfig
  },
  data() {
    return {
      error: null,
      info: null
    }
  },
  created() {
    console.log("LiveViewShare created() selectedMap: " + this.selectedMap)
      this.$store.dispatch('liveViewShare/fetchShareLinkByHash', this.$route.params.hash)
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      shareLink: state => state.liveViewShare.shareLink.entity,
      selectedMap: state => state.liveViewShare.selectedMap,
      selectedBuilding: state => state.liveViewShare.shareLink.entity.building,
      selectedLocation: state => state.liveViewShare.selectedLocation,
      mapConfig: state => state.liveViewShare.mapConfig,
      graphConfig: state => state.liveViewShare.graphConfig,
      graphData: state => state.liveViewShare.graphData
    })
  },
  watch: {
    '$route' (to, from) { // watch it
      console.log('URL changed: ', to, ' | was: ', from, ' | query ', this.$route.query)
      this.$store.dispatch('liveViewShare/loadLocationFromRouteQueryParam')
    }
  }
}
</script>
