<template>
  <div>
    <div class="row" v-if="selectedLocation && sensorValuesMap && sensorValuesMap.size > 0">
      <div class="col-12">
        <highcharts :options="state_graphData" ref="chart"></highcharts>
      </div>
    </div>
    <div v-else class="text-center mt-5 mb-5">
      <div class="font-bold">{{ $t('graphs.location.graphView.placeholder')}}</div>
    </div>
  </div>
</template>


<script>
import {mapState} from "vuex"
import moment from 'moment-timezone'
import Highcharts from 'highcharts';

let chartOptionsBase = {
  credits: false,
  title: {
    useHTML: true,
    style: {
      fontSize: '14px',
      fontStyle: 'bold'
    }
  },
  tooltip: {
    split: true,
    crosshairs: true,
  },
  chart: {
    zoomType: 'x',
    type: 'spline',
    height: '700px',
    spacingTop: 40,
    style: {
      fontFamily: 'open sans, Helvetica Neue, Helvetica, Arial, sans-serif',
    }
  },
  xAxis: {
    type: 'datetime',
    labels: {
      autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
      formatter: function () {
        return Highcharts.dateFormat('%H:%M', this.value);
      },
    },
    minPadding: 0,
    maxPadding: 0,
    tickPixelInterval: 50
  },
  plotOptions: {
    area: {
      fillOpacity: 0.3,
    },
    spline: {
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3
        }
      },
      marker: {
        enabled: false
      }
    }
  },
  yAxis: [],
  legend: {
    enabled: true
  },
  series: []
}

export default {
  data() {
    return {
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.removeWeekendSeries();
        });
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedLocation: state => state.graphLocation.selectedLocation,
      selectedBuilding: state => state.graphLocation.selectedLocation.map.building,
      selectedSensorAttributes: state => state.graphLocation.graphConfig.selectedSensorAttributes,
      sensorValuesMap: state => state.graphLocation.selectedLocation.sensorValuesMap,
      sensorMinValuesMap: state => state.graphLocation.selectedLocation.sensorMinValuesMap,
      sensorMaxValuesMap: state => state.graphLocation.selectedLocation.sensorMaxValuesMap,
      sensorMeanValuesMap: state => state.graphLocation.selectedLocation.sensorMeanValuesMap,
      graphConfig: state => state.graphLocation.graphConfig
    }),

    state_graphData() {

      console.log('state_graphData sensorValuesMap.size ' + this.sensorValuesMap.size + ', sensorMeanValuesMap.size ' + this.sensorMeanValuesMap.size)

      let vm = this

      if (this.sensorValuesMap && this.sensorValuesMap.size > 0) {

        let yAxisIdx = 0
        let temperatureYAxisIdx = -1

        let count = 0
        let plotBandAr = [];
        let plotBand = {color: '#E9D8DB'};

        let chartDef = JSON.parse(JSON.stringify(chartOptionsBase))
        chartDef.title.text = vm.$i18n.t('graphs.location.graphView.graphTitle')
        chartDef.time = {}
        chartDef.exporting = {
          chartOptions: {
            title: {
              text: this.selectedBuilding.name + " / " + this.selectedLocation.map.name + " / " + this.selectedLocation.name
            },
            xAxis: [{}],
            series: [] 
          }
        }
        chartDef.time.getTimezoneOffset = function (timestamp) {
          return -moment.tz(timestamp, vm.selectedBuilding.timeZone).utcOffset();
        }

        if (this.graphConfig.enableWeekends !== false) {
          let plotBandSeries = {
            id: 'weekend',
            name: 'Weekend Highlight',
            color: '#E9D8DB',
            marker: {
              symbol: "square",
              symbolName: "square",
              lineColor: null,
              lineWidth: 8,
            }
          }

          chartDef.series.push(plotBandSeries)
          chartDef.plotOptions = {
            series: {
              events: {
                legendItemClick: function () {
                  if (this.options.id === 'weekend') {
                    if (this.visible) {
                      this.chart.xAxis[0].update({
                        plotBands: []
                      })
                    } else {
                      this.chart.xAxis[0].update({
                        plotBands: plotBandAr
                      })
                    }
                  }
                },
              }
            }
          }
          chartDef.exporting.chartOptions.xAxis[0].plotBands = plotBandSeries;
        } else {
          chartDef.exporting.chartOptions.xAxis[0].plotBands = [];
        }

        this.selectedSensorAttributes.forEach(field => {

          if(this.sensorValuesMap.has(field) && this.sensorValuesMap.get(field).length > 1) {

            let sensorData = this.sensorValuesMap.get(field)
            let sensorDataMin = this.sensorMinValuesMap.get(field);
            let sensorDataMax = this.sensorMaxValuesMap.get(field);
            let sensorDef = vm.$i18n.t('enums.sensorType.' + field)

            let yAxisDef = {
              maxPadding: 0.1,
              allowDecimals: false,
              title: "",
              labels:{
                format: '{value} ' +  sensorDef.unit,
                style: {
                  color: sensorDef.color
                }
              }
            }

            //customizing yAxis according to attribute type
            if(field == 'temperature' || field == 'target_temperature'){
              yAxisDef.softMin = 5
              yAxisDef.softMax = 30
            } else if(field == 'humidity'){
              yAxisDef.min = 0
              yAxisDef.max = 100
            }

            if(count%2 == 1){
              yAxisDef.gridLineWidth = 0
              yAxisDef.opposite = true
            }

            if( field === 'target_temperature' || field === 'temperature') {
              if (temperatureYAxisIdx < 0) {
            chartDef.yAxis.push(yAxisDef)
                temperatureYAxisIdx = chartDef.yAxis.length - 1
              }
              yAxisIdx = temperatureYAxisIdx
            } else {
              chartDef.yAxis.push(yAxisDef)
              yAxisIdx = chartDef.yAxis.length - 1
            }

            let series = {
              unit: sensorDef.unit,
              name:  sensorDef.name,
              data: [],
              tooltip: {
                valueSuffix: ' ' + sensorDef.unit
              },
              yAxis: yAxisIdx,
              color: sensorDef.color
            }
            
            if(field == 'target_temperature') {
              series.type = 'line'
              series.step = 'right'
            }

            let ranges = {
              id: "range",
              unit: sensorDef.unit,
              name: sensorDef.name + ' ' + vm.$i18n.t('graphs.location.graphView.range'),
              type: 'arearange',
              lineWidth: 0,
              yAxis: yAxisIdx,
              data: [],
              color: sensorDef.color,
              fillOpacity: 0.1,
              zIndex: 0,
              marker: {
                enabled: false
              },
              legend: {
                  enabled: true,
              },
              tooltip: {
                valueSuffix: ' ' + sensorDef.unit,
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}:<br> Min: <b>{point.low}</b><br> Max: <b>{point.high}</b><br/>'
              },
            };

            for (let i = 0; i < sensorData.length; i++) {
              var date = new Date(Date.parse(sensorData[i].time))
              if (date.getDay() === 6) {
                plotBand.from = date.setHours(0, 0, 0, 0)
                plotBand.to = date.setHours(23, 59, 59) + (24 * 60 * 60 * 1000)
                plotBandAr.push(plotBand)
                plotBand = {
                  color: '#E9D8DB',
                };
              }

              ranges.data.push(
                [Date.parse(sensorData[i].time), sensorDataMin[i].value ? Math.round(+(sensorDataMin[i].value) * 10) / 10 : null, sensorDataMax[i].value ? Math.round(+(sensorDataMax[i].value) * 10) / 10 : null]
              )

              series.data.push({
                x: Date.parse(sensorData[i].time),
                y: sensorData[i].value ? Math.round(+(sensorData[i].value) * 10) / 10 : null
              })
            }

            series.data.sort((a, b) => {
              return a.x - b.x
            })

            ranges.data.sort((a, b) => {
              return a.x - b.x
            })

            chartDef.series.push(series)
            if(!this.graphConfig.enableMinMax) {
              this.$nextTick(() => {
                chartDef.series.forEach((serie, index) => {
                  if (serie.id === 'range') {
                    chartDef.series.splice(index, 1)
                  }
                })
              })
            } else {
              chartDef.series.push(ranges)
            }
            chartDef.xAxis.plotBands = plotBandAr

            // add mean data serie
            let sensorMeanData = this.sensorMeanValuesMap.get(field)

            let serieMean = {
              id: "mean",
              unit: sensorDef.unit,
              name:  sensorDef.name + ' ' + vm.$i18n.t('graphs.location.graphView.mean'),
              data: [],
              dashStyle: 'shortdot',
              lineWidth: 3,
              tooltip: {
                valueSuffix: ' ' + sensorDef.unit
              },
              marker: {
                enabled: true,
                radius: 4
              },
              yAxis: yAxisIdx,
              color: sensorDef.color
            }

            for (let i = 0; i < sensorMeanData.length; i++) {
              serieMean.data.push({
                x: Date.parse(sensorMeanData[i].time),
                y: sensorMeanData[i].value ? Math.round(+(sensorMeanData[i].value) * 10) / 10 : null
              })
            }
            serieMean.data.sort((a, b) => {
              return a.x - b.x
            })
            if(!this.graphConfig.enableMean) {
              this.$nextTick(() => {
                chartDef.series.forEach((serie, index) => {
                  if (serie.id === 'mean') {
                    chartDef.series.splice(index, 1)
                  }
                })
              })
            } else {
              chartDef.series.push(serieMean)
            }
            count++
          }
        })

        return chartDef
      }

      return null
    }
  },
  methods: {
    removeWeekendSeries() {
      $('g.highcharts-plot-bands-0').remove();
      const chartDef = this.chartDef;
      if(chartDef){
        chartDef.series.forEach((serie, index) => {
          if (serie.id === 'weekend') {
            chartDef.series.splice(index, 1);
          }
        });
      }
    }
  }
}
</script>
