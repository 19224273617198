<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2 v-if="$route.meta.titleKey">{{ $t($route.meta.titleKey) }}</h2>
      <h2 id="viewHeaderTitle" v-else></h2>
      <h4 id="viewHeaderSubtitle" v-if="$route.meta.subtitleKey">{{ $t($route.meta.subtitleKey) }}</h4>
    </div>
  </div>
</template>

<script>

/*global Vue, router, i18n, toastr */

export default {
  data() {
    return {
    }
  }
}
</script>
