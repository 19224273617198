<template>
  <div>

    <div class="alert alert-info" v-if="info">{{ info }}</div>

    <div class="row mb-3">

      <div class="col-xl-8 col-lg-12 mb-lg-3">

        <validation-observer ref="form" v-slot="{ handleSubmit }">

          <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(submit)">

            <div class="row">

              <div class="col-12">

                <b-form-group v-if="map.status" id="input-group-user-status"
                  :label="$t('orgAdmin.user.attributes.status')" label-for="input-resource-status" label-cols-xl="2"
                  label-cols-lg="3" label-cols-sm="4">
                  <span class="label"
                    :class="[(map.status == 'BLOCKED' || map.status == 'PARENT_BLOCKED') ? 'label-danger' : 'label-primary']">{{
                      $i18n.t('enums.ResourceStatus.' + map.status) }}</span>
                </b-form-group>

                <validation-provider name="orgAdmin.map.attributes.name" :rules="{ required: true, min: 3, max: 50 }"
                  v-slot="validationContext">
                  <b-form-group id="input-group-map-name" :label="$t('orgAdmin.map.attributes.name')"
                    label-for="input-map-name" label-cols-xl="2" label-cols-lg="3" label-cols-sm="4">
                    <b-form-input :plaintext="type=='view'" id="input-map-name" name="input-map-name"
                      v-model="form.name" :state="getValidationState(validationContext)"
                      aria-describedby="input-feedback-map-name"></b-form-input>
                    <b-form-invalid-feedback id="input-feedback-map-name">{{ validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider name="orgAdmin.map.attributes.floorPlanImage" :rules="{ required: false }"
                  v-slot="validationContext" v-if="type=='create' || type=='edit'">
                  <b-form-group id="input-group-map-name" :label="$t('orgAdmin.map.attributes.floorPlanImage')"
                    label-for="input-map-floorPlanImage" label-cols-xl="2" label-cols-lg="3" label-cols-sm="4">
                    <b-form-file id="input-map-floorPlanImage" name="input-map-floorPlanImage"
                      placeholder="Choose a floor plan image or drop it here..."
                      drop-placeholder="Drop floor plan image here..." :state="getValidationState(validationContext)"
                      @input="fileUploadChange" aria-describedby="input-feedback-map-floorPlanImage"></b-form-file>
                    <b-form-invalid-feedback id="input-feedback-map-floorPlanImage">{{ validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <div class="row" v-show="img.src">
                  <div class="col-12">
                    <div class="cropper-wrapper" style="max-width: 1000px; margin: 0 auto;">
                      <cropper ref="cropper" class="cropper" :src="img.src" :transitions="true" :stencil-props="{}" />
                      <vertical-buttons>
                        <square-button title="Flip Horizontal" @click="flip(true, false)">
                          <img :src="require('@/assets/custom/icons/flip-horizontal.svg')" />
                        </square-button>
                        <square-button title="Flip Vertical" @click="flip(false, true)">
                          <img :src="require('@/assets/custom/icons/flip-vertical.svg')" />
                        </square-button>
                        <square-button title="Rotate Clockwise" @click="rotate(90)">
                          <img :src="require('@/assets/custom/icons/rotate-clockwise.svg')" />
                        </square-button>
                        <square-button title="Rotate Counter-Clockwise" @click="rotate(-90)">
                          <img :src="require('@/assets/custom/icons/rotate-counter-clockwise.svg')" />
                        </square-button>
                        <square-button title="Maximize" @click="maximize()">
                          <img :src="require('@/assets/custom/icons/resize-maximize.svg')" />
                        </square-button>
                      </vertical-buttons>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <button type="button" class="btn btn-sm btn-primary"
                      v-if="canEdit && type=='view' && map.status=='ACTIVE'" @click.prevent="type='edit'">
                      <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.map.actions.edit') }}
                    </button>
                    <button type="submit" v-if="type=='create'" class="btn btn-sm btn-primary">{{
                      $t('common.actions.create') }}</button>
                    <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary">{{
                      $t('common.actions.save') }}</button>
                    <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-secondary m-l-sm"
                      @click="reset">{{ $t('common.actions.reset') }}</button>
                    <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-white m-l-sm"
                      @click="cancel">{{ $t('common.actions.cancel') }}</button>
                    <button type="button" v-if="type=='edit' && map.status=='ACTIVE'"
                      class="btn btn-sm btn-danger m-l-sm float-right" @click="blockMap">{{ $t('common.actions.block')
                      }}</button>
                    <button type="button" v-if="type!='create' && map.status=='BLOCKED'"
                      class="btn btn-sm btn-danger float-right" @click="unblockMap">{{ $t('common.actions.unblock')
                      }}</button>
                    <div class="float-right" v-if="canvasControlsVisible">
                      <button class="btn btn-sm btn-primary m-l-sm" type="button" @click="editPolygon()"
                        v-if="editShapeVisible">Edit Shape</button>
                      <!--button class="btn btn-sm btn-primary m-l-sm" type="button" @click="resizePolygon()">Resize/Move</button-->
                      <button class="btn btn-sm btn-primary m-l-sm" type="button" @click="toggleGrid()">Toggle
                        grid</button>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </b-form>
        </validation-observer>
      </div>

    </div>

    <div class="row">

      <div class="col-xl-8 col-lg-12 mb-lg-3">
        <div class="row" v-if="type!=='create'" v-show="map.image">
          <div class="col-12">
            <div id="fabric-canvas-wrapper">
              <div id="button-container">
                <button type="button" class="btn btn-sm btn-primary" @click="toggleInfoPopup"><i
                    class="fas fa-question"></i></button>
                <div v-if="isPopupVisible" class="popup-overlay">
                  <div class="popup">
                    <div class="popup-header">
                      <h2 class="popup-title">{{ $t('orgAdmin.map.popup.title') }}</h2>
                      <button class="close-button" @click="toggleInfoPopup">×</button>
                    </div>
                    <div class="popup-columns">
                      <div class="popup-column">
                        <h3 class="popup-column-title">{{ $t('orgAdmin.map.popup.movingTitle') }}</h3>
                        <div class="values-grid">
                          <div class="card" v-for="controlOption in controlOptions" :key="controlOption.title">
                            <h4 class="card-title">
                              <i :class="`fas ${controlOption.icon}`"></i>
                              {{ $t(controlOption.title) }}
                            </h4>
                            <p>{{ $t(controlOption.description) }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="popup-column">
                        <h3 class="popup-column-title">{{ $t('orgAdmin.map.popup.drawingTitle') }}</h3>
                        <div class="values-grid">
                          <div class="card" v-for="drawingOption in drawingOptions" :key="drawingOption.title">
                            <h4 class="card-title">
                              <i :class="`fas ${drawingOption.icon}`"></i>
                              {{ $t(drawingOption.title) }}
                            </h4>
                            <p>{{ $t(drawingOption.description) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="button" class="btn btn-sm btn-primary m-l-xs" @click="toggleLock">
                  <i :class="isLocked ? 'fas fa-lock' : 'fas fa-lock-open'"></i>
                </button>
                <button type="button" class="btn btn-sm btn-primary m-l-xs" @click="zoomIn"><i
                    class="fas fa-plus"></i></button>
                <button type="button" class="btn btn-sm btn-primary m-l-xs" @click="zoomOut"><i
                    class="fas fa-minus"></i></button>
              </div>
              <canvas id="floor-map-canvas" style="border:1px solid #ccc;"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-12" v-if="map.image && canEdit">
        <div class="pl-4">
          <div v-if="locationFormOpened">
            <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated"
              leave-active-class="fadeOut animated" appear>
              <location-view-create-edit :location="inputLocation" :location-profiles="locationProfiles"
                :scheduler-profiles="schedulerProfiles" :map="map" :can-edit="canEdit" :initial-type="locationFormType"
                :success-callback="editLocationSuccessCallback" :cancel-callback="editLocationCancelCallback"
                :fetch-points="fetchPoints" @edit-mode="enterLocationEditMode" />
            </transition>
          </div>
          <div v-else>
            <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated"
              leave-active-class="fadeOut animated" appear>
              <div>
                <map-locations-list v-if="map.locations && map.locations.length > 0"
                  :can-edit="hasRole('ADMIN') || (map.building && hasResourceRole('BUILDING_MANAGER', map.building.id)) || (map.building && map.building.organisation && hasResourceRole('ORGANISATION_MANAGER', map.building.organisation.id))"
                  :selected-callback="selectLocationFromListCallback" />
                <button class="btn btn-sm btn-primary" @click="openLocationAddForm" v-if="createLocationVisible">
                  <i class="fa fa-plus"></i>&nbsp;{{ $t('orgAdmin.location.actions.new') }}
                </button>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Cropper } from 'vue-advanced-cropper'
import Vue from "vue"
import * as commons from "@/commons"
import 'vue-advanced-cropper/dist/style.css';
import { mapState } from "vuex";
import { fabric } from 'fabric';
import LocationViewCreateEdit from '@/components/views/buildings/LocationViewCreateEdit';
import VerticalButtons from '@/components/common/VerticalButtons';
import SquareButton from '@/components/common/SquareButton';
import i18n from "@/i18n";
import MapLocationsList from "@/components/views/buildings/MapLocationsList"
import pdfjsLib from 'pdfjs-dist/build/pdf';

/**
 * define a function that can locate the controls.
 * this function will be used both for drawing and for interaction.
 */
function polygonPositionHandler(dim, finalMatrix, fabricObject) {
  console.debug("polygonPositionHandler")
  let x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x);
  let y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);

  return fabric.util.transformPoint({ x: x, y: y },
      fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform,fabricObject.calcTransformMatrix()));
}

/**
 * define a function that will define what the control does
 * this function will be called on every mouse move after a control has been
 * clicked and is being dragged.
 * The function receive as argument the mouse event, the current trasnform object
 * and the current position in canvas coordinate
 * transform.target is a reference to the current object being transformed,
 */
function actionHandler(eventData, transform, x, y) {
  console.debug("actionHandler")

  let polygon = transform.target;
  let currentControl = polygon.controls[polygon.__corner];
  let mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
  let polygonBaseSize = polygon._getNonTransformedDimensions();
  let size = polygon._getTransformedDimensions(0, 0);
  let	finalPointPosition = {
    x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
    y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
  };
  polygon.points[currentControl.pointIndex] = finalPointPosition;
  return true;
}

/**
 * define a function that can keep the polygon in the same position when we change its
 * width/height/top/left.
 */
function anchorWrapper(anchorIndex, fn) {
  return function(eventData, transform, x, y) {
    let fabricObject = transform.target;
    let absolutePoint = fabric.util.transformPoint({
          x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
          y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
        },
        fabricObject.calcTransformMatrix());
    let actionPerformed = fn(eventData, transform, x, y);
    let newDim = fabricObject._setPositionDimensions({});
    let polygonBaseSize = fabricObject._getNonTransformedDimensions();
    let newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x;
    let newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
    return actionPerformed;
  }
}

const colors = ['#E0BBE4','#957DAD', '#FEC8D8', '#FFDFD3', '#C3BAD6', '#C9DDD5']

export default {
  components: {
    MapLocationsList,
    ValidationProvider,
    ValidationObserver,
    LocationViewCreateEdit,
    Cropper,
    VerticalButtons,
    SquareButton
  },
  props: {
    map: {
      type: Object
    },
    canEdit: {
      type: Boolean
    },
    initialType: {
      validator: function (value) {
        return ['view', 'edit', 'create'].includes(value)
      }
    },
    successCallback: {
      type: Function
    },
    cancelCallback: {
      type: Function
    },
  },
  data() {
    return {
      error: null,
      info: null,
      type: this.initialType,
      form: {
        name: '',
        building: null,
      },
      img: {
        src: null
      },
      // cropper variables
      imgRatio: 1,
      // fabricjs variables
      activeLine: null,
      activeShape: null,
      canvas: null,
      gridGroup: null,
      lineArray: [],
      pointArray: [],
      drawMode: false,
      // location form variables
      locationFormOpened: false,
      locationFormType: 'create',
      isLocked: true,
      isPopupVisible: false,
      controlOptions: [
        {
          title: 'orgAdmin.map.popup.controlOptions.lock.title',
          icon: 'fa-lock',
          description: 'orgAdmin.map.popup.controlOptions.lock.description'
        },
        {
          title: 'orgAdmin.map.popup.controlOptions.zoomIn.title',
          icon: 'fa-plus',
          description: 'orgAdmin.map.popup.controlOptions.zoomIn.description'
        },
        {
          title: 'orgAdmin.map.popup.controlOptions.zoomOut.title',
          icon: 'fa-minus',
          description: 'orgAdmin.map.popup.controlOptions.zoomOut.description'
        },
        {
          title: 'orgAdmin.map.popup.controlOptions.drag.title',
          icon: 'fa-arrows-up-down-left-right',
          description: 'orgAdmin.map.popup.controlOptions.drag.description'
        }
      ],
      drawingOptions: [
        {
          title: 'orgAdmin.map.popup.drawingOptions.firstPoint.title',
          icon: 'fa-circle',
          description: 'orgAdmin.map.popup.drawingOptions.firstPoint.description'
        },
        {
          title: 'orgAdmin.map.popup.drawingOptions.points.title',
          icon: 'fa-circle',
          description: 'orgAdmin.map.popup.drawingOptions.points.description'
        },
        {
          title: 'orgAdmin.map.popup.drawingOptions.removePoints.title',
          icon: 'fa-ban',
          description: 'orgAdmin.map.popup.drawingOptions.removePoints.description'
        },
        {
          title: 'orgAdmin.map.popup.drawingOptions.movePoints.title',
          icon: 'fa-arrows-up-down-left-right',
          description: 'orgAdmin.map.popup.drawingOptions.movePoints.description'
        },
      ]
    }
  },
  watch: {
    map: function(newVal, oldVal) {
      console.log('Prop map changed: ', newVal, ' | was: ', oldVal)
      this.reset()
      this.setupCanvas();
    },
  },
  created() {
    console.log("MapViewCreateEdit: created")
    this.$store.commit("buildings/SAVE_LOCATION_DETAILS_ENTITY", {})
  },
  computed: {
    ...mapState({
      locationProfiles: state => state.profiles.locationProfiles,
      schedulerProfiles: state => state.schedulers.schedulerProfiles,
      selectedLocation: state => state.buildings.locationDetails.entity
    }),
    inputLocation: function() {
      if(Object.keys(this.selectedLocation).length !== 0){
        //console.log("inputLocation: " + JSON.stringify(this.selectedLocation))
        //console.log("inputLocation: " + this.selectedLocation.id)
        return this.selectedLocation
      } else {
        //console.log("inputLocation: " + JSON.stringify({ map: this.map }))
        return { map: this.map }
      }
    },
    createLocationVisible: function() {
      console.log("locationFormOpened: " + this.locationFormOpened)
      console.log("selectedLocation: " + JSON.stringify(this.selectedLocation))
      return this.type != 'create' && !this.locationFormOpened && this.selectedLocation && Object.keys(this.selectedLocation).length === 0;
    },
    editLocationVisible: function() {
      console.log("locationFormOpened: " + this.locationFormOpened)
      console.log("selectedLocation: " + JSON.stringify(this.selectedLocation))
      return this.type != 'create' && !this.locationFormOpened && this.selectedLocation && Object.keys(this.selectedLocation).length !== 0;
    },
    editShapeVisible: function() {
      console.log("editShapeVisible - active object: " + this.canvas.getActiveObject())
      return this.canvas.getActiveObject();
    },
    canvasControlsVisible: function() {
      console.log("canvasControlsVisible: " + this.locationFormOpened + ", " + this.locationFormType);
      return this.locationFormOpened && this.locationFormType !== 'view';
    }
  },
  beforeDestroy() {
    console.log("Removing window.onresize")
    window.onresize = null
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    pdfFileUploadChange(pdfFile) {
      const vm = this;
      if (pdfFile !== null) {
        const reader = new FileReader();
        reader.onload = async function () {
          const pdfData = new Uint8Array(reader.result);
          
          //TO-DO: should worker be imported differently?
          const pdfjsLib = await import('pdfjs-dist');
          const workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');
          pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;

          vm.$nextTick(async () => {
            try {
              const loadingTask = pdfjsLib.getDocument({ data: pdfData });
              const pdf = await loadingTask.promise;

              //We assume it is 1 page and we just take 1st page
              const pageNumber = 1;
              const page = await pdf.getPage(pageNumber);
              const scale = 1.5;

              const viewport = page.getViewport({ scale });
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.width = viewport.width;
              canvas.height = viewport.height;

              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };

              await page.render(renderContext).promise;

              const imageDataUrl = canvas.toDataURL('image/png');
              
              vm.img.src = imageDataUrl;
            } catch (error) {
              console.error('Error loading PDF:', error);
            }
          });
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
        reader.readAsArrayBuffer(pdfFile);
      }
    },

    // file upload
    fileUploadChange(file) {
      const vm = this;
      const reader = new FileReader();
      if (file !== null) {
        if (file.type.includes('image')) {
          // Handle image file upload
          reader.readAsDataURL(file);
          reader.onload = function () {
            vm.img.src = reader.result;
          };
        } else if (file.type === 'application/pdf') {
          vm.pdfFileUploadChange(file);
        }
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    },
    //cropper
    flip(x, y) {
      if (this.$refs.cropper.imageTransforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y);
      } else {
        this.$refs.cropper.flip(x, y);
      }
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    maximize() {
      const { cropper } = this.$refs;
      const center = {
        left: cropper.coordinates.left + cropper.coordinates.width / 2,
        top: cropper.coordinates.top + cropper.coordinates.height / 2,
      };
      cropper.setCoordinates([
        ({ coordinates, imageSize }) => ({
          width: imageSize.width,
          height: imageSize.height,
        }),
        ({ coordinates, imageSize }) => ({
          left: center.left - coordinates.width / 2,
          top: center.top - coordinates.height / 2,
        }),
      ]);
    },
    defaultCropperSize({imageSize, visibleArea}){
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },
    // fabric
    setupCanvas() {
      console.log("MapViewCreateEdit: setupCanvas")
      if(this.canvas !== null){
        console.log("dispose");
        this.canvas.dispose();
      }

      this.canvas = new fabric.Canvas('floor-map-canvas', { selection: false })
      document.getElementById("floor-map-canvas").fabric = this.canvas;

      this.canvas.hoverCursor = 'default'
      this.canvas.setHeight(800);
      this.canvas.setWidth(800);

      let vm = this;

      this.canvas.on('mouse:down', function(opt) {
        var evt = opt.e;
        //alt key pressed so that image is not dragged when dragging a point
        if (evt.altKey === true) {
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = evt.clientX;
          this.lastPosY = evt.clientY;
        }
      });
      this.canvas.on('mouse:move', function(opt) {
        if (!vm.isLocked && this.isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
      });
      this.canvas.on('mouse:up', function(opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
      });

      this.canvas.on('mouse:wheel', function (opt) {
        if(!this.isLocked) {
          var delta = opt.e.deltaY;
          var zoom = this.canvas.getZoom();
          zoom *= 0.999 ** delta;
          if (zoom > 10) zoom = 10;
          if (zoom < 0.5) zoom = 0.5;
          this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
          opt.e.preventDefault();
          opt.e.stopPropagation(); 
        }
      }.bind(this));

      // add grid
      this.gridGroup = new fabric.Group([ ], { visible: false });

      const grid = 10;
      const width = 1000;
      for (let i = 0; i < (width / grid); i++) {
        const line1 = new fabric.Line([i * grid, 0, i * grid, width], {stroke: '#eeeeee', selectable: false, visible: false});
        const line2 = new fabric.Line([0, i * grid, width, i * grid], {stroke: '#eeeeee', selectable: false, visible: false});
        this.canvas.add(line1)
        this.canvas.add(line2)
        this.gridGroup.addWithUpdate(line1);
        this.gridGroup.addWithUpdate(line2);
      }

      // add floor map image
      fabric.Image.fromURL("data:text/plain;base64," + vm.map.image, function (img) {
        img.set({
          left: 0,
          top: 0,
          opacity: 1,
          selectable: false,
        });

        vm.imgRatio = img.width / img.height;

        if(img.width  > img.height) {
          img.scaleToWidth(800, false);
        } else {
          img.scaleToHeight(800, false);
        }

        vm.canvas.add(img);
        vm.canvas.sendToBack(img);

        vm.resizeCanvas();
        window.onresize = vm.resizeCanvas;
      });

      //draw existing locations
      //console.log("map locations: " + this.map.locations)
      if (this.map.locations) {
        this.map.locations.forEach((location) => {
          let polygon = new fabric.Polygon(location.points, {
            fill: colors[location.id % colors.length],
            strokeWidth: 2,
            stroke: 'red',
            objectCaching: false,
            transparentCorners: false,
            cornerColor: 'blue',
            opacity: 0.7,
            id: location.id,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            hoverCursor: "pointer",
            hasBorders: false,
            perPixelTargetFind: true
          })
          this.canvas.add(polygon)
        });
      }

      if(!this.drawMode) {
        // add event listeners
        this.canvas.on('mouse:over', function(e) {
          if(e.target && e.target.selectable) {
            //e.target.set('fill', 'red');
            e.target.set('fill', 'red');
            vm.canvas.renderAll();
          }
        });

        this.canvas.on('mouse:out', function(e) {
          if(e.target && e.target.selectable) {
            //e.target.set('fill', '#b8c6ff');
            e.target.set('fill', colors[e.target.id % colors.length]);
            vm.canvas.renderAll();
          }
        });

        this.canvas.on('object:moving', function (option) {
          const object = option.target;
          object._calcDimensions();
          object.setCoords();
          vm.canvas.renderAll();
        });

        this.canvas.on('selection:created', function (option) {
          const object = option.target;
          let obj = vm.canvas.getActiveObject();
          console.log('selection:created: ' + obj.id)
          if(obj.id < 100000 ) {
            vm.openLocationViewForm(obj.id);
          }
        });

        this.canvas.on('selection:updated', function (option) {
          const object = option.target;
          let obj = vm.canvas.getActiveObject();
          console.log('selection:updated: ' + obj.id)
          if(obj.id < 100000 ) {
            vm.openLocationViewForm(obj.id);
          }
        });

        this.canvas.on('selection:cleared', function (option) {
          console.log("selection:cleared");
          const object = option.target;
          console.log("state: " + vm.drawMode + ", " + vm.activeShape);
          if (!vm.drawMode && vm.activeShape == null) {
            vm.locationFormOpened = false;
          }
          console.log("locationFormOpened: " + vm.locationFormOpened);
          vm.$store.dispatch('buildings/clearLocationDetails')
        });
      }

      this.canvas.on('mouse:down', function (options) {
        console.log("onMouseDown")
        console.log("activeObject: " + vm.canvas.getActiveObject());
        console.log("locationFormOpened: " + vm.locationFormOpened);
      });

      this.canvas.on('mouse:up', function (options) {
        console.log("onMouseUp")

        if (vm.drawMode) {
          console.log("onMouseUp: drawMode")

          if (options.target && vm.pointArray[0] && options.target.id === vm.pointArray[0].id) {
            console.log("onMouseUp: starting point")
            vm.generatePolygon(vm.pointArray);
          } else if (options.target && vm.pointArray) {   //TODO: what is this case?
            console.log("onMouseUp: check if click landed on existing point")
            for (let point of vm.pointArray) {
              if (options.target.id === point.id) {
                //vm.canvas.setActiveObject(vm.activeShape);
                //vm.editPolygon();
                console.log("onMouseUp: existing point")
                return;
              }
            }
            console.log("onMouseUp: empty space")
            if(!this.isDragging && options.e.altKey !== true) {
              vm.addPoint(options);
              vm.canvas.setActiveObject(vm.activeShape);
              vm.editPolygon();
            }
          }
        } else if (vm.activeShape && vm.activeShape.edit) {
          console.log("onMouseUp (AS)")
          console.log("corner: " + vm.activeShape.__corner);

          //check if click landed on some corner

          if (vm.activeShape.__corner) {
            console.log("onMouseUp (AS): check if corner is hit")
            let evt = options.e;
            if (evt.ctrlKey === true) {
              console.log("onMouseUp (AS): ctrl is pressed")
              console.log("onMouseUp (AS): deleting corner - " + vm.activeShape.__corner + ", " + Number(vm.activeShape.__corner));
              console.log("onMouseUp (AS): controls: " + JSON.stringify(vm.activeShape.controls))
              console.log("onMouseUp (AS): points: " + JSON.stringify(vm.activeShape.get('points')));

              const pointIndex = vm.activeShape.controls[vm.activeShape.__corner].pointIndex;
              vm.activeShape.points.splice(pointIndex, 1);
              delete vm.activeShape.controls[vm.activeShape.__corner];
              vm.canvas.requestRenderAll();
            } else {
              console.log("onMouseUp (AS): clicked on existing point");
              return;
            }
          } else {
            if(!this.isDragging && options.e.altKey !== true) {
              console.log("onMouseUp: adding point to poly");
              const pos = vm.canvas.getPointer(options.e);
              const points = vm.activeShape.get('points');
              points.push({
                x: pos.x,
                y: pos.y
              });
              vm.activeShape.set({points});
            }
          }

          vm.canvas.setActiveObject(vm.activeShape);
          vm.editPolygon();
        }
      });

      this.canvas.on('mouse:move', function onMouseMove(options) {
        //console.log("onMouseMove")
        if (vm.drawMode) {
          // dynamically render new polygon while adding points
          //if (vm.activeLine && vm.activeLine.class === 'line') {
          if (vm.activeLine) {
            const pointer = vm.canvas.getPointer(options.e);
            vm.activeLine.set({
              x2: pointer.x,
              y2: pointer.y
            });
            const points = vm.activeShape.get('points');
            points[vm.pointArray.length] = {
              x: pointer.x,
              y: pointer.y,
            };
          }
          vm.canvas.renderAll();
        }
      });
    },

    resizeCanvas() {
      console.debug("resizeCanvas");
      let canvas = document.getElementById("floor-map-canvas").fabric;
      const outerCanvasContainer = document.getElementById('fabric-canvas-wrapper');

      const containerWidth = outerCanvasContainer.clientWidth;
      const scale          = containerWidth / canvas.getWidth();
      const zoom           = canvas.getZoom() * scale;
      canvas.setDimensions({width: containerWidth, height: containerWidth / (this.imgRatio > 1 ? this.imgRatio : 1) });
      canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    },

    editPolygon() {
      console.log("editPolygon")

      // find activeObject
      let activeObject = this.canvas.getActiveObject();
      console.log("activeObject: " + activeObject)

      if (!activeObject) {
        return;
      }

      activeObject.edit = true;
      activeObject.objectCaching = false;

      this.activeShape = activeObject;

      // add custom controls to polygon
      const lastControl = activeObject.points.length - 1;
      activeObject.cornerStyle = 'circle';
      activeObject.cornerColor = "rgb(255,20,147)";
      activeObject.transparentCorners = false;
      activeObject.hoverCursor = 'pointer';
      activeObject.controls = activeObject.points.reduce((acc, point, index) => {
        acc['p' + index] = new fabric.Control({
          positionHandler: polygonPositionHandler,
          actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
          actionName: 'modifyPolygon',
          pointIndex: index,
          cursorStyle:'move'
        });
        return acc;
      }, {});

      activeObject.hasBorders = false;

      activeObject.lockMovementX = true;
      activeObject.lockMovementY = true;

      this.canvas.requestRenderAll();
    },

    resizePolygon() {
      //TODO fix activeObject selection
      let activeObject = this.canvas.getActiveObject();
      if (!activeObject) {
        activeObject = this.canvas.getObjects()[0];
        this.canvas.setActiveObject(activeObject);
      }

      activeObject.edit = false;
      activeObject.objectCaching = false;
      activeObject.controls = fabric.Object.prototype.controls;
      activeObject.cornerStyle = 'rect';
      activeObject.hasBorders = true;

      activeObject.lockMovementX = false;
      activeObject.lockMovementY = false;

      this.canvas.requestRenderAll();
    },

    fetchPoints(){
      console.log("fetchPoints")
      if(this.activeShape) {
        return this.activeShape.get('points')
      } else {
        // find activeObject
        let activeObject = this.canvas.getActiveObject()
        console.log("activeObject: " + activeObject)
        if (!activeObject) {
          return null
        }
        return activeObject.get('points')
      }
    },

    enableDrawPolygon() {
      // start draw mode
      this.drawMode = true;
    },
    
    disableDrawPolygon() {
      // stop draw mode
      this.activeLine = null;
      this.activeShape = null;
      this.lineArray = [];
      this.pointArray = [];
      this.drawMode = false;
    },

    toggleDrawPolygon() {
      if (this.drawMode) {
        // stop draw mode
        this.activeLine = null;
        this.activeShape = null;
        this.lineArray = [];
        this.pointArray = [];
        this.drawMode = false;
      } else {
        // start draw mode
        this.drawMode = true;
      }
    },

    addPoint(options) {
      console.log("add point")

      const pointOption = {
        id: new Date().getTime(),
        radius: 5,
        fill: this.pointArray.length === 0 ? 'red' : '#ffffff',
        stroke: '#333333',
        strokeWidth: 0.5,
        left: (options.e.layerX  - this.canvas.viewportTransform[4]) / this.canvas.getZoom(),
        top: (options.e.layerY - this.canvas.viewportTransform[5]) / this.canvas.getZoom(),
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
        objectCaching: false,
      };
      const point = new fabric.Circle(pointOption);

      const linePoints = [
        (options.e.layerX  - this.canvas.viewportTransform[4]) / this.canvas.getZoom(),
        (options.e.layerY - this.canvas.viewportTransform[5]) / this.canvas.getZoom(),
        (options.e.layerX  - this.canvas.viewportTransform[4]) / this.canvas.getZoom(),
        (options.e.layerY - this.canvas.viewportTransform[5]) / this.canvas.getZoom(),
      ];
      const lineOption = {
        strokeWidth: 2,
        fill: '#999999',
        stroke: '#999999',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      };
      const line = new fabric.Line(linePoints, lineOption);
      //line.class = 'line';

      if (this.activeShape) {
        //add point to existing polygon
        const pos = this.canvas.getPointer(options.e);
        const points = this.activeShape.get('points');
        points.push({
          x: pos.x,
          y: pos.y
        });
        this.activeShape.set({points});
        this.activeShape.edit;
      } else {
        //start new polygon
        const polyPoint = [{
          x: (options.e.layerX  - this.canvas.viewportTransform[4]) / this.canvas.getZoom(),
          y: (options.e.layerY - this.canvas.viewportTransform[5]) / this.canvas.getZoom(),
        }, ];
        const polygon = new fabric.Polygon(polyPoint, {
          stroke: '#333333',
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false
        });
        this.activeShape = polygon;
        this.canvas.add(polygon);
      }

      this.activeLine = line;
      this.pointArray.push(point);
      this.lineArray.push(line);

      this.canvas.add(line);
      this.canvas.add(point);
    },

    generatePolygon() {
      console.log("generatePolygon")

      const points = [];
      // collect points and remove them from canvas
      for (const point of this.pointArray) {
        points.push({
          x: point.left,
          y: point.top,
        });
        this.canvas.remove(point);
      }

      // remove lines from canvas
      for (const line of this.lineArray) {
        this.canvas.remove(line);
      }

      // remove selected Shape and Line
      this.canvas.remove(this.activeShape).remove(this.activeLine);

      // create polygon from collected points
      const polygon = new fabric.Polygon(points, {
        id: new Date().getTime(),
        stroke: 'red',
        fill: '#f00',
        opacity: 0.5,
        objectCaching: false,
        moveable: false,
        selectable: true
      });
      this.canvas.add(polygon);
      this.canvas.setActiveObject(polygon);
      console.log("activeObject: " + this.canvas.getActiveObject());
      //this.toggleDrawPolygon();
      this.disableDrawPolygon();
      this.editPolygon(this.activeShape);
    },

    toggleGrid(){
        console.log('visible: ' + this.gridGroup.get('visible'));
        this.gridGroup.forEachObject((item) => { item.set({visible: !this.gridGroup.get('visible')})});
        this.gridGroup.set({visible: !this.gridGroup.get('visible')});
        this.canvas.renderAll()
    },
    // map form functions
    reset() {
      if(this.map.id){
        this.form.id = this.map.id;
        this.form.name = this.map.name;
        this.form.image = this.map.image
        this.img.src = ''
      } else {
        this.form.name = ''
        this.form.image = null
        this.img.src = ''
      }
      this.$refs.form.reset()
    },

    cancel(){
      this.reset()
      this.type = this.initialType
      if(this.cancelCallback){
        this.cancelCallback()
      }
    },

    blockMap(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.map.confirm.block', { '0': self.form.name }),
        callbackYes: function(){
          Vue.axios.put('/admin/maps/' + self.form.id + '/block')
              .then(response => {
                self.reset()
                self.type = self.initialType
                self.$toastr.i(i18n.t('orgAdmin.map.info.blocked'))
                if (self.successCallback) {
                  self.successCallback()
                }
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },

    unblockMap(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.map.confirm.unblock', { '0': self.form.name }),
        callbackYes: function(){
          Vue.axios.put('/admin/maps/' + self.form.id + '/unblock')
              .then(response => {
                self.reset()
                self.type = self.initialType
                self.$toastr.i(i18n.t('orgAdmin.map.info.unblocked'))
                if (self.successCallback) {
                  self.successCallback()
                }
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },

    submit() {
      this.error = ""
      this.info = ""
      let vm = this

      if(this.type=="create") {

        this.form.building = this.map.building;

        const result = this.$refs.cropper.getResult();

        if(result && result.canvas) {

          result.canvas.toBlob(function (blob) {

            let formData = new FormData();
            formData.append("map", new Blob([JSON.stringify(vm.form)], {type: "application/json"}));
            formData.append('floorPlanImage', blob, 'floor-plan-image');

            Vue.axios.post('/admin/maps', formData,
                {
                  headers: {'Content-Type': 'multipart/form-data'}
            }).then(response => {

              vm.reset()
              vm.type = vm.initialType
              vm.$toastr.i(i18n.t('orgAdmin.map.info.created'))

              if (vm.successCallback) {
                vm.successCallback()
              }

            }).catch(error => {
              if (error.response.status === 422) {
                vm.$refs.form.setErrors(error.response.data)
              } else {
                commons.processRestError(error)
              }
            })
          })
        }
      } else if(this.type=="edit") {

        this.form.building = this.map.building;

        const result = this.$refs.cropper.getResult();

        if(result && result.canvas) {

          result.canvas.toBlob(function (blob) {

            let formData = new FormData();
            formData.append("map", new Blob([JSON.stringify(vm.form)], {type: "application/json"}));
            formData.append('floorPlanImage', blob, 'floor-plan-image');

            Vue.axios.put('/admin/maps', formData,
                {
                  headers: {'Content-Type': 'multipart/form-data'}
                }).then(response => {

              vm.reset()
              vm.type = vm.initialType
              vm.$toastr.i(i18n.t('orgAdmin.map.info.saved'))

              if (vm.successCallback) {
                vm.successCallback()
              }

            }).catch(error => {
              if (error.response.status === 422) {
                vm.$refs.form.setErrors(error.response.data)
              } else {
                commons.processRestError(error)
              }
            })
          })

        } else {

          // if image is not set just update name
          let formData = new FormData();
          formData.append("map", new Blob([JSON.stringify(vm.form)], {type: "application/json"}));

          Vue.axios.put('/admin/maps', formData,
              {
                headers: {'Content-Type': 'multipart/form-data'}
              }).then(response => {

            vm.reset()
            vm.type = vm.initialType

            if (vm.successCallback) {
              vm.successCallback()
            }

          }).catch(error => {
            if (error.response.status === 422) {
              vm.$refs.form.setErrors(error.response.data)
            } else {
              commons.processRestError(error)
            }
          })
        }
      }
    },
    // location
    openLocationAddForm() {
      this.locationFormType = 'create'
      this.locationFormOpened = true
      this.enableDrawPolygon()
      this.setupCanvas()
    },

    openLocationViewForm(locationId) {
      this.$store.dispatch('buildings/fetchLocationDetails', { locationId: locationId, organisationId: this.map.building.organisation.id });
      this.locationFormType = 'view'
      this.locationFormOpened = true
      this.location = this.selectedLocation
    },

    enterLocationEditMode() {
      this.locationFormType = 'edit'
    },

    editLocationSuccessCallback(){
      this.locationFormOpened = false
      this.activeShape = null;
      this.canvas.discardActiveObject().renderAll()
      this.$store.dispatch('buildings/fetchMapDetails', this.$route.params.id)
    },

    editLocationCancelCallback() {
      this.locationFormOpened = false
      this.locationFormType = 'view'
      this.disableDrawPolygon();
      this.activeShape = null;
      this.canvas.discardActiveObject().renderAll();
      this.setupCanvas();
    },

    selectLocationFromListCallback(locationId) {
      this.openLocationViewForm(locationId)
      this.canvas._objects.forEach(object => {
        if(object.id == locationId){
          this.canvas.setActiveObject(object)
          this.canvas.renderAll()
          return
        }
      })
    },

    zoomIn() {
      const zoom = this.canvas.getZoom();
      const newZoom = zoom * 1.1;
      this.zoomCanvas(newZoom);
    },

    zoomOut() {
      const zoom = this.canvas.getZoom();
      const newZoom = zoom / 1.1;
      this.zoomCanvas(newZoom);
    },

    zoomCanvas(newZoom) {
      if(!this.isLocked) {
        if (newZoom < 0.5) newZoom = 0.5;
        if (newZoom > 10) newZoom = 10;

        const center = new fabric.Point(this.canvas.width / 2, this.canvas.height / 2);
        this.canvas.zoomToPoint(center, newZoom);
        this.canvas.renderAll();
      }
    },

    toggleLock() {
      this.isLocked = !this.isLocked;
    },

    toggleInfoPopup() {
      this.isPopupVisible = !this.isPopupVisible;
    },

  },
}
</script>

<style scoped>
#fabric-canvas-wrapper {
  min-height: 200px;
  min-width: 300px;
}
#button-container {
  padding-bottom: 8px;
  display: flex;
  justify-content: flex-end;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 15px;
  text-align: left;
}

.card p {
  margin-left: 20px;
  margin-bottom: 0;
}

.card-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 5px;
}

.card-title i {
  margin-right: 10px;
}
</style>