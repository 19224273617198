<template>
  <div>

    <div class="form-group row m-b-sm" v-if="locationsWithSelectedProfile.length != 0">
      <div class="col-lg-6">
        <div class="input-group">
          <span class="input-group-prepend">
            <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length == 0" @click="clearFilter"> {{
                $t('common.actions.clear')
              }}</b-button>
          </span>
          <b-form-input type="text"
                v-model="table.filter"
                debounce="300"
                max="50"
                :placeholder="$t('enterForSearch')"
                class="form-control form-control-sm"/>
        </div>
      </div>
      <div class="col-lg-2 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="locationsWithSelectedProfile.length"></row-count>
      </div>
      <div class="col-lg-4 text-right" v-if="locationsWithSelectedProfile.length && locationsWithSelectedProfile.length > table.size">
        <b-pagination v-if="locationsWithSelectedProfile.length" size="sm" class="m-t-xs" align="right"
                      :total-rows="locationsWithSelectedProfile.length"
                      v-model="table.page"
                      :per-page="table.size"></b-pagination>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-table responsive hover
                 v-if="locationsWithSelectedProfile.length != 0"
                 class="text-nowrap"
                 :filter="table.filter"
                 :fields="table.fields"
                 :current-page="table.page"
                 :per-page="table.size"
                 :items="locationsWithSelectedProfile"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc">
          <template #cell(name)="data">
            <b-link :to="{ name: 'mapDetails', params: { id: data.item.map.id } }">{{ data.value }}</b-link>
          </template>
          <template slot="cell(schedulerProfile.name)" slot-scope="data">
            <b-link :to="{ path: routePrefix + '/scheduler-profiles/' + data.item.schedulerProfile.id }">
              {{ data.value }}
            </b-link>
          </template>
        </b-table>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import RowCount from '@/components/common/RowCount';
import Vue from "vue";
import * as commons from "@/commons";

export default {
  components: {
    RowCount
  },
  props: {
    locationsWithSelectedProfile: {
      type: Array
    },
    routePrefix: {
      default: ''
    }
  },

  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: 'name',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50}
        ],
        fields: [
          {
            key: 'name',
            label: this.$i18n.t('orgAdmin.locationsWithSchedulerProfiles.attributes.locationName'),
            sortable: true
          },
          {
            key: 'schedulerProfile.name',
            label: this.$i18n.t('orgAdmin.locationsWithSchedulerProfiles.attributes.profileName'),
            sortable: true
          }
        ]
      }
    }
  },
  mounted() {
    console.log('LocationsWithSchedulerProfileList created()')
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    }
  }
}
</script>
