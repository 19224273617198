import Vue from "vue";
import * as commons from '@/commons'

const getDefaultState = () => {
    return {
        schedulerProfiles: [],
        locationsWithSelectedSchedulerProfile: [],
        schedulerProfileDetails: {
            entity: {}
        }
    }

}

// initial state
const state = getDefaultState()

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('schedulers/appInit')
    },

    fetchOrganisationSchedulerProfiles({commit, state, dispatch, rootState}, id) {

        console.log('schedulers/fetchOrganisationScheduleProfiles')

        Vue.axios.get('/admin/organisations/'+ id +'/scheduler-profiles').then(response => {
            commit('SAVE_SCHEDULER_PROFILES', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchOrganisationLocationsWithSelectedProfile({commit, state, dispatch, rootState}, id) {

        console.log('schedulers/fetchOrganisationLocationsWithSelectedProfile')

        Vue.axios.get('/admin/organisations/' + id + '/locations-with-selected-scheduler-profile').then(response => {
            commit('SAVE_LOCATIONS_WITH_SELECTED_PROFILE', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchBuildingLocationsWithSelectedProfile({commit, state, dispatch, rootState}, id) {

        console.log('schedulers/fetchBuildingLocationsWithSelectedProfile')

        Vue.axios.get('/admin/buildings/' + id + '/locations-with-selected-scheduler-profile').then(response => {
            commit('SAVE_LOCATIONS_WITH_SELECTED_PROFILE', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchScheduleProfileDetails({commit, state, dispatch, rootState}, id) {

        console.log('schedulers/fetchScheduleProfileDetails')

        //load map
        Vue.axios.get('/admin/scheduler-profiles/' + id).then(response => {
            commit('SAVE_SCHEDULER_PROFILE_DETAILS_ENTITY', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    }
}


// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['SAVE_ALL_ACTIVE_TAB']: (state, data) => {
        state.allActiveTab = data
    },

    ['SAVE_SCHEDULER_PROFILES']: (state, data) => {
        state.schedulerProfiles = data
    },

    ['SAVE_LOCATIONS_WITH_SELECTED_PROFILE']: (state, data) => {
        state.locationsWithSelectedSchedulerProfile = data
    },

    ['SAVE_SCHEDULER_PROFILE_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.schedulerProfileDetails, 'entity', data)
    },
}

export default {
    namespaced: true, state, actions, mutations
}