<template>
  <div>
    <div class="row" v-if="Object.keys(chartsMap).length > 0">
      <div :class="graphConfig.fullSize ? 'col-xxxl-12' : 'col-xxxl-6'" class="col-xxl-12 p-xl-2 p-md-0"
        v-for="(chart, index) in Object.values(chartsMap)" :key="`chart-${chart.sensorDef.type}-${index}`">
        <highcharts :options="chart" :ref="chart.sensorDef.type"></highcharts>
      </div>
    </div>
    <div v-else class="text-center mt-5 mb-5">
      <div class="font-bold">
        <template
          v-if="sensorValuesPerAttributesPerLocation && sensorValuesPerAttributesPerLocation.size > 0 && graphConfig.selectedLocations.length === 0">
          {{ $t('analytics.graphView.placeholderSelectRooms') }}
        </template>
        <template v-else>
          {{ $t('analytics.graphView.placeholder') }}
        </template>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex"
import moment from "moment-timezone"

let chartOptionsBase = {
  credits: false,
  title: {
    useHTML: true,
    style: {
      fontSize: '14px',
      fontStyle: 'bold'
    }
  },
  chart: {
    zoomType: 'x',
    type: 'heatmap',
    height: '550px',
    spacingTop: 40,
    style: {
      fontFamily: 'open sans, Helvetica Neue, Helvetica, Arial, sans-serif',
    }
  },
  xAxis: {
    type: 'datetime',
    labels: {
      autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
      _style: {
        color: '#333',
        fontSize: '12px'
      }
    },
    minPadding: 0,
    maxPadding: 0,
    tickPixelInterval: 50
  },
  yAxis: {
    maxPadding: 0.1,
    allowDecimals: false,
    title: {
      text: ''
    }
  },
  legend: {
    enabled: true
  },
  series: []
}

export default {
  data() {
    return {
      chartsMap: {},
      loadingStatus: {},
    }
  },
  created() {
    this.loadCharts();
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedMap: state => state.hourlyHeatmaps.selectedMap,
      selectedBuilding: state => state.hourlyHeatmaps.selectedMap.building,
      graphConfig: state => state.hourlyHeatmaps.graphConfig,
      selectedLocations: state => state.hourlyHeatmaps.graphConfig.selectedLocations,
      selectedSensorAttributes: state => state.hourlyHeatmaps.graphConfig.selectedSensorAttributes,
      sensorValuesPerAttributesPerLocation: state => state.hourlyHeatmaps.sensorValuesPerAttributesPerLocation
    }),
  },
  watch: {
    graphConfig(newVal, oldVal) {
      console.log('graphConfig changed: ', JSON.stringify(newVal), ' | was: ', oldVal)

      let shouldUpdateCharts = false;

      if (newVal.fullSize !== oldVal.fullSize) {
        shouldUpdateCharts = true;
      }

      if (newVal.thresholdLegend !== oldVal.thresholdLegend) {
        shouldUpdateCharts = true;
      }

      if (newVal.customCO2Thresholds !== oldVal.customCO2Thresholds) {
        shouldUpdateCharts = true;
      }

      if (shouldUpdateCharts) {
        if (this.sensorValuesPerAttributesPerLocation && this.sensorValuesPerAttributesPerLocation.size > 0) {
          let vm = this;
          this.sensorValuesPerAttributesPerLocation.forEach((data, key) => {
            this.$nextTick(() => {
              let comp = vm.$refs[key];
              if (comp) {
                setTimeout(() => {
                  window.dispatchEvent(new Event('resize'));
                }, 1000);
              }
            });
          });
          this.loadCharts();
        }
      }
    },

    sensorValuesPerAttributesPerLocation: function (newVal, oldVal) {
      console.log('sensorValuesPerAttributesPerLocation changed: ', newVal ? "size: " + newVal.size : null, ' | was: ', oldVal)
      this.loadCharts();
    },

    selectedLocations(newLocations, oldLocations) {
      this.selectedSensorAttributes.forEach(attribute => {
        newLocations.forEach(locationId => {
          if (!oldLocations.includes(locationId)) {
            this.loadChartIfNeeded(attribute, locationId);
          }
        });
      });

      oldLocations.forEach(locationId => {
        if (!newLocations.includes(locationId)) {
          this.removeChartsForLocation(locationId);
        }
      });
    },

    selectedSensorAttributes(newAttributes, oldAttributes) {
      this.selectedLocations.forEach(locationId => {
        newAttributes.forEach(attribute => {
          if (!oldAttributes.includes(attribute)) {
            this.loadChartIfNeeded(attribute, locationId);
          }
        });
      });

      oldAttributes.forEach(attribute => {
        if (!newAttributes.includes(attribute)) {
          this.removeChartsForAttribute(attribute);
        }
      });
    },
  },
  methods: {
    async loadChartIfNeeded(attribute, locationId) {
      const chartKey = `${attribute}-${locationId}`;
      // Check if the chart is already loaded or being loaded
      if (!this.chartsMap[chartKey] && !this.loadingStatus[chartKey]) {
        this.loadingStatus[chartKey] = true; // Mark as loading

        const entry = this.sensorValuesPerAttributesPerLocation.get(attribute);
        if (entry && entry.has(locationId)) {
          const chartDef = await this.processChartData(attribute, entry.get(locationId), locationId);
          this.$set(this.chartsMap, chartKey, chartDef);
        }

        this.loadingStatus[chartKey] = false; // Mark as loaded
      }
    },

    async loadCharts() {
      const newChartsMap = {};
      if (this.selectedLocations.length > 0 && this.selectedSensorAttributes.length > 0) {
        for (const attribute of this.selectedSensorAttributes) {
          for (const locationId of this.selectedLocations) {
            const chartKey = `${attribute}-${locationId}`;
            const entry = this.sensorValuesPerAttributesPerLocation.get(attribute);
            if (entry && entry.has(locationId)) {
              newChartsMap[chartKey] = await this.processChartData(attribute, entry.get(locationId), locationId);
            }
          }
        }
      }
      this.chartsMap = newChartsMap;
      this.updateHighcharts(); // Call this to update all charts
    },

    async processChartData(attribute, sensorData, locationId) {
      let chartDef = JSON.parse(JSON.stringify(chartOptionsBase));

      chartDef.time = {}
      chartDef.time.getTimezoneOffset = function (timestamp) {
        return -moment.tz(timestamp, this.selectedBuilding.timeZone).utcOffset();
      }

      chartDef.sensorDef = this.$i18n.t('enums.sensorType.' + attribute);
      chartDef.sensorDef.type = attribute;
      chartDef.title.text = '<i class="' + chartDef.sensorDef.icon + '  mr-2"></i>' + chartDef.sensorDef.name + ' - ' + this.selectedMap.locations.find(location => location.id == locationId).name;

      chartDef.xAxis = {
        categories: Array.from({ length: 31 }, (_, i) => i + 1),
        title: { text: this.$t('analytics.graphView.dayOfMonth') }
      };
      chartDef.yAxis = {
        reversed: true,
        categories: Array.from({ length: 24 }, (_, i) => ('0' + i).slice(-2) + ':00'),
        title: { text: this.$t('analytics.graphView.hourOfDay') }
      };

      let seriesData = [];

      sensorData.forEach(dataPoint => {
        var date = moment(dataPoint.time).tz(this.selectedBuilding.timeZone).toDate();
        var dayOfMonth = date.getDate();
        var hour = date.getHours();
        let value = this.formatSensorValue(attribute, dataPoint.value);

        seriesData.push([dayOfMonth - 1, hour, value]);
      });

      chartDef.series = [{
        name: this.selectedMap.locations.find(location => location.id == locationId).name,
        data: seriesData,
        borderWidth: 1
      }];

      this.setColorAxis(chartDef, attribute);

      return chartDef;
    },

    updateHighcharts() {
      this.$nextTick(() => {
        Object.keys(this.$refs).forEach(refKey => {
          const chartComponents = this.$refs[refKey];
          if (Array.isArray(chartComponents)) {
            chartComponents.forEach(comp => {
              if (comp && comp.chart) {
                const chartData = this.chartsMap[refKey];
                if (chartData) {
                  comp.chart.update({
                    series: chartData.series
                  }, true);
                }
              }
            });
          }
        });
      });
    },

    formatSensorValue(attribute, value) {
      if (typeof value === 'string') {
        value = Number(value);
      }

      if (!isNaN(value) && value != null) {
        if (attribute === 'temperature') {
          return parseFloat(value.toFixed(1));
        } else {
          return Math.round(value);
        }
      } else {
        // Handle case where value is not a valid number
        return null; // what should be default?
      }
    },

    setColorAxis(chartDef, attribute) {

      const temperatureColors = ['#025286', '#1F6E95', '#3F8DA6', '#5BA8B4', '#9BC4C9', '#EDE4E3', '#EB9486', '#E64C36', '#CE392E', '#BA2828', '#A31621'];
      const temperatureThresholdColors = ['#025286', '#1F6E95', '#3F8DA6', '#5BA8B4', '#9BC4C9', '#EDE4E3', '#EB9486', '#E64C36', '#CE392E', '#BA2828', '#A31621'];

      const co2Colors = ['#2C6E49', '#8EA448', '#FFDB36', '#FFCA14', '#FAA90B', '#F07126', '#E14834', '#CD382E', '#B82727', '#A31621'];
      const co2ThresholdColors = ['#2C6E49', '#FFCA14', '#B82727'];

      const humidityColors = ['#025286', '#1F6E95', '#3F8DA6', '#5BA8B4', '#9BC4C9', '#EDE4E3', '#EB9486', '#E64C36', '#CE392E', '#BA2828', '#A31621'];
      const humidityThresholdColors = ['#025286', '#1F6E95', '#3F8DA6', '#5BA8B4', '#9BC4C9', '#EDE4E3', '#EB9486', '#E64C36', '#CE392E', '#BA2828', '#A31621'];

      const motionColors = ['#C8C8C8', '#FFCA14', '#025286'];
      const motionThresholdColors = ['#C8C8C8', '#FFCA14', '#025286'];
      
      if (attribute === 'temperature') {
        chartDef.tooltip ={
          pointFormat: "{point.y}:00: <b>{point.value} ℃</b>" 
        };
        const temperatureStops = this.graphConfig.thresholdLegend ?
          temperatureThresholdColors.map((color, index, arr) => [index / (arr.length - 1), color]) :
          temperatureColors.map((color, index, arr) => [index / (arr.length - 1), color]);
        chartDef.colorAxis = { min: 10, max: 28, stops: temperatureStops };
      } else if (attribute === 'co2') {
        chartDef.tooltip ={
          pointFormat: "{point.y}:00: <b>{point.value} ppm</b>" 
        };
        if (this.graphConfig.thresholdLegend) {
          chartDef.colorAxis = {
            dataClasses: [
              {
                from: 0,
                to: this.graphConfig.customCO2Thresholds.low - 1,
                color: co2ThresholdColors[0],
                name: `< ${this.graphConfig.customCO2Thresholds.low}`
              },
              {
                from: this.graphConfig.customCO2Thresholds.low,
                to: this.graphConfig.customCO2Thresholds.high,
                color: co2ThresholdColors[1],
                name: `${this.graphConfig.customCO2Thresholds.low} - ${this.graphConfig.customCO2Thresholds.high}`
              },
              {
                from: this.graphConfig.customCO2Thresholds.high,
                color: co2ThresholdColors[2],
                name: `> ${this.graphConfig.customCO2Thresholds.high}`
              }
            ]
          };
        } else {
          const co2Stops = co2Colors.map((color, index, arr) => [index / (arr.length - 1), color]);
          chartDef.colorAxis = { min: 400, max: 4000, stops: co2Stops };
        }
      } else if (attribute === 'humidity') {
        chartDef.tooltip ={
          pointFormat: "{point.y}:00: <b>{point.value}%</b>" 
        };
        const humidityStops = this.graphConfig.thresholdLegend ?
          humidityThresholdColors.map((color, index, arr) => [index / (arr.length - 1), color]) :
          humidityColors.map((color, index, arr) => [index / (arr.length - 1), color]);
        chartDef.colorAxis = { min: 0, max: 100, stops: humidityStops };
      } else if (attribute === 'motion') {
        chartDef.tooltip ={
          pointFormat: "{point.y}:00: <b>{point.value}</b>" 
        };
        chartDef.colorAxis = {
          dataClasses: [
            {
              from: 0,
              to: 0,
              color: motionThresholdColors[0],
              name: this.$t('analytics.heatmap.motion.unoccupied')
            },
            {
              from: 0.1,
              to: 4,
              color: motionThresholdColors[1],
              name: this.$t('analytics.heatmap.motion.lowOccupancy')
            },
            {
              from: 4,
              color: motionThresholdColors[2],
              name: this.$t('analytics.heatmap.motion.highOccupancy')
            }
          ]
        };
      } else {
        chartDef.colorAxis = { min: 0, minColor: '#FFFFFF', maxColor: '#000000' };
      }
    },

    removeChartsForLocation(locationId) {
      Object.keys(this.chartsMap).forEach(chartKey => {
        if (chartKey.endsWith(`-${locationId}`)) {
          this.$delete(this.chartsMap, chartKey);
        }
      });
    },

    removeChartsForAttribute(attribute) {
      Object.keys(this.chartsMap).forEach(chartKey => {
        if (chartKey.startsWith(`${attribute}-`)) {
          this.$delete(this.chartsMap, chartKey);
        }
      });
    },
  },
}
</script>
