<template>
  <div>
    <div>
      <l-map id="leafletMap"
             ref="map"
             @ready="onLeafletReady"
             @dblclick="onMapDblClick"
             :zoom="zoom"
             :maxZoom="maxZoom"
             :minZoom="minZoom"
             :center="[ position.lat || userLocation.lat || defaultLocation.lat, position.lng || userLocation.lng || defaultLocation.lng ]">
        <template v-if="leafletReady">
          <l-tile-layer :url="url"></l-tile-layer>
          <l-geosearch :options="geoSearchOptions"></l-geosearch>
          <l-marker
            v-if="position.lat && position.lng"
            visible
            draggable
            :lat-lng.sync="position"
            @dragend="dragEnd">
            <l-tooltip :content="tooltipContent" :options="{ permanent: true }" />
            </l-marker>
        </template>
      </l-map>
    </div>
    <div class="alert alert-info mt-3" v-if="info">{{ info }}</div>
    <div class="alert alert-danger mt-3" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet"
import { OpenStreetMapProvider, EsriProvider, AlgoliaProvider } from "leaflet-geosearch"
import LGeosearch from "vue2-leaflet-geosearch"
import {getConfigParamValue} from "@/commons";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LGeosearch
  },
  props: {
    initialCoordinates: {
      type: Object
    },
    initialAddress: {
      type: Object
    },
    selectCallback: {
      type: Function
    },
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: 8.9806,
        lng: 38.7578
      })
    }
  },
  data() {
    return {
      info: null,
      error: null,
      leafletReady: false,
      mapObject: null,
      maxZoom: 18,
      minZoom: 4,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 15,
      position: {},
      userLocation: {},
      address: "",
      timeZone: "",
      tooltipContent: "",
      dragging: false,
      geoSearchOptions: {
        provider: new OpenStreetMapProvider(),
        // provider: new AlgoliaProvider(),
        showMarker: false,
        autoClose: true
      }
    }
  },
  mounted() {

    let initialInfoSet = false

    if(this.initialCoordinates && this.initialCoordinates.lat && this.initialCoordinates.lng) {
      this.position = this.initialCoordinates
      initialInfoSet = true
    }

    if(this.initialAddress && (this.initialAddress.address || this.initialAddress.city || this.initialAddress.zipCode || this.initialAddress.country)){
      this.updateTooltip({
        address: this.initialAddress.address,
        city: this.initialAddress.city,
        zipCode: this.initialAddress.zipCode,
        countryCode: this.address.country
      })
      if(!initialInfoSet){
        if(this.initialAddress.address) {
          this.zoom = 17
        } else if(this.initialAddress.city) {
          this.zoom = 12
        } else if(this.initialAddress.country) {
          this.zoom = 8
        }
      }
      initialInfoSet = true
    }

    if(!initialInfoSet) {
      this.getUserPosition()
    }
  },

  methods: {

    async onLeafletReady(_map) {
      await this.$nextTick()
      this.mapObject = this.$refs.map.mapObject
      this.mapObject.on("geosearch/showlocation", this.onSearch)
      this.leafletReady = true
    },

    onMapDblClick(value) {
      // place the marker on the clicked spot
      this.position = value.latlng
      this.positionChanged()
    },

    dragEnd(value){
      this.positionChanged()
    },

    onSearch(value) {
      this.position = { lat: value.location.x, lng: value.location.y }
      this.positionChanged()
    },

    async positionChanged(){

      this.error = null

      this.address = await this.getAddressByPosition()
      this.timeZone = await this.getTimeZoneByPosition()

      console.log('positionChanged: this.position ' + this.position + ', new address: ' + JSON.stringify(this.address) + ', timezone: ' + this.timeZone)

      if(this.address) {

        let addressData = {
          address: this.address.road,
          city: this.address.city ? this.address.city : (this.address.town ? this.address.town : this.address.village),
          zipCode: this.address.postcode,
          countryCode: this.address.country_code
        }

        this.updateTooltip(addressData)

        if(this.selectCallback){
          this.selectCallback({ position: this.position, address: addressData, timeZone: this.timeZone })
        }

      }
    },

    updateTooltip(data){

      this.tooltipContent = ''

      if(data.address) {
        this.tooltipContent += data.address + '<br/>'
      }
      if(data.city) {
        this.tooltipContent += data.city + '<br/>'
      }
      if(data.zipCode) {
        this.tooltipContent += data.zipCode + '<br/>'
      }
      if(data.countryCode) {
        this.tooltipContent += this.countryNameFromCode(data.countryCode.toUpperCase()) + '<br/>'
      }
      if(this.position){
        this.tooltipContent += `<strong>lat:</strong> ${this.position.lat}<br/><strong>lng:</strong> ${this.position.lng}` + '<br/>'
      }
      if(this.timeZone){
        this.tooltipContent += `<strong>Time zone:</strong> ${this.timeZone}`
      }
    },

    async getAddressByPosition() {
      let address = "Unresolved address"
      try {
        const { lat, lng } = this.position
        const result = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        )
        if (result.status === 200) {
          const body = await result.json()
          address = body.address
        }
      } catch (e) {
        console.error("Reverse Geocode Error -> ", e)
        this.error = "Get address for location using openstreetmaps failed error: " + e.message
      }
      return address
    },

    async getTimeZoneByPosition() {
      let timezone = ""
      try {
        const apiKey = this.getConfigParamValue('timezonedb.api-key', null)
        const { lat, lng } = this.position
        const result = await fetch(
            `https://api.timezonedb.com/v2.1/get-time-zone?key=${apiKey}&format=json&by=position&lat=${lat}&lng=${lng}`
        )
        if (result.status === 200) {
          const body = await result.json()
          timezone = body.zoneName
        }
      } catch (e) {
        console.error("Get timezone for location using timezonedb error -> ", e)
        this.error = "Get timezone for location using timezonedb error: " + e.message
      }
      return timezone
    },

    async getUserPosition() {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition(pos => {
          // set the user location
          this.userLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude
          };
        });
      }
    }

  }
}
</script>

<style scoped>
#leafletMap {
  width: 100%;
  height: 400px;
}
</style>

