import Vue from "vue";
import * as commons from '@/commons'

// default state
const getDefaultState = () => {
    return {
        integrations: [],
        integrationDetails: {
            entity: {}
        },
    }

}

// initial state
const state = getDefaultState()

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('integrations/appInit')
    },

    fetchIntegrations({commit, state, dispatch, rootState}, id) {

        console.log('integrations/fetchIntegrations')

        Vue.axios.get('/admin/organisations/' + id + '/integrations').then(response => {
            commit('SAVE_INTEGRATIONS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchIntegrationDetails({commit, state, dispatch, rootState}, id) {
        console.log('integrations/fetchIntegrationDetails')

        return new Promise((resolve, reject) => {
            Vue.axios.get('/admin/integrations/' + id).then(response => {
                commit('SAVE_INTEGRATION_DETAILS_ENTITY', response.data)
                resolve(response)
            }).catch(error => {
                commons.processRestError(error)
                reject(error)
            })
        })
    },

    activateIntegration({commit, state, dispatch, rootState}, id) {
        console.log('integrations/activateIntegration')
        return new Promise((resolve, reject) => {
            Vue.axios.post('/admin/integrations/' + id + '/activate').then(response => {
                console.log("response: " + response);
                if(response) {
                    commit('SAVE_INTEGRATION_DETAILS_ENTITY', response.data)
                }
                resolve(response)
            }).catch(error => {
                commons.processRestError(error)
                reject(error)
            })
        })
    }

}


// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['SAVE_ALL_ACTIVE_TAB']: (state, data) => {
        state.allActiveTab = data
    },

    ['SAVE_INTEGRATIONS']: (state, data) => {
        state.integrations =  data
    },

    ['SAVE_INTEGRATION_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.integrationDetails, 'entity', data)
    }
}

export default {
    namespaced: true, state, actions, mutations
}