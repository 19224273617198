<template>
  <div>
    <transition name="fade" appear>
      <div>
        <div class="row mt-5">
          <div class="col-12">
            <h4>{{ $t('feedback.feedbackConfig.dateRangeSelector.title') }}</h4>
            <div class="text-center pt-4">
              <div class="row">
                <div class="col-12">
                  <date-range-picker
                      ref="picker"
                      opens="left"
                      :locale-data="Object.assign({}, $t('dateRangePickerLabels'), { format: 'dd-mm-yyyy' })"
                      :maxDate="new Date()"
                      :autoApply="false"
                      :showDropdowns="true"
                      v-model="dateRange"
                      @update="selectedDateRangeChanged"
                  >
                    <template v-slot:input="picker">
                      <div style="max-width: 350px;">
                        {{ picker.startDate | formatDateByLocale }} - {{ picker.endDate | formatDateByLocale}}
                      </div>
                    </template>
                  </date-range-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="row justify-content-center">
              <!--button type="button" class="btn btn-sm btn-primary" @click.prevent="fetchData">{{ $t('feedback.feedbackConfig.actions.fetchData') }}</button-->
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapState} from "vuex";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    DateRangePicker
  },
  data() {
    return {
      info: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
    }
  },
  created(){
    console.log('FeedbackConfig $route.name: ' + this.$route.name)
    this.dateRange = this.feedbackConfig.dateRange
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      feedbackConfig: state => state.feedback.feedbackConfig
    }),
  },
  watch: {

  },
  methods: {
    selectedDateRangeChanged(){
      let now = new Date()
      this.dateRange.startDate.setHours(0, 0, 0, 0)
      if(this.dateRange.endDate.toDateString() !== now.toDateString()) {
        this.dateRange.endDate.setHours(23, 59, 59, 999)
      }else {
        this.dateRange.endDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds())
      }
      this.$store.dispatch('feedback/feedbackConfigDateRangeSelected', { dateRange: this.dateRange })
    },
    fetchData(){
      console.log('fetchData')
      this.$store.dispatch('feedback/fetchGraphData')
    }
  }
}
</script>
