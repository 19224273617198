<template>
  <div class="register register-user">
    <div class="middle-box text-center animated fadeInDown">
      <div>
        <div class="mb-4">
          <img :src="require('@/assets/custom/img/CLIMIFY.svg')" width="200px" />
        </div>

        <div v-if="!registered">
          <h3>{{ $t('registerUser.title')}}</h3>
          <p>{{ $t('registerUser.subtitle') }}</p>

          <validation-observer ref="form" v-slot="{ handleSubmit }">
            <form class="m-t text-left" role="form" @submit.prevent="handleSubmit(register)">

              <div class="alert alert-danger" v-if="error">
                {{ error }}
              </div>

              <div class="alert alert-info" v-if="info">
                {{ info }}
              </div>

              <div class="form-group">
                <validation-provider :name="$t('registerUser.firstName')" rules="required|max:50"
                  v-slot="{ errors, classes }">
                  <input type="text" v-model.trim="registerRequest.firstName" class="form-control" :class="classes"
                    :placeholder="$t('registerUser.firstName')">
                  <span>{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <validation-provider :name="$t('registerUser.lastName')" rules="required|max:50"
                  v-slot="{ errors, classes }">
                  <input type="text" class="form-control" v-model.trim="registerRequest.lastName" :class="classes"
                    :placeholder="$t('registerUser.lastName')">
                  <span>{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <validation-provider vid="email" :name="$t('registerUser.email')" rules="required|max:100|email"
                  v-slot="{ errors, classes }">
                  <input type="email" class="form-control" v-model.trim="registerRequest.email" :class="classes"
                    :placeholder="$t('registerUser.email')">
                  <span>{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <validation-provider vid="username" :name="$t('registerUser.username')"
                  rules="required|alpha_dash|min:3|max:50" v-slot="{ errors, classes }">
                  <input type="text" class="form-control" v-model="registerRequest.username"
                    @input="registerRequest.username = $event.target.value.toLowerCase().trim()" :class="classes"
                    :placeholder="$t('registerUser.username')">
                  <span>{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <validation-provider vid="registrationCode" :name="$t('registerUser.registrationCode')" rules="required"
                  v-slot="{ errors, classes }">
                  <input type="text" class="form-control" v-model.trim="registerRequest.registrationCode"
                    :class="classes" :placeholder="$t('registerUser.registrationCode')">
                  <span>{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <validation-provider :name="$t('registerUser.message')" rules="required|max:500"
                  v-slot="{ errors, classes }">
                  <textarea type="textarea" class="form-control" v-model.trim="registerRequest.message" :class="classes"
                    :placeholder="$t('registerUser.message')" />
                  <span>{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group text-center">
                <div class="checkbox i-checks">
                  <label style="display: flex; justify-content: center; align-items: center;">
                    <input type="checkbox" id="termsCheck" v-model="registerRequest.termsChecked"><i></i>
                    <a href="https://climify.com/tos" style="padding-left: 1em;" target="_blank">{{
                      $t('registerUser.termsAndPolicy') }}</a>
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <button type="button" class="btn btn-primary btn-outline btn-xs m-r-xs" :class="{active:enSelected}"
                  @click.prevent="setLang('en')">EN
                </button>
                <button type="button" class="btn btn-primary btn-outline btn-xs m-r-xs" :class="{active:daSelected}"
                  @click.prevent="setLang('da')">DA
                </button>
              </div>

              <button type="submit" class="btn btn-primary block full-width m-b">{{ $t('registerUser.sendRequest')
                }}</button>

            </form>
          </validation-observer>

          <p class="text-muted text-center"><small>{{ $t('registerUser.alreadyHaveAnAccount') }}</small></p>
          <router-link to="/indoor-climate" class="btn btn-sm btn-white btn-block">{{ $t('registerUser.login')
            }}</router-link>
          <p class="m-t"><small>{{ $t('credits') }}</small></p>

        </div>
        <div v-else>
          <p class="m-t-lg" v-html="$t('registerUser.success')"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from "vue";
import * as commons from "@/commons";
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      error: '',
      info: '',
      registered: false,
      registerRequest: {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        registrationCode: '',
        message: '',
        termsChecked: false,
        language: 'en'
      }
    }
  },
  mounted(){
    let vm = this
    $('#termsCheck').iCheck({
      checkboxClass: 'icheckbox_square-green',
    }).on('ifUnchecked', function(event){
      vm.registerRequest.termsChecked=false
    }).on('ifChecked', function(event){
      vm.registerRequest.termsChecked=true
    })
  },
  computed: {
    enSelected(){
      return this.$store.getters.getLang=='en'
    },
    daSelected(){
      return this.$store.getters.getLang=='da'
    },
    deSelected(){
      return this.$store.getters.getLang=='de'
    },
    itSelected(){
      return this.$store.getters.getLang=='it'
    },
    svSelected(){
      return this.$store.getters.getLang=='sv'
    }
  },
  methods: {
    register() {
      let vm = this;
      vm.error = "";
      vm.info = "";

      if(!vm.registerRequest.termsChecked){
        vm.info = this.$i18n.t('registerUser.acceptTerms')
        return;
      }

      Vue.axios.post(`/public/register-user`, JSON.stringify(this.registerRequest),
          { headers: { 'Content-Type': 'application/json'}
          }).then(response => {
        vm.registered = true;
      }).catch(error => {
        //console.log("error: ", error.response)
        //console.log("error status: ", error.response.status)
        if (error.response.status === 422) {
          vm.$refs.form.setErrors(error.response.data);
        } else {
          vm.error = commons.processRestError(error)
        }
      })
    },
    setLang(lang) {
      this.registerRequest.language = lang
      this.$store.dispatch('set_lang', lang)
    }
  }
}
</script>

<style>
</style>