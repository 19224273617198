import "bootstrap-vue/dist/bootstrap-vue.css"
import '@/assets/custom/bootstrap_custom.scss'
import '@/assets/font-awesome/css/fontawesome.css'
import '@/assets/font-awesome/css/brands.css'
import '@/assets/font-awesome/css/solid.css'
import '@/assets/font-awesome/css/regular.css'
import '@/assets/css/animate.css'
import '@/assets/css/plugins/dialog/bootstrap-dialog.min.css'
import 'tooltipster/dist/css/tooltipster.bundle.min.css'
import 'tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-punk.min.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import "vue-select/dist/vue-select.css"
import '@/assets/css/plugins/iCheck/custom.css'
import '@/assets/css/plugins/jasny/jasny-bootstrap.min.css'
import '@/assets/custom/inspinia_custom.less'

//leaflet init
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'
import { Icon } from "leaflet"
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
})

//window.$ = window.jQuery = require('jquery')
window.paceOptions = {
  ajax: {
    trackWebSockets: false
  }
}

window.Popper = require('popper.js').default
require('bootstrap/dist/js/bootstrap')
require('@/assets/js/plugins/dialog/bootstrap-dialog.min.js')
require('@/assets/js/plugins/metisMenu/jquery.metisMenu.js')
require('@/assets/js/plugins/iCheck/icheck.min.js')
require('tooltipster/dist/js/tooltipster.bundle.min.js')
require('@/assets/js/plugins/jasny/jasny-bootstrap.min.js')
require('@/assets/js/inspinia.js')

// Introduce the necessary package for development
import Vue from 'vue'
import BootstrapVue from "bootstrap-vue"
import VueCompositionAPI from '@vue/composition-api'
import App from './components/App'
import router from './router'
import mixins from './mixins'
import i18n from './i18n'
import { sync } from 'vuex-router-sync'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment-timezone'
import VueToastr from "vue-toastr"
import VueSelect from "vue-select"
import Keycloak from 'keycloak-js'
import store from './store'
import * as commons from "./commons"
import * as filters from './filters'
import VueHighcharts from 'vue-highcharts'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import exportingOffline from 'highcharts/modules/offline-exporting';
import exportingData from 'highcharts/modules/export-data'
import highchartsMore from 'highcharts/highcharts-more';
import highchartsHeatmaps from 'highcharts/modules/heatmap';
import loadWordcloud from 'highcharts/modules/wordcloud';
import highchartsDrilldown from 'highcharts/modules/drilldown';
import datePicker from 'vue-bootstrap-datetimepicker'
import 'popper.js'
import 'bootstrap'
import './validation'


import '@/assets/css/plugins/tinymce-skins/skins/ui/oxide/skin.min.css'
var tinymce = require('tinymce/tinymce')
require('tinymce/themes/silver/theme')
require('tinymce/plugins/paste')
require('tinymce/plugins/link')
require('tinymce/plugins/wordcount')
require('tinymce/plugins/print')
require('tinymce/plugins/table')
require('tinymce/plugins/quickbars')
require('tinymce/plugins/charmap')
require('tinymce/plugins/media')
require('tinymce/plugins/anchor')
require('tinymce/plugins/template')
require('tinymce/plugins/code')
require('tinymce/plugins/colorpicker')
require('tinymce/plugins/autolink')
require('tinymce/plugins/lists')
require('tinymce/plugins/preview')
require('tinymce/plugins/quickbars')
require('tinymce/plugins/textcolor')
require('tinymce/plugins/fullpage')
require('tinymce/plugins/image')
require('tinymce/plugins/fullscreen')

//loadMap(Highcharts)
exportingInit(Highcharts);
exportingOffline(Highcharts);
exportingData(Highcharts);
highchartsMore(Highcharts);
loadWordcloud(Highcharts);
highchartsHeatmaps(Highcharts);
highchartsDrilldown(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.fontAwesomeSymbol = function (x, y, width, height) {
    return ['M15.835938 1.75C12.035156 1.753906 8.390625 3.265625 5.703125 5.953125C3.015625 8.640625 1.503906 12.285156 1.5 16.085938C1.503906 19.886719 3.015625 23.527344 5.703125 26.214844C8.390625 28.898438 12.035156 30.410156 15.835938 30.414062C19.632812 30.410156 23.277344 28.898438 25.964844 26.210938C28.648438 23.527344 30.160156 19.882812 30.164062 16.082031C30.160156 12.285156 28.648438 8.640625 25.964844 5.953125C23.277344 3.269531 19.632812 1.753906 15.835938 1.75 Z M20.890625 20.664062L16.394531 23.660156L16.390625 23.664062C16.226562 23.773438 16.03125 23.835938 15.835938 23.832031C15.636719 23.835938 15.441406 23.777344 15.28125 23.664062L15.195312 23.601562L10.78125 20.664062C10.558594 20.515625 10.40625 20.289062 10.351562 20.027344C10.300781 19.769531 10.355469 19.5 10.5 19.277344C10.648438 19.058594 10.875 18.90625 11.136719 18.851562C11.398438 18.800781 11.667969 18.851562 11.890625 19L14.835938 20.964844L14.835938 9.332031C14.835938 9.203125 14.859375 9.070312 14.910156 8.949219C14.960938 8.828125 15.035156 8.71875 15.125 8.625C15.21875 8.53125 15.332031 8.457031 15.453125 8.410156C15.574219 8.359375 15.703125 8.332031 15.835938 8.332031C16.101562 8.332031 16.355469 8.4375 16.542969 8.625C16.730469 8.8125 16.835938 9.066406 16.835938 9.332031L16.835938 20.964844L19.78125 19C20 18.851562 20.269531 18.800781 20.53125 18.851562C20.789062 18.90625 21.019531 19.058594 21.164062 19.277344C21.3125 19.5 21.367188 19.769531 21.3125 20.027344C21.261719 20.289062 21.109375 20.515625 20.890625 20.664062 Z M20.890625 20.664062']
};


Highcharts.setOptions({
    exporting: {
        scale: 3,
        sourceWidth: 1200, 
        buttons: {
            contextButton: {
                symbol: 'fontAwesomeSymbol',
                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadSVG', 'downloadCSV', 'downloadXLS'],
                symbolStroke: '#61AEB7',
                symbolFill: '#61AEB7',
                symbolStrokeWidth: 0,
                height: 32,
                width: 32,
                symbolSize: 16,
                symbolX: 16,
                symbolY: 32,
                y: -5
            }
        }
    }
});

//set our options
window.Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

// tough-cookie requires net dependency
import tough from 'tough-cookie'
var Cookie = tough.Cookie
window.cookieJar = new tough.CookieJar()

// Promoted to global variable
window.Vue = Vue
window.router = router
window.i18n = i18n

// vue configuration
Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
const appUrl = (process.env.NODE_ENV === 'production') ? '/app' : '/'

// imports
Vue.use(BootstrapVue)
Vue.use(VueCompositionAPI)
Vue.use(VueAxios, axios)
Vue.use(VueHighcharts, { Highcharts: Highcharts })
//Vue.use(VueMoment)
Vue.prototype.$moment = moment
Vue.use(datePicker)
Vue.use(VueToastr, {
    defaultTimeout: 4000,
    defaultPosition: "toast-top-full-width",
    closeButton: true,
    preventDuplicates: true,
    progressBar: true
})

Vue.component("v-select", VueSelect)

// global functions
Vue.mixin(mixins)

// store, router synchronization
sync(store, router)

// axios configuration
axios.defaults.timeout = 30000
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API + '/web'
axios.defaults.withCredentials = true
axios.defaults.crossDomain = true

// http request interceptor
axios.interceptors.request.use(
    config => {

        store.commit('SET_ROOT_ERROR', null)

        //console.log('localStorage.getItem(vue-token)    ' + localStorage.getItem('vue-token'))
        if (localStorage.getItem('vue-token')) {
            config.headers['Authorization'] = "Bearer " + localStorage.getItem('vue-token')
        }
        if (config.headers['Background-process']) {
            delete config.headers['Background-process']
        } else {
            store.commit('UPDATE_SHOW_GLOBAL_LOADER', true)
        }

        return Promise.resolve(config)
    },
    error => {

        store.commit('UPDATE_SHOW_GLOBAL_LOADER', false)
        console.log('interceptor request error ' + error)
        return Promise.reject(error)
    }
)

// http response interceptor
axios.interceptors.response.use(
    response => {
        store.commit('UPDATE_SHOW_GLOBAL_LOADER', false)
        if (store.state.connectionError && response.request.responseURL.indexOf(axios.defaults.baseURL) != -1) {
            store.commit('UPDATE_CONNECTION_ERROR', false)
        }
        return response
    },
    error => {
        store.commit('UPDATE_SHOW_GLOBAL_LOADER', false)
        if (error.response) {
            //console.log('error.response.request.responseURL: ' + error.response.request.responseURL + ', axios.defaults.baseURL: ' + axios.defaults.baseURL)
            if (error.response.request.responseURL.indexOf(axios.defaults.baseURL) != -1) {

                if (error.response.status == 401) {
                    // reload page
                    window.location.reload()
                }

                if (error.response.status == 401 || (error.response.status == 403 && error.response.headers['authentication-error'] == 'true')) {
                    if (router.currentRoute.meta.hasOwnProperty('requiresAuth')) {
                        //Vue.$keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path })
                    }

                } else if (error.response.status == 404 || error.response.status == 502 || error.response.status == 503 || error.response.status == 504) {
                    store.commit('UPDATE_CONNECTION_ERROR', true)
                }
            }
        } else if (error.message && (error.message.toLowerCase().indexOf('network error') != -1 || error.message.toLowerCase().indexOf('timeout of ') != -1)) {
            store.commit('UPDATE_CONNECTION_ERROR', true)
        }

        return Promise.reject(error)
    }
)

// Register the filter
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

Vue.directive('inputed', function (el) {
    if (el.value != null && el.value.length > 0) {
        el.style.background = '#e6f2ff'
    } else {
        el.style.background = 'white'
    }
})

//require('./keycloak')

Vue.axios.get('/public/system-params').then(response => {

    store.commit('SET_SYSTEM_PARAMS', response.data)

    const _keycloak = Keycloak({
        url: store.state.systemParams["keycloak.auth-server-url"],
        realm: store.state.systemParams["keycloak.realm"],
        clientId: store.state.systemParams["kc.frontend.client-id"]
    })

    const KeycloakPlugin = {
        install(Vue) {
            Vue.$keycloak = _keycloak
        }
    }

    KeycloakPlugin.install = Vue => {
        Vue.$keycloak = _keycloak
        Object.defineProperties(Vue.prototype, {
            $keycloak: {
                get() {
                    return _keycloak
                }
            }
        })
    }

    Vue.use(KeycloakPlugin)

    // init keycloak and set refresh interval task
    let refreshTokenInterval = null

    Vue.$keycloak.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + appUrl + "/silent-check-sso.html",
        _checkLoginIframe: false
    }).then((authenticated) => {

        if (authenticated) {

            console.log('KEYCLOAK main.js - > Authenticated exp ')

            store.dispatch('loggedIn')

            if(refreshTokenInterval) {
                clearInterval(refreshTokenInterval)
            }

            let now = new Date().getTime()
            let tokenExpiryInSec = Math.floor(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew + 1 - (now / 1000))
            let refreshTokenBeforeExpiryInSec = tokenExpiryInSec * 0.2

            console.log("KEYCLOAK main.js  -> token expiry in " + tokenExpiryInSec + " seconds, refreshTokenBeforeExpiryInSec " + refreshTokenBeforeExpiryInSec)

            refreshTokenInterval = setInterval(() => {

                if (Vue.$keycloak.refreshToken) {

                    // set minValidity (refresh token before expiry in seconds) to be half of the expiration interval (just to be sure)
                    Vue.$keycloak.updateToken(refreshTokenBeforeExpiryInSec).then(refreshed => {

                        if (refreshed) {
                            console.log("KEYCLOAK main.js  -> token_refreshed ")
                            store.dispatch('saveKeycloakData')
                        } else {
                            console.log("KEYCLOAK main.js  -> will be refreshed in " + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - refreshTokenBeforeExpiryInSec - (new Date().getTime() / 1000)) + " seconds")
                        }
                    }).catch((e) => {
                        console.error("KEYCLOAK main.js  -> interval error", e)
                    })
                } else {
                    console.warn('No refresh token in keycloak object, skip updateToken')
                }
            }, 30000)

        }

        const vm = new Vue({
            router,
            store,
            i18n,
            render: h => h(App)
        }).$mount('#app')

    }).catch(error => {
        console.log('Authenticated Failed', error)
    })

}).catch(error => {
    commons.processRestError(error)
    store.commit('SET_ERROR_PAGE', i18n.t('common.errors.500'), { root: true })
    router.push({ path: `/errorGeneric` })
})
