<template>
  <div class="row">
    <div class="col-12">

      <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
        <button class="close" type="button" @click="error = ''">×</button>
      </div>

      <div class="text-center mt-5 mb-5" v-if="!selectedLocation">
        <div class="font-bold">
          {{ $t('feedback.feedbackView.placeholder') }}
        </div>
      </div>
      <div v-else>

        <div class="col-12">
          <div v-for="(response, index) in formCharts" :key="index">

            <h4 class="mb-4">{{ response.title }}</h4>

            <b-tabs content-class="px-3 py-3 white-bg" v-if="response.pieChart && response.pieChart.series[0].data.length > 0">

              <b-tab :title="$t('feedback.feedbackView.charts.pie')">
                <div class="row">
                  <div class="col-12">
                    <highcharts :options="response.pieChart" :ref="response.pieChart.title"></highcharts>
                  </div>
                </div>
              </b-tab>

              <b-tab :title="$t('feedback.feedbackView.charts.stacked')">
                <div class="row">
                  <div class="col-12">
                    <highcharts :options="response.stackedChart" :ref="response.stackedChart.title"></highcharts>
                  </div>
                </div>
              </b-tab>

            </b-tabs>
            <div v-else>{{ $t('feedback.feedbackView.noResponses') }}</div>

          </div>
        </div>

        <div class="col-12 mt-4">
          <div>
            <div v-for="(field, index) in formTextFields" :key="index">
              <h4 class="mb-4">{{ field.title }}</h4>
              <form-responses-list :field="field"></form-responses-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import FormResponsesList from "@/components/views/feedback/FormResponsesList.vue";

export default {
  components: {
    FormResponsesList
  },
  data() {
    return {
      error: null,
      //formCharts: []
    }
  },
  created() {
    console.log('feedbackView created')
  },
  mounted() {

  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedForm: state => state.feedback.selectedForm,
      selectedLocation: state => state.feedback.selectedLocation,
      graphData: state => state.feedback.graphData
    }),
    formTextFields() {
      let formTextFields = [];

      let formFields = convertJsonSchemaToModel(this.selectedForm)

      for(const field of formFields) {
        if(field.type === 'text' || field.type === 'textarea') {
          formTextFields.push(field)
        }
      }
      return formTextFields;
    },
    formCharts() {
      console.log("watch graphsData")
      //this.form = null

      let charts = []

      let responses = this.graphData.graphData ? this.graphData.graphData : []

      if(responses.length === 0){
        return charts
      }

      for(const response of responses) {
        let formFields = convertJsonSchemaToModel(this.selectedForm)

        let currentField = null
        for(const field of formFields) {
          if(field.name === response.name) {
            currentField = field
          }
        }

        if(currentField !== null){
          let pieChart = createPieChart(currentField, response.pieChart)
          let stackedChart = createStackedChart(currentField, response.stackedChart)

          pieChart.exporting = {
            chartOptions: {
              title: {
                align: 'center',
                text:  this.selectedForm.name + " / " + this.selectedLocation.name
              },
              subtitle: {
                align: 'center',
                text: currentField.title
              }
            }
          }

          stackedChart.exporting = {
            chartOptions: {
              title: {
                text: this.selectedLocation.name + " / " + this.selectedForm.name
              },
              subtitle: {
                align: 'center',
                text: currentField.title
              }
            }
          }

          charts.push({fieldName: response.name, title: currentField.title, pieChart: pieChart, stackedChart: stackedChart})
        }
      }

      return charts
    }
  }
}

let chartStackedOptionsBase = {
  exporting: {
    buttons: {
      contextButton: {
        verticalAlign: 'bottom',
        y: 0
      }
    }
  },
  credits: false,
  chart: {
    type: 'column'
  },
  title: {
    text: ''
  },
  xAxis: {
    categories: []
  },
  yAxis: {
    min: 0,
    title: {
      text: ''
    }
  },
  tooltip: {
    pointFormat: '<span style="color:{series.color}">{series.name}</span>: ({point.percentage:.0f}% - Total votes: {point.y})<br/>',
    shared: true
  },
  plotOptions: {
    column: {
      stacking: 'percent'
    }
  },
  series: [],
  colors: ['#F90093', '#025286', '#61AEB7', '#2C6E49', '#FFE347', '#FFC000', '#E94F37', '#A31621', '#C3979F', '#36151E']
}

let chartPieOptionsBase = {
  exporting: {
    buttons: {
      contextButton: {
        verticalAlign: 'bottom',
        y: 0
      }
    }
  },
  credits: false,
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  title: {
    text: '',
    align: 'left'
  },
  tooltip: {
      pointFormat: '{point.percentage:.1f} %<br>Total votes: {point.y}'
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} % <br>Total votes: {point.y}'
      },
      showInLegend: true
    }
  },
  series: [],
  colors: ['#F90093', '#025286', '#61AEB7', '#2C6E49', '#FFE347', '#FFC000', '#E94F37', '#A31621', '#C3979F', '#36151E']
}

function createStackedChart(field, data) {
  console.log('createStackedChart')

  const graphData = data

  let chartDef = JSON.parse(JSON.stringify(chartStackedOptionsBase))
  //chartDef.title = { text: field.title }

  let categorySet = new Set()

  // create base series map ot of all possible options
  let seriesMap = new Map()
  field.options.map(option => seriesMap.set(option.value, new Map()))

  for (let i = 0; i < graphData.length && i < 30; i++) {
    categorySet.add(graphData[i].date)
    if(seriesMap.has(graphData[i].value)) {
      seriesMap.get(graphData[i].value).set(graphData[i].date, graphData[i].count)
    }
  }

  // fill categories
  chartDef.xAxis.categories = Array.from(categorySet).sort();

  // fill series
  chartDef.series = []

  for (let [key, value] of seriesMap) {
    //console.log(key + " = " + value);
    let dataArr = []
    for (let category of Array.from(categorySet).sort()) {
      if (value.has(category)) {
        dataArr.push(value.get(category))
      } else {
        dataArr.push(0)
      }
    }

    let label = field.options.filter(el => el.value === key)[0]['label'];

    chartDef.series.push({ name: label, data: dataArr })
  }

  return chartDef;
}

function createPieChart(field, data) {
  console.log('createPieChart')
  const graphData = data

  let chartDef = JSON.parse(JSON.stringify(chartPieOptionsBase))
  //chartDef.title = { text: field.title }
  chartDef.series[0] = {}
  chartDef.series[0].colorByPoint = true
  chartDef.series[0].name = {}
  chartDef.series[0].data = []

  for (let i = 0; i < graphData.length; i++) {
    let filteredElements = field.options.filter(el => el.value === graphData[i].value);
    if (filteredElements.length > 0) {
      let label = filteredElements[0]['label'];
      chartDef.series[0].data.push({
        name: label,
        y: graphData[i].count
      });
    } else {
      // If no matching element is found, skip this iteration and continue with the next
      continue;
    }
  }

  return chartDef;
}

function convertJsonSchemaToModel(form) {
  console.log("convertJsonSchemaToModel")

  let fields = []
  if(form === null) {
    return fields
  }
  for (const [property, value] of Object.entries(form.settings.schema.properties)) {
    //console.log("property: " + property)
    //console.log("property type: " + value.type)

    let fieldName = property
    let fieldType = value.type
    let fieldTitle = value.title
    let fieldRequired = value.required

    let fieldOptions = form.settings.options.fields[fieldName]
    if (fieldOptions) {
      //console.log("option type: " + fieldOptions.type)
      fieldType = fieldOptions.type
    }

    let newField = addFieldFromSchema(fields.length, fieldType, fieldName, fieldTitle, fieldRequired)

    if (value.enum) {
      newField.options = []

      for (let index = 0; index < value['enum'].length; ++index) {
        let value = form.settings.schema.properties[fieldName]['enum'][index]
        let label = form.settings.options.fields[fieldName].optionLabels[index]
        newField.options.push({value: value, label: label})
      }
    }

    fields.push(newField)
  }
  return fields;
}

function addFieldFromSchema(fieldOrder, fieldType, fieldName, fieldTitle, fieldRequired){
  //console.log("addFieldFromSchema: " + fieldType)
  let newField;
  if(fieldType == 'text' || fieldType == 'textarea') {
    newField = { type: fieldType, name: fieldName, title: fieldTitle, required: fieldRequired, order: fieldOrder }
  } else if(fieldType == 'radio') {
    newField = { type: fieldType, name: fieldName, title: fieldTitle, order: fieldOrder }
  } else if(fieldType == 'checkbox') {
    newField = { type: fieldType, name: fieldName, title: fieldTitle, order: fieldOrder }
  }
  return newField
}

</script>
