<template>
  <div>

    <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
      <button class="close" type="button" @click="error = ''">×</button>
    </div>

    <div class="row">

      <div class="col-12">

        <b-form-group v-if="selector.buildings.length > 0" id="input-group-assign-building"
                      :label="$t('resourceSelector.building')" label-for="input-assign-building" label-cols-sm="3">
          <v-select
              :disabled="selector.buildings.length == 1 && selector.buildingSelected && !selector.buildingSelected.includeAllChildren"
              id="input-assign-building"
              name="input-assign-building"
              v-model="selector.buildingSelected"
              :options="selector.buildings"
              label="name"
              @input="buildingSelectedChanged"
              :clearable="selectionClearable"
          />
        </b-form-group>

        <b-form-group v-if="selector.maps.length > 0" id="input-group-assign-map" :label="$t('resourceSelector.map')"
                      label-for="input-assign-map" label-cols-sm="3">
          <v-select
              :disabled="selector.maps.length == 1 && selector.mapSelected && !selector.mapSelected.includeAllChildren"
              id="input-assign-map"
              name="input-assign-map"
              v-model="selector.mapSelected"
              :options="selector.maps"
              label="name"
              @input="mapSelectedChanged"
              :clearable="selectionClearable"
          />
        </b-form-group>

        <b-form-group v-if="selector.locations.length > 0" id="input-group-assign-location"
                      :label="$t('resourceSelector.location')" label-for="input-assign-location" label-cols-sm="3">
          <v-select
              :disabled="selector.locations.length == 1 && selector.locationSelected && !selector.locationSelected.includeAllChildren"
              id="input-assign-location"
              name="input-assign-location"
              v-model="selector.locationSelected"
              :options="selector.locations"
              label="name"
              @input="select"
              :clearable="selectionClearable"
          />
        </b-form-group>

      </div>

    </div>

  </div>
</template>

<script>
import Vue from "vue"
import * as commons from "@/commons"
import {mapState} from "vuex"
import {getUserAuthorities} from "@/commons"
import _ from "lodash"

export default {
  props: {
    selectDepth: {
      default: 'location',
      validator: function (value) {
        return ['organisation', 'building', 'map', 'location'].includes(value)
      }
    },
    preselectResource: {
      type: Object
    },
    forcePreselectCascade: {
      type: Boolean,
      default: true
    },
    selectionClearable: {
      type: Boolean
    },
    resourceSelectedCallback: {
      type: Function
    },
    resourceSelectedResetCallback: {
      type: Function
    }
  },
  data() {
    return {
      error: null,
      info: null,
      preselectedResourceAll: null,
      selector: {
        buildings: [],
        maps: [],
        locations: [],
        buildingSelected: null,
        mapSelected: null,
        locationSelected: null,
      }
    }
  },
  created() {
    this.initialFetch()
  },
  computed: {
    ...mapState({
      contextOrg: state => state.contextOrg
    }),
  },
  watch: {
    contextOrg: function (newVal, oldVal) {
      console.log('contextOrg changed: ', JSON.stringify(newVal), ' | was: ', JSON.stringify(oldVal))
      if (newVal?.id !== oldVal?.id) {
        this.initialFetch()
      }
    },
    preselectResource: function (newVal, oldVal) {
      //console.log('Prop preselectResource changed: ', JSON.stringify(newVal), ' | was: ', JSON.stringify(oldVal))
      console.log('Prop preselectResource changed')
      if (newVal?.building?.id !== oldVal?.building?.id || newVal?.map?.id !== oldVal?.map?.id || newVal?.location?.id !== oldVal?.location?.id) {
        // check against current selection
        if ((this.selectDepth === 'building' && newVal?.building?.id !== this.selector.buildingSelected?.id)
            || (this.selectDepth === 'map' && newVal?.map?.id !== this.selector.mapSelected?.id)
            || (this.selectDepth === 'location' && newVal?.location?.id !== this.selector.locationSelected?.id)) {
          this.initialFetch()
        }
      }
    }
  },
  methods: {
    initialFetch() {

      console.log('initialFetch')

      this.selector.buildings = []
      this.selector.maps = []
      this.selector.locations = []

      this.selector.buildingSelected = null
      this.selector.mapSelected = null
      this.selector.locationSelected = null

      if (this.preselectResource) {
        if (this.preselectResource.organisation) {
          this.preselectedResourceAll = {organisation: this.preselectResource.organisation}
        } else if (this.preselectResource.building) {
          this.preselectedResourceAll = {
            organisation: this.preselectResource.building.organisation,
            building: this.preselectResource.building
          }
        } else if (this.preselectResource.map) {
          this.preselectedResourceAll = {
            organisation: this.preselectResource.map.building.organisation,
            building: this.preselectResource.map.building,
            map: this.preselectResource.map
          }
        } else if (this.preselectResource.location) {
          this.preselectedResourceAll = {
            organisation: this.preselectResource.location.map.building.organisation,
            building: this.preselectResource.location.map.building,
            map: this.preselectResource.location.map,
            location: this.preselectResource.location
          }
        }
      }

      if (this.selectDepth === 'org') {
        return this.select()
      }

      if (this.hasRole('ADMIN') || getUserAuthorities().some(auth => auth.authorizationTarget?.id == this.contextOrg.id && auth.role === 'ORGANISATION_MANAGER')) {

        //load organisation with all buildings
        Vue.axios.get('/admin/organisations/' + this.contextOrg.id).then(response => {
          this.selector.buildings = _.sortBy(response.data.buildings.filter(building => {
            return building.status == 'ACTIVE'
          }).map(building => {
            building.includeAllChildren = true
            return building
          }), [function (o) {
            return o.name;
          }])

          if (this.preselectedResourceAll && this.preselectedResourceAll.building && this.selector.buildings.some(building => building.id == this.preselectedResourceAll.building.id)) {
            this.selector.buildingSelected = this.selector.buildings.find(building => building.id == this.preselectedResourceAll.building.id)
            this.buildingSelectedChanged(true)
            return
          } else if (this.selector.buildings.length == 1 || (this.forcePreselectCascade && this.selector.buildings.length > 0)) {
            this.selector.buildingSelected = this.selector.buildings[0]
            this.buildingSelectedChanged(false)
            return
          }
        }).catch(error => {
          commons.processRestError(error)
        })

      } else {

        // load only explicitly assigned buildings
        this.selector.buildings = getUserAuthorities().filter(auth => {
          return auth.authorizationTargetPath.building && auth.authorizationTargetPath.building.status == 'ACTIVE' && auth.authorizationTargetPath.organisation.id == this.contextOrg.id
        }).map(auth => {
          if (auth.role == 'BUILDING_MANAGER') {
            auth.authorizationTargetPath.building.includeAllChildren = true
          }
          return auth.authorizationTargetPath.building
        })

        this.selector.buildings = _.sortBy(this.getUniqueListBy(this.selector.buildings, "id"), [function (o) {
          return o.name;
        }])

        if (this.preselectedResourceAll && this.preselectedResourceAll.building && this.selector.buildings.some(building => building.id == this.preselectedResourceAll.building.id)) {
          this.selector.buildingSelected = this.selector.buildings.find(building => building.id == this.preselectedResourceAll.building.id)
          this.buildingSelectedChanged(true)
          return
        } else if (this.selector.buildings.length == 1 || (this.forcePreselectCascade && this.selector.buildings.length > 0)) {
          this.selector.buildingSelected = this.selector.buildings[0]
          this.buildingSelectedChanged(false)
          return
        }
      }

      this.callResetCallBack()
    },

    buildingSelectedChanged(preselect) {

      console.log('buildingSelectedChanged ' + this.selector.buildingSelected?.id + ', preselect ' + preselect)

      this.error = ""
      this.selector.maps = []
      this.selector.locations = []
      this.selector.mapSelected = null
      this.selector.locationSelected = null

      if (this.selectDepth === 'building') {
        if (preselect !== true) {
          return this.select()
        } else {
          return
        }
      }

      if(this.selector.buildingSelected == null){
        return
      }

      if (this.selector.buildingSelected.includeAllChildren) {

        //load building with maps
        Vue.axios.get('/admin/buildings/' + this.selector.buildingSelected.id).then(response => {
          this.selector.maps = _.sortBy(response.data.maps.filter(map => {
            return map.status == 'ACTIVE'
          }).map(map => {
            map.includeAllChildren = true
            return map
          }), [function (o) {
            return o.name;
          }])

          if (preselect === true && this.preselectedResourceAll && this.preselectedResourceAll.map && this.selector.maps.some(map => map.id == this.preselectedResourceAll.map.id)) {
            this.selector.mapSelected = this.selector.maps.find(map => map.id == this.preselectedResourceAll.map.id)
            this.mapSelectedChanged(true)
            return
          } else if (this.selector.maps.length == 1 || (this.forcePreselectCascade && this.selector.maps.length > 0)) {
            this.selector.mapSelected = this.selector.maps[0]
            this.mapSelectedChanged(false)
            return
          }
        }).catch(error => {
          commons.processRestError(error)
        })

      } else {

        // load only explicitly assigned location maps
        this.selector.maps = getUserAuthorities().filter(auth => {
          return auth.authorizationTargetPath.map && auth.authorizationTargetPath.map.status == 'ACTIVE' && auth.authorizationTargetPath.building.id == this.selector.buildingSelected.id
        }).map(auth => {
          return auth.authorizationTargetPath.map
        })

        this.selector.maps = _.sortBy(this.getUniqueListBy(this.selector.maps, "id"), [function (o) {
          return o.name;
        }])

       if (preselect === true && this.preselectedResourceAll && this.preselectedResourceAll.map && this.selector.maps.some(map => map.id == this.preselectedResourceAll.map.id)) {
          this.selector.mapSelected = this.selector.maps.find(map => map.id == this.preselectedResourceAll.map.id)
          this.mapSelectedChanged(true)
          return
       } else if (this.selector.maps.length == 1 || (this.forcePreselectCascade && this.selector.maps.length > 0)) {
         this.selector.mapSelected = this.selector.maps[0]
         this.mapSelectedChanged(false)
         return
       }
      }
      this.callResetCallBack()
    },

    mapSelectedChanged(preselect) {

      console.log('mapSelectedChanged ' + this.selector.mapSelected.id + ', preselect ' + preselect)

      this.error = ""
      this.selector.locations = []
      this.selector.locationSelected = null

      if (this.selectDepth === 'map') {
        if (preselect !== true) {
          return this.select()
        } else {
          return
        }
      }

      if(this.selector.mapSelected == null){
        return
      }

      if (this.selector.mapSelected.includeAllChildren) {

        //load map with locations
        Vue.axios.get('/admin/maps/' + this.selector.mapSelected.id).then(response => {
          this.selector.locations = _.sortBy(response.data.locations.filter(location => {
            return location.status == 'ACTIVE'
          }), [function (o) {
            return o.name;
          }])

         if (preselect === true && this.preselectedResourceAll && this.preselectedResourceAll.location && this.selector.locations.some(location => location.id == this.preselectedResourceAll.location.id)) {
            this.selector.locationSelected = this.selector.locations.find(location => location.id == this.preselectedResourceAll.location.id)
            this.select(true)
            return
          } else if (this.selector.locations.length == 1 || (this.forcePreselectCascade && this.selector.locations.length > 0)) {
           this.selector.locationSelected = this.selector.locations[0]
           this.select(false)
           return
         }
        }).catch(error => {
          commons.processRestError(error)
        })

      } else {

        // load only explicitly assigned locations
        this.selector.locations = getUserAuthorities().filter(auth => {
          return auth.authorizationTargetPath.location && auth.authorizationTargetPath.location.status == 'ACTIVE' && auth.authorizationTargetPath.map.id == this.selector.mapSelected.id
        }).map(auth => {
          return auth.authorizationTargetPath.location
        })

        this.selector.locations = _.sortBy(this.getUniqueListBy(this.selector.locations, "id"), [function (o) {
          return o.name;
        }])

        if (preselect === true && this.preselectedResourceAll && this.preselectedResourceAll.location && this.selector.locations.some(location => location.id == this.preselectedResourceAll.location.id)) {
          this.selector.locationSelected = this.selector.locations.find(location => location.id == this.preselectedResourceAll.location.id)
          this.select()
          return
        } else if (this.selector.locations.length == 1 || (this.forcePreselectCascade && this.selector.locations.length > 0)) {
          this.selector.locationSelected = this.selector.locations[0]
          this.select()
          return
        }
      }
      this.callResetCallBack()
    },

    locationSelectedChanged(){
      if(this.selector.mapSelected == null){
        return
      }
      this.select()
    },

    select() {

      let resolvedResourceObject = null
      let resolvedResourceType = null
      if (this.selector.locationSelected) {
        resolvedResourceObject = this.selector.locationSelected
        resolvedResourceType = 'location'
      } else if (this.selector.mapSelected) {
        resolvedResourceObject = this.selector.mapSelected
        resolvedResourceType = 'map'
      } else if (this.selector.buildingSelected) {
        resolvedResourceObject = this.selector.buildingSelected
        resolvedResourceType = 'building'
      } else if (this.contextOrg && this.selectDepth === 'organisation') {
        resolvedResourceObject = this.contextOrg
        resolvedResourceType = 'organisation'
      }

      let payload = {
        resource: resolvedResourceObject,
        type: resolvedResourceType,
        organisationSelected: this.contextOrg,
        buildingSelected: this.selector.buildingSelected,
        mapSelected: this.selector.mapSelected,
        locationSelected: this.selector.locationSelected
      }

      console.log('Resource selector calling callback with ' + JSON.stringify(payload))

      if (this.resourceSelectedCallback) {
        this.resourceSelectedCallback(payload)
      }
    },

    callResetCallBack() {
      if (this.resourceSelectedResetCallback) {
        this.resourceSelectedResetCallback()
      }
    }
  }
}
</script>