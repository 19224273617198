<template>
  <div>

    <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
      <button class="close" type="button" @click="error = ''">×</button>
    </div>

    <div class="row">

      <div class="col-12">

        <b-form-group v-if="selector.buildings.length > 0" id="input-group-assign-building" :label="$t('resourceSelector.building')" label-for="input-assign-building" label-cols-sm="3">
          <v-select
              :disabled="selector.buildings.length == 1 && selector.buildingSelected && !selector.buildingSelected.includeAllChildren"
              id="input-assign-building"
              name="input-assign-building"
              v-model="selector.buildingSelected"
              :options="selector.buildings"
              label="name"
              @input="buildingSelectedChanged"
              :clearable="false"
          />
        </b-form-group>

        <b-form-group v-if="selector.devices.length > 0" id="input-group-assign-metering-point" :label="$t('resourceSelector.meteringPoint')" label-for="input-assign-metering-point" label-cols-sm="3">
          <v-select
              :disabled="selector.devices.length == 1 && selector.deviceSelected && !selector.deviceSelected.includeAllChildren"
              id="input-assign-metering-point"
              name="input-assign-metering-point"
              v-model="selector.deviceSelected"
              :options="selector.devices"
              label="alias"
              @input="select"
              :clearable="false"
          />
        </b-form-group>

        <table class="table no-margins" v-if="selector.deviceSelected && selector.deviceSelected.resources.length > 0">
            <thead>
              <tr>
                  <th>{{ $t('systemAdmin.device.attributes.map') }}</th>
                  <th>{{ $t('systemAdmin.device.attributes.location') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="resource in selector.deviceSelected.resources" :key="resource.id">
                  <td><small>{{ resource.map ? resource.map.name : $t("graphs.meteringPoints.meteringPointSelector.deviceResourceAllMaps") }}</small></td>
                  <td><small>{{ resource.location ? resource.location.name : $t("graphs.meteringPoints.meteringPointSelector.deviceResourceAllLocations") }}</small></td>
              </tr>
            </tbody>
        </table>

      </div>

    </div>

  </div>
</template>

<script>
import Vue from "vue"
import * as commons from "@/commons"
import { mapState } from "vuex"
import { getUserAuthorities } from "@/commons"
import _ from "lodash"

export default {
  props: {
    preselectResource:{
      required: false,
      type: Object
    },
    selectedCallback: {
      type: Function,
      required: false
    },
    resourceSelectedResetCallback: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      error: null,
      info: null,
      selector: {
        buildings: [],
        devices: [],
        buildingSelected: null,
        deviceSelected: null
      },
      localResource: null
    }
  },
  created(){
    this.initialFetch()
    this.localResource = { ...this.preselectResource };
  },
  computed: {
    ...mapState({
      contextOrg: state => state.contextOrg
    })
  },
  watch: {
    contextOrg: function (newVal, oldVal) {
      console.log('contextOrg changed: ', JSON.stringify(newVal), ' | was: ', JSON.stringify(oldVal))
      if (newVal?.id !== oldVal?.id) {
        this.initialFetch()
      }
    }
  },
  methods: {
    initialFetch() {

      this.selector.buildings = []
      this.selector.devices = []

      this.selector.buildingSelected = null
      this.selector.deviceSelected = null

      if(this.localResource && this.localResource.device) {
        this.localResource.organisation = this.localResource.device.organisation;
        this.localResource.building = this.localResource.device.building;
      }

      if (this.hasRole('ADMIN') || getUserAuthorities().some(auth => auth.authorizationTarget?.id == this.contextOrg.id && auth.role === 'ORGANISATION_MANAGER')) {

        //load organisation with all buildings
        Vue.axios.get('/admin/organisations/' + this.contextOrg.id).then(response => {
          this.selector.buildings = _.sortBy(response.data.buildings.filter(building => {
            return building.status == 'ACTIVE'
          }).map(building => {
            building.includeAllChildren = true
            return building
          }), [function (o) {
            return o.name;
          }])

          if (this.preselectedResourceAll && this.preselectedResourceAll.building && this.selector.buildings.some(building => building.id == this.preselectedResourceAll.building.id)) {
            this.selector.buildingSelected = this.selector.buildings.find(building => building.id == this.preselectedResourceAll.building.id)
            this.buildingSelectedChanged(true)
            return
          } else if(this.selector.buildings.length > 0){
            this.selector.buildingSelected = this.selector.buildings[0]
            this.buildingSelectedChanged(false)
            return
          }

        }).catch(error => {
          commons.processRestError(error)
        })

      } else {

        // load only explicitly assigned buildings
        this.selector.buildings = getUserAuthorities().filter(auth => {
          return auth.authorizationTargetPath.building && auth.authorizationTargetPath.building.status == 'ACTIVE' && auth.authorizationTargetPath.organisation.id == this.contextOrg.id
        }).map(auth => {
          if (auth.role == 'BUILDING_MANAGER') {
            auth.authorizationTargetPath.building.includeAllChildren = true
          }
          return auth.authorizationTargetPath.building
        })

        this.selector.buildings = _.sortBy(this.getUniqueListBy(this.selector.buildings, "id"), [function (o) {
          return o.name;
        }])

        if (this.preselectedResourceAll && this.preselectedResourceAll.building && this.selector.buildings.some(building => building.id == this.preselectedResourceAll.building.id)) {
          this.selector.buildingSelected = this.selector.buildings.find(building => building.id == this.preselectedResourceAll.building.id)
          this.buildingSelectedChanged(true)
          return
        } else if(this.selector.buildings.length > 0){
          this.selector.buildingSelected = this.selector.buildings[0]
          this.buildingSelectedChanged(false)
          return
        }
      }

      this.callResetCallBack()
    },

    buildingSelectedChanged(preselect){

      console.log('buildingSelectedChanged ' + this.selector.buildingSelected.id)

      this.error = ""
      this.selector.devices = []
      this.selector.deviceSelected = null

      if(this.selector.buildingSelected.includeAllChildren){

        //load building metering points
        Vue.axios.get('/admin/buildings/' + this.selector.buildingSelected.id + '/devices', { params: { type: "METERING_POINT" }}).then(response => {

          this.selector.devices = _.sortBy(response.data.map(mp => {
            mp.includeAllChildren = true
            return mp
          }), [function(o) { return o.name }])

          if(preselect===true && this.preselectResource && this.preselectResource.device && this.selector.devices.some(map => map.id == this.preselectResource.device.id)){
            this.selector.deviceSelected = this.selector.devices.find(device => device.id == this.preselectResource.device.id)
            this.select(true)
            return
          } else if(this.selector.devices.length > 0){
            this.selector.deviceSelected = this.selector.devices[0]
            this.select(false)
            return
          }

        }).catch(error => {
          commons.processRestError(error)
        })

      } else {

        // load only explicitly assigned location maps
        let authorizedMaps = getUserAuthorities().filter(auth => { return auth.authorizationTargetPath.map && auth.authorizationTargetPath.map.status == 'ACTIVE' && auth.authorizationTargetPath.building.id == this.selector.buildingSelected.id }).map(auth => {
          return auth.authorizationTargetPath.map
        })

        let authorizedLocations = getUserAuthorities().filter(auth => { return auth.authorizationTargetPath.map && auth.authorizationTargetPath.map.status == 'ACTIVE' && auth.authorizationTargetPath.building.id == this.selector.buildingSelected.id }).map(auth => {
            return auth.authorizationTargetPath.map
        })


        this.selector.maps = _.sortBy(this.getUniqueListBy(this.selector.maps, "id"), [function(o) { return o.name; }])

        if(preselect===true && this.preselectResource && this.preselectResource.device && this.selector.devices.some(map => map.id == this.preselectResource.device.id)){
          this.selector.deviceSelected = this.selector.devices.find(mp => mp.id == this.preselectResource.device.id)
          this.select(true)
          return
        } else if(this.selector.devices.length > 0){
          this.selector.deviceSelected = this.selector.devices[0]
          this.select(false)
          return
        }
      }

      this.callResetCallBack()
    },

    select(preselect) {

      if(this.selectedCallback && preselect !== true){
        this.selectedCallback({
          organisationSelected: this.contextOrg,
          buildingSelected: this.selector.buildingSelected,
          device: this.selector.deviceSelected
        })
      }
    },

    callResetCallBack() {
      if (this.resourceSelectedResetCallback) {
        this.resourceSelectedResetCallback()
      }
    }
  }
}
</script>