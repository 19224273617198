<template>
  <div class="row wrapper border-bottom white-bg page-heading" v-if="map && $route.meta.titleKey">
    <div class="col-lg-10">
      <h2>{{ $t($route.meta.titleKey) }}</h2>
      <h4 v-if="$route.meta.subtitleKey">{{ $t($route.meta.subtitleKey) }}</h4>
      <ol class="breadcrumb" v-if="map.building && map.building.organisation">
        <li class="breadcrumb-item" v-if="$route.params.prefix === 'admin'">
          <router-link to="/admin/organisations" >{{ $t('menu.organisationAdmin') }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link v-if="hasRole('ADMIN') || hasResourceRole('ORGANISATION_MANAGER', map.building.organisation.id) "
                       :to="$route.params.prefix ? ('/' + $route.params.prefix + '/organisations/' + map.building.organisation.id) : ('/organisations/' + map.building.organisation.id)" >{{ map.building.organisation.name }}</router-link>
          <span v-else>{{ map.building.organisation.name }}</span>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="$route.params.prefix ? ('/' + $route.params.prefix + '/buildings/' + map.building.id) : ('/buildings/' + map.building.id)" >{{ map.building.name }}</router-link>
        </li>
        <li class="breadcrumb-item active">
          <strong>{{ map.name }}</strong>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      map: state => state.buildings.mapDetails.entity
    }),
  },
  methods: {
  },
}
</script>
