<template>
  <div class="animated fadeInRight">

    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="ibox-content p-5">

            <validation-observer ref="form" v-slot="{ handleSubmit }">

              <b-form role="form" @submit.stop.prevent="handleSubmit(submit)">

                <div class="row">

                  <div class="col-12">

                    <validation-provider name="orgAdmin.shareLink.attributes.name" :rules="{ required: true, min: 3, max: 50 }" v-slot="validationContext">
                      <b-form-group id="input-group-shareLink-name" :label="$t('orgAdmin.shareLink.attributes.name')" label-for="input-shareLink-name" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-shareLink-name"
                            name="input-shareLink-name"
                            v-model="form.name"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-shareLink-name"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-shareLink-name">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <b-form-group v-if="form.status" id="input-group-shareLink-status" :label="$t('orgAdmin.shareLink.attributes.status')" label-for="input-shareLink-status" label-cols-sm="2">
                        <span class="label" :class="[form.status == 'INACTIVE' ? 'label-default' : 'label-primary']">{{ $i18n.t('enums.ShareLinkStatus.' + form.status) }}</span>
                    </b-form-group>

                    <b-form-group v-if="form.hash" id="input-group-shareLink-link" :label="$t('orgAdmin.shareLink.attributes.link')" label-for="input-shareLink-link" label-cols-sm="2">
                        <b-link id="shareViewLink" :to="{ name: 'shareLiveView', params: { hash: form.hash } }" target="_blank">#/share-live-view/{{form.hash}}</b-link>
                        <button type="button" v-if="type!='create'" class="btn btn-xs btn-default ml-3" @click="copyLink"><i class="fa fa-copy"></i></button>
                    </b-form-group>

                    <validation-provider name="orgAdmin.shareLink.attributes.attributes" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-shareLink-attributes" :label="$t('orgAdmin.shareLink.attributes.attributes')" label-for="input-shareLink-attributes" label-cols-sm="2">
                            <b-form-checkbox-group
                                switches
                                size="lg"
                                :disabled="type=='view'"
                                id="input-shareLink-attributes"
                                v-model="form.attributes"
                                :options="$t('orgAdmin.shareLink.attributeOptions')"
                                name="input-shareLink-attributes"
                                aria-describedby="input-feedback-shareLink-attributes"
                            ></b-form-checkbox-group>
                            <b-form-invalid-feedback id="input-feedback-shareLink-attributes" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <validation-provider v-if="state_groupLocationsByMap && Object.keys(state_groupLocationsByMap).length > 0" name="orgAdmin.shareLink.attributes.locations" :rules="{ 'locationsSelected':true }" v-slot="validationContext">

                        <b-form-group id="input-group-shareLink-locations" class="mt-4" :label="$t('orgAdmin.shareLink.attributes.locations')" label-for="input-shareLink-locations">
                            <div class="row">
                                <div class="col-xxxl-3 col-xxl-4 col-lg-6" v-for="(value, propertyName) in state_groupLocationsByMap" :key="propertyName">
                                      <b-form-checkbox
                                              v-if="state_groupLocationsByMap[propertyName]"
                                              :disabled="type=='view'"
                                              switch
                                              size="lg"
                                              v-model="allSelected[propertyName]"
                                              :aria-describedby="'group_' + propertyName"
                                              :aria-controls="'group_' + propertyName"
                                              @change="toggleMapSelectAllLocations($event, propertyName)"
                                      >
                                          {{ state_groupLocationsByMap[propertyName].nameWithStatus }} <template v-if="false">- {{ allSelected[propertyName] === true ? $t('common.actions.unselectAll') : $t('common.actions.selectAll')  }}</template>
                                      </b-form-checkbox>
                                      <b-form-checkbox-group
                                              v-if="state_groupLocationsByMap[propertyName] && state_groupLocationsByMap[propertyName].locations"
                                              :disabled="type=='view'"
                                              switches
                                              stacked
                                              size="lg"
                                              :id="'group_' + propertyName"
                                              :name="'group_' + propertyName"
                                              v-model="form.locations"
                                              value-field="id"
                                              text-field="nameWithStatus"
                                              :options="state_groupLocationsByMap[propertyName].locations"
                                              class="ml-5 m-t-sm m-b-lg"
                                              aria-describedby="input-feedback-shareLink-locations"
                                      ></b-form-checkbox-group>
                                </div>
                            </div>
                            <b-form-invalid-feedback id="input-feedback-shareLink-locations" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>

                    </validation-provider>

                  </div>

                </div>

                <div class="row m-t-md">
                  <div class="col-12">
                    <button  type="button" class="btn btn-sm btn-primary" v-if="shareLink && type=='view' && hasOneOfRoles('ADMIN', 'ORGANISATION_MANAGER', 'BUILDING_MANAGER')" @click.prevent="type='edit'">
                      <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.shareLink.actions.edit') }}
                    </button>
                    <button type="submit" v-if="type=='create'" class="btn btn-sm btn-primary">{{ $t('common.actions.create') }}</button>
                    <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary">{{ $t('common.actions.save') }}</button>
                    <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-secondary m-l-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
                    <button type="button" v-if="shareLink && shareLink.status=='ACTIVE' && type!='create'" class="btn btn-sm btn-warning m-l-sm" @click="deactivate">{{ $t('orgAdmin.shareLink.actions.deactivate') }}</button>
                    <button type="button" v-if="shareLink && shareLink.status=='INACTIVE' && type!='create'" class="btn btn-sm btn-warning m-l-sm" @click="activate">{{ $t('orgAdmin.shareLink.actions.activate') }}</button>
                    <button type="button" class="btn btn-sm btn-white m-l-sm" @click="cancel">{{ $t('common.actions.back') }}</button>
                    <button type="button" v-if="shareLink && shareLink.status=='INACTIVE' && type!='create'" class="btn btn-sm btn-info float-right" @click="deleteLink">{{ $t('orgAdmin.shareLink.actions.delete') }}</button>
                  </div>
                </div>

              </b-form>
            </validation-observer>


          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {mapState} from "vuex"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue from "vue"
import * as commons from "@/commons"
import i18n from "@/i18n"
import router from "@/router"
import { extend } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            error: null,
            type: 'view',
            allSelected: {},
            form: {
                id: null,
                name: '',
                hash: null,
                attributes: [],
                locations: []
            }
        }
    },
    created() {
        console.log('shareLinkDetails $route.name: ' + this.$route.name)
        this.type = this.$route.name == 'shareLinkNew' ? 'create' : 'view'
        if (this.type == 'view') {
            this.$store.dispatch('shareData/fetchShareLinkDetails', this.$route.params.id)
        } else {
            this.$store.dispatch('shareData/fetchBuildingLocations', this.building.id)
            this.$store.commit('shareData/SAVE_SHARE_LINK_DETAILS_ENTITY', null)
            this.init()
        }
        extend("locationsSelected", {
            message:
                "One or more locations must be selected.",
            validate: value => {
                return this.form.locations.length > 0
            }
        })
    },
    watch: {
        shareLink: function (newVal, oldVal) { // watch it
            console.log('Prop shareLink changed: ', newVal, ' | was: ', oldVal)
            this.init()
        },
        state_groupLocationsByMap: function (newVal, oldVal) {
            console.log("watch state_groupLocationsByMap newVal " + newVal + ", oldVal " + oldVal)
            if(newVal) {
                for (const [key, value] of Object.entries(newVal)) {
                    this.allSelected[key] = value.locations.every(val => this.form.locations.includes(val.id));
                }
            }
        },
    },
    computed: {
        ...mapState({
            currentUser: state => state.userInfo,
            contextOrg: state => state.contextOrg,
            contextBuilding: state => state.contextBuilding,
            shareLink: state => state.shareData.shareLinkDetails.entity,
            buildingLocations: state => state.shareData.shareLinkDetails.locations
        }),
        organisation() {
          console.log('organisation() prefix ' + this.$route.params.prefix)
          return this.$route.params.prefix === 'admin' ? this.$store.state.organisation.organisationDetails.entity : this.$store.state.contextOrg
        },
        building() {
          console.log('building() prefix ' + this.$route.params.prefix)
          return this.$route.params.prefix === 'admin' ? this.$store.state.buildings.buildingDetails.entity : this.$store.state.contextBuilding
        },
        state_groupLocationsByMap() {
            let result = null
            let vm = this
            if (this.buildingLocations && this.buildingLocations.length > 0) {
                result = this.buildingLocations.reduce((acc, location) => {
                    const key = location.map.id;
                    location.nameWithStatus = (location.name + (location.status !== 'ACTIVE' ? ' (' + vm.$t("enums.ResourceStatus.BLOCKED") + ')': ''))
                    if(acc[key]){
                        acc[key].locations.push(location)
                    } else {
                        acc[key] = { name: location.map.name, nameWithStatus: location.map.name + (location.map.status !== 'ACTIVE' ? ' (' + vm.$t("enums.ResourceStatus.BLOCKED") + ')' : '') , locations: [location] }
                    }
                    return acc;
                }, {})
                console.log(result)
            }
            return result
        }

    },
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null
        },
        copyLink(){
            navigator.clipboard.writeText(document.getElementById("shareViewLink").href)
            this.$toastr.i(i18n.t('orgAdmin.shareLink.info.linkCopied'))
        },
        reset() {
            if (this.$route.name == 'shareLinkNew') {
                this.form.id = null
                this.form.name = ''
                this.form.hash = ''
                this.form.attributes = ['temperature']
                this.form.locations = []
                this.allSelected = {}
            } else {
                this.form.id = this.shareLink.id
                this.form.name = this.shareLink.name
                this.form.status = this.shareLink.status
                this.form.hash = this.shareLink.hash
                this.form.attributes = JSON.parse(JSON.stringify(this.shareLink.attributes))
                this.form.locations = this.shareLink.locations.map(location => location.id)
                this.allSelected = {}
                this.type = 'view'
            }

            if (this.$refs.form) {
                this.$refs.form.reset()
            }

        },
        init() {
            this.reset()
            let vm = this
            this.$nextTick(() => {
                vm.$refs.form.reset()
            })
        },
        toggleMapSelectAllLocations(checked, mapId){
            console.log('checked ' + checked + ', mapId ' + mapId)
            let mapLocations = this.state_groupLocationsByMap[mapId] ? this.state_groupLocationsByMap[mapId].locations : null
            if(mapLocations) {
                if (checked) {
                    mapLocations.forEach(location => {
                        if (this.form.locations.findIndex(id => id === location.id) === -1) {
                            this.form.locations.push(location.id)
                        }
                    })
                }else {
                    mapLocations.forEach(location => {
                        let indexToRemove = this.form.locations.findIndex(id => id === location.id)
                        if (indexToRemove !== -1) {
                            this.form.locations.splice(indexToRemove, 1)
                        }
                    })
                }
            }
        },
        cancel() {
            this.goBack()
        },
        activate() {
            let self = this
            Vue.axios.put('/admin/share-links/' + self.form.id + '/activate')
                .then(response => {
                    self.$toastr.i(i18n.t('orgAdmin.shareLink.info.activated'))
                    self.$store.dispatch('orgAdmin/fetchShareLinkDetails', this.$route.params.id)
                })
                .catch(error => {
                    commons.processRestError(error)
                })
        },
        deactivate() {
            let self = this
            Vue.axios.put('/admin/share-links/' + self.form.id + '/deactivate')
                .then(response => {
                    self.$toastr.i(i18n.t('orgAdmin.shareLink.info.deactivated'))
                    self.$store.dispatch('orgAdmin/fetchShareLinkDetails', this.$route.params.id)
                })
                .catch(error => {
                    commons.processRestError(error)
                })
        },
        deleteLink() {
            let self = this
            this.confirmDialog({
                text: this.$t('orgAdmin.shareLink.confirm.delete', {'0': self.form.name}),
                callbackYes: function () {
                    Vue.axios.delete('/admin/share-links/' + self.form.id)
                        .then(response => {
                            self.$toastr.i(i18n.t('orgAdmin.shareLink.info.deleted'))
                            self.goBack()
                        })
                        .catch(error => {
                            commons.processRestError(error)
                        })
                }
            })
        },
        submit() {
            this.error = ""
            this.info = ""
            let vm = this
            if (this.$route.name == 'shareLinkNew') {
                let formToSend = Object.assign({}, this.form)
                formToSend.building = { id: router.currentRoute.query.buildingId ? router.currentRoute.query.buildingId : this.building.id }
                formToSend.locations = formToSend.locations.map(locationId => { return { id: locationId } })
                Vue.axios.post('/admin/share-links', JSON.stringify(formToSend),
                    {
                        headers: {'Content-Type': 'application/json'}
                    }).then(response => {

                    this.$toastr.i(i18n.t('orgAdmin.shareLink.info.created'))
                    this.goBack()
                }).catch(error => {
                    if (error.response.status === 422) {
                        vm.$refs.form.setErrors(error.response.data)
                    } else {
                        commons.processRestError(error)
                    }
                })
            } else {
                let formToSend = Object.assign({}, this.form)
                formToSend.building = this.shareLink.building
                formToSend.locations = formToSend.locations.map(locationId => { return { id: locationId } })
                Vue.axios.put('/admin/share-links/' + formToSend.id, JSON.stringify(formToSend),
                    {
                        headers: {'Content-Type': 'application/json'}
                    }).then(response => {

                    this.$toastr.i(i18n.t('orgAdmin.shareLink.info.saved'))
                    this.goBack()
                }).catch(error => {
                    if (error.response.status === 422) {
                        vm.$refs.form.setErrors(error.response.data)
                    } else {
                        commons.processRestError(error)
                    }
                })
            }
        }
    }
}
</script>

<style>
</style>
