<template>
  <div>
    <div class="row equal-height">
      <div class="col-lg-12">
        <div>
          <feedback-responses />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import FeedbackResponses from "@/components/views/feedback/FeedbackResponses.vue";

export default {
  components:{
    FeedbackResponses,
  },
  data() {
    return {
      error: null,
      info: null,
      activeTab: this.$store.state.feedback.activeTab
    }
  },
  created() {
    console.log("Feedback created")
    if (this.$route.query.activeTab != null) {
      this.activeTab = parseInt(this.$route.query.activeTab)
      this.$router.replace({'query': null});
      this.tabSelected()
    }
    this.fetchOrganisationForms()
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      contextOrg: state => state.contextOrg,
    }),
  },
  watch: {
    contextOrg: function (newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.fetchIfOrgChanged()
      }
    }
  },
  methods: {
    fetchIfOrgChanged(){
      console.log('fetchIfOrgChanged ' + this.contextOrg?.id)
      this.$store.commit('feedback/UPDATE_SELECTED_FORM', null)
      this.form = null
      this.location = null
    }
  }
}
</script>
