<template>
  <div class="animated fadeInRight">
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="ibox-content p-5">
            <div class="row">
              <div class="col-12">
                <building-view-create-edit
                    :building="{ organisation: organisation }"
                    :can-edit="true"
                    initial-type="create"
                    :success-callback="createSuccessCallback"
                    :cancel-callback="createCancelCallback"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import BuildingViewCreateEdit from "@/components/views/admin/organisations/BuildingViewCreateEdit.vue";

export default {
  components: {
    BuildingViewCreateEdit
  },
  data() {
    return {
      error: null,
      filterText: '',
      locationList: {
        filter: null,
        page: 1,
        size: 10
      },
      map: {
        name: '',
        image: ''
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      contextOrg: state => state.contextOrg
    }),
    organisation() {
      console.log('organisation() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.organisation.organisationDetails.entity : this.$store.state.contextOrg
    }
  },
  methods: {
    createSuccessCallback(){
      this.goBack()
    },
    createCancelCallback(){
      this.goBack()
    },
  }
}
</script>

<style scoped>

</style>