<template>
  <div>
    <transition name="fade" appear>
      <div>
        <div class="row mt-5">
          <div class="col-12">
            <h4>{{ $t('analytics.mapConfig.dateRangeSelector.title') }}</h4>
            <div class="text-center pt-4">
              <div class="row">
                <div class="col-12">
                  <date-range-picker
                      ref="picker"
                      opens="left"
                      :locale-data="Object.assign({}, $t('dateRangePickerLabels'), { format: 'dd-mm-yyyy' })"
                      :maxDate="maxDate"
                      :autoApply="false"
                      :showDropdowns="true"
                      v-model="dateRange"
                      @update="selectedDateRangeChanged"
                  >
                    <template v-slot:input="picker">
                      <div style="max-width: 350px;">
                        {{ picker.startDate | formatDateByLocale }} - {{ picker.endDate | formatDateByLocale}}
                      </div>
                      </template>
                  </date-range-picker>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <h4>{{ $t('analytics.mapConfig.limits.title') }}</h4>
            <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
              <div>
                <b-form-group id="input-group-attribute-1" label-cols-sm="2" :label="$t('analytics.mapConfig.limits.property')">

                  <v-select
                      id="input-device-model-attribute"
                      name="input-device-model-attribute"
                      v-model="selectedSensorAttribute"
                      :options="availableSensorAttributesSorted"
                      @input="selectedSensorAttributeChanged"
                      :clearable="false"
                      style="display: inline-block; width: 12em;"
                      class="ml-2 mr-2">
                  </v-select>
                </b-form-group>

              </div>
            </transition>
          </div>
          <div class="col-12 mt-4">
            <div class="row justify-content-center">
              <button type="button" :disabled="fetchEnabled" class="btn btn-sm btn-primary" @click.prevent="fetchData">{{ $t('analytics.mapConfig.actions.fetchData') }}</button>
            </div>
          </div>
        </div>
      </div>

    </transition>

  </div>
</template>

<script>
import {mapState} from "vuex";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import i18n from "@/i18n";

export default {
  components: {
    DateRangePicker
  },
  data() {
    return {
      info: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedSensorAttribute: null,
    }
  },
  created(){
    console.log('MapConfig $route.name: ' + this.$route.name)
    this.dateRange = this.mapConfig.dateRange
    this.selectedSensorAttribute = this.mapConfig.selectedSensorAttribute
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedMap: state => state.analytics.selectedMap,
      availableSensorAttributes: state => state.analytics.availableSensorAttributes,
      mapConfig: state => state.analytics.mapConfig,
    }),
    maxDate(){
        let nowMoved = this.$moment.tz(new Date(), this.selectedMap.building.timeZone)
        let newMaxDate = nowMoved.format("YYYY-MM-DD HH:mm")
        console.log('maxDate resolved date ' + newMaxDate)
        return newMaxDate
    },
    availableSensorAttributesSorted() {
      let preferredOrder = this.$store.state.systemParams.preferredSensorOrder
      let sorted = [...this.availableSensorAttributes].sort(function (a, b) {
        return preferredOrder.indexOf(a) - preferredOrder.indexOf(b)
      })
      return sorted.map( item => { return { label: i18n.t('enums.sensorType.' + item + '.name'), code: item } })
    },
    fetchEnabled() {
      return !this.dateRange || !this.mapConfig.selectedSensorAttribute
    }
  },
  watch: {
    availableSensorAttributes: function(newVal, oldVal) {
      console.log('availableSensorAttribute changed: ', newVal, ' | was: ', oldVal)
    }
  },
  methods: {
    selectedDateRangeChanged(){
      this.$store.dispatch('analytics/mapConfigDateRangeSelected', { dateRange: this.dateRange })
    },
    selectedSensorAttributeChanged() {
      console.log('selectedSensorAttribute changed: ' + this.selectedSensorAttribute)
      this.$store.dispatch('analytics/mapConfigSensorAttributeChanged', this.selectedSensorAttribute)
    },
    fetchData(){
      console.log('fetchData')
      this.$store.dispatch('analytics/fetchDataHeatmap', {selectedSensorAttribute: this.selectedSensorAttribute.code} )
    }
  }
}
</script>

<style>
.b-time  .form-control {
  padding: 6px;
}
.custom-switch.b-custom-control-lg .custom-control-label, .input-group-lg .custom-switch .custom-control-label {
  font-size: 1rem;
  padding-top: 3px;
  line-height: 1.5;
}
.custom-control.custom-radio.b-custom-control-lg, custom-control.custom-radio.b-custom-control-lg .custom-control-label{
  font-size: 1rem;
  padding-top: 3px;
  line-height: 1.5;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>