<template>
  <div>

    <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
      <button class="close" type="button" @click="error = ''">×</button>
    </div>

    <div class="row">

      <div class="col-12">

        <b-form-group id="input-group-assign-org" :label="$t('orgAdmin.userResourceRoleAssign.organisation')" label-for="input-assign-org" label-cols-sm="3">
            <v-select
                :disabled="selector.organisations.length == 1 && selector.organisationSelected && !selector.organisationSelected.includeAllChildren"
                id="input-assign-org"
                name="input-assign-org"
                v-if="selector.organisations.length > 0"
                v-model="selector.organisationSelected"
                :options="selector.organisations"
                label="name"
                @input="orgSelected"
                :clearable="false"
            />
        </b-form-group>

        <b-form-group v-if="selector.buildings.length > 0" id="input-group-assign-building" :label="$t('orgAdmin.userResourceRoleAssign.building')" label-for="input-assign-building" label-cols-sm="3">
          <v-select
              :disabled="selector.buildings.length == 1 && selector.buildingSelected && !selector.buildingSelected.includeAllChildren"
              id="input-assign-building"
              name="input-assign-building"
              v-model="selector.buildingSelected"
              :options="selector.buildings"
              label="name"
              @input="buildingSelected"
              :clearable="true"
          />
        </b-form-group>

        <b-form-group v-if="selector.maps.length > 0" id="input-group-assign-map" :label="$t('orgAdmin.userResourceRoleAssign.map')" label-for="input-assign-map" label-cols-sm="3">
          <v-select
              :disabled="selector.maps.length == 1 && selector.mapSelected && !selector.mapSelected.includeAllChildren"
              id="input-assign-map"
              name="input-assign-map"
              v-model="selector.mapSelected"
              :options="selector.maps"
              label="name"
              @input="mapSelected"
              :clearable="true"
          />
        </b-form-group>

        <b-form-group v-if="selector.locations.length > 0" id="input-group-assign-location" :label="$t('orgAdmin.userResourceRoleAssign.location')" label-for="input-assign-location" label-cols-sm="3">
          <v-select
              :disabled="selector.locations.length == 1 && selector.locationSelected && !selector.locationSelected.includeAllChildren"
              id="input-assign-location"
              name="input-assign-location"
              v-model="selector.locationSelected"
              :options="selector.locations"
              label="label"
              @input="locationSelected"
              :clearable="true"
          />
        </b-form-group>

        <b-form-group v-if="selector.roles.length > 0" id="input-group-assign-role" :label="$t('orgAdmin.userResourceRoleAssign.role')" label-for="input-assign-role" label-cols-sm="3">
          <v-select
              readonly="selector.roles.length < 2"
              id="input-assign-role"
              name="input-assign-role"
              v-model="selector.roleSelected"
              :options="selector.roles"
              :reduce="role => role.id"
              label="name"
              :clearable="false"
          />
        </b-form-group>

      </div>

    </div>

    <div class="row">
      <div class="col-12">
        <button type="button" class="btn btn-sm btn-primary" @click.prevent="assign">{{ $t('common.actions.assign') }}</button>
        <button type="button" class="btn btn-sm btn-secondary m-l-sm" @click="initialFetch">{{ $t('common.actions.reset') }}</button>
        <button v-if="cancelCallback" type="button" class="btn btn-sm btn-white m-l-sm" @click="cancel">{{ $t('common.actions.cancel') }}</button>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from "vue"
import * as commons from "@/commons"
import { getUserAuthorities } from "@/commons"
import _ from "lodash"

export default {
  props: {
    user: {
      type: Object,
      required: false
    },
    preselectOrg: {
      type: Object
    },
    successCallback: {
      type: Function,
      required: false
    },
    cancelCallback: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      error: null,
      info: null,
      selector: {
        organisations: [],
        buildings: [],
        maps: [],
        locations: [],
        roles: [],
        organisationSelected: null,
        buildingSelected: null,
        mapSelected: null,
        locationSelected: null,
        roleSelected: null
      }
    }
  },
  created(){
    this.initialFetch()
  },
  watch: {
    preselectOrg: function (newVal, oldVal) {
      console.log('Prop preselectResource changed')
      if (newVal?.id !== oldVal?.id) {
          this.initialFetch()
      }
    }
  },
  methods: {
    cancel(){
      if(this.cancelCallback){
        this.cancelCallback()
      }
    },
    initialFetch() {

      console.log('UserResourceRoleAssign.vue initialFetch preselectOrg ' + this.preselectOrg?.id)

      this.selector.organisations = []
      this.selector.buildings = []
      this.selector.maps = []
      this.selector.locations = []

      this.selector.organisationSelected = null
      this.selector.buildingSelected = null
      this.selector.mapSelected = null
      this.selector.locationSelected = null

      this.selector.roles = []
      this.selector.roleSelected = null

      //first get logged user authorities to calculate what can he assign to target user
      if (this.hasRole('ADMIN')) {

        // can assign literally anything -  get all organisations first
        Vue.axios.get('/admin/organisations').then(response => {
          this.selector.organisations = _.sortBy(response.data.map(org => {
            org.includeAllChildren = true
            return org
          }), [function(o) { return o.name; }])

          this.initialFetchOrganisationLoaded()

        }).catch(error => {
          commons.processRestError(error)
        })

      } else {

        this.selector.organisations = getUserAuthorities().filter(auth => { return auth.authorizationTargetPath.organisation }).map(auth => {
          if(auth.role == 'ORGANISATION_MANAGER'){
            auth.authorizationTargetPath.organisation.includeAllChildren = true
          }
          return auth.authorizationTargetPath.organisation
        })

        this.selector.organisations = _.sortBy(this.getUniqueListBy(this.selector.organisations, "id"), [function(o) { return o.name; }])

        this.initialFetchOrganisationLoaded()

      }
    },

    initialFetchOrganisationLoaded(){

      if (this.preselectOrg && this.selector.organisations.some(org => org.id == this.preselectOrg.id)) {
        this.selector.organisationSelected = this.selector.organisations.find(org => org.id == this.preselectOrg.id)
        this.orgSelected()
      } else if (this.selector.organisations.length == 1){
        this.selector.organisationSelected = this.selector.organisations[0]
        this.orgSelected()
      }
    },

    orgSelected(){

      console.log('orgSelected ' + this.selector.organisationSelected)

      this.error = ""
      this.selector.buildings = []
      this.selector.maps = []
      this.selector.locations = []
      this.selector.buildingSelected = null
      this.selector.mapSelected = null
      this.selector.locationSelected = null
      this.selector.roles = []
      this.selector.roleSelected = null

      if(this.selector.organisationSelected.includeAllChildren){

        //load organisation with buildings
        Vue.axios.get('/admin/organisations/' + this.selector.organisationSelected.id).then(response => {
          this.selector.buildings = _.sortBy(response.data.buildings.map(building => {
            building.includeAllChildren = true
            return building
          }), [function(o) { return o.name; }])
        }).catch(error => {
          commons.processRestError(error)
        })

        this.selector.roles = [{id: "ORGANISATION_MANAGER", name: this.$i18n.t('enums.UserRole.ORGANISATION_MANAGER')}]
        this.selector.roleSelected = "ORGANISATION_MANAGER"

      } else {

          // load only explicitly assigned buildings
        this.selector.buildings = getUserAuthorities().filter(auth => { return auth.authorizationTargetPath.building && auth.authorizationTargetPath.organisation.id == this.selector.organisationSelected.id }).map(auth => {
            if(auth.role == 'BUILDING_MANAGER'){
              auth.authorizationTargetPath.building.includeAllChildren = true
            }
            return auth.authorizationTargetPath.building
          })

        this.selector.buildings = _.sortBy(this.getUniqueListBy(this.selector.buildings, "id"), [function(o) { return o.name; }])

        if (this.selector.buildings.length == 1) {
          this.selector.buildingSelected = this.selector.buildings[0]
          this.buildingSelected()
        }
      }
    },

    buildingSelected(){

      console.log('buildingSelected ' + this.selector.buildingSelected)

      this.error = ""
      this.selector.maps = []
      this.selector.locations = []
      this.selector.mapSelected = null
      this.selector.locationSelected = null
      this.selector.roles = []
      this.selector.roleSelected = null

      if(this.selector.buildingSelected == null){
        this.orgSelected()
        return
      }

      if(this.selector.buildingSelected.includeAllChildren){

        //load building with maps
        Vue.axios.get('/admin/buildings/' + this.selector.buildingSelected.id).then(response => {
          this.selector.maps = _.sortBy(response.data.maps.map(map => {
            map.includeAllChildren = true
            return map
          }), [function(o) { return o.name; }])
        }).catch(error => {
          commons.processRestError(error)
        })

        this.selector.roles = [{id: "BUILDING_MANAGER", name: this.$i18n.t('enums.UserRole.BUILDING_MANAGER')}]
        this.selector.roleSelected = "BUILDING_MANAGER"

      } else {

        // load only explicitly assigned location maps
        this.selector.maps = getUserAuthorities().filter(auth => { return auth.authorizationTargetPath.map && auth.authorizationTargetPath.building.id == this.selector.buildingSelected.id }).map(auth => {
          return auth.authorizationTargetPath.map
        })

        this.selector.maps = _.sortBy(this.getUniqueListBy(this.selector.maps, "id"), [function(o) { return o.name; }])

        if (this.selector.maps.length == 1) {
          this.selector.mapSelected = this.selector.maps[0]
          this.mapSelected()
        }
      }
    },

    mapSelected(){

      console.log('mapSelected ' + this.selector.mapSelected)

      this.error = ""
      this.selector.locations = []
      this.selector.locationSelected = null
      this.selector.roles = []
      this.selector.roleSelected = null

      if(this.selector.mapSelected == null){
        this.buildingSelected()
        return
      }

      if(this.selector.mapSelected.includeAllChildren){

        //load map with locations
        Vue.axios.get('/admin/maps/' + this.selector.mapSelected.id).then(response => {
          this.selector.locations = _.map(
            _.sortBy(response.data.locations, [function (o) { return o.name; }]),
            location => ({
              ...location,
              label: location.nameAlternate ? `${location.name} (${location.nameAlternate})` : location.name
            })
          );
        }).catch(error => {
          commons.processRestError(error)
        })

      } else {

        // load only explicitly assigned locations
        this.selector.locations = getUserAuthorities().filter(auth => { return auth.authorizationTargetPath.location && auth.authorizationTargetPath.map.id == this.selector.mapSelected.id }).map(auth => {
          return auth.authorizationTargetPath.location
        })

        let uniqueSortedLocations = _.sortBy(this.getUniqueListBy(this.selector.locations, "id"), [function (o) { return o.name; }]);
        this.selector.locations = uniqueSortedLocations.map(location => ({
            ...location,
            label: location.nameAlternate ? `${location.name} (${location.nameAlternate})` : location.name
        }));

        if (this.selector.locations.length == 1) {
          this.selector.locationSelected = this.selector.locations[0]
          this.locationSelected()
        }
      }
    },

    locationSelected(){
      console.log('locationSelected ' + this.selector.locationSelected)
      this.selector.roles = [{ id: "ROOM_USER", name: this.$i18n.t('enums.UserRole.ROOM_USER') }, { id: "ROOM_MANAGER", name: this.$i18n.t('enums.UserRole.ROOM_MANAGER') }]
      this.selector.roleSelected = null

      if(this.selector.locationSelected == null){
        this.mapSelected()
        return
      }
    },

    assign() {

      this.error = ""

      if(this.selector.roleSelected == null){
        this.error = this.$i18n.t('orgAdmin.userResourceRoleAssign.validation.roleRequired')
        return
      }

      let resolvedResourceObject =  null
      if(this.selector.locationSelected){
        resolvedResourceObject = this.selector.locationSelected
      } else if(this.selector.buildingSelected){
        resolvedResourceObject = this.selector.buildingSelected
      } else if(this.selector.organisationSelected){
        resolvedResourceObject = this.selector.organisationSelected
      }

      if(this.selector.organisationSelected == null){
        this.error = this.$i18n.t('orgAdmin.userResourceRoleAssign.validation.resourceRequired')
        return
      }

      if(this.user) {

        if (this.user.authorities && this.user.authorities.some(authority => authority.role === this.selector.roleSelected && authority.authorizationTarget.id == resolvedResourceObject.id)){
          this.error = this.$i18n.t('orgAdmin.userResourceRoleAssign.validation.alreadyAssigned')
          return
        }

        let self = this

        Vue.axios.post('/admin/user-resource-role', {
          userId: self.user.id,
          resourceId: resolvedResourceObject.id,
          role: this.selector.roleSelected
        })
            .then(response => {
              self.$toastr.i(self.$i18n.t('orgAdmin.userResource.info.created'))
              if (self.successCallback) {
                self.successCallback()
              }
            })
            .catch(error => {
              if (error.response.status === 403) {
                self.error = self.$i18n.t('orgAdmin.userResourceRoleAssign.error.assignNotAuthorized')
              } else if (error.response.status === 422) {
                self.error = self.$i18n.t('orgAdmin.userResourceRoleAssign.error.alreadyAssigned')
                self.$refs.form.setErrors(error.response.data)
              } else {
                commons.processRestError(error)
              }
            })
      } else if(this.successCallback){
        this.successCallback({
          resource: resolvedResourceObject,
          role: this.selector.roleSelected
        })
      }
    }
  }
}
</script>