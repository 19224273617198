<template>
  <div class="animated fadeInRight">
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="ibox-content p-5">
            <div class="row">
              <div class="col-12">
                <org-view-create-edit
                    initial-type="create"
                    :success-callback="createSuccessCallback"
                    :cancelCallback = "createCancelCallback"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import OrgViewCreateEdit from "@/components/views/admin/organisations/OrgViewCreateEdit";

export default {
  components: {
    OrgViewCreateEdit,
  },
  data() {
    return {
      error: null
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
    }),
  },
  methods: {
    createSuccessCallback(){
      this.$store.dispatch("reloadContextOrgOptionsForAdmin")
      this.goBack()
    },
    createCancelCallback(){
      this.goBack()
    },
  }
}
</script>

<style scoped>

</style>