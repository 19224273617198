<template>
  <div>

    <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
      <button class="close" type="button" @click="error = ''">×</button>
    </div>

    <div class="form-group row m-b-md" v-if="items.length != 0">
      <div class="col-lg-6">
        <div class="input-group">
          <span class="input-group-prepend">
            <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length == 0" @click="clearFilter"> {{
                $t('common.actions.clear')
              }}</b-button>
          </span>
          <b-form-input type="text"
                v-model="table.filter"
                debounce="300"
                max="50"
                :placeholder="$t('enterForSearch')"
                class="form-control form-control-sm"/>
        </div>
      </div>
      <div class="col-lg-3 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="items.length"></row-count>
      </div>
      <div class="col-lg-3 text-right" v-if="items.length && items.length > table.size">
        <b-pagination v-if="items.length" size="sm" class="m-t-xs" align="right"
                      :total-rows="items.length"
                      v-model="table.page"
                      :per-page="table.size"></b-pagination>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-table responsive _hover _striped
                 v-if="items.length != 0"
                 _class="text-nowrap"
                 thead-class="text-nowrap"
                 :filter="table.filter"
                 :fields="table.fields"
                 :current-page="table.page"
                 :per-page="table.size"
                 :items="items"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc">
          <template #thead-top>
            <b-tr>
              <b-th colspan="6" class="text-center border border-default" variant="">{{ $t('orgAdmin.registerOrganisationRequest.tableHeaderGroupOrganisation') }}</b-th>
              <b-th colspan="6" class="text-center border border-default" variant="">{{ $t('orgAdmin.registerOrganisationRequest.tableHeaderGroupUser') }}</b-th>
            </b-tr>
          </template>
          <template #cell(organisationName)="data">
            <b-link :to="{ path: routePrefix + '/organisation/register-organisation-requests/' + data.item.id }">{{ data.value }}</b-link>
          </template>
          <template #cell(status)="data">
            <span class="label" :class="{'label-danger':data.item.status == 'DECLINED','label-primary':data.item.status == 'APPROVED','label-warning':data.item.status == 'PENDING'}">{{ data.value}}</span>
          </template>
          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">{{ data.value }}</template>
        </b-table>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import i18n from '@/i18n'
import {mapState} from 'vuex'
import RowCount from '@/components/common/RowCount'

export default {
  components: {
    RowCount
  },
  props: {
    routePrefix: {
      default: ''
    }
  },
  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: '',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50},
          {'value': 100}
        ],
        fields: [
          {
            key: 'organisationName',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.organisationName'),
            sortable: true,
          },
          {
            key: 'organisationCountry',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.organisationCountry'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return this.countryNameFromCode(item.organisationCountry)
            }
          },
          {
            key: 'organisationCity',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.organisationCity'),
            sortable: true,
          },
          {
            key: 'organisationZipCode',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.organisationZipCode'),
            sortable: true,
          },
          {
            key: 'organisationAddress',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.organisationAddress'),
            sortable: true,
          },
          {
            key: 'organisationAddress2',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.organisationAddress2'),
            sortable: true,
          },
          {
            key: 'userUsername',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.userUsername'),
            sortable: true,
          },
          {
            key: 'userFirstName',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.userFirstName'),
            sortable: true,
          },
          {
            key: 'userLastName',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.userLastName'),
            sortable: true,
          },
          {
            key: 'userEmail',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.userEmail'),
            sortable: true,
          },
          {
            key: 'userPhone',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.userPhone'),
            sortable: true,
          },
          {
            key: 'status',
            label: this.$i18n.t('orgAdmin.registerOrganisationRequest.attributes.status'),
            sortable: true,
            class: ["text-center"],
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return i18n.t('enums.RegisterOrgRequestStatus.' + item.status)
            }
          }
        ]
      }
    }
  },
  created() {
    this.$store.dispatch('organisation/fetchRegisterOrganisationRequests')
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      items: state => state.organisation.registerOrganisationRequests
    })
  }
}
</script>
