<template>
  <!-- Use teleport to fix animations breaking popup -->
  <Teleport to="body">
    <div class="popup-overlay">
      <div class="popup">
        <div class="popup-header">
          <h2 class="popup-title">{{ title }}</h2>
          <button class="close-button" @click="closePopup()">×</button>
        </div>
        <div class="slot-wrapper">
          <slot></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>
  
<script>
import Teleport from 'vue2-teleport';

export default {
    name: 'PopupWindow',
    components: {
      Teleport,
    },
    props: {
      title: {
          type: String,
          default: 'Default Title'
      },
      closeCallback: {
        type: Function
      },
    },
    methods: {
        closePopup() {
          this.closeCallback();
        }
    }
}
</script>

<style scoped>
#fabric-canvas-wrapper {
  min-height: 200px;
  min-width: 300px;
}
#button-container {
  padding-bottom: 8px;
  display: flex;
  justify-content: flex-end;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 15px;
  text-align: left;
}

.card p {
  margin-left: 20px;
  margin-bottom: 0;
}

.card-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 5px;
}

.card-title i {
  margin-right: 10px;
}

.slot-wrapper {
  padding: 3rem;
}

.popup {
  overflow: visible;
}
</style>
