<template>
  <div class="animated fadeInRight">

    <div class="row">

      <div class="col-xxxl-6">

        <div class="ibox">
          <div class="ibox-content p-5">

            <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
              <button class="close" type="button" @click="error = ''">×</button>
            </div>

            <validation-observer ref="form" v-slot="{ handleSubmit }">

              <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(submit)">

                <div class="row">

                  <div class="col-12">

                    <validation-provider name="systemAdmin.deviceModel.attributes.name" :rules="{ required: true, min: 3, max: 100 }" v-slot="validationContext">
                      <b-form-group id="input-group-device-model-name" :label="$t('systemAdmin.deviceModel.attributes.name')" label-for="input-device-model-name" label-cols-sm="3">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-device-model-name"
                            name="input-device-model-name"
                            v-model="form.name"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-device-model-name"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-device-model-name">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider name="systemAdmin.deviceModel.attributes.deviceModelKey" :rules="{ min: 3, max: 100 }" v-slot="validationContext">
                      <b-form-group id="input-group-device-model-device-model-key" :label="$t('systemAdmin.deviceModel.attributes.deviceModelKey')" label-for="input-device-model-device-model-Key" label-cols-sm="3">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-device-model-device-model-key"
                            name="input-device-model-device-model-key"
                            v-model="form.deviceModelKey"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-device-model-device-model-key"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-device-model-device-model-key">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider name="systemAdmin.deviceModel.attributes.manufacturer" :rules="{ required: true, min: 3, max: 100 }" v-slot="validationContext">
                      <b-form-group id="input-group-device-model-manufacturer" :label="$t('systemAdmin.deviceModel.attributes.manufacturer')" label-for="input-device-model-manufacturer" label-cols-sm="3">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-device-model-manufacturer"
                            name="input-device-model-manufacturer"
                            v-model="form.manufacturer"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-device-model-manufacturer"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-device-model-manufacturer">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider name="systemAdmin.deviceModel.attributes.type" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group id="input-group-device-model-type" :label="$t('systemAdmin.deviceModel.attributes.type')" label-for="input-device-model-type" label-cols-sm="3">
                          <v-select
                                  id="input-device-model-type"
                                  name="input-device-model-type"
                                  :disabled="type=='view'"
                                  item-value="value"
                                  :reduce="type => type.value"
                                  label="text"
                                  v-model="form.type"
                                  :options="typeOptions"
                                  :clearable="false"
                                  :class="{'is-invalid': validationContext.errors.length > 0}"
                                  aria-describedby="input-feedback-device-model-type"
                          />
                      </b-form-group>
                      <b-form-invalid-feedback id="input-feedback-device-model-type">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>

                    <validation-provider name="systemAdmin.deviceModel.attributes.attributes" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group id="input-group-device-model-attributes" :label="$t('systemAdmin.deviceModel.attributes.attributes')" label-for="input-device-model-attributes" label-cols-sm="3">
                        <b-form-checkbox-group
                            switches
                            stacked
                            size="lg"
                            :disabled="type=='view'"
                            id="input-device-model-attributes"
                            v-model="form.attributes"
                            :options="$t('systemAdmin.deviceModel.attributeOptions')"
                            name="input-device-model-attribute"
                        ></b-form-checkbox-group>
                        <b-form-invalid-feedback id="input-feedback-device-model-attributes">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                  </div>

                </div>

                <div class="row">
                  <button  type="button" class="btn btn-sm btn-primary" v-if="type=='view'" @click.prevent="type='edit'">
                    <i class="fa fa-edit"></i>&nbsp;{{ $t('systemAdmin.deviceModel.actions.edit') }}
                  </button>
                  <button type="submit" v-if="type=='create'" class="btn btn-sm btn-primary">{{ $t('common.actions.create') }}</button>
                  <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary">{{ $t('common.actions.save') }}</button>
                  <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-secondary m-l-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
                  <button type="button" class="btn btn-sm btn-white m-l-sm" @click="cancel">{{ $t('common.actions.back') }}</button>
                </div>

              </b-form>
            </validation-observer>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Vue from "vue";
import * as commons from "@/commons";
import i18n from "@/i18n";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      error: null,
      type: 'view',
      form: {
        name: '',
        deviceModelKey: '',
        manufacturer: '',
        attributes: []
      }
    }
  },
  created() {
    this.type = this.$route.name == 'deviceModelNew' ? 'create' : 'view'
    if(this.type == 'view') {
      this.$store.dispatch('systemAdmin/fetchDeviceModelDetails', this.$route.params.id)
    } else {
      this.$store.commit('systemAdmin/SAVE_DEVICE_MODEL_DETAILS_ENTITY', null)
      this.reset()
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      deviceModel: state => state.systemAdmin.deviceModelDetails.entity,
    }),
    typeOptions(){
        return  [
            { value: "SENSOR", "text": this.$t("enums.DeviceModelType.SENSOR") },
            { value: "METERING_POINT", text: this.$t("enums.DeviceModelType.METERING_POINT") },
            { value: "BLE_BEACON", text: this.$t("enums.DeviceModelType.BLE_BEACON") }
        ]
    },
  },
  watch: {
    deviceModel: function(newVal, oldVal) { // watch it
      this.reset()
    },
    'form.integrationType': function(newVal, oldVal) { 
    if (newVal !== oldVal) {
      // Clear existing commands when integrationType changes
      this.clearCommandsForNewIntegrationType();
    }
  }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    reset() {
      if(this.deviceModel){
        this.form = Object.assign({}, this.deviceModel)
        // remove all values not found in current options
        let options = this.$t('systemAdmin.deviceModel.attributeOptions')
        this.form.attributes = this.form.attributes.filter(attribute => options.some(option => option.value === attribute))
        this.type = "view"
      } else {
        this.form.name = ''
        this.form.deviceModelKey = ''
        this.form.manufacturer = ''
        this.form.type = ''
        this.form.attributes = []
      }
      if(this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    cancel(){
      this.goBack()
    },
    submit() {
      this.error = ""
      this.info = ""
      let self = this
      if(this.type=="create") {
        Vue.axios.post('/admin/device-models', JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          self.$toastr.i(i18n.t('systemAdmin.deviceModel.info.created'))
          self.$router.replace({
            name: 'deviceModelDetails',
            params: { id: response.data.id }
          })
          self.$store.dispatch('systemAdmin/fetchDeviceModelDetails', self.$route.params.id)
          self.type = 'view'
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            self.error = commons.processRestError(error)
          }
        })
      } else if(this.type=="edit") {
        Vue.axios.put('/admin/device-models/' + this.form.id, JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {
          self.$toastr.i(i18n.t('systemAdmin.deviceModel.info.saved'))
          // self.$store.dispatch('systemAdmin/fetchDeviceModelDetails', self.$route.params.id)
          self.type = 'view'
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            self.error = commons.processRestError(error)
          }
        })
      }
    },
  }
}
</script>