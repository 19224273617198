<template>
  <div class="register delete-user-request">
    <div class="middle-box text-center animated fadeInDown">
      <div>
        <div class="mb-4">
          <img :src="require('@/assets/custom/img/CLIMIFY.svg')" width="200px" />
        </div>
        <h3 class="font-bold">Account Deletion</h3>
        <div class="login-desc">
          <div class="mb-4">
            Please log in to delete your account.
          </div>
          <button v-if="!isAuthenticated" @click="loginWithKeycloak" type="submit" class="btn btn-primary m-b">Login</button>
          <button v-if="isAuthenticated" @click="confirmDeletion" type="submit" class="btn btn-primary m-b">Confirm Deletion</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import Vue from 'vue'
import i18n from "@/i18n"
import * as commons from "@/commons"

export default {
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated;
    }
  },
  methods: {
    loginWithKeycloak() {
      this.$keycloak.login();
    },
    confirmDeletion() {
      let self = this;
      this.confirmDialog({
        text: this.$t('userSettings.requestDeleteConfirmation'),
        callbackYes: function () {
          Vue.axios.delete('/user/' + self.$store.state.userInfo.id)
            .then(response => {
              self.$toastr.i(i18n.t('userSettings.requestDeleteSuccess'))
              self.goBack()
              self.forceLogout()
            })
            .catch(error => {
              commons.processRestError(error)
            });
        }
      })
    },
    forceLogout() {
      this.$store.dispatch('forceLogout', this.$data)
    }
  }
}
</script>
