<template>
  <div class="row wrapper border-bottom white-bg page-heading" v-if="building && $route.meta.titleKey">
    <div class="col-lg-10">
      <h2>{{ $t($route.meta.titleKey) }}</h2>
      <h4 v-if="$route.meta.subtitleKey">{{ $t($route.meta.subtitleKey) }}</h4>
      <ol class="breadcrumb" v-if="building.organisation">
        <li class="breadcrumb-item" v-if="hasRole('ADMIN')">
          <router-link to="/admin/organisations" >{{ $t('menu.organisationAdmin') }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link v-if="hasRole('ADMIN') || hasResourceRole('ORGANISATION_MANAGER', building.organisation.id) "
                       :to=" $route.params.prefix ? ('/' + $route.params.prefix + '/organisations/' + building.organisation.id) : ('/organisations/' + building.organisation.id)" >{{ building.organisation.name }}</router-link>
          <span v-else>{{ building.organisation.name }}</span>
        </li>
        <li class="breadcrumb-item active">
          <strong>{{ building.name }}</strong>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      building: state => state.buildings.buildingDetails.entity
    })
  },
  methods: {
  },
}
</script>
