<template>
  <div v-if="contextOrg">
    <b-tabs ref="tabs" @input="tabSelected" v-if="building && building.id" v-model="activeTab" content-class="px-5 py-5 white-bg">
      <b-tab :title="$t('orgAdmin.building.details.info')" :active="activeTab === 0">
        <building-view-create-edit
          :building="building"
          :can-edit="hasRole('ADMIN') || (building.organisation && hasResourceRole('ORGANISATION_MANAGER', building.organisation.id)) || (building && hasResourceRole('BUILDING_MANAGER', building.id))"
          initial-type="view"
          :success-callback="editBuildingSuccessCallback"
        />

        <h4 class="mt-5 mb-4">{{ $t('orgAdmin.building.details.maps') }}</h4>

        <div class="form-group row m-b-sm" v-if="maps && maps.length != 0">
          <div class="col-lg-6">
            <div class="input-group">
              <span class="input-group-prepend">
                <b-button class="btn btn-sm btn-primary" :disabled="!mapList.filter" @click="clearFilter">
                  {{ $t('common.actions.clear') }}
                </b-button>
              </span>
              <b-form-input type="text"
                            v-model="mapList.filter"
                            debounce="300"
                            max="50"
                            :placeholder="$t('enterForSearch')"
                            class="form-control form-control-sm"/>
            </div>
          </div>
          <div class="col-lg-3 m-t-sm text-right">
            <row-count :size="mapList.size" :page="mapList.page"
                       :total="mapListFiltered.length"></row-count>
          </div>
          <div class="col-lg-3 text-right" v-if="mapListFiltered.length && mapListFiltered.length > mapList.size">
            <b-pagination size="sm" class="m-t-xs" align="right"
                          :total-rows="mapListFiltered.length"
                          v-model="mapList.page"
                          :per-page="mapList.size"></b-pagination>
          </div>
        </div>

        <div class="row" v-if="mapListFiltered.length != 0">
          <div class="col-xxxl-3 col-xxl-4 col-lg-6 col-sm-12"
               v-for="(item) in mapListFiltered.slice((mapList.page - 1) * mapList.size, (mapList.page * mapList.size) - 1)"
               :key="item.id">
            <map-card :item="item"></map-card>
          </div>
        </div>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>
        <div class="row" v-if="hasRole('ADMIN') || hasResourceRole('BUILDING_MANAGER', $route.params.id) || (building.organisation && hasResourceRole('ORGANISATION_MANAGER', building.organisation.id))">
          <div class="col-lg-12">
            <router-link to="/maps/new">
              <button class="btn btn-sm btn-primary pull-left mr-1">
                <i class="fa fa-plus"></i>&nbsp;{{ $t('orgAdmin.map.actions.new') }}
              </button>
            </router-link>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('orgAdmin.building.logs.buildingLogs')" :active="activeTab === 1">
        <building-logs
          :can-add="hasRole('ADMIN') || hasResourceRole('BUILDING_MANAGER', $route.params.id) || (building.organisation && hasResourceRole('ORGANISATION_MANAGER', building.organisation.id))"
          :orgId="organisation.id ? organisation.id : null"
          :buildingId="building.id ? building.id : null"
        />
      </b-tab>
      <b-tab :title="$t('orgAdmin.building.parameters.buildingParameters')" :active="activeTab === 2">
        <building-parameters :buildingId="building.id"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BuildingViewCreateEdit from "@/components/views/admin/organisations/BuildingViewCreateEdit.vue";
import BuildingLogs from "@/components/views/buildings/BuildingLogs.vue";
import BuildingParameters from "@/components/views/buildings/BuildingParameters.vue";
import RowCount from "@/components/common/RowCount.vue";
import MapCard from "@/components/views/buildings/MapCard.vue";
import _ from "lodash";
import eventBus from "@/eventBus";

export default {
  components: {
    BuildingViewCreateEdit,
    BuildingLogs,
    BuildingParameters,
    MapCard,
    RowCount
  },
  data() {
    return {
      error: null,
      activeTab: this.$store.state.buildings.buildingDetails.activeTab,
      mapList: {
        filter: null,
        page: 1,
        size: 10
      }
    }
  },
  created() {
    this.type = this.$route.path === '/buildings/new' ? 'create' : 'view';
    this.fetchBuildingData();
  },
  watch: {
    contextOrg: {
      handler(newVal, oldVal) {
        if (newVal && oldVal && newVal.id !== oldVal.id){
          this.$store.commit('buildings/SAVE_BUILDING_DETAILS_ACTIVE_TAB', 0);
          this.$router.push("/buildings");
        }
      },
      deep: true,
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      contextOrg: state => state.contextOrg,
      organisation: state => state.contextOrg,
      building: state => state.buildings.buildingDetails.entity,
      maps: state => state.buildings.buildingDetails.entity.maps,
    }),
    mapListFiltered() {
      if (this.mapList.filter != null && this.mapList.filter != '') {
        return _.sortBy(this.maps.filter(item =>
            item.name.toLowerCase().indexOf(this.mapList.filter.toLowerCase()) != -1
        ), [function(o) { return o.name; }])
      } else {
        return _.sortBy(this.maps ? this.maps : [], [function(o) { return o.name; }])
      }
    }
  },
  methods: {
    fetchBuildingData() {
      if (this.type === 'view') {
        this.$store.dispatch('buildings/fetchBuildingDetails', this.$route.params.id);
      }
    },
    clearFilter() {
      this.mapList.filter = null;
    },
    editBuildingSuccessCallback() {
      this.$store.dispatch('buildings/fetchBuildingDetails', this.$route.params.id);
    },
    tabSelected() {
      this.$store.commit('buildings/SAVE_BUILDING_DETAILS_ACTIVE_TAB', this.activeTab);
      if (this.activeTab == 0) {
        eventBus.$emit("refresh-geolocation");
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.id !== from.params.id && to.params.id !== "undefined") {
      this.fetchBuildingData();
      this.$store.dispatch('buildingLogs/loadLogsForBuilding', to.params.id);
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchBuildingData();
      vm.$store.dispatch('buildingLogs/loadLogsForBuilding', to.params.id);
    });
  }
}
</script>

<style scoped>
</style>
