<template>
	<div class="row animated fadeInDown">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-content">

                  <button type="button" class="btn btn-primary" @click="doTests">Do tests</button>

                  <div class="row mt-3">
                    <div class="col-12">
                      <label for="results">Test results:</label>
                      <div class="" id="results">
                        <pre>{{ results }}</pre>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  data() {
    return {
        error: null,
        info: null,
        callResponse: null
    }
  },
  methods: {
    doTests(){
      this.error = null
      this.$store.dispatch('testExternalApi/doTests')
    }
  },
  computed: {
    ...mapState({
        currentUser: state => state.userInfo,
        results: state => state.testExternalApi.results,
    })
  },
  watch: {
  }
}
</script>