<template>
  <div>
    <div class="animated fadeInRight" v-if="contextOrg">
      <div class="ibox">
        <div class="ibox-content p-5">
          <form-list :items="forms"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import FormList from "@/components/views/feedback/FormList.vue";

export default {
  components:{
    FormList
  },
  data() {
    return {
      error: null,
      info: null,
      activeTab: this.$store.state.feedback.activeTab
    }
  },
  created() {
    console.log("Feedback created")
    if (this.$route.query.activeTab != null) {
      this.activeTab = parseInt(this.$route.query.activeTab)
      this.$router.replace({'query': null});
      this.tabSelected()
    }
    this.fetchOrganisationForms()
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      contextOrg: state => state.contextOrg,
      forms: state => state.feedback.forms
    }),
  },
  watch: {
    contextOrg: function (newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.fetchIfOrgChanged()
      }
    }
  },
  methods: {
    fetchIfOrgChanged(){
      console.log('fetchIfOrgChanged ' + this.contextOrg?.id)
      this.$store.commit('feedback/UPDATE_SELECTED_FORM', null)
      this.form = null
      this.location = null
      this.fetchOrganisationForms()
    },
    fetchOrganisationForms(){
      this.$store.dispatch('feedback/fetchOrganisationForms', this.contextOrg.id)
    }
  }
}
</script>
