<template>
  <div>
    <div class="row equal-height">
      <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="slideInLeft animated" leave-active-class="slideOutLeft animated">
        <div class="col-xxl-9 col-xl-8 col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ $t('feedback.subtitle') }}{{ ": " }} {{ selectedForm !== null ? selectedForm.name : '' }}</h5>
            </div>
            <div class="ibox-content">
              <div class="pl-2 pr-2">
                <feedback-view></feedback-view>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="col-xxl-3 col-xl-4 col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>
              <template v-if="selectedLocation">{{ $t('feedback.feedbackConfig.title') }}</template>
              <template v-else>{{ $t('feedback.feedbackConfig.titleNotConfigured') }}</template>
            </h5>
          </div>
          <div class="ibox-content">

            <b-form-group v-if="contextOrg" id="input-group-form" :label="$t('feedback.feedbackConfig.form')" label-for="input-form" label-cols-sm="3">
              <v-select
                  id="input-from"
                  name="input-form"
                  v-if="availableForms && availableForms.length > 0"
                  v-model="form"
                  :options="availableForms"
                  label="name"
                  @input="formSelected"
                  :clearable="false"
              />
            </b-form-group>

            <b-form-group v-if="selectedForm" id="input-group-location" :label="$t('feedback.feedbackConfig.location')" label-for="input-location" label-cols-sm="3">
              <v-select
                  id="inputLocation"
                  name="input-location"
                  v-if="availableLocations && availableLocations.length > 0"
                  v-model="location"
                  :options="availableLocations"
                  label="name"
                  @input="locationSelected"
                  :clearable="false"
              />
            </b-form-group>

            <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
              <feedback-config v-if="selectedLocation"/>
            </transition>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import FeedbackConfig from "@/components/views/feedback/FeedbackConfig"
import FeedbackView from "@/components/views/feedback/FeedbackView"

export default {
  components:{
    FeedbackConfig,
    FeedbackView
  },
  data() {
    return {
      error: null,
      info: null,
      form: null,
      location: null
    }
  },
  created() {
    console.log("Feedback created")
    if(this.selectedForm) {
      this.form = this.selectedForm
      this.location = this.selectedLocation
    }else {
      this.$store.dispatch('feedback/loadFormsFromServer', this.contextOrg)
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      contextOrg: state => state.contextOrg,
      selectedForm: state => state.feedback.selectedForm,
      selectedLocation: state => state.feedback.selectedLocation,
      feedbackConfig: state => state.feedback.feedbackConfig,
      availableForms: state => state.feedback.availableForms,
      availableLocations: state => state.feedback.availableLocations,
      forms: state => state.feedback.forms
    }),
  },
  methods: {
    formSelected(){
      console.log("selectedForm")
      this.$store.dispatch('feedback/updateSelectedForm', this.form)
      this.$store.dispatch('feedback/loadFormLocationsFromServer', { org: this.contextOrg, form: this.form })
    },
    locationSelected(){
      this.$store.dispatch('feedback/updateSelectedLocation', this.location)
      this.$store.dispatch('feedback/fetchGraphData')
    },
  },
  watch: {
    contextOrg: function(newVal, oldVal) {
      this.$store.commit('feedback/UPDATE_SELECTED_FORM', null)
      this.form = null
      this.location = null
      this.$store.dispatch('feedback/loadFormsFromServer', newVal)
    },
    selectedForm: function(newVal, oldVal) {
      this.$store.commit('feedback/UPDATE_SELECTED_LOCATION', null)
      this.location = null
    }
  }
}
</script>
