<template>

  <div>

    <h3 class="d-inline-flex" v-if="title">{{ $t(title)}}</h3>

    <div class="float-right" v-if="paging" v-show="show">
          <div class="horizontal-align float-right">
              <row-count :size="table.size" :page="table.page" :total="table.total" class="mr-3"></row-count>
              <b-pagination v-if="table.total > table.size"
                            size="sm" class="mt-3"
                            :total-rows="table.total"
                            v-model="table.page"
                            :per-page="table.size"></b-pagination>
          </div>
    </div>

    <div class="d-block mt-2">
        <b-table responsive striped
                 v-show="show"
                 :filter="table.filter"
                 :fields="visibleFields"
                 :current-page="table.page"
                 :per-page="table.size"
                 :items="fetchData"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc"
                 ref="table">
          <template slot="cell(device.alias)" slot-scope="data">
            <b-link :to="{ name: 'messageDetails', params: { id: data.item.id } }">
              {{ data.value }}
            </b-link>
          </template>
          <!--template #cell(description)="data">
            <truncate :text="data.value" :length="50" action-class="truncate-more-less-link text-info" :clamp="$t('common.actions.showMore')" :less="$t('common.actions.showLess')"></truncate>
          </template-->
          <template #cell(severity)="data">
            <span class="label" :class="{'text-strike': data.item.status === 'DELETED', 'label-danger':data.value == 'CRITICAL','label-primary':data.value == 'MINOR' || data.value == 'LOW','label-warning':data.value == 'WARNING'}">{{ $t('enums.MessageSeverity.' + data.value) }}</span>
          </template>
          <template #cell(status)="data">
            <span _class="label label-primary" :class="{'text-strike': data.item.status === 'DELETED'}">{{ data.value }}</span>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <button v-if="data.item.status !== 'DELETED'" class="btn btn-xs btn-default ml-2" :title="$t('messageCentre.actions.' + (data.item.priority == 'PINNED' ? 'unpin' : 'pin'))" :class="{'opacity-25': data.item.priority != 'PINNED'}" @click="togglePinMessage(data.item)">
                <i class="fa fa-thumb-tack"></i>
              </button>
              <button v-if="data.item.status !== 'DELETED'" class="btn btn-xs btn-default ml-2" :title="$t('messageCentre.actions.' + (data.item.priority == 'IGNORED' ? 'un-ignore' : 'ignore'))" :class="{'opacity-25': data.item.priority != 'IGNORED'}" @click="toggleIgnoreMessage(data.item)">
                <i class="fa fa-ban"></i>
              </button>
            </div>
          </template>
          <template #cell()="data"><span :class="{'text-strike': data.item.status === 'DELETED'}">{{ data.value }}</span></template>
        </b-table>
    </div>

    <div v-if="show == false">{{ $t(noEntriesInfo) }}</div>

  </div>
</template>

<script>
import Vue from "vue"
import {mapState} from 'vuex'
import eventBus from "@/eventBus"
import RowCount from '@/components/common/RowCount'
import moment from 'moment'
import * as commons from "@/commons"
//import truncate from 'vue-truncate-collapsed'
import i18n from "@/i18n"
import qs from 'qs'

export default {
  components: {
    RowCount,
    //truncate
  },
  props: {
    type: {
      default: 'issues',
      validator: function (value) {
        return ['issues', 'advices'].includes(value)
      }
    },
    id: {
      required: true
    },
    title:{
    },
    queryUrl: {
      default: '/messages'
    },
    queryParams:{
      type: Object
    },
    paging:{
      type: Boolean,
      default: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    noEntriesInfo: {
      default: 'common.noData'
    }
  },
  data() {
    return {
      error: null,
      info: null,
      show: true,
      table: {
        filter: '',
        sortBy: 'createdAt',
        sortDesc: true,
        page: 1,
        size: this.pageSize,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50},
          {'value': 100}
        ],
        fields: [
          {
            key: 'severity',
            label: this.$i18n.t('messageCentre.' + this.type + '.attributes.severity'),
            sortable: true
          },
          {
            key: 'createdAt',
            label: this.$i18n.t('messageCentre.' + this.type + '.attributes.createdAt'),
            sortable: true,
            tdClass: "text-nowrap",
            formatter: (value, key, item) => {
              return (item.createdAt && this.selectedBuilding.timeZone) ? moment(item.createdAt).tz(this.selectedBuilding.timeZone).format(i18n.t('dateTimeShortFormatMoment')) : ''
            }
          },
          {
            key: 'type',
            hidden: this.type === 'advices',
            label: this.$i18n.t('messageCentre.' + this.type + '.attributes.type'),
            sortable: true,
            formatter: (value, key, item) => {
              return this.$t('enums.MessageType.' + item.type)
            }
          },
          {
            key: 'device.alias',
            label: this.$i18n.t('messageCentre.' + this.type + '.attributes.deviceAlias'),
            sortable: true
          },
          {
            key: 'device.map.name',
            label: this.$i18n.t('messageCentre.' + this.type + '.attributes.map'),
            sortable: true,
            thClass: "text-nowrap"
          },
          {
            key: 'device.location.name',
            label: this.$i18n.t('messageCentre.' + this.type + '.attributes.location'),
            sortable: true,
            thClass: "text-nowrap"
          },
          {
            key: 'category',
            label: this.$i18n.t('messageCentre.' + this.type + '.attributes.category'),
            sortable: true,
            formatter: (value, key, item) => {
              return this.$t('enums.MessageCategory.' + item.category)
            }
          },
          {
            key: 'description',
            label: this.$i18n.t('messageCentre.' + this.type + '.attributes.description'),
            sortable: true
          },
          {
            key: 'status',
            label: this.$i18n.t('messageCentre.' + this.type + '.attributes.status'),
            sortable: true,
            formatter: (value, key, item) => {
              return this.$t('enums.MessageStatus.' + item.status)
            }
          },
          {
            key: 'lastModified',
            label: this.$i18n.t('messageCentre.' + this.type + '.attributes.lastModified'),
            sortable: true,
            tdClass: "text-nowrap",
            formatter: (value, key, item) => {
              return (item.lastModified && this.selectedBuilding.timeZone) ? moment(item.lastModified).tz(this.selectedBuilding.timeZone).format(i18n.t('dateTimeShortFormatMoment')) : ''
            }
          },
          {
            key: 'actions',
            label: '',
            sortable: false,
            class: ["text-center"]
          }
        ]
      }
    }
  },
  created(){
    eventBus.$on("refresh-message-lists", this.refreshTable);
    // check saved state if any for table pre-setup
    let listConf = this.listsConf[this.id]
    if(listConf) {
      if (listConf.sortBy) {
        this.table.sortBy = listConf.sortBy
      }
      if (listConf.sortDesc) {
        this.table.sortDesc = listConf.sortDesc
      }
      if (listConf.page) {
        this.table.page = listConf.page
      }
      if (listConf.size) {
        this.table.size = listConf.size
      }
    }
  },
  destroyed() {
    eventBus.$off("refresh-message-lists", this.refreshTable);
  },
  computed: {
    ...mapState({
      selectedBuilding: state => state.messageCentre.selectedBuilding,
      listsConf: state => state.messageCentre.listsConf,
      listsFilter: state => state.messageCentre.listsFilter
    }),
    visibleFields(){
      return this.table.fields.filter(field => field.hidden !== true)
    }
  },
  methods: {
    refreshTable(){
      if(this.$refs.table) {
        this.$refs.table.refresh()
      }
    },
    fetchData(){
      console.log("fetchData")

      const pageable = {
        page: this.table.page - 1,
        size: this.table.size,
        sort: this.table.sortBy ? this.table.sortBy.concat(",").concat(this.table.sortDesc ? "DESC" : "ASC") : ""
      }

      const filterParams = {...this.queryParams}

      if(this.listsFilter){

        if(this.listsFilter.status){
          filterParams.statuses = [this.listsFilter.status]
        }

        if(this.listsFilter.severity){
          filterParams.severities = [this.listsFilter.severity]
        }

        if(this.listsFilter.category){
          filterParams.categories = [this.listsFilter.category]
        }

        if(this.listsFilter.deviceAlias){
          filterParams.alias = [this.listsFilter.deviceAlias]
        }

        if(this.listsFilter.description){
          filterParams.description = this.listsFilter.description
        }

        if(this.listsFilter.dateTimeFrom){
          filterParams.dateTimeFrom = moment(this.listsFilter.dateTimeFrom).toISOString()
        }

        if(this.listsFilter.dateTimeTo){
          filterParams.dateTimeTo = moment(this.listsFilter.dateTimeTo).toISOString()
        }
      }

      let promise = Vue.axios.get(this.queryUrl, {
        params: {
          building_id: this.selectedBuilding.id,
          ...filterParams,
          ...pageable
        },
        'paramsSerializer': function(params) {
          return qs.stringify(params, {arrayFormat: 'repeat'})
        }
      })

      return promise.then(response => {
        this.table.page = response.data.pageable.pageNumber + 1
        this.table.total = response.data.totalElements
        this.show = this.table.total > 0

        this.$store.commit("messageCentre/UPDATE_LIST_CONF", {
            id: this.id,
            sortBy: this.table.sortBy,
            sortDesc: this.table.sortDesc,
            page: this.table.page,
            size: this.table.size
        })

        return(response.data.content)
      }).catch(error => {
        commons.processRestError(error)
        return [];
      })
    },
    togglePinMessage(message){
      Vue.axios.put('/messages/' + message.id, JSON.stringify({ priority: message.priority == 'PINNED' ? 'NORMAL' : 'PINNED'}),
          {
            headers: {'Content-Type': 'application/json'}
          })
          .then(response => {
            eventBus.$emit("refresh-message-lists")
          })
          .catch(error => {
            commons.processRestError(error)
          })
    },
    toggleIgnoreMessage(message){
      Vue.axios.put('/messages/' + message.id, JSON.stringify({ priority: message.priority == 'IGNORED' ? 'NORMAL' : 'IGNORED'}),
          {
            headers: {'Content-Type': 'application/json'}
          })
          .then(response => {
            eventBus.$emit("refresh-message-lists")
          })
          .catch(error => {
            commons.processRestError(error)
          })
    }
  },
  watch: {
    selectedBuilding: function() {
      this.refreshTable()
    }
  }
}

</script>