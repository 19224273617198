<template>
  <div>

    <b-tabs ref="tabs" @input="tabSelected" v-if="building" v-model="activeTab" content-class="px-5 py-5 white-bg">

      <b-tab :title="$t('orgAdmin.building.details.info')">
        <building-view-create-edit
            :building="building"
            :can-edit="hasRole('ADMIN') || (building.organisation && hasResourceRole('ORGANISATION_MANAGER', building.organisation.id))"
            initial-type="view"
            :success-callback="editBuildingSuccessCallback" />

        <h4 class="mt-5 mb-4">{{ $t('orgAdmin.building.details.maps') }}</h4>

        <div class="form-group row m-b-sm" v-if="maps && maps.length != 0">
          <div class="col-lg-6">
            <div class="input-group">
              <span class="input-group-prepend">
                <b-button class="btn btn-sm btn-primary" :disabled="mapList.filter == null || mapList.filter == ''" @click="clearFilter"> {{
                    $t('common.actions.clear')
                  }}</b-button>
              </span>
              <b-form-input type="text"
                     v-model="mapList.filter"
                     debounce="300"
                     max="50"
                     :placeholder="$t('enterForSearch')"
                     class="form-control form-control-sm"/>
            </div>
          </div>
          <div class="col-lg-3 m-t-sm text-right">
            <row-count :size="mapList.size" :page="mapList.page"
                        :total="mapListFiltered.length"></row-count>
          </div>
          <div class="col-lg-3 text-right" v-if="mapListFiltered.length && mapListFiltered.length > mapList.size">
            <b-pagination size="sm" class="m-t-xs" align="right"
                          :total-rows="mapListFiltered.length"
                          v-model="mapList.page"
                          :per-page="mapList.size"></b-pagination>
          </div>
        </div>

        <div class="row" v-if="mapListFiltered.length != 0">
          <div class="col-xxxl-3 col-xxl-4 col-lg-6 col-sm-12"
               v-for="(item) in mapListFiltered.slice((mapList.page - 1) * mapList.size, (mapList.page * mapList.size) - 1)"
               v-bind:key="item.id">
            <map-card :item="item"></map-card>
          </div>
        </div>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>
        <div class="row" v-if="hasRole('ADMIN') || hasResourceRole('BUILDING_MANAGER', $route.params.id) || (building.organisation && hasResourceRole('ORGANISATION_MANAGER', building.organisation.id))">
          <div class="col-lg-12">
            <router-link to="/admin/maps/new">
              <button class="btn btn-sm btn-primary pull-left mr-1">
                <i class="fa fa-plus"></i>&nbsp;{{ $t('orgAdmin.map.actions.new') }}
              </button>
            </router-link>
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('orgAdmin.building.details.userResourceRoles')" @click="$store.dispatch('userManagement/fetchBuildingUsers', $route.params.id)" lazy>
        <users-resources-list
            :can-add="hasRole('ADMIN') || hasResourceRole('BUILDING_MANAGER', $route.params.id) || (building.organisation && hasResourceRole('ORGANISATION_MANAGER', building.organisation.id))"
            route-prefix="/admin"
        />
      </b-tab>

      <b-tab :title="$t('orgAdmin.building.details.devices')" @click="$store.dispatch('devices/fetchBuildingDevices', $route.params.id)" lazy>
        <device-list :items="devices" />
      </b-tab>

      <b-tab :title="$t('orgAdmin.building.details.beacons')" @click="$store.dispatch('devices/fetchBuildingBeacons', $route.params.id)" lazy>
        <beacon-list :items="beacons" />
      </b-tab>

      <b-tab :title="$t('orgAdmin.building.details.metering_points')" @click="$store.dispatch('devices/fetchBuildingMeteringPoints', $route.params.id)" lazy>
        <device-list :items="meters" />
      </b-tab>

      <b-tab :title="$t('orgAdmin.building.details.forms')" v-if="building.organisation" @click="$store.dispatch('feedback/fetchOrganisationForms', building.organisation.id)" lazy>
        <form-list :items="forms" />
      </b-tab>

      <b-tab :title="$t('orgAdmin.building.details.locationProfiles')" v-if="building.organisation"
             @click="$store.dispatch('profiles/fetchOrganisationLocationProfiles', building.organisation.id);$store.dispatch('profiles/fetchBuildingLocationsWithSelectedProfile', building.id)"
             lazy>
        <div class="row">
          <div class="col-xxl-6 col-xl-12">
            <location-profile-list v-if="locationProfiles" :profiles="locationProfiles" :refresh-callback="refreshLocationProfilesCallback" :canAdd="true" route-prefix="/admin" />
            <h3 class="mt-5 mb-4" v-if="locationProfiles" >{{ $t('orgAdmin.locationsWithLocationProfiles.title') }}</h3>
            <locations-with-profile-list :locations-with-selected-profile="locationsWithSelectedLocationProfile" route-prefix="/admin" />
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('orgAdmin.building.details.schedulerProfiles')" v-if="building.organisation"
             @click="$store.dispatch('schedulers/fetchOrganisationSchedulerProfiles', building.organisation.id);$store.dispatch('schedulers/fetchBuildingLocationsWithSelectedProfile', building.id)"
             lazy>
        <div class="row">
          <div class="col-xxl-6 col-xl-12">
              <scheduler-profile-list v-if="schedulerProfiles" :profiles="schedulerProfiles" :canAdd="true" route-prefix="/admin"/>
              <h3 class="mt-5 mb-4">{{ $t('orgAdmin.locationsWithSchedulerProfiles.title') }}</h3>
              <locations-with-scheduler-profile-list :locations-with-selected-profile="locationsWithSelectedSchedulerProfile" route-prefix="/admin"/>
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('orgAdmin.building.details.shareLinks')" @click="$store.dispatch('shareData/fetchBuildingShareLinks', $route.params.id)" lazy>
          <div class="row">
              <div class="col-xxl-6 col-xl-12">
                  <share-link-list v-if="shareLinks" :share-links="shareLinks" :refresh-callback="refreshLocationProfilesCallback" :canAdd="true" route-prefix="/admin"/>
              </div>
          </div>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {mapState} from "vuex"
import eventBus from "@/eventBus"
import MapCard from "@/components/views/buildings/MapCard"
import RowCount from "@/components/common/RowCount"
import UsersResourcesList from "@/components/views/userManagement/UsersResourcesList.vue"
import BuildingViewCreateEdit from "@/components/views/admin/organisations/BuildingViewCreateEdit"
import LocationProfileList from '@/components/views/profiles/LocationProfileList'
import LocationsWithProfileList from '@/components/views/profiles/LocationsWithProfileList'
import SchedulerProfileList from "@/components/views/schedulers/SchedulerProfileList"
import LocationsWithSchedulerProfileList from '@/components/views/schedulers/LocationsWithSchedulerProfileList'
import ShareLinkList from "@/components/views/shareData/ShareLinkList"
import DeviceList from "@/components/views/devices/DeviceList";
import BeaconList from "@/components/views/devices/BeaconList";
import FormList from "@/components/views/feedback/FormList"
import _ from "lodash";

export default {
  components: {
    BuildingViewCreateEdit,
    MapCard,
    RowCount,
    DeviceList,
    BeaconList,
    UsersResourcesList,
    LocationProfileList,
    SchedulerProfileList,
    LocationsWithProfileList,
    LocationsWithSchedulerProfileList,
    ShareLinkList,
    FormList,
  },
  data() {
    return {
      error: null,
      activeTab: this.$store.state.orgAdmin.buildingDetails.activeTab,
      mapList: {
        filter: null,
        page: 1,
        size: 10
      }
    }
  },
  created() {
    this.$store.dispatch('buildings/fetchBuildingDetails', this.$route.params.id)
    this.$nextTick(() => {
      if (this.activeTab != 0) {
        this.$refs.tabs.$el.querySelectorAll('.nav-link.active')[0].click()
      }
    })
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      building: state => state.buildings.buildingDetails.entity,
      maps: state => state.buildings.buildingDetails.entity.maps,
      devices: state => state.devices.devices,
      beacons: state => state.devices.beacons,
      meters: state => state.devices.meters,
      locationProfiles: state => state.profiles.locationProfiles,
      locationsWithSelectedLocationProfile: state => state.profiles.locationsWithSelectedProfile,
      schedulerProfiles: state => state.schedulers.schedulerProfiles,
      locationsWithSelectedSchedulerProfile: state => state.schedulers.locationsWithSelectedSchedulerProfile,
      shareLinks: state => state.shareData.buildingShareLinks,
      forms: state => state.feedback.forms

    }),
    mapListFiltered() {
      console.log('mapListFiltered this.mapList.filter ' + this.mapList.filter)
      if (this.mapList.filter != null && this.mapList.filter != '') {
        return _.sortBy(this.maps.filter(item =>
            item.name.toLowerCase().indexOf(this.mapList.filter.toLowerCase()) != -1
        ), [function(o) { return o.name; }])
      } else {
        return _.sortBy(this.maps ? this.maps : [], [function(o) { return o.name; }])
      }
    }
  },
  methods: {
    tabSelected() {
      this.$store.commit('building/SAVE_BUILDING_DETAILS_ACTIVE_TAB', this.activeTab)
      if(this.activeTab == 0){
        eventBus.$emit("refresh-geolocation")
      }
    },
    clearFilter() {
      this.mapList.filter = null
    },
    editBuildingSuccessCallback(){
      this.$store.dispatch('buildings/fetchBuildingDetails', this.$route.params.id)
    },
    refreshLocationProfilesCallback(){
      this.$store.dispatch('buildings/fetchBuildingDetails', this.$route.params.id)
    }
  }
}
</script>

<style scoped>

</style>