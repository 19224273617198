<template>
  <div>
    <transition name="fade" appear>
      <div>
        <div class="row">
          <div class="col-12">
              <b-form-group :label="$t('resourceSelector.organisation')" label-for="input-assign-org" label-cols-sm="3">
                  <b-form-input id="input-assign-org" :plaintext="true" :value="shareLink.building.organisation.name"/>
              </b-form-group>
              <b-form-group :label="$t('resourceSelector.building')" label-for="input-assign-building" label-cols-sm="3">
                  <b-form-input id="input-assign-building" :plaintext="true" :value="shareLink.building.name"/>
              </b-form-group>
              <b-form-group v-if="shareLinkMaps.length > 0" id="input-group-assign-map" :label="$t('resourceSelector.map')" label-for="input-assign-map" label-cols-sm="3">
                  <v-select
                          id="input-assign-map"
                          name="input-assign-map"
                          v-model="mapSelected"
                          :options="shareLinkMaps"
                          label="name"
                          @input="mapSelectedChanged"
                          :clearable="false"
                  />
              </b-form-group>

          </div>
        </div>
        <div class="row m-t-lg" v-if="availableSensorAttributes && availableSensorAttributes.length > 0">
          <div class="col-12">
            <h4>{{ $t('indoorClimate.mapConfig.monitorSensorValues') }}</h4>
            <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
              <b-form-group id="input-group-device-model-attributes">
                <b-form-checkbox-group
                    switches
                    stacked
                    size="lg"
                    id="input-device-model-attributes"
                    v-model="selectedSensorAttributes"
                    :options="availableSensorAttributesSorted"
                    name="input-device-model-attribute"
                    @change="selectedSensorAttributesChanged"
                ></b-form-checkbox-group>
              </b-form-group>
            </transition>
          </div>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
import {mapState} from "vuex"
import i18n from "@/i18n"
import moment from "moment"

export default {
  data() {
    return {
      info: null,
      mapSelected: null,
      selectedSensorAttributes: []
    }
  },
  created(){
    console.log('MapConfig: created - $route.name: ' + this.$route.name)
    this.mapSelected = this.selectedMap
    this.$store.dispatch('liveViewShare/startPolling')
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      shareLink: state => state.liveViewShare.shareLink.entity,
      selectedMap: state => state.liveViewShare.selectedMap,
      shareLinkMaps: state => state.liveViewShare.shareLink.maps,
      availableSensorAttributes: state => state.liveViewShare.availableSensorAttributes,
    }),
    availableSensorAttributesSorted() {
      let preferredOrder = this.$store.state.systemParams.preferredSensorOrder
      let sorted = [...this.availableSensorAttributes].sort(function (a, b) {
        return preferredOrder.indexOf(a) - preferredOrder.indexOf(b)
      })
      return sorted.map( item => { return { text: i18n.t('enums.sensorType.' + item + '.name'), value: item } })
    },
  },
  watch: {
    availableSensorAttributes(newVal, oldVal) {
      console.log('availableSensorAttributes changed: ', newVal, ' | was: ', oldVal)
      if (this.selectedSensorAttributes.length !== 0){
        this.selectedSensorAttributes = newVal.filter(value => this.selectedSensorAttributes.includes(value));
      } else {
        this.selectedSensorAttributes = [...newVal]
      }
      this.selectedSensorAttributesChanged()
    }
  },
  methods: {
    mapSelectedChanged(){
        this.$store.dispatch('liveViewShare/loadMapFromServer', this.mapSelected.id)
    },
    selectedSensorAttributesChanged() {
      console.log('selectedSensorAttributes changed: ' + this.selectedSensorAttributes)
      this.$store.dispatch('liveViewShare/mapConfigSensorAttributesChanged', this.selectedSensorAttributes)
    },
  },
  beforeDestroy () {
    console.log('MapConfig: beforeDestroy')
    this.$store.dispatch('liveViewShare/stopPolling')
  }
}
</script>

<style>
</style>