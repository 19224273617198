import Vue from "vue"
import * as commons from '@/commons'
import moment from 'moment-timezone'
import chroma from "chroma-js"
import i18n from "@/i18n";

// default state
const getDefaultState = () => {

    return {
        selectedMap: null,
        availableSensorAttributes: [],
        mapConfig: {
            dateRange: {
                startDate: null,
                endDate: null
            },
            selectedSensorAttribute: null
        }
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    mapConfigDateTimeRangeFormatted: state => {
        if(state.mapConfig.dateRange.startDate && state.mapConfig.dateRange.endDate) {
            return moment(state.mapConfig.dateRange.startDate).format(i18n.t('dateTimeShortFormatMoment'))
                + ' - '
                + moment(state.mapConfig.dateRange.endDate).format(i18n.t('dateTimeShortFormatMoment'))
        } else {
            return ''
        }
    }
}

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('analytics/appInit')
        dispatch('resetMapConfig')
    },

    resetMapConfig({ commit, state }, data) {
        console.log('analytics/resetMapConfig')

        if(state.mapConfig.dateRange.startDate === null || state.mapConfig.dateRange.endDate === null){
            return new Promise((resolve) => {
                let startDate = new Date()
                startDate.setDate(startDate.getDate() - 1)
                startDate.setHours(0, 0, 0, 0)
                let endDate = new Date()
                endDate.setHours(23, 59, 59, 59)
                commit('UPDATE_MAP_CONFIG', {
                    dateRange: {startDate: startDate, endDate: endDate},
                })
                resolve()
            })
        }
    },

    loadMapFromServer({ commit, state, dispatch }, mapId){
        //load map with locations and image
        Vue.axios.get('/maps/' + mapId).then(response => {
            commit('UPDATE_SELECTED_MAP', response.data)
            dispatch('loadSelectedMapSensorValues')
        }).catch(error => {
            commons.processRestError(error)
        })
        dispatch('resetMapConfig')
    },

    loadSelectedMapSensorValues({ commit, state, dispatch, rootState }){

        console.log('loadSelectedMapSensorValues')
        // get map sensor-values
        return new Promise((resolve, reject) => {

            let params = { duration : "PT1H"}

            Vue.axios.get('/maps/' + state.selectedMap.id + '/sensor-values', { params: params }).then(response => {
                commit('UPDATE_MAP_SENSOR_VALUES', response.data )
                resolve(response)
            }).catch(error => {
                commons.processRestError(error)
                reject(error)
            })
        })
    },

    mapConfigDateRangeSelected({ commit, state, dispatch }, data){
        console.log('analytics/mapConfigDateRangeSelected')
        commit('UPDATE_MAP_CONFIG',  { dateRange: data.dateRange })
    },

    mapConfigSensorAttributeChanged({ commit, state, dispatch }, data){
        console.log('analytics/mapConfigSensorAttributeChanged')
        commit('UPDATE_MAP_CONFIG',  { selectedSensorAttribute:  data })
    },

    fetchDataHeatmap({ commit, state, dispatch }, data){
        console.log('fetchDataHeatmap')

        return new Promise((resolve, reject) => {

            let promises = []
            let params = {}
            if(state.mapConfig.dateRange){
                state.mapConfig.dateRange.startDate.setHours(0, 0, 0, 0)
                state.mapConfig.dateRange.endDate.setHours(23, 59, 59, 999)
                params.dateTimeFrom = moment(state.mapConfig.dateRange.startDate).format('YYYY-MM-DDTHH:mm:ss')
                params.dateTimeTo = moment(state.mapConfig.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss')
            }
            params.aggregateWindowDuration = "PT1H"
            params.field = data.selectedSensorAttribute

            promises.push(new Promise((resolve, reject) => {

                Vue.axios.get('/maps/' + state.selectedMap.id + '/statistic', { params: params }).then(response => {
                    commit('UPDATE_SELECTED_MAP_LOCATION_HEAT', response.data)
                    resolve(response)
                }).catch(error => {
                    commons.processRestError(error)
                    reject(error)
                })
            }))

            Promise.all(promises).then(function() {
                resolve()
            }).catch((error) => {
                commons.processRestError(error)
                reject(error)
            })

        })
    }

}

// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['UPDATE_SELECTED_MAP']: (state, data) => {
        state.selectedMap = data
    },

    ['UPDATE_MAP_CONFIG']: (state, data) => {
        console.log('analytics/UPDATE_MAP_CONFIG: ' + JSON.stringify(data))
        state.mapConfig = Object.assign({}, state.mapConfig, data)
    },

    ['UPDATE_MAP_SENSOR_VALUES']: (state, data) => {
        state.availableSensorAttributes =  commons.getAvailableSensorAttributesFromSensorData(data)
    },

    ['UPDATE_SELECTED_MAP_LOCATION_HEAT']: (state, data) => {
        console.log('analytics/UPDATE_SELECTED_MAP_LOCATION_HEAT: ' + JSON.stringify(data))
        processSelectedMapSensorData(state, data)
        // parse data and set state to map locations
        if(data && state.selectedMap) {
            let selectedMap = state.selectedMap
            let min = 100;
            let max = 0;
            var colors = ['#0000FF', '#FF0000'];
            switch (data[0].field) {
                case "co2":
                    colors = ['#2C6E49', '#8EA448', '#FFDB36', '#FFCA14', '#FAA90B', '#F07126', '#E14834', '#CD382E', '#B82727', '#A31621']
                    min = 400;
                    max = 4000;
                    break;
                case "humidity":
                    colors = ['#025286', '#1F6E95', '#3F8DA6', '#5BA8B4', '#9BC4C9', '#EDE4E3', '#EB9486', '#E64C36', '#CE392E', '#BA2828', '#A31621']
                    min = 0;
                    max = 100;
                    break;
                case "temperature":
                    colors = ['#025286', '#1F6E95', '#3F8DA6', '#5BA8B4', '#9BC4C9', '#EDE4E3', '#EB9486', '#E64C36', '#CE392E', '#BA2828', '#A31621']
                    min = 10;
                    max = 28;
                    break;
                case "light":
                    //in special cases we have to find max value, min is always 0
                    colors = ['#C8C8C8', '#FFD966']
                    min = 0;
                    break;
                case "motion":
                    colors = ['#FFFFFF', '#C3979F'];
                    min = 0;
                    break;
                case "tvoc":
                    colors = ['#FFFFFF', '#025286'];
                    min = 0;
                    break;
            }
            state.selectedMap.locations.forEach(location => {
                Vue.set(location, 'state', 'grey')
                data.forEach(item => {
                    if (item.locationId && location.id == item.locationId) {
                        if(data[0].field == "light" || data[0].field == "motion" || data[0].field == "tvoc") {
                            max = Number(max) < Number(item.value).toFixed(1) ? Number(item.value).toFixed(1) : Number(max);
                        }
                        Vue.set(location, 'state', getRGBValueBetweenColors(item.value, min, max, colors));
                    }
                })
            })
            state.selectedMap = selectedMap;
            state.selectedMap.min = min;
            state.selectedMap.max = max;
            state.selectedMap.colors = colors;
        }
    }
}

function getRGBValueBetweenColors(value, min = 0, max = 100, colors) {
    var f = chroma.scale(colors);
    var normalizedValue = ((value - min) / (max - min));
    return f(normalizedValue).css();
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

function processSelectedMapSensorData(state, sensorValues) {
    console.log('processSelectedHeatmapSensorData')
    // parse all sensor values and set them to map locations
    if (sensorValues && state.selectedMap) {
        state.selectedMap.locations.forEach(location => {
            // clear current data
            location.sensorValuesAvgMap = []
            sensorValues.forEach(sensorValue => {
                if (sensorValue.locationId && location.id == sensorValue.locationId) {
                    location.sensorValuesAvgMap.push(sensorValue)
                }
            })
        })
    }
}
