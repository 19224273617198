<template>

  <div>

    <div class="form-group row m-b-sm" v-if="userResourceRoles.length != 0">
      <div class="col-lg-6">
        <div class="input-group">
          <span class="input-group-prepend">
            <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length == 0" @click="clearFilter"> {{
                $t('common.actions.clear')
              }}</b-button>
          </span>
          <b-form-input type="text"
                v-model="table.filter"
                debounce="300"
                max="50"
                :placeholder="$t('enterForSearch')"
                class="form-control form-control-sm"/>
        </div>
      </div>
      <div class="col-lg-2 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="userResourceRoles.length"></row-count>
      </div>
      <div class="col-lg-4 text-right" v-if="userResourceRoles.length && userResourceRoles.length > table.size">
        <b-pagination v-if="userResourceRoles.length" size="sm" class="m-t-xs" align="right"
                      :total-rows="userResourceRoles.length"
                      v-model="table.page"
                      :per-page="table.size"></b-pagination>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-table responsive hover striped
                 v-if="userResourceRoles.length != 0"
                 class="text-nowrap"
                 :filter="table.filter"
                 :fields="table.fields"
                 :current-page="table.page"
                 :per-page="table.size"
                 :items="userResourceRoles"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc">
          <template #cell(userUsername)="data">
            <b-link :to="{ path: routePrefix + '/users/' + data.item.userId }">{{ data.value }}</b-link>
          </template>
          <template #cell(userStatus)="data">
            <span class="label" :class="[data.item.status == 'BLOCKED' ? 'label-danger' : 'label-primary']">{{ $i18n.t('enums.UserStatus.' + data.value)}}</span>
          </template>
          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">{{ data.value }}</template>
        </b-table>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

        <div class="row" v-if="canAdd">
          <div class="col-lg-12 buttons">
            <router-link :to=" { path: routePrefix + '/users/new/' }">
              <button class="btn btn-sm btn-primary pull-left mr-1">
                <i class="fa fa-plus"></i>&nbsp;{{ $t('orgAdmin.user.actions.new') }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import i18n from '@/i18n'
import {mapState} from 'vuex'
import RowCount from '@/components/common/RowCount.vue';

export default {
  components: {
    RowCount
  },
  props: {
    routePrefix: {
      default: ''
    },
    canAdd: {
      type: Boolean
    }
  },
  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: 'name',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50},
          {'value': 100}
        ],
        fields: [
          {
            key: 'userUsername',
            label: this.$i18n.t('orgAdmin.userResource.attributes.username'),
            sortable: true,
          },
          {
            key: 'userName',
            label: this.$i18n.t('orgAdmin.userResource.attributes.name'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.userFirstName + ' ' + item.userLastName
            }
          },
          {
            key: 'userStatus',
            label: this.$i18n.t('orgAdmin.userResource.attributes.status'),
            sortable: true,
            class: ["text-center"]
          },
          {
            key: 'role',
            label: this.$i18n.t('orgAdmin.userResource.attributes.role'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return i18n.t('enums.UserRole.' + item.role)
            }
          },
          {
            key: 'resourceName',
            label: this.$i18n.t('orgAdmin.userResource.attributes.resourceName'),
            sortable: true,
          }
        ]
      }
    }
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    }
  },
  computed: {
    ...mapState({
      userResourceRoles: state => state.userManagement.userResourceRoles
    })
  }
}
</script>
