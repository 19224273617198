<template>
  <div>

    <div class="row">
      <div class="col-12">
        <div id="fabric-canvas-wrapper" :class="{ visible: selectedMap && selectedMap.locations, hidden: !(selectedMap && selectedMap.locations) }">
          <div id="legend">
            <i id="legend-down-arrow" class="fa fa-chevron-down" aria-hidden="true"></i>
            <div id="legend-gradient"></div>
            <div id="legend-values-wrapper"></div>
          </div>
          <div id="button-container">
            <button type="button" class="btn btn-sm btn-primary" @click="toggleLock">
              <i :class="isLocked ? 'fas fa-lock' : 'fas fa-lock-open'"></i>
            </button>
            <button type="button" class="btn btn-sm btn-primary m-l-xs" @click="zoomIn"><i class="fas fa-plus"></i></button>
            <button type="button" class="btn btn-sm btn-primary m-l-xs" @click="zoomOut"><i class="fas fa-minus"></i></button>
          </div>
          <canvas id="floor-map-canvas"></canvas>
        </div>
        
        <div class="text-center mt-5 mb-5" v-if="!(selectedMap && selectedMap.locations)">
          
          <div class="font-bold">
            {{ $t('analytics.mapView.placeholder') }}
          </div>
        </div>
      </div>
    </div>

    <div id="popover">
      <h4>{{ popover.title }}</h4>
      <div v-if="popover.sensorData.length > 0">
        <div v-for="sensorData in popover.sensorData" :key="sensorData.field" :class="sensorData.state">
          <i :class="$t('enums.sensorType.' + sensorData.field + '.icon')"></i> <span>{{ $t('enums.sensorType.' + sensorData.field + '.name') }} </span> <span>{{ sensorData.value }} {{ $t('enums.sensorType.' + sensorData.field + '.unit') }}</span>
        </div>
      </div>
      <div v-else>No data available</div>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import { fabric } from 'fabric'

export default {
  data() {
    return {
      info: null,
      canvas: null,
      popover: {
        title: '',
        sensorData: []
      },
      firstDraw: true,
      colors: { green: '#66C28F', yellow: '#FFD966', red: '#E94F37', grey: '#C8C8C8' },
      isLocked: true
    }
  },
  mounted(){
    console.log('MapView $route.name: ' + this.$route.name)
    this.setupCanvas()
    if(this.selectedMap){
      this.loadMap()
      this.repaintPolygons()
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedMap: state => state.analytics.selectedMap,
    }),
  },
  watch: {
    selectedMap: {
      handler(newVal, oldVal) {
        console.log('selectedMap changed: ',  newVal ? "(" + newVal.id + ") " + newVal.name: null, ' | was: ', oldVal ? "(" + oldVal.id + ") " + oldVal.name: null)
        if ((newVal && oldVal == null) || (newVal && newVal.id != oldVal.id) || this.firstDraw) {
          this.loadMap()
          this.firstDraw = false;
        } else if (newVal && oldVal && newVal.id == oldVal.id) {
          this.repaintPolygons()
        }
      },
      deep: true
    },
  },
  beforeDestroy() {
    console.log("Removing window.onresize")
    window.onresize = null
  },
  methods: {
    setupCanvas() {

      console.log("MapView: setupCanvas")
      let vm = this

      if(this.canvas !== null){
        console.log("dispose")
        this.canvas.dispose()
      }

      this.canvas = new fabric.Canvas('floor-map-canvas', { selection: false })
      
      this.canvas.hoverCursor = 'default'
      this.canvas.targetFindTolerance = 2
      this.canvas.setHeight(800)
      this.canvas.setWidth(800)

      let arrow = document.getElementById("legend-down-arrow")

      this.canvas.on('mouse:down', function(opt) {
        var evt = opt.e;
        //if (evt.altKey === true) {
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = evt.clientX;
          this.lastPosY = evt.clientY;
        //}
      });
      this.canvas.on('mouse:move', function(opt) {
        if (!vm.isLocked && this.isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
      });
      this.canvas.on('mouse:up', function(opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
      });

      this.canvas.on('mouse:wheel', function (opt) {
        if(!this.isLocked) {
          var delta = opt.e.deltaY;
          var zoom = this.canvas.getZoom();
          zoom *= 0.999 ** delta;
          if (zoom > 10) zoom = 10;
          if (zoom < 0.5) zoom = 0.5;
          this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
          opt.e.preventDefault();
          opt.e.stopPropagation(); 
        }
      }.bind(this));
      
      // add event listeners
      this.canvas.on('mouse:over', function(e) {

        vm.canvas.selection = false

        arrow.style.display = 'none'

        if(e.target && e.target.selectable) {

          let popover = document.getElementById("popover");

          // Get the exact mouse position relative to the canvas
          const canvasOffset = vm.canvas.lowerCanvasEl.getBoundingClientRect();
          const mouseX = e.e.clientX - canvasOffset.left + 40;
          const mouseY = e.e.clientY - canvasOffset.top + 40;

          popover.style.left = `${mouseX}px`;
          popover.style.top = `${mouseY}px`; 
          popover.style.display = 'block';

          const location = vm.selectedMap.locations.find(location => location.id == e.target.id);

          vm.popover.title = `${location.name}${location.nameAlternate ? ` (${location.nameAlternate})` : ''}`;
          vm.popover.sensorData = []

          if(location.hasOwnProperty('sensorValuesAvgMap')){
            vm.popover.sensorData = []
            location.sensorValuesAvgMap.forEach(value => {
              
              let valueFormatted
              if (value['field'] == 'closed') {
                valueFormatted = value['value'].filter(item => item == "false").length + "/" + value['value'].length
              } if (value['field'] == 'door_closed') {
                valueFormatted = value['value'].filter(item => item == "0.0").length + "/" + value['value'].length
              } else {
                valueFormatted = Math.round(value['value'] * 10) / 10
              }
              vm.popover.sensorData.push({
                field: value['field'],
                value: valueFormatted,
                state: value[1]})
              
              arrow.style.left = ((valueFormatted - vm.selectedMap.min)/ (vm.selectedMap.max - vm.selectedMap.min)) * 100 + '%';
              arrow.style.display = 'block'
            })

            let preferredOrder = vm.$store.state.systemParams.preferredSensorOrder
            vm.popover.sensorData = vm.popover.sensorData.sort(function (a, b) {
              return preferredOrder.indexOf(a.field) - preferredOrder.indexOf(b.field)
            })

          }
        }
      })

      this.canvas.on('mouse:out', function(e) {
        if(e.target && e.target.selectable && !e.target.isSelected) {
          //e.target.set('stroke', '#C8C8C8')
          //vm.canvas.renderAll()
        }
        let popover = document.getElementById("popover")
        popover.style.display = 'none'
        let arrow = document.getElementById("legend-down-arrow")
        arrow.style.display = 'none'
      })

    },

    removeAll() {
      let vm = this
      let o = this.canvas.getObjects();
      o.forEach(function (object, key) {
          vm.canvas.remove(object);
      });
      this.canvas.renderAll();
    },

    removePolygons() {
      let vm = this
      let o = this.canvas.getObjects();
      o.forEach(function (object, key) {
        if (object.type === "polygon") {
          vm.canvas.remove(object);
        }
      });
      this.canvas.renderAll();
    },

    repaintPolygons() {
      let vm = this
      let o = this.canvas.getObjects();

      var maxSegments = 4;
      var legendWidth = $('#legend-gradient').outerWidth();
      var colorArray = $.map(vm.selectedMap.colors, function (item) { return item })

      $('#legend-gradient').css({
        'background': 'linear-gradient(to right,' + colorArray.toString() + ')',
      });

      document.getElementById("legend-values-wrapper").innerHTML = null;

      var value = (vm.selectedMap.max - vm.selectedMap.min) / maxSegments;

      //offsets are wrong should be 16px (1em) * 0.5 * number of digits - too much work!
      for (var i = 0; i <= maxSegments; ++i) {
        var offset = i * legendWidth / maxSegments;
        if (i > 0 && i < maxSegments) {
          offset -= 0.5;
        } else if (i == maxSegments) {
          offset -= 1;
        }
        $('#legend-values-wrapper').append($('<div class="legend-values-tick">').css({
          'left': offset + 'px',
        }));
        $('#legend-values-wrapper').append($('<div class="legend-values">').css({
          'left': offset - 4 + 'px', //this offset is for part of 1em to make number in the middle, works only for 1 digit!
        }).html(Math.round((i * value + vm.selectedMap.min) * 100) / 100));
      }

      o.forEach(function (object, key) {
        if (object.type === "polygon") {
          const location = vm.selectedMap.locations.find(location => location.id == object.id);
          object.set('fill', location.state);
        }
      });
      this.canvas.renderAll();
    },

    loadMap(){
      console.log("MapView: loadMap")

      this.removeAll()

      let vm = this

     // add floor map image
      fabric.Image.fromURL("data:text/plain;base64," + this.selectedMap.image, function (img) {
        img.set({
          left: 0,
          top: 0,
          opacity: 1,
          selectable: false,
        })

        vm.imgRatio = img.width / img.height

        if(img.width  > img.height) {
          img.scaleToWidth(800, false)
        } else {
          img.scaleToHeight(800, false)
        }

        vm.canvas.add(img)
        vm.canvas.sendToBack(img)

        vm.resizeCanvas()
        window.onresize = vm.resizeCanvas
      })

      //draw existing locations
      if (this.selectedMap.locations) {
        this.selectedMap.locations.filter(location => location.status == 'ACTIVE').forEach((location) => {
          let polygon = new fabric.Polygon(location.points, {
            fill: location.state,
            strokeWidth: 2,
            stroke: '#C8C8C8',
            objectCaching: false,
            transparentCorners: false,
            cornerColor: 'blue',
            opacity: 0.9,
            id: location.id,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            hoverCursor: "pointer",
            hasControls: false,
            hasBorders: false,
            perPixelTargetFind: true
          })
          this.canvas.add(polygon)
        })
      }
    },

    findTopRightCorner(object) {
      let maxX = 0
      let minY = 5000
      object.points.forEach(point => {
        maxX = Math.max(maxX, point.x)
        minY = Math.min(minY, point.y)
      })
      return { x: maxX * object.canvas.viewportTransform[0], y: minY * object.canvas.viewportTransform[3] }
    },

    resizeCanvas() {
      console.debug("resizeCanvas")
      const outerCanvasContainer = document.getElementById('fabric-canvas-wrapper')

      const containerWidth = outerCanvasContainer.clientWidth
      const scale          = containerWidth / this.canvas.getWidth()
      const zoom           = this.canvas.getZoom() * scale
      this.canvas.setDimensions({width: containerWidth, height: containerWidth / (this.imgRatio > 1 ? this.imgRatio : 1) });
      this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
    },

    zoomIn() {
      const zoom = this.canvas.getZoom();
      const newZoom = zoom * 1.1;
      this.zoomCanvas(newZoom);
    },

    zoomOut() {
      const zoom = this.canvas.getZoom();
      const newZoom = zoom / 1.1;
      this.zoomCanvas(newZoom);
    },

    zoomCanvas(newZoom) {
      if(!this.isLocked) {
        if (newZoom < 0.5) newZoom = 0.5;
        if (newZoom > 10) newZoom = 10;

        const center = new fabric.Point(this.canvas.width / 2, this.canvas.height / 2);
        this.canvas.zoomToPoint(center, newZoom);
        this.canvas.renderAll();
      }
    },

    toggleLock() {
      this.isLocked = !this.isLocked;
    }

  }
}
</script>
<style>
#fabric-canvas-wrapper {
  min-height: 200px;
  min-width: 300px;
}
#popover {
  display: none;
  background: rgba(50, 50, 50, 0.9);
  color: #fff;
  padding: 20px;
  position: absolute;
  pointer-events: none;
  z-index: 1000;
  width:auto;
  min-width: 250px;
  height:auto;
  border-radius: 5px;
}
#popover .fa {
  width: 1.5em;
  text-align: center;
  padding-right: 0.5em;
}
.red {
  color: red;
}
.yellow {
  color: yellow;
}
.green {
  color: green;
}
.pink {
  color: green;
}
.visible {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}

#legend {
    position: relative;
    margin: 20px;
    height: 30px;
    margin-top: 10px;
}

.legend-values {
  position: absolute;
  top: 20px;
  width: 10px;
  text-align: center;
  font-size: 1em;
}

.legend-values-tick {
  position: absolute;
  top: 20px;
  width: 1px;
  height: 3px;
  background: #676a6c;
}

#legend-gradient {
    width: 100%;
    height: 20px;
    border: 1px solid #676a6c;
    opacity: 0.9;
}

#legend-down-arrow {
    position: absolute;
    top: -10px;
    margin-left: -8px;
}

#button-container {
  padding-bottom: 8px;
  display: flex;
  justify-content: flex-end;
}
</style>
