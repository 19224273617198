import { render, staticRenderFns } from "./RegisterOrganisationRequestDetails.vue?vue&type=template&id=5e2184bd"
import script from "./RegisterOrganisationRequestDetails.vue?vue&type=script&lang=js"
export * from "./RegisterOrganisationRequestDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports