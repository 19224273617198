import Vue from "vue"
import * as commons from '@/commons'
import router from '@/router'
import moment from 'moment-timezone'
import i18n from "@/i18n";

const getDefaultState = () => {

  return {
    selectedMap: null,
    sensorValuesPerAttributesPerLocation: null,
    availableSensorAttributes: [],
    graphConfig: {
      dateRange: {
        startDate: null,
        endDate: null
      },
      dateRangeFormatted: null,
      selectedSensorAttributes: [],
      fullSize: false,
      selectedLocations: [],
      thresholdLegend: false,
      customCO2Thresholds: {
        low: 1000,
        high: 2000,
      },
      enableWeekends: false
    }
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  graphConfigDateTimeRangeFormatted: state => {
    if(state.graphConfig.dateRange.startDate && state.graphConfig.dateRange.endDate) {
      return moment(state.graphConfig.dateRange.startDate).format(i18n.t('dateTimeShortFormatMoment'))
          + ' - '
          + moment(state.graphConfig.dateRange.endDate).format(i18n.t('dateTimeShortFormatMoment'))
    } else {
      return ''
    }
  }
}

// action
const actions = {

  appInit({ commit, state, dispatch, rootState }) {
    console.log('v/appInit')
    dispatch('resetGraphConfig')
  },

  resetGraphConfig({ commit, state }, data) {
    console.log('hourlyHeatmps/resetGraphConfig')

    return new Promise((resolve) => {
      let startDate = new Date()
      startDate.setDate(startDate.getDate() - 1)
      let endDate = new Date()
      commit('UPDATE_GRAPH_CONFIG', {
        dateRange: {startDate: startDate, endDate: endDate},
        selectedLocations: []
      })
      resolve()
    })
  },

  loadMapFromRouteQueryParam({ commit, state, dispatch }){

    console.log('loadMapFromRouteQueryParam ' + router.currentRoute.query.mapId)

    if (router.currentRoute.query.mapId) {
      if (state.selectedMap && state.selectedMap.locations && state.selectedMap.id == router.currentRoute.query.mapId) {
        dispatch('loadSelectedMapSensorValues')
      } else {
        //load map from server
        Vue.axios.get('/maps/' + router.currentRoute.query.mapId).then(response => {
          commit('UPDATE_SELECTED_MAP', response.data)
          commit('UPDATE_GRAPH_CONFIG', { selectedLocations: [] })
          dispatch('loadSelectedMapSensorValues')
        }).catch(error => {
          commons.processRestError(error)
        })
      }
    }else{
      commit('UPDATE_SELECTED_MAP', null)
      dispatch('resetGraphConfig')
    }
  },

  loadSelectedMapSensorValues({ commit, state, dispatch, rootState }){

    console.log('loadSelectedMapSensorValues graphConfig ' + JSON.stringify(state.graphConfig))

    return new Promise((resolve, reject) => {

      if(state.selectedMap.locations.length == 0) {
        resolve()
      }

      let params = {}

      if(state.graphConfig.dateRange){
        params.dateTimeFrom = state.graphConfig.dateRange.startDate
        params.dateTimeFrom = moment(state.graphConfig.dateRange.startDate).format('YYYY-MM-DDTHH:mm:ss')
        let nowInBuildingTimezone = moment.tz(moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), state.selectedMap.building.timeZone)
        let endDateInBuildingTimezone = moment.tz(moment(state.graphConfig.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss'), state.selectedMap.building.timeZone)
        if(!endDateInBuildingTimezone.isSame(nowInBuildingTimezone,'day')) {
          params.dateTimeTo = state.graphConfig.dateRange.endDate
          params.dateTimeTo = moment(state.graphConfig.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss')
        }
        params.aggregateWindowDuration = "PT1H"
      }

      Vue.axios.get('/maps/' + state.selectedMap.id + '/sensor-values', { params: params }).then(response => {
        commit('UPDATE_SELECTED_MAP_SENSOR_VALUES', response.data)
        resolve(response)
      }).catch(error => {
        commons.processRestError(error)
        reject(error)
      })
    })
  },

  graphConfigMonthSelected({ commit, state, dispatch }, data){
    return new Promise((resolve, reject) => {
      commit('UPDATE_GRAPH_CONFIG',  { dateRange: { startDate: data.from, endDate: data.to} })
      dispatch('loadSelectedMapSensorValues').then(response => {
      })
      resolve();
    });
  },

  graphConfigSelectedLocationsChanged({ commit, state, dispatch }, data){
    return new Promise((resolve, reject) => {
      commit('UPDATE_GRAPH_CONFIG',  { selectedLocations:  data })
      resolve();
    });
  },

  graphConfigOptionsChanged({ commit, state, dispatch }, data){
    return new Promise((resolve, reject) => {
      commit('UPDATE_GRAPH_CONFIG',  data)
      resolve();
    });
  },

  graphConfigSensorAttributesChanged({ commit, state, dispatch }, data){
    return new Promise((resolve, reject) => {
      commit('UPDATE_GRAPH_CONFIG',  { selectedSensorAttributes:  data })
      resolve();
    });
  },

}

// mutations
const mutations = {

  ['GLOBAL_RESET']: (state, data) => {
    Object.assign(state, getDefaultState())
  },

  ['UPDATE_SELECTED_MAP']: (state, data) => {
    state.selectedMap = data
  },

  ['UPDATE_SELECTED_MAP_SENSOR_VALUES']: (state, data) => {
    Vue.set(state.selectedMap, 'sensorValues', data)
    processSelectedMapSensorData(state, data)
  },

  ['UPDATE_GRAPH_CONFIG']: (state, data) => {
    state.graphConfig = Object.assign({}, state.graphConfig, data)
  },

  ['UPDATE_LOCATION_SENSOR_VALUES']: (state, data) => {
    console.log(data)
    state.availableSensorAttributes = commons.getAvailableSensorAttributesFromSensorData(data[0], null, true)
    if(state.availableSensorAttributes.length > 0 && state.graphConfig.selectedSensorAttributes.length == 0){
      Vue.set(state.graphConfig, 'selectedSensorAttributes', [state.availableSensorAttributes[0]])
    }
  }
}

function processSelectedMapSensorData(state, sensorValues) {
  let sensorAttributesMap = new Map();
  let locationSensorData = new Map();

  if (state.selectedMap && state.selectedMap.locations) {
    state.selectedMap.locations.forEach(location => {
      location.sensorValues = [];
      location.sensorValuesMap = new Map();
      locationSensorData.set(location.id, location);
    });
  }

  sensorValues.forEach(sensorValue => {
    if (!sensorAttributesMap.has(sensorValue.field)) {
      sensorAttributesMap.set(sensorValue.field, new Map());
    }

    let location = locationSensorData.get(sensorValue.locationId);
    if (location) {
      location.sensorValues.push(sensorValue);

      let fieldValues = location.sensorValuesMap.get(sensorValue.field) || [];
      fieldValues.push(sensorValue.value);
      location.sensorValuesMap.set(sensorValue.field, fieldValues);

      if (['temperature', 'humidity', 'noise', 'light', 'co2', 'motion'].includes(sensorValue.field)) {
        let attributeMap = sensorAttributesMap.get(sensorValue.field);
        let attributeData = attributeMap.get(location.id) || [];
        attributeData.push(sensorValue);
        attributeMap.set(location.id, attributeData);
      }
    }
  });

  state.availableSensorAttributes = Array.from(sensorAttributesMap.keys());
  state.sensorValuesPerAttributesPerLocation = sensorAttributesMap;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
