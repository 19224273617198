<template>
  <div>

    <div class="row">
      <div class="col-12">
        <div id="fabric-canvas-wrapper"
          :class="{ visible: selectedMap && selectedMap.locations, hidden: !(selectedMap && selectedMap.locations) }">
          <div id="button-container">
            <button type="button" class="btn btn-sm btn-primary" @click="toggleLock">
              <i :class="isLocked ? 'fas fa-lock' : 'fas fa-lock-open'"></i>
            </button>
            <button type="button" class="btn btn-sm btn-primary m-l-xs" @click="zoomIn"><i class="fas fa-plus"></i></button>
            <button type="button" class="btn btn-sm btn-primary m-l-xs" @click="zoomOut"><i class="fas fa-minus"></i></button>
          </div>
          <canvas id="floor-map-canvas"></canvas>
        </div>
        <div class="text-center mt-5 mb-5" v-if="!(selectedMap && selectedMap.locations)">
          <div class="font-bold">
            {{ $t('indoorClimate.mapView.placeholder') }}
          </div>
        </div>
      </div>
    </div>

    <div id="popover">
      <h4>{{ popover.title }}</h4>
      <div v-if="popover.sensorData.length > 0">
        <div v-for="sensorData in popover.sensorData" :key="sensorData.field" :class="sensorData.state">
          <i :class="$t('enums.sensorType.' + sensorData.field + '.icon')"></i> <span>{{ $t('enums.sensorType.' +
            sensorData.field + '.name') }} </span> <span>{{ sensorData.value }} {{ $t('enums.sensorType.' +
    sensorData.field + '.unit') }}</span>
        </div>
      </div>
      <div v-else>No data available</div>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import { fabric } from 'fabric'

export default {
  data() {
    return {
      info: null,
      canvas: null,
      popover: {
        title: '',
        sensorData: []
      },
      firstDraw: true,
      colors: { green: '#66C28F', yellow: '#FFD966', red: '#E94F37', grey: '#C8C8C8' },
      isLocked: true
    }
  },
  mounted(){
    this.setupCanvas()
    if(this.selectedMap){
      this.loadMap()
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedMap: state => state.liveViewShare.selectedMap,
    }),
  },
  watch: {
    selectedMap: {
      handler(newVal, oldVal) {
        console.log('selectedMap changed: ',  newVal ? "(" + newVal.id + ") " + newVal.name: null, ' | was: ', oldVal ? "(" + oldVal.id + ") " + oldVal.name: null)
        if ((newVal && oldVal == null) || (newVal && newVal.id != oldVal.id) || this.firstDraw) {
          this.loadMap()
          this.firstDraw = false;
        } else if (newVal && oldVal && newVal.id == oldVal.id) {
          this.repaintPolygons()
        }
      },
      deep: true
    },
  },
  beforeDestroy() {
    console.log("Removing window.onresize")
    window.onresize = null
  },
  methods: {
    setupCanvas() {
      console.log("MapView: setupCanvas")
      let vm = this

      if(this.canvas !== null){
        console.log("dispose")
        this.canvas.dispose()
      }

      this.canvas = new fabric.Canvas('floor-map-canvas', { selection: false, preserveObjectStacking: true })

      this.canvas.hoverCursor = 'default'
      this.canvas.targetFindTolerance = 2
      this.canvas.setHeight(800)
      this.canvas.setWidth(800)

      this.canvas.on('mouse:down', function(opt) {
        var evt = opt.e;
        //TO-DO: should dragging be with button combo?
        //if (evt.altKey === true) {
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = evt.clientX;
          this.lastPosY = evt.clientY;
        //}
      });

      this.canvas.on('mouse:move', function(opt) {
        if (!vm.isLocked && this.isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
      });

      this.canvas.on('mouse:up', function(opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
        
        var pointer = vm.canvas.getPointer(opt.e, true);
        let target = opt.target;
            
        // check if icon was clicked and get polygon under it
        vm.canvas.forEachObject(function(obj) {
          if (!obj.selectable && obj.isIcon && obj.containsPoint(pointer)) {
            target = vm.canvas.getObjects().find(obj => obj !== target && obj.containsPoint(target.getCenterPoint()) && obj.selectable);
              if (target && target.selectable) {
                vm.canvas.setActiveObject(target);
              }
          }
        });
      });

      this.canvas.on('mouse:wheel', function (opt) {
        if(!this.isLocked) {
          var delta = opt.e.deltaY;
          var zoom = this.canvas.getZoom();
          zoom *= 0.999 ** delta;
          if (zoom > 10) zoom = 10;
          if (zoom < 0.5) zoom = 0.5;
          this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
          opt.e.preventDefault();
          opt.e.stopPropagation(); 
        }
      }.bind(this));

      // add event listeners
      this.canvas.on('mouse:over', function(e) {
        vm.canvas.selection = false

        let target = e.target;
        let isIcon = target && !target.selectable && target.isIcon;

        if (isIcon) {
          let canvasOffset = vm.canvas.lowerCanvasEl.getBoundingClientRect();
          let mouseX = e.e.clientX - canvasOffset.left;
          let mouseY = e.e.clientY - canvasOffset.top;

          let mousePoint = new fabric.Point(mouseX, mouseY);
          target = vm.canvas.getObjects().find(obj => obj !== target && obj.containsPoint(mousePoint) && obj.selectable);
        }

        if (target && target.selectable) {
          vm.updatePopoverPosition(e.e);
          const location = vm.selectedMap.locations.find(loc => loc.id == target.id);
          vm.setPopoverData(location);
        }
      })

      this.canvas.on('mouse:out', function(e) {
        if(e.target && e.target.selectable && !e.target.isSelected) {
          //e.target.set('stroke', '#C8C8C8')
          //vm.canvas.renderAll()
        }
        let popover = document.getElementById("popover")
        popover.style.display = 'none'
      })

      this.canvas.on('selection:created', function(e){
        console.log('selection:created')
        e.selected[0].set('stroke', '#0703fc')
        vm.canvas.renderAll()
        vm.$router.replace({ ...vm.$router.currentRoute, query: { locationId: e.selected[0].id} })
      })

      this.canvas.on('selection:updated', function(e) {
        console.log('selection:updated')
        e.selected[0].set('stroke', '#0703fc')
        if (e.deselected) {
          e.deselected[0].set('stroke', '#C8C8C8')
        }
        vm.canvas.renderAll()
        vm.$router.replace({ ...vm.$router.currentRoute, query: { locationId: e.selected[0].id} })
      })

      this.canvas.on('selection:cleared', function(e){
        console.log('selection:cleared')
        if (e.deselected) {
          e.deselected[0].set('stroke', '#C8C8C8')
        }
        vm.canvas.renderAll()
        vm.$router.replace({ ...vm.$router.currentRoute, query: {} })
      })
    },

    updatePopoverPosition(target) {
      let popover = document.getElementById("popover");
      const canvasOffset = this.canvas.lowerCanvasEl.getBoundingClientRect();

      popover.style.left = `${target.clientX + 8 - canvasOffset.left + 40}px`;
      popover.style.top = `${target.clientY + 8 - canvasOffset.top + 40}px`;
      popover.style.display = 'block';
    },

    setPopoverData(location) {
      this.popover.title = location.name;
      this.popover.sensorData = [];

      if (location.hasOwnProperty('sensorValuesAvgMap')) {
          location.sensorValuesAvgMap.forEach((value, field) => {
            if (field === "target_temperature" || field === "devEUI") {
                return
              }
              let valueFormatted
              if (typeof value[0] == "number") {
                valueFormatted = Math.round(value[0] * 10) / 10
              } else if (field == 'closed') {
                valueFormatted = Array.from(location.windowMap.values()).filter(item => item === "false" || item === "0.0").length + "/" + Array.from(location.windowMap.values()).length
              } else if (field == 'door_closed') {
                valueFormatted = Array.from(location.doorMap.values()).filter(item => item === "0.0").length + "/" + Array.from(location.doorMap.values()).length
              } else {
                valueFormatted = value
              }

              this.popover.sensorData.push({
                field: field,
                value: valueFormatted,
                state: value[1]
              })
          });

          let preferredOrder = this.$store.state.systemParams.preferredSensorOrder;
          this.popover.sensorData.sort((a, b) => preferredOrder.indexOf(a.field) - preferredOrder.indexOf(b.field));
      }
    },

    removeAll() {
      console.log('removeAll')
      let vm = this
      let o = this.canvas.getObjects();
      o.forEach(function (object, key) {
          vm.canvas.remove(object);
      });
      this.canvas.renderAll();
    },

    removePolygons() {
      console.log('removePolygons')
      let vm = this
      let o = this.canvas.getObjects();
      o.forEach(function (object, key) {
        if (object.type === "polygon") {
          vm.canvas.remove(object);
        }
      });
      this.canvas.renderAll();
    },

    repaintPolygons() {
      console.log('repaintPolygons')
      let vm = this

      let o = this.canvas.getObjects();

      //remove all text (presence icons)
      o.forEach(function (object, key) {
        if (object.type === "text") {
          vm.canvas.remove(object);
        }
      });

      o.forEach(function (object, key) {
        if (object.type === "polygon") {

          //set color on polygon based on location state
          const location = vm.selectedMap.locations.find(location => location.id == object.id);
          object.set('fill', vm.colors[location.state])
          //set presence and window icons on polygon
          if (location.showPresence || location.showWindow) {

            let icons = ''
            if (location.showPresence && location.presence) {
              icons += '\uf70c'
            }
            if (location.showWindow && location.window) {
              if (icons) {
                icons += ' '
              }
              icons += '\uf009';
            }

            let text = new fabric.Text(icons, {
              fontSize: 22,
              fontFamily: 'Font Awesome 6 Free',
              fontWeight: 900,
              left: object.getCenterPoint().x - 8,
              top: object.getCenterPoint().y - 8,
              selectable: false,
              isIcon: true,
              hoverCursor: 'pointer'
            });
            vm.canvas.insertAt(text, vm.canvas.getObjects().length);
          }

        }
      });

      this.canvas.renderAll();
    },

    loadMap(){
      console.log("MapView: loadMap")

      this.removeAll()

      let vm = this

      // add floor map image
      fabric.Image.fromURL("data:text/plain;base64," + this.selectedMap.image, function (img) {
        img.set({
          left: 0,
          top: 0,
          opacity: 1,
          selectable: false,
        })

        vm.imgRatio = img.width / img.height

        if(img.width > img.height) {
          img.scaleToWidth(800, false)
        } else {
          img.scaleToHeight(800, false)
        }

        vm.canvas.add(img)
        vm.canvas.sendToBack(img)

        vm.resizeCanvas()
        window.onresize = vm.resizeCanvas
      })

      //draw existing locations
      if (this.selectedMap.locations) {
        this.selectedMap.locations.filter(location => location.status == 'ACTIVE').forEach((location) => {
          let polygon = new fabric.Polygon(location.points, {
            fill: vm.colors[location.state],
            strokeWidth: 2,
            stroke: '#C8C8C8',
            objectCaching: false,
            transparentCorners: false,
            cornerColor: 'blue',
            opacity: 0.75,
            id: location.id,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            hoverCursor: "pointer",
            hasControls: false,
            hasBorders: false,
            perPixelTargetFind: true
          })
          this.canvas.add(polygon)
        })
      }

      this.repaintPolygons()

    },

    findTopRightCorner(object) {
      let maxX = 0
      let minY = 5000
      object.points.forEach(point => {
        maxX = Math.max(maxX, point.x)
        minY = Math.min(minY, point.y)
      })
      return { x: maxX * object.canvas.viewportTransform[0], y: minY * object.canvas.viewportTransform[3] }
    },

    resizeCanvas() {
      console.debug("resizeCanvas")
      const outerCanvasContainer = document.getElementById('fabric-canvas-wrapper')

      const containerWidth = outerCanvasContainer.clientWidth
      const scale          = containerWidth / this.canvas.getWidth()
      const zoom           = this.canvas.getZoom() * scale
      this.canvas.setDimensions({width: containerWidth, height: containerWidth / (this.imgRatio > 1 ? this.imgRatio : 1) });
      this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
    },

    zoomIn() {
      const zoom = this.canvas.getZoom();
      const newZoom = zoom * 1.1;
      this.zoomCanvas(newZoom);
    },

    zoomOut() {
      const zoom = this.canvas.getZoom();
      const newZoom = zoom / 1.1;
      this.zoomCanvas(newZoom);
    },

    zoomCanvas(newZoom) {
      if(!this.isLocked) {
        if (newZoom < 0.5) newZoom = 0.5;
        if (newZoom > 10) newZoom = 10;

        const center = new fabric.Point(this.canvas.width / 2, this.canvas.height / 2);
        this.canvas.zoomToPoint(center, newZoom);
        this.canvas.renderAll();
      }
    },

    toggleLock() {
      this.isLocked = !this.isLocked;
    }

  }
}
</script>
<style scoped>
#fabric-canvas-wrapper {
  min-height: 200px;
  min-width: 300px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: 1px solid #ccc;
  display: inline-block;
  position: relative;
}
#popover {
  display: none;
  background: #323232;
  color: #fff;
  padding: 20px;
  position: absolute;
  pointer-events: none;
  z-index: 1000;
  width:auto;
  min-width: 250px;
  height:auto;
  border-radius: 5px;
}
#popover .fa {
  width: 1.5em;
  text-align: center;
  padding-right: 0.5em;
}
.red {
  color: #E94F37;
}
.yellow {
  color: #FFD966;
}
.green {
  color: #66C28F;
}
.visible {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}
#button-container {
  padding-bottom: 8px;
  display: flex;
  justify-content: flex-end;
}
</style>
