<template>
  <div class="animated fadeInRight" v-if="contextOrg">
    <div class="ibox">
      <div class="ibox-content p-5">
          <div class="form-group row m-b-md" v-if="buildings.length !== 0">
            <div class="col-lg-6">
              <div class="input-group">
                <span class="input-group-prepend">
                  <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length === 0" @click="clearFilter"> {{
                      $t('common.actions.clear')
                    }}</b-button>
                </span>
                <b-form-input type="text"
                              v-model="table.filter"
                              debounce="300"
                              max="50"
                              :placeholder="$t('enterForSearch')"
                              class="form-control form-control-sm"/>
              </div>
            </div>
            <div class="col-lg-3 m-t-sm text-right">
              <row-count :size="table.size" :page="table.page" :total="buildings.length"></row-count>
            </div>
            <div class="col-lg-3 text-right" v-if="buildings.length && buildings.length > table.size">
              <b-pagination v-if="buildings.length" size="sm" class="m-t-xs" align="right"
                            :total-rows="buildings.length"
                            v-model="table.page"
                            :per-page="table.size"></b-pagination>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <b-table responsive _hover _striped
                       v-if="buildings.length !== 0"
                       _class="text-nowrap"
                       thead-class="text-nowrap"
                       :filter="table.filter"
                       :fields="table.fields"
                       :current-page="table.page"
                       :per-page="table.size"
                       :items="buildings"
                       :sort-by.sync="table.sortBy"
                       :sort-desc.sync="table.sortDesc">
                <template #cell(name)="data">
                  <b-link :to="{ path: '/buildings/' + data.item.id, params: { id: data.item.id } }">{{ data.value !== '' ? data.value : $t('common.notAvailable')}}</b-link>
                  <template v-if="data.item.status != 'ACTIVE'">
                    <span class="label ml-3" :class="[data.item.status == 'BLOCKED' || data.item.status == 'PARENT_BLOCKED'? 'label-danger' : 'label-primary']">{{ $i18n.t('enums.ResourceStatus.' + data.item.status) }}</span>
                  </template>
                </template>
                <template #cell(settings)="data">
                  <pre>{{ JSON.stringify(data.value, null, 2) }}</pre>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">{{ data.value }}</template>
              </b-table>
              <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

              <div class="row" v-if="hasOneOfRoles('ADMIN') || hasResourceRole('ORGANISATION_MANAGER', contextOrg.id)">
                <div class="col-lg-12 buttons">
                  <button class="btn btn-sm btn-primary pull-left mr-1" @click="addNew">
                    <i class="fa fa-plus"></i>&nbsp;{{ $t('orgAdmin.building.actions.new') }}
                  </button>
                </div>
              </div>

            </div>
          </div>

      </div>
    </div>
  </div>
</template>

<script>
import RowCount from '@/components/common/RowCount'
import {mapState} from "vuex"
import {getUserAuthorities} from "@/commons";

export default {
  components: {
    RowCount
  },
  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: '',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50},
          {'value': 100}
        ],
        fields: [
          {
            key: 'name',
            label: this.$i18n.t('orgAdmin.building.attributes.name'),
            sortable: true,
            tdClass: 'vertical-align-middle'
          },
          {
            key: 'address',
            label: this.$i18n.t('orgAdmin.building.attributes.address'),
            sortable: true,
            tdClass: 'vertical-align-middle'
          },
          {
            key: 'city',
            label: this.$i18n.t('orgAdmin.building.attributes.city'),
            sortable: true,
            tdClass: 'vertical-align-middle'
          },
          {
            key: 'country',
            label: this.$i18n.t('orgAdmin.building.attributes.country'),
            sortable: true,
            tdClass: 'vertical-align-middle'
          },
        ]
      }
    }
  },
  created() {
    console.log('Buildings created()')
    this.fetchIfOrgChanged()
  },
  computed: {
    ...mapState({
      contextOrg: state => state.contextOrg,
      buildings: state => state.buildings.buildings
    })
  },
  watch: {
    contextOrg: function (newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.fetchIfOrgChanged()
      }
    }
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    },
    addNew() {
      this.$router.push({ path: '/buildings/new'})
    },
    fetchIfOrgChanged(){
      console.log('fetchIfOrgChanged ' + this.contextOrg?.id)
      if(this.isContextOrgAdmin) {
        this.$store.dispatch('buildings/fetchOrganisationBuildings', this.contextOrg.id)
      } else {
        this.$store.commit('buildings/SAVE_ORGANISATION_DETAILS_BUILDINGS',
            getUserAuthorities()
                .filter(auth => auth.authorizationTargetPath.organisation?.id == this.contextOrg.id && auth.role === 'BUILDING_MANAGER')
                .map(auth => auth.authorizationTarget))
      }
    }
  }
}
</script>
<style scoped>
.table > tbody > tr > td.vertical-align-middle {
  vertical-align: middle !important;
}
</style>