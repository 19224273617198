import Vue from "vue";
import * as commons from '@/commons'

// default state
const getDefaultState = () => {
    return {
        activeTab: 0,
        devices: [],
        deviceModels: [],
        deviceModelDetails: {
            entity: {}
        },
        deviceDetails: {
            entity: {}
        }
    }
}

// initial state
const state = getDefaultState()

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('systemAdmin/appInit')
    },

    fetchDeviceModels({commit, state, dispatch, rootState}) {

        console.log('system/fetchDeviceModels')

        //load device models
        Vue.axios.get('/admin/device-models').then(response => {
            commit('SAVE_SYSTEM_DEVICE_MODELS', response.data)
        }).catch(error => {
            this.error = commons.processRestError(error)
        })

    },

    fetchDevices({commit, state, dispatch, rootState}) {

        console.log('system/fetchDevices')

        //load devices
        Vue.axios.get('/admin/devices/unassigned').then(response => {
            commit('SAVE_SYSTEM_DEVICES', response.data)
        }).catch(error => {
            this.error = commons.processRestError(error)
        })
    },

    fetchDeviceModelDetails({commit, state, dispatch, rootState}, id) {

        console.log('system/fetchDeviceModelDetails')

        Vue.axios.get('/admin/device-models/' + id).then(response => {
            commit('SAVE_DEVICE_MODEL_DETAILS_ENTITY', response.data)
        }).catch(error => {
            this.error = commons.processRestError(error)
        })
    },

    fetchDeviceDetails({commit, state, dispatch, rootState}, id) {

        console.log('system/fetchDeviceDetails')

        Vue.axios.get('/admin/devices/' + id).then(response => {
            commit('SAVE_DEVICE_DETAILS_ENTITY', response.data)
        }).catch(error => {
            this.error = commons.processRestError(error)
        })
    }
}


// getters
const getters = {}

// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['SAVE_SYSTEM_DEVICES']: (state, data) => {
        state.devices = data
    },

    ['SAVE_SYSTEM_DEVICE_MODELS']: (state, data) => {
        state.deviceModels = data
    },

    ['SAVE_DEVICE_MODEL_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.deviceModelDetails, 'entity', data)
    },

    ['SAVE_DEVICE_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.deviceDetails, 'entity', data)
    },

    ['SAVE_ACTIVE_TAB']: (state, data) => {
        state.activeTab = data
    },

}

export default {
    namespaced: true, state, getters, actions, mutations
}