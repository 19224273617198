<template>
  <div v-if="contextOrg">
    <div class="ibox m-2">
      <div class="ibox-content m-2 mb-4">
        <building-logs
        :can-add="hasRole('ADMIN') || hasResourceRole('ORGANISATION_MANAGER', contextOrg.id)"
        :orgId="contextOrg.id"
        backRoute="/organisation/logs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import BuildingLogs from "@/components/views/buildings/BuildingLogs.vue";

export default {
  components: {
    BuildingLogs,
  },
  computed: {
    ...mapState({
      contextOrg: state => state.contextOrg,
    }),
  },
  watch: {
    contextOrg: {
      handler(newVal) {
        if (newVal && newVal.id) {
          this.loadLogs();
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    loadLogs() {
      this.$store.dispatch('buildingLogs/loadLogsForOrg', this.contextOrg.id);
    }
  },
}
</script>

<style scoped>
</style>
