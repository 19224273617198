<template>
    <span class="float-right small text-muted rows-count">{{
        $t('common.rowCount.fetchTotalCompact')
      }}<b>{{ showingFrom }}-{{ showingTo }}/{{ total }}</b></span>
</template>

<script>

export default {
  props: {
    page: {
      type: Number,
    },
    size: {
      type: Number,
    },
    total: {
      type: Number,
    }
  },
  computed: {
    showingFrom() {
      return ((this.page - 1) * this.size) + 1
    },
    showingTo() {
      return Math.min(this.page * this.size, this.total)
    }
  }

}
</script>

<style>
.rows-count {
  font-size: 13px;
}
</style>
