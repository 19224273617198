<template>
  <div class="animated fadeInRight">

    <div class="row">

      <div class="col-xxxl-6">

        <div class="ibox">
          <div class="ibox-content p-5">

            <validation-observer ref="form" v-slot="{ handleSubmit }">

              <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(save)">

                <div class="row">

                  <div class="col-12">

                    <b-form-group v-if="form.status" id="input-group-register-user-request-status" :label="$t('orgAdmin.registerUserRequest.attributes.status')" label-for="input-register-user-request-status" label-cols-sm="2">
                      <span class="label" :class="{'label-danger':form.status == 'DECLINED','label-primary':form.status == 'APPROVED','label-warning':form.status == 'PENDING'}">{{ $i18n.t('enums.RegisterUserRequestStatus.' + form.status) }}</span>
                    </b-form-group>

                    <b-form-group v-if="form.matchedOrganisation" id="input-group-register-user-request-matched-organisation" :label="$t('orgAdmin.registerUserRequest.attributes.matchedOrganisation')" label-for="input-register-user-request-matched-organisation" label-cols-sm="2">
                      <b-form-input
                          :plaintext="true"
                          id="input-register-user-request-matched-organisation"
                          :value="form.matchedOrganisation.name"
                      ></b-form-input>
                    </b-form-group>

                    <validation-provider vid="username" name="orgAdmin.registerUserRequest.attributes.username" :rules="{ required: true, alpha_dash: true, min: 3, max: 50 }" v-slot="validationContext">
                      <b-form-group id="input-group-register-user-request-username" :label="$t('orgAdmin.registerUserRequest.attributes.username')" label-for="input-register-user-request-username" label-cols-sm="2">
                        <b-form-input
                            :plaintext="true"
                            id="input-register-user-request-username"
                            name="input-register-user-request-username"
                            v-model="form.username"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-register-user-request-username"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-register-user-request-username">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="firstName" name="orgAdmin.registerUserRequest.attributes.firstName" :rules="{ required: true, max: 50 }" v-slot="validationContext">
                      <b-form-group id="input-group-register-user-request-firstName" :label="$t('orgAdmin.registerUserRequest.attributes.firstName')" label-for="input-register-user-request-firstName" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-register-user-request-firstName"
                            name="input-register-user-request-firstName"
                            v-model="form.firstName"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-register-user-request-firstName"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-register-user-request-firstName">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="lastName" name="orgAdmin.registerUserRequest.attributes.lastName" :rules="{ required: true, max: 50 }" v-slot="validationContext">
                      <b-form-group id="input-group-register-user-request-lastName" :label="$t('orgAdmin.registerUserRequest.attributes.lastName')" label-for="input-register-user-request-lastName" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-register-user-request-lastName"
                            name="input-register-user-request-lastName"
                            v-model="form.lastName"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-register-user-request-lastName"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-register-user-request-lastName">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="email" name="orgAdmin.registerUserRequest.attributes.email" :rules="{ required: true, email: true, max: 100 }" v-slot="validationContext">
                      <b-form-group id="input-group-register-user-request-email" :label="$t('orgAdmin.registerUserRequest.attributes.email')" label-for="input-register-user-request-email" label-cols-sm="2">
                        <b-form-input
                            :plaintext="true"
                            id="input-register-user-request-email"
                            name="input-register-user-request-email"
                            v-model="form.email"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-register-user-request-email"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-register-user-request-email">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="phone" name="orgAdmin.registerUserRequest.attributes.phone" :rules="{ max: 20 }" v-slot="validationContext">
                      <b-form-group id="input-group-register-user-request-phone" :label="$t('orgAdmin.registerUserRequest.attributes.phone')" label-for="input-register-user-request-phone" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-register-user-request-phone"
                            name="input-register-user-request-phone"
                            v-model="form.phone"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-register-user-request-phone"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-register-user-request-phone">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <b-form-group id="input-group-register-user-request-language" :label="$t('orgAdmin.registerUserRequest.attributes.language')" label-for="input-register-user-request-language" label-cols-sm="2">
                      <span class="badge badge-primary text-uppercase" v-if="type!='create'">{{ form.language }}</span>
                      <div class="pt-1" v-if="type=='create'">
                        <button type="button" class="btn btn-primary btn-outline btn-xs m-r-xs"
                                :class="{active:form.language=='en'}" @click.prevent="form.language='en'">EN
                        </button>
                        <button type="button" class="btn btn-primary btn-outline btn-xs m-r-xs"
                                :class="{active:form.language=='da'}" @click.prevent="form.language='da'">DA
                        </button>
                      </div>
                    </b-form-group>

                    <b-form-group v-if="form.resource" :label="$t('orgAdmin.registerUserRequest.attributes.resource')" label-for="input-register-user-request-resource" label-cols-sm="2">
                      <b-form-input
                          :plaintext="true"
                          id="input-register-user-request-resource"
                          :value="form.resource.name"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group v-if="form.resourceRole" :label="$t('orgAdmin.registerUserRequest.attributes.resourceRole')" label-for="input-register-user-request-resourceRole" label-cols-sm="2">
                      <b-form-input
                          :plaintext="true"
                          id="input-register-user-request-resourceRole"
                          :value="$t('enums.UserRole.' + form.resourceRole)"
                      ></b-form-input>
                    </b-form-group>

                  </div>

                </div>

                <div class="row" v-if="type=='view' && form.status == 'PENDING' && form.resource == null">
                  <div class="col-12">
                    <hr/>
                    <user-resource-role-assign :success-callback="selectResourceRoleCallback" :preselect-org="organisation"/>
                    <hr/>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <!--button  type="button" class="btn btn-sm btn-primary m-r-sm" v-if="type=='view' && form.status == 'PENDING'" @click.prevent="type='edit'">
                      <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.registerUserRequest.actions.edit') }}
                    </button-->
                    <!--button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary m-r-sm">{{ $t('common.actions.save') }}</button-->
                    <button type="button" v-if="form.status == 'PENDING'" class="btn btn-sm btn-secondary m-r-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
                    <button type="button" class="btn btn-sm btn-white m-r-sm" @click="cancel">{{ $t('common.actions.back') }}</button>
                    <button type="button" v-if="type=='view' && form.status == 'PENDING'" class="btn btn-sm btn-danger float-right m-l-sm" @click="decline">{{ $t('orgAdmin.registerUserRequest.actions.decline') }}</button>
                    <button type="button" v-if="type=='view' && form.status == 'PENDING'" class="btn btn-sm btn-primary float-right" @click="approve">{{ $t('orgAdmin.registerUserRequest.actions.approve') }}</button>
                  </div>
                </div>

              </b-form>
            </validation-observer>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {mapState} from "vuex"
import UserResourceRoleAssign from "@/components/views/userManagement/UserResourceRoleAssign.vue"

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue from "vue"
import * as commons from "@/commons"
import i18n from "@/i18n"


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    UserResourceRoleAssign
  },
  data() {
    return {
      error: null,
      type: 'view',
      form: {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        username: '',
        language: '',
        resource: null,
        resourceRole: null
      }
    }
  },
  created(){
    console.log('RegisterUserRequestDetails $route.name: ' + this.$route.name)
    this.$store.dispatch('userManagement/fetchRegisterUserRequestDetails', this.$route.params.id)
  },
  computed: {
    ...mapState({
      registerUserRequest: state => state.userManagement.registerUserRequestDetails.entity
    }),
    organisation() {
      console.log('organisation() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.organisation.organisationDetails.entity : this.$store.state.contextOrg
    }
  },
  watch: {
    registerUserRequest: function(newVal, oldVal) { // watch it
      console.log('Prop registerUserRequest changed: ', newVal, ' | was: ', oldVal)
      this.reset()
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    reset() {
      this.error = ""
      this.info = ""
      this.form.resource = null
      this.form.resourceRole = null
      this.form.id = this.registerUserRequest.id
      this.form.username = this.registerUserRequest.username
      this.form.firstName = this.registerUserRequest.firstName
      this.form.lastName = this.registerUserRequest.lastName
      this.form.email = this.registerUserRequest.email
      this.form.phone = this.registerUserRequest.phone
      this.form.language = this.registerUserRequest.language
      this.form.message = this.registerUserRequest.message
      this.form.status = this.registerUserRequest.status
      this.form.matchedOrganisation = this.registerUserRequest.matchedOrganisation
      this.type = 'view'

      if(this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    cancel(){
      this.goBack()
    },
    save() {

      this.error = ""
      this.info = ""

      let vm = this
      if(this.$route.name == 'registerUserRequestNew') {

        this.form.settings = { lang: this.form.language }

        Vue.axios.post('/admin/register-user-requests', JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          this.$toastr.i(i18n.t('orgAdmin.registerUserRequest.info.created'))
          self.$router.replace({
            name: 'registerUserRequestDetails',
            params: { id: response.data.id }
          })
          self.$store.dispatch('userManagement/fetchRegisterUserRequestDetails', self.$route.params.id)
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      } else {
        Vue.axios.put('/admin/register-user-requests/' + this.registerUserRequest.id, JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {
          this.$toastr.i(i18n.t('orgAdmin.registerUserRequest.info.saved'))
          self.$store.dispatch('userManagement/fetchRegisterUserRequestDetails', self.$route.params.id)
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      }
    },
    decline(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.registerUserRequest.confirm.decline'),
        callbackYes: function(){
          Vue.axios.put('/admin/register-user-requests/' + self.form.id + '/decline')
              .then(response => {
                self.$toastr.i(i18n.t('orgAdmin.registerUserRequest.info.declined'))
                self.$store.dispatch('userManagement/fetchRegisterUserRequestDetails', self.$route.params.id)
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
    selectResourceRoleCallback(data){
      this.form.resource = data.resource
      this.form.resourceRole = data.role
    },
    approve(){

      if(this.form.resource == null){
        this.error = this.$t('orgAdmin.registerUserRequest.error.noResourceSelected')
        return
      }

      let self = this
      this.confirmDialog({
        text: this.$t('orgAdmin.registerUserRequest.confirm.approve'),
        callbackYes: function(){
          self.form.resourceId = self.form.resource.id
          Vue.axios.put('/admin/register-user-requests/' + self.form.id + '/approve', JSON.stringify(self.form),
              {
                headers: {'Content-Type': 'application/json'}
              }).then(response => {
                self.$toastr.i(i18n.t('orgAdmin.registerUserRequest.info.approved'))
                self.$store.dispatch('userManagement/fetchRegisterUserRequestDetails', self.$route.params.id)
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    }
  }
}
</script>
