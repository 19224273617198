<template>
  <div class="animated fadeInRight">

    <div class="row">
      <div class="col-12">
        <div class="float-right w-400">
          <resource-selector
              :resourceSelectedCallback="buildingSelectedCallback"
              :force-preselect-cascade="isBuildingAdminInContextOrg"
              :preselect-resource="contextBuilding ? { building: contextBuilding } : null"
              :selection-clearable="isContextOrgAdmin"
              selectDepth="building" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-tabs ref="tabs" @input="tabSelected" v-if="contextOrg" v-model="activeTab" content-class="px-5 py-5 white-bg">

          <b-tab :title="$t('orgAdmin.organisation.details.userResourceRoles')">
            <users-resources-list :can-add="isContextOrgAdmin"/>
          </b-tab>

          <b-tab :title="$t('orgAdmin.registerUserRequests.title')" v-if="contextBuilding == null" lazy>
            <register-user-requests-list />
          </b-tab>

        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import UsersResourcesList from '@/components/views/userManagement/UsersResourcesList.vue'
import RegisterUserRequestsList from '@/components/views/userManagement/RegisterUserRequestsList.vue'
import ResourceSelector from "@/components/common/ResourceSelector.vue";

export default {
  components: {
    ResourceSelector,
    UsersResourcesList,
    RegisterUserRequestsList
  },
  data() {
    return {
      error: null,
      activeTab: this.$store.state.userManagement.activeTab,
      orgEdit: false,
      buildingList: {
        filter: null,
        page: 1,
        size: 10
      },
      addNewBuildingOpened: false,
      addNewBuildingForm: {
        name: '',
        coordinates: ''
      }
    }
  },
  created() {
    console.log('UserManagement created()');
    if (this.contextBuilding) {
      this.fetchIfBuildingChanged()
    } else {
      this.fetchIfOrgChanged()
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      contextOrg: state => state.contextOrg,
      contextBuilding: state => state.contextBuilding,
      userResourceRoles: state => state.userManagement.userResourceRoles,
      registerUserRequests: state => state.userManagement.registerUserRequests
    })
  },
  watch: {
    contextOrg: function (newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.fetchIfOrgChanged()
      }
    },
    contextBuilding: function (newVal, oldVal) {
      if(newVal == null){
        this.fetchIfOrgChanged()
      } else if (newVal?.id !== oldVal?.id) {
        this.fetchIfBuildingChanged()
      }
    },
  },
  methods: {
    tabSelected() {
      console.log('tabSelected ' + this.activeTab)
      this.$store.commit('userManagement/SAVE_ACTIVE_TAB', this.activeTab)
    },
    fetchIfOrgChanged(){
      console.log('fetchIfOrgChanged ' + this.contextOrg?.id)
      if(this.isContextOrgAdmin) {
        this.$store.dispatch('userManagement/fetchOrganisationUsers', this.contextOrg.id)
        this.$store.dispatch('userManagement/fetchOrganisationUserRegistrationRequests', this.contextOrg.id)
      }
    },
    fetchIfBuildingChanged(){
      console.log('fetchIfBuildingChanged ' + this.contextBuilding?.id)
      if(this.contextBuilding && (this.isBuildingAdminInContextOrg || this.isContextOrgAdmin)) {
        this.$store.dispatch('userManagement/fetchBuildingUsers', this.contextBuilding.id)
      }
    },
    buildingSelectedCallback(payload) {
      console.log('buildingSelectedCallback ' + payload.resource?.id)
      this.$store.commit('SET_CONTEXT_BUILDING', payload.resource)
    },
    refreshRegisterUserRequestCallback(){
      this.$store.dispatch('userManagement/fetchOrganisationUserRegistrationRequests', this.$route.params.id)
    }
  }
}
</script>