<template>
  <div class="animated fadeInRight" v-if="contextOrg">
    <div class="ibox">
      <div class="ibox-content p-5">
        <div class="row" >
          <div class="col-12">
            <div class="float-right w-400">
              <resource-selector
                  :resourceSelectedCallback="buildingSelectedCallback"
                  :force-preselect-cascade="isBuildingAdminInContextOrg"
                  :preselect-resource="contextBuilding ? { building: contextBuilding } : null"
                  :selection-clearable="isContextOrgAdmin"
                  selectDepth="building" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-9 col-xl-12">
            <scheduler-profile-list :profiles="schedulerProfiles" :canAdd="true"/>
            <template v-if="hasRole('ADMIN') || isContextOrgAdmin || contextBuilding">
              <h3 class="mt-5 mb-4">{{ $t('orgAdmin.locationsWithSchedulerProfiles.title') }}</h3>
              <locations-with-scheduler-profile-list :locationsWithSelectedProfile="locationsWithSelectedProfile" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import SchedulerProfileList from '@/components/views/schedulers/SchedulerProfileList'
import LocationsWithSchedulerProfileList from '@/components/views/schedulers/LocationsWithSchedulerProfileList'
import ResourceSelector from "@/components/common/ResourceSelector.vue"

export default {
  components: {
    ResourceSelector,
    SchedulerProfileList,
    LocationsWithSchedulerProfileList,
  },
  data() {
    return {
      error: null,
      orgEdit: false,
      buildingList: {
        filter: null,
        page: 1,
        size: 10
      },
      addNewBuildingOpened: false,
      addNewBuildingForm: {
        name: '',
        coordinates: ''
      }
    }
  },
  created() {
    console.log('Profiles created()')
    this.fetchIfOrgChanged()
  },
  computed: {
    ...mapState({
      contextOrg: state => state.contextOrg,
      contextBuilding: state => state.contextBuilding,
      schedulerProfiles: state => state.schedulers.schedulerProfiles,
      locationsWithSelectedProfile: state => state.schedulers.locationsWithSelectedSchedulerProfile
    })
  },
  watch: {
    contextOrg: function (newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.fetchIfOrgChanged()
      }
    },
    contextBuilding: function (newVal, oldVal) {
      if(newVal == null){
        this.fetchIfOrgChanged()
      } else if (newVal?.id !== oldVal?.id) {
        this.fetchIfBuildingChanged()
      }
    },
  },
  methods: {
    fetchIfOrgChanged(){
      console.log('fetchIfOrgChanged ' + this.contextOrg?.id)
      this.$store.dispatch('schedulers/fetchOrganisationSchedulerProfiles', this.contextOrg.id)
      if(this.isContextOrgAdmin) {
        this.$store.dispatch('schedulers/fetchOrganisationLocationsWithSelectedProfile', this.contextOrg.id)
      }
      this.fetchIfBuildingChanged()
    },
    fetchIfBuildingChanged(){
      console.log('fetchIfBuildingChanged ' + this.contextBuilding?.id)
      if(this.contextBuilding && (this.isBuildingAdminInContextOrg || this.isContextOrgAdmin)) {
        this.$store.dispatch('schedulers/fetchBuildingLocationsWithSelectedProfile', this.contextBuilding.id)
      }
    },
    buildingSelectedCallback(payload) {
      console.log('buildingSelectedCallback ' + payload.resource?.id)
      this.$store.commit('SET_CONTEXT_BUILDING', payload.resource)
    }
  }
}
</script>