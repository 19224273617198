<template>
  <div class="animated fadeInRight">
    <div class="row" v-if="map">

      <div class="col-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5 class="d-inline-block">{{ $t('orgAdmin.map.details.info') }}</h5>
          </div>
          <div class="ibox-content">
            <map-view-create-edit
                v-if="map"
                :map="map"
                :can-edit="hasRole('ADMIN') || (map.building && hasResourceRole('BUILDING_MANAGER', map.building.id)) || (map.building && map.building.organisation && hasResourceRole('ORGANISATION_MANAGER', map.building.organisation.id))"
                initial-type="view"
                :success-callback="editMapSuccessCallback" />
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {mapState} from "vuex"
import MapViewCreateEdit from "@/components/views/buildings/MapViewCreateEdit";

export default {
  components: {
    MapViewCreateEdit,
  },
  data() {
    return {
      error: null,
      location: {}
    }
  },
  created() {
      this.$store.dispatch('buildings/fetchMapDetails', this.$route.params.id)
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      map: state => state.buildings.mapDetails.entity,
      locations: state => state.buildings.mapDetails.entity.locations
    })
  },
  methods: {
    editMapSuccessCallback(){
      this.$store.dispatch('buildings/fetchMapDetails', this.$route.params.id)
    }
  }
}
</script>

<style scoped>

</style>
