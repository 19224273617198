<template>
  <div class="register register-company">
    <div class="middle-box text-center animated fadeInDown">
      <div>
        <div class="mb-4">
          <img :src="require('@/assets/custom/img/CLIMIFY.svg')" width="200px" />
        </div>

        <div v-if="!registered">
          <h3>{{ $t('registerOrganisation.title')}}</h3>
          <p>{{ $t('registerOrganisation.subtitle') }}</p>

          <validation-observer ref="form" v-slot="{ handleSubmit }">
            <form class="m-t text-left" role="form" @submit.prevent="handleSubmit(register)">
              <div class="alert alert-danger" v-if="error">
                {{ error }}
              </div>

              <div class="alert alert-info" v-if="info">
                {{ info }}
              </div>

              <div class="row justify-content-center">
                <div class="col-sm-6 b-r">
                  <h3 class="m-t-none m-b">{{ $t('registerOrganisation.organisation') }}</h3>
                  <div class="form-group">
                    <validation-provider vid="organisationName" :name="$t('registerOrganisation.organisationName')"
                      rules="required|max:100" v-slot="{ errors, classes }">
                      <input type="text" v-model.trim="registerRequest.organisationName" class="form-control"
                        :class="classes" :placeholder="$t('registerOrganisation.organisationName')">
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <validation-provider :name="$t('registerOrganisation.organisationCountry')" rules="required"
                      v-slot="{ errors, classes }">
                      <select id="countrySelect" class="form-control" :class="classes"
                        v-model="registerRequest.organisationCountry">
                        <option value="" disabled>{{ $t('registerOrganisation.organisationCountry') }}</option>
                        <option v-for="country in countries" :value="country.code" :key="country.code">
                          {{ country.name }}
                        </option>
                      </select>

                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <validation-provider :name="$t('registerOrganisation.organisationCity')" rules="required|max:100"
                      v-slot="{ errors, classes }">
                      <input type="text" v-model.trim="registerRequest.organisationCity" class="form-control"
                        :class="classes" :placeholder="$t('registerOrganisation.organisationCity')">
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <validation-provider :name="$t('registerOrganisation.organisationAddress')" rules="required|max:100"
                      v-slot="{ errors, classes }">
                      <input type="text" v-model.trim="registerRequest.organisationAddress" class="form-control"
                        :class="classes" :placeholder="$t('registerOrganisation.organisationAddress')">
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <validation-provider :name="$t('registerOrganisation.organisationAddress2')"
                      rules="required|max:100" v-slot="{ errors, classes }">
                      <input type="text" v-model.trim="registerRequest.organisationAddress2" class="form-control"
                        :class="classes" :placeholder="$t('registerOrganisation.organisationAddress2')">
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <validation-provider :name="$t('registerOrganisation.organisationZipCode')" rules="required|max:20"
                      v-slot="{ errors, classes }">
                      <input type="text" v-model.trim="registerRequest.organisationZipCode" class="form-control"
                        :class="classes" :placeholder="$t('registerOrganisation.organisationZipCode')">
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
                <div class="col-sm-6">
                  <h3 class="m-t-none m-b">{{ $t('registerOrganisation.contact') }}</h3>
                  <div class="form-group">
                    <validation-provider :name="$t('registerOrganisation.userFirstName')" rules="required|max:50"
                      v-slot="{ errors, classes }">
                      <input type="text" v-model.trim="registerRequest.userFirstName" class="form-control"
                        :class="classes" :placeholder="$t('registerOrganisation.userFirstName')">
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <validation-provider :name="$t('registerOrganisation.userLastName')" rules="required|max:50"
                      v-slot="{ errors, classes }">
                      <input type="text" v-model.trim="registerRequest.userLastName" class="form-control"
                        :class="classes" :placeholder="$t('registerOrganisation.userLastName')">
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <validation-provider vid="userEmail" :name="$t('registerOrganisation.userEmail')"
                      rules="required|email|max:100" v-slot="{ errors, classes }">
                      <input type="email" v-model.trim="registerRequest.userEmail" class="form-control" :class="classes"
                        :placeholder="$t('registerOrganisation.userEmail')">
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <validation-provider :name="$t('registerOrganisation.userPhone')" rules="required|max:20"
                      v-slot="{ errors, classes }">
                      <input type="text" v-model.trim="registerRequest.userPhone" class="form-control" :class="classes"
                        :placeholder="$t('registerOrganisation.userPhone')">
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <validation-provider vid="userUsername" :name="$t('registerOrganisation.userUsername')"
                      rules="required|alpha_dash|min:3|max:50" v-slot="{ errors, classes }">
                      <input type="text" v-model="registerRequest.userUsername"
                        @input="registerRequest.userUsername = $event.target.value.toLowerCase().trim()"
                        class="form-control" :class="classes" :placeholder="$t('registerOrganisation.userUsername')">
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="form-group">
                  <div class="checkbox i-checks">
                    <label style="display: flex; justify-content: center; align-items: center;">
                      <input type="checkbox" id="termsCheck" v-model="registerRequest.termsChecked"><i></i>
                      <a href="https://climify.com/tos" style="padding-left: 1em;" target="_blank">{{
                        $t('registerOrganisation.termsAndPolicy') }}</a>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">

                <div class="form-group text-center">
                  <button type="button" class="btn btn-primary btn-outline btn-xs m-r-xs" :class="{active:enSelected}"
                    @click.prevent="setLang('en')">EN
                  </button>
                  <button type="button" class="btn btn-primary btn-outline btn-xs m-r-xs" :class="{active:daSelected}"
                    @click.prevent="setLang('da')">DA
                  </button>
                </div>

                <button type="submit" class="btn btn-primary block full-width m-b">{{
                  $t('registerOrganisation.sendRequest') }}</button>

              </div>
            </form>
          </validation-observer>

          <p class="text-muted text-center"><small>{{ $t('registerOrganisation.alreadyHaveAnAccount') }}</small></p>
          <router-link to="/indoor-climate" class="btn btn-sm btn-white btn-block">{{ $t('registerOrganisation.login')
            }}</router-link>
          <p class="m-t"><small>{{ $t('credits') }}</small></p>

        </div>
        <div v-else>
          <p class="m-t-lg" v-html="$t('registerOrganisation.success')"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from "vue";
import * as commons from "@/commons";
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      error: '',
      info: '',
      registered: false,
      registerRequest: {
        userFirstName: '',
        userLastName: '',
        userEmail: '',
        userUsername: '',
        userPhone: '',
        userLanguage: 'en',
        organisationName: '',
        organisationAddress: '',
        organisationAddress2: '',
        organisationZipCode: '',
        organisationCity: '',
        organisationCountry: '',
        termsChecked: null
      }
    }
  },
  mounted(){
    let vm = this
    $('#termsCheck').iCheck({
      checkboxClass: 'icheckbox_square-green',
    }).on('ifUnchecked', function(event){
      vm.registerRequest.termsChecked=false
    }).on('ifChecked', function(event){
      vm.registerRequest.termsChecked=true
    })
  },
  computed: {
    enSelected(){
      return this.$store.getters.getLang=='en'
    },
    daSelected(){
      return this.$store.getters.getLang=='da'
    },
    deSelected(){
      return this.$store.getters.getLang=='de'
    },
    itSelected(){
      return this.$store.getters.getLang=='it'
    },
    svSelected(){
      return this.$store.getters.getLang=='sv'
    },
    countries() {
      return this.$t('enums.countries');
    }
  },
  methods: {
    register() {
      let vm = this;
      vm.error = "";
      vm.info = "";

      if(!vm.registerRequest.termsChecked){
        vm.info = this.$i18n.t('registerUser.acceptTerms')
        return;
      }

      Vue.axios.post(`/public/register-organisation`, JSON.stringify(this.registerRequest),
          { headers: { 'Content-Type': 'application/json'}
          }).then(response => {
        vm.registered = true;
      }).catch(error => {
        //console.log("error: ", error.response)
        //console.log("error status: ", error.response.status)
        if (error.response.status === 422) {
          vm.$refs.form.setErrors(error.response.data);
        } else {
          vm.error = commons.processRestError(error)
        }
      })
    },
    setLang(lang) {
      this.registerRequest.language = lang
      this.$store.dispatch('set_lang', lang)
    }
  }
}
</script>

<style>
</style>