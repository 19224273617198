<template>
  <router-view></router-view>
</template>

<script>
import * as inspinia from "@/assets/js/inspinia";
export default {
  name: "app",
  components: {},
  mounted() {
    inspinia.initialize()
    this.$store.dispatch('appInit')
  },
};
</script>