<template>
  <div class="animated fadeInRight">

    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="ibox-content p-5">

            <validation-observer ref="form" v-slot="{ handleSubmit }">

              <b-form role="form" @submit.stop.prevent="handleSubmit(submit)">

                <div class="row">

                  <div class="col-12">

                    <validation-provider name="orgAdmin.locationProfile.attributes.name" :rules="{ required: true, min: 3, max: 50 }" v-slot="validationContext">
                      <b-form-group id="input-group-locationProfile-name" :label="$t('orgAdmin.locationProfile.attributes.name')" label-for="input-locationProfile-name" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-locationProfile-name"
                            name="input-locationProfile-name"
                            v-model="form.name"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-locationProfile-name"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-locationProfile-name">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <hr />

                    <div class="location-profile-sliders" v-if="form.parameters">

                      <b-form-group id="input-group-locationProfile-parameters-temperature" :label="$t('orgAdmin.locationProfile.paramType.temperature')" label-for="sliderTemperature" label-cols-sm="2">
                        <Veeno
                            v-if="form.parameters"
                            :disabled="type=='view'"
                            id="sliderTemperature"
                            ref="sliderTemperature"
                            :tooltips="[sliderTemperatureTooltipFormatter, sliderTemperatureTooltipFormatter, sliderTemperatureTooltipFormatter, sliderTemperatureTooltipFormatter]"
                            :handles = "form.parameters.temperature"
                            :connect = "[true, true, true, true, true]"
                            :step = "0.5"
                            :pipsy = "{ mode: 'count', values: 12 }"
                            :range = "{ 'min': 8, 'max': 30 }"
                            :options = "{
                              format: {
                                from: function (formattedValue) {
                                  return Number(formattedValue)
                                },
                                to: function (numericValue) {
                                  return numericValue
                                }
                              },
                              margin: 1
                            }"
                        />
                      </b-form-group>

                      <b-form-group id="input-group-locationProfile-parameters-humidity" :label="$t('orgAdmin.locationProfile.paramType.humidity')" label-for="sliderHumidity" label-cols-sm="2">
                        <Veeno
                            v-if="form.parameters"
                            :disabled="type=='view'"
                            id="sliderHumidity"
                            ref="sliderHumidity"
                            :tooltips="[sliderHumidityTooltipFormatter, sliderHumidityTooltipFormatter, sliderHumidityTooltipFormatter, sliderHumidityTooltipFormatter]"
                            :handles = "form.parameters.humidity"
                            :connect = "[true, true, true, true, true]"
                            :step = "1"
                            :pipsy = "{ mode: 'count', values: 11 }"
                            :range = "{ 'min': 0, 'max': 100 }"
                            :options = "{
                              format: {
                                from: function (formattedValue) {
                                  return Number(formattedValue)
                                },
                                to: function (numericValue) {
                                  return Math.round(numericValue)
                                }
                              },
                              margin: 1
                            }"
                        />
                      </b-form-group>

                      <b-form-group id="input-group-locationProfile-parameters-co2" :label="$t('orgAdmin.locationProfile.paramType.co2')" label-for="sliderCo2" label-cols-sm="2">
                        <Veeno
                            v-if="form.parameters"
                            :disabled="type=='view'"
                            id="sliderCo2"
                            ref="sliderCo2"
                            :tooltips="[sliderCo2TooltipFormatter, sliderCo2TooltipFormatter]"
                            :handles = "form.parameters.co2"
                            :connect = "[true, true, true]"
                            :step = "50"
                            :pipsy = "{ mode: 'count', values: 11 }"
                            :range = "{ 'min': 0, 'max': 4000 }"
                            :options = "{
                              format: {
                                from: function (formattedValue) {
                                  return Number(formattedValue)
                                },
                                to: function (numericValue) {
                                  return Math.round(numericValue)
                                }
                              },
                              margin: 100
                            }"
                        />
                      </b-form-group>

                      <b-form-group id="input-group-locationProfile-parameters-noise" :label="$t('orgAdmin.locationProfile.paramType.noise')" label-for="sliderNoise" label-cols-sm="2">
                        <Veeno
                            v-if="form.parameters"
                            :disabled="type=='view'"
                            id="sliderNoise"
                            ref="sliderNoise"
                            :tooltips="[sliderNoiseTooltipFormatter, sliderNoiseTooltipFormatter]"
                            :handles = "form.parameters.noise"
                            :connect = "[true, true, true]"
                            :step = "1"
                            :pipsy = "{ mode: 'count', values: 11 }"
                            :range = "{ 'min': 20, 'max': 120 }"
                            :options = "{
                              format: {
                                from: function (formattedValue) {
                                  return Number(formattedValue)
                                },
                                to: function (numericValue) {
                                  return Math.round(numericValue)
                                }
                              },
                              margin: 1
                            }"
                        />
                      </b-form-group>

                    </div>

                  </div>

                </div>

                <div class="row">
                  <div class="col-12">
                    <button  type="button" class="btn btn-sm btn-primary" v-if="locationProfile && type=='view' && hasOneOfRoles('ADMIN', 'ORGANISATION_MANAGER', 'BUILDING_MANAGER')" @click.prevent="type='edit'">
                      <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.locationProfile.actions.edit') }}
                    </button>
                    <button type="submit" v-if="type=='create'" class="btn btn-sm btn-primary">{{ $t('common.actions.create') }}</button>
                    <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary">{{ $t('common.actions.save') }}</button>
                    <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-secondary m-l-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
                    <button type="button" class="btn btn-sm btn-info m-l-sm" v-if="type!='create' && building" @click="assignToBuildingLocations">{{ $t('orgAdmin.locationProfile.actions.assignToBuildingLocations') }}</button>
                    <button type="button" class="btn btn-sm btn-info m-l-sm" v-if="type!='create' &&  building" @click="clearFromBuildingLocations">{{ $t('orgAdmin.locationProfile.actions.removeFromBuildingLocations') }}</button>
                    <button type="button" class="btn btn-sm btn-white m-l-sm" @click="cancel">{{ $t('common.actions.back') }}</button>
                    <button type="button" v-if="locationProfile && type!='create'" class="btn btn-sm btn-danger float-right" @click="deleteProfile">{{ $t('orgAdmin.locationProfile.actions.delete') }}</button>
                  </div>
                </div>

              </b-form>
            </validation-observer>


          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {mapState} from "vuex"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Veeno from 'veeno'
import 'nouislider/distribute/nouislider.min.css'
import Vue from "vue"
import * as commons from "@/commons"
import i18n from "@/i18n"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Veeno
  },
  data() {
    return {
      error: null,
      type: 'view',
      form: {
        id: null,
        name: '',
        parameters: null
      }
    }
  },
  created() {
    console.log('locationProfileDetails $route.name: ' + this.$route.name)
    this.type = this.$route.name == 'locationProfileNew' ? 'create' : 'view'
    if(this.type == 'view') {
      this.$store.dispatch('profiles/fetchLocationProfileDetails', this.$route.params.id)
    } else {
      this.$store.commit('profiles/SAVE_LOCATION_PROFILE_DETAILS_ENTITY', null)
      this.init()
    }
  },
  watch: {
    locationProfile: function(newVal, oldVal) { // watch it
      console.log('Prop locationProfile changed: ', newVal, ' | was: ', oldVal)
      this.init()
    }
  },
  computed: {
    ...mapState({
      locationProfile: state => state.profiles.locationProfileDetails.entity,
      defaultLocationProfileParams: state => state.systemParams.defaultLocationProfileParams
    }),
    organisation() {
      console.log('organisation() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.organisation.organisationDetails.entity : this.$store.state.contextOrg
    },
    building() {
      console.log('building() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.orgAdmin.buildingDetails.entity : this.$store.state.contextBuilding
    },
    sliderTemperatureTooltipFormatter() {
      return {
        to: function(numericValue) {
          return Math.round(numericValue * 10) / 10 + ' °C'
        }
      }
    },
    sliderHumidityTooltipFormatter() {
      return {
        to: function(numericValue) {
          return numericValue + ' %'
        }
      }
    },
    sliderCo2TooltipFormatter() {
      return {
        to: function(numericValue) {
          return numericValue + ' ppm'
        }
      }
    },
    sliderNoiseTooltipFormatter() {
      return {
        to: function(numericValue) {
          return numericValue + ' dB'
        }
      }
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    reset() {
      if(this.type == 'create'){
        this.form.id = null
        this.form.name = ''
        this.form.parameters = JSON.parse(JSON.stringify(this.defaultLocationProfileParams))
      } else {
        this.form.id = this.locationProfile.id
        this.form.name = this.locationProfile.name
        this.form.parameters = JSON.parse(JSON.stringify(this.locationProfile.parameters))
        this.type = 'view'
      }

      if(this.$refs.form){
        this.$refs.form.reset()
      }

      if(this.$refs.sliderTemperature){
        this.$refs.sliderTemperature.$el.noUiSlider.set(this.form.parameters.temperature)
      }

      if(this.$refs.sliderHumidity){
        this.$refs.sliderHumidity.$el.noUiSlider.set(this.form.parameters.humidity)
      }

      if(this.$refs.sliderCo2){
        this.$refs.sliderCo2.$el.noUiSlider.set(this.form.parameters.co2)
      }

      if(this.$refs.sliderNoise){
        this.$refs.sliderNoise.$el.noUiSlider.set(this.form.parameters.noise)
      }
    },
    init(){
      this.reset()

      let vm = this

      this.$nextTick(() => {

        vm.$refs.form.reset()

        if(vm.$refs.sliderTemperature) {
          let connects = vm.$refs.sliderTemperature.$el.querySelectorAll('.noUi-connect')
          connects[0].classList.add('color-red')
          connects[1].classList.add('color-yellow')
          connects[2].classList.add('color-green')
          connects[3].classList.add('color-yellow')
          connects[4].classList.add('color-red')
          vm.$refs.sliderTemperature.$el.noUiSlider.on('update', function (values, handle) {
            vm.form.parameters.temperature = values
          }.bind(vm))

          connects = vm.$refs.sliderHumidity.$el.querySelectorAll('.noUi-connect')
          connects[0].classList.add('color-red')
          connects[1].classList.add('color-yellow')
          connects[2].classList.add('color-green')
          connects[3].classList.add('color-yellow')
          connects[4].classList.add('color-red')
          vm.$refs.sliderHumidity.$el.noUiSlider.on('update', function (values, handle) {
            vm.form.parameters.humidity = values
          }.bind(vm))

          connects = vm.$refs.sliderCo2.$el.querySelectorAll('.noUi-connect')
          connects[0].classList.add('color-green')
          connects[1].classList.add('color-yellow')
          connects[2].classList.add('color-red')
          vm.$refs.sliderCo2.$el.noUiSlider.on('update', function (values, handle) {
            vm.form.parameters.co2 = values
          }.bind(vm))

          connects = vm.$refs.sliderNoise.$el.querySelectorAll('.noUi-connect')
          connects[0].classList.add('color-green')
          connects[1].classList.add('color-yellow')
          connects[2].classList.add('color-red')
          vm.$refs.sliderNoise.$el.noUiSlider.on('update', function (values, handle) {
            vm.form.parameters.noise = values
          }.bind(vm))
        }

      })

    },
    cancel(){
      this.goBack()
    },
    deleteProfile(){
      let self = this
      this.confirmDialog({
        text: this.$t('orgAdmin.locationProfile.confirm.delete', { '0': self.form.name }),
        callbackYes: function(){
          Vue.axios.delete('/admin/location-profiles/' + self.form.id)
              .then(response => {
                self.$toastr.i(i18n.t('orgAdmin.locationProfile.info.deleted'))
                self.goBack()
              })
              .catch(error => {
                commons.processRestError(error)
              })
        }
      })
    },
    submit() {
      this.error = ""
      this.info = ""
      let vm = this
      if(this.type == 'create'){
        let formToSend =  Object.assign({}, this.form)
        formToSend.organisation = this.organisation
        Vue.axios.post('/admin/location-profiles', JSON.stringify(formToSend),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          this.$toastr.i(i18n.t('orgAdmin.locationProfile.info.created'))
          this.goBack()
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      } else {
        let formToSend =  Object.assign({}, this.form)
        formToSend.organisation = this.locationProfile.organisation
        Vue.axios.put('/admin/location-profiles/' + formToSend.id, JSON.stringify(formToSend),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          this.$toastr.i(i18n.t('orgAdmin.locationProfile.info.saved'))
          this.goBack()
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      }
    },
    assignToBuildingLocations(){

      let vm = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.locationProfile.confirm.assignToBuildingLocations', { '0': vm.building.name }),
        callbackYes: function(){
          let formToSend =  Object.assign({}, vm.locationProfile)
          Vue.axios.put('/admin/buildings/' + vm.building.id + '/assign-location-profile', JSON.stringify(formToSend),
              {
                headers: {'Content-Type': 'application/json'}
              }).then(response => {
            vm.$toastr.i(i18n.t('orgAdmin.locationProfile.info.assignedToBuildingLocations', { '0': vm.building.name }))
          }).catch(error => {
            if (error.response.status === 422) {
              vm.$refs.form.setErrors(error.response.data)
            } else {
              commons.processRestError(error)
            }
          })
        }
      })
    },
    clearFromBuildingLocations(){

      let vm = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.locationProfile.confirm.clearFromBuildingLocations', { '0': vm.building.name }),
        callbackYes: function(){
          Vue.axios.delete('/admin/buildings/' + vm.building.id + '/clear-location-profile', { params: { locationProfileId: vm.locationProfile.id }
          }).then(response => {
            vm.$toastr.i(i18n.t('orgAdmin.locationProfile.info.removedFromBuildingLocations', { '0': vm.building.name }))
          }).catch(error => {
            commons.processRestError(error)
          })
        }
      })
    }
  }
}
</script>

<style>
  .location-profile-sliders .noUi-connect.color-red { background: #E94F37; opacity: 0.75;}
  .location-profile-sliders .noUi-connect.color-green { background: #66C28F; opacity: 0.75;}
  .location-profile-sliders .noUi-connect.color-yellow { background: #FFD966; opacity: 0.75;}
  .location-profile-sliders .noUi-value-horizontal {
    transform: translate(-50%,90%);
    font-size: smaller;
  }

  .location-profile-sliders .noUi-horizontal {
    height: 30px;
    margin-bottom: 5em;
  }

  .location-profile-sliders .noUi-tooltip {
    _font-size: smaller;
    padding: 10px 7px;
    _border-radius: 20px;
    _box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
  }

  .location-profile-sliders .noUi-horizontal .noUi-tooltip {
    transform: translate(-50%,0);
    left: 50%;
    bottom: -12px;
  }

  .location-profile-sliders .noUi-handle{
    border: none;
    background: none;
    box-shadow: none;
  }

  .location-profile-sliders .noUi-handle::before, .location-profile-sliders .noUi-handle::after {
    display: none;
  }

  .location-profile-sliders .noUi-target:not([disabled]) .noUi-handle {
    cursor: ew-resize;
  }

  .location-profile-sliders .noUi-pips-horizontal {
    padding: 15px 0 5px;
  }
</style>
