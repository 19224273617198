<template>
  <div class="cursor-pointer widget gray-bg card-class building text-center split" @click="open">
    <div style="flex-grow: 1;">
      {{$route.params.prefix }}
      <span v-if="item.status !=='ACTIVE'" class="card-status badge badge-danger">{{ $t('enums.ResourceStatus.' + item.status) }}</span>
      <div class="m-b-sm">{{ $t('orgAdmin.map.name') }}</div>
      <h4 class="font-bold">{{ item.name }}</h4>
    </div>
    <img v-if="item.image" :src="base64ToDataURL(item.image)" style="flex-grow: 2; width:50px;"/>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object
    },
  },
  methods: {
    open(){
      this.$router.push({
        path: this.$route.path.startsWith('/admin') ? ('/admin/maps/' + this.item.id) : ('/maps/' + this.item.id),
      })
    },
    base64ToDataURL(image) {
      return 'data:image/png;base64,' + image;
    }
  }
}
</script>

<style scoped>
  .split {
    display: flex;
    justify-content: space-between;
  }
</style>