import Vue from "vue"
import * as commons from '@/commons'
import moment from 'moment-timezone'
import i18n from "@/i18n";

// default state
const getDefaultState = () => {

    return {
        selectedMap: null,
        availableSensorAttributes: [],
        mapConfig: {
            dateRange: {
                startDate: null,
                endDate: null
            },
            hourLimit: null,
            hourLimitBound: null,
            selectedSensorAttribute: null,
            selectedSensorAttributeLimit: null,
            selectedSensorAttributeLimitBound: null,
        }
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    mapConfigDateTimeRangeFormatted: state => {
        if(state.mapConfig.dateRange.startDate && state.mapConfig.dateRange.endDate) {
            return moment(state.mapConfig.dateRange.startDate).format(i18n.t('dateTimeShortFormatMoment'))
                + ' - '
                + moment(state.mapConfig.dateRange.endDate).format(i18n.t('dateTimeShortFormatMoment'))
        } else {
            return ''
        }
    }
}

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('statistics/appInit')
        dispatch('resetMapConfig')
    },

    resetMapConfig({ commit, state }, data) {
        console.log('statistics/resetMapConfig')

        if(state.mapConfig.dateRange.startDate === null || state.mapConfig.dateRange.endDate === null){
            return new Promise((resolve) => {
                let startDate = new Date()
                startDate.setDate(startDate.getDate() - 1)
                startDate.setHours(0, 0, 0, 0)
                let endDate = new Date()
                endDate.setHours(23, 59, 59, 59)
                commit('UPDATE_MAP_CONFIG', {
                    dateRange: {startDate: startDate, endDate: endDate},
                })
                resolve()
            })
        }
    },

    loadMapFromServer({ commit, state, dispatch }, mapId){
        //load map with locations and image
        Vue.axios.get('/maps/' + mapId).then(response => {
            commit('UPDATE_SELECTED_MAP', response.data)
            dispatch('loadSelectedMapSensorValues')
        }).catch(error => {
            commons.processRestError(error)
        })
        dispatch('resetMapConfig')
    },

    loadSelectedMapSensorValues({ commit, state, dispatch, rootState }){

        console.log('loadSelectedMapSensorValues')
        // get map sensor-values
        return new Promise((resolve, reject) => {

            let params = { duration : "PT1H"}

            Vue.axios.get('/maps/' + state.selectedMap.id + '/sensor-values', { params: params }).then(response => {
                commit('UPDATE_MAP_SENSOR_VALUES', response.data )
                resolve(response)
            }).catch(error => {
                commons.processRestError(error)
                reject(error)
            })
        })
    },

    mapConfigDateRangeSelected({ commit, state, dispatch }, data){
        console.log('statistics/mapConfigDateRangeSelected')
        commit('UPDATE_MAP_CONFIG',  { dateRange: data.dateRange })
    },

    mapConfigHourLimitChanged({ commit, state, dispatch }, data){
        console.log('statistics/mapConfigHourLimitChanged')
        commit('UPDATE_MAP_CONFIG',  { hourLimit:  data })
    },

    mapConfigHourLimitBoundChanged({ commit, state, dispatch }, data){
        console.log('statistics/mapConfigHourLimitBoundChanged')
        commit('UPDATE_MAP_CONFIG',  { hourLimitBound:  data })
    },

    mapConfigSensorAttributeChanged({ commit, state, dispatch }, data){
        console.log('statistics/mapConfigSensorAttributeChanged')
        commit('UPDATE_MAP_CONFIG',  { selectedSensorAttribute:  data })
    },

    mapConfigSensorAttributeLimitChanged({ commit, state, dispatch }, data){
        console.log('statistics/mapConfigSensorAttributeLimitChanged')
        commit('UPDATE_MAP_CONFIG',  { selectedSensorAttributeLimit:  data })
    },

    mapConfigSensorAttributeLimitBoundChanged({ commit, state, dispatch }, data){
        console.log('statistics/mapConfigSensorAttributeLimitChanged')
        commit('UPDATE_MAP_CONFIG',  { selectedSensorAttributeLimitBound:  data })
    },

    fetchData({ commit, state, dispatch }, data){
        console.log('fetchData')

        return new Promise((resolve, reject) => {

            let promises = []
            let params = {}
            if(state.mapConfig.dateRange){
                state.mapConfig.dateRange.startDate.setHours(0, 0, 0, 0)
                state.mapConfig.dateRange.endDate.setHours(23, 59, 59, 999)
                params.dateTimeFrom = moment(state.mapConfig.dateRange.startDate).format('YYYY-MM-DDTHH:mm:ss')
                params.dateTimeTo = moment(state.mapConfig.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss')
            }
            params.aggregateWindowDuration = "PT1H"
            params.hourLimit = data.hourLimit
            params.hourLimitBound = data.hourLimitBound
            params.field = data.selectedSensorAttribute
            params.attributeLimit = data.selectedSensorAttributeLimit
            params.attributeLimitBound = data.selectedSensorAttributeLimitBound

            promises.push(new Promise((resolve, reject) => {

                Vue.axios.get('/maps/' + state.selectedMap.id + '/statistic', { params: params }).then(response => {
                    commit('UPDATE_SELECTED_MAP_LOCATION_STATES', response.data)
                    resolve(response)
                }).catch(error => {
                    commons.processRestError(error)
                    reject(error)
                })
            }))

            Promise.all(promises).then(function() {
                resolve()
            }).catch((error) => {
                commons.processRestError(error)
                reject(error)
            })

        })
    }

}

// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['UPDATE_SELECTED_MAP']: (state, data) => {
        state.selectedMap = data
    },

    ['UPDATE_MAP_CONFIG']: (state, data) => {
        console.log('statistics/UPDATE_MAP_CONFIG: ' + JSON.stringify(data))
        state.mapConfig = Object.assign({}, state.mapConfig, data)
    },

    ['UPDATE_MAP_SENSOR_VALUES']: (state, data) => {
        state.availableSensorAttributes =  commons.getAvailableSensorAttributesFromSensorData(data)
    },

    ['UPDATE_SELECTED_MAP_LOCATION_STATES']: (state, data) => {
        console.log('statistics/UPDATE_SELECTED_MAP_LOCATION_STATES: ' + JSON.stringify(data))
        processSelectedMapSensorData(state, data)
        // parse data and set state to map locations
        if(data && state.selectedMap) {
            let selectedMap = state.selectedMap
            state.selectedMap.locations.forEach(location => {
                Vue.set(location, 'state', 'grey')
                data.forEach(item => {
                    if(item.locationId && location.id == item.locationId){
                        if(state.mapConfig.hourLimitBound.code === "greater" && parseFloat(item.value) >= parseFloat(state.mapConfig.hourLimit)) {
                            Vue.set(location, 'state', 'yellow')
                        } else if(state.mapConfig.hourLimitBound.code === "less" && parseFloat(item.value) <= parseFloat(state.mapConfig.hourLimit)) {
                            Vue.set(location, 'state', 'yellow')
                        } else {
                            Vue.set(location, 'state', 'grey')
                        }
                    }
                })
            })
            state.selectedMap = selectedMap
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

function processSelectedMapSensorData(state, sensorValues) {
    console.log('processSelectedHeatmapSensorData')
    // parse all sensor values and set them to map locations
    if (sensorValues && state.selectedMap) {
        state.selectedMap.locations.forEach(location => {
            // clear current data
            location.sensorValuesAvgMap = []
            sensorValues.forEach(sensorValue => {
                if (sensorValue.locationId && location.id == sensorValue.locationId) {
                    location.sensorValuesAvgMap.push(sensorValue)
                }
            })
        })
    }
}
