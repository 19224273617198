openUserManual<template>
  <div class="row header border-bottom">
    <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0">
      <div class="navbar-header">
        <a class="navbar-minimalize minimalize-styl-2 btn btn-primary" @click.prevent="minimalizeClicked" href="#"><i
            class="fa fa-bars"></i> </a>
      </div>
      <ul class="nav navbar-top-links navbar-right">
        <li>
          <span class="m-r-sm welcome-message">{{ $t('contextOrgSelectorLabel') }}:</span>
        </li>
        <li v-if="contextOrgOptions && contextOrgOptions.length > 0">
              <v-select
                  class="m-r-sm"
                  :disabled="contextOrgOptions.length == 1"
                  id="input-context-org"
                  name="input-context-org"
                  v-if="contextOrgOptions.length > 0"
                  v-model="contextOrgSelected"
                  :options="contextOrgOptions"
                  label="name"
                  @input="contextOrgSelectedChanged"
                  :clearable="false"
                  placeholder="Select org"
              />
        </li>
        <li v-if="$store.state.connectionError">
          <span class="badge badge-danger m-r-sm">{{ $t('common.errors.connectionError') }}</span>
        </li>
        <li>
          <a href="#" @click.prevent="openUserManual" :title="$t('navbar.userManual')" class="mr-3">
            <i class="fa fa-regular fa-circle-question fa-lg" style="color: #676a6c"></i>
          </a>
        </li>
        <settings-panel></settings-panel>
      </ul>
    </nav>

  </div>
</template>

<script>
import * as inspinia from '@/assets/js/inspinia'
import SettingsPanel from '@/components/common/SettingsPanel'
import {mapState} from 'vuex'

/*global Vue, router, i18n, toastr */

export default {
  components: {
    SettingsPanel
  },
  data() {
    return {
      newSupMessageObj: '',
      newMessageObj: '',
      contextOrgSelected: null
    }
  },
  created() {
    this.contextOrgSelected = this.contextOrg
  },
  computed: {
    ...mapState({
      messageObj: state => state.messageObj,
      contextOrgOptions: state => state.contextOrgOptions,
      contextOrg: state => state.contextOrg
    }),
  },
  methods: {
    minimalizeClicked() {
      $('body').toggleClass("mini-navbar");
      inspinia.SmoothlyMenu();
    },
    contextOrgSelectedChanged(){
      console.log('contextOrgSelectedChanged ' + this.contextOrgSelected.id)
      this.$store.dispatch('setContextOrg', this.contextOrgSelected)
    },
    showMessageDlg(parMessageObj) {
      this.newMessageObj = parMessageObj
      try {
        $('#msgModalDlg').modal({
          keyboard: false
        })
      } catch (e) {
        console.error('javascript error while opening modal ', e)
      }
    },
    openUserManual() {
      let userManualUrl = this.$store.state.systemParams['user-manual-url']
      window.open(userManualUrl);
    },
  },
  watch: {
    contextOrg: function (newVal, oldVal) {
      console.log('contextOrg changed: ', JSON.stringify(newVal), ' | was: ', JSON.stringify(oldVal))
      this.contextOrgSelected = this.contextOrg
    },
    messageObj(newMessage) {
      if (newMessage) {
        if (newMessage.showMessageAsToastr == true) {
          if (newMessage.type === 'INFO') {
            toastr.info(newMessage.message)
          } else if (newMessage.type === 'WARNING') {
            toastr.warning(newMessage.message)
          }
        } else {
          this.showMessageDlg(newMessage);
        }
      }
    }
  }
}
</script>

<style>
  #input-context-org {
    min-width: 200px;
  }
  #input-context-org .vs__selected {
    color: #676a6c;
    font-weight: 600;
  }
  #input-context-org .vs__dropdown-toggle {
    border: 1px solid #676a6c;
    border-radius: 5px;
  }
</style>
