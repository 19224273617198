<template>
  <div class="animated fadeInRight">

    <div class="ibox">
      <div class="ibox-content p-5">

        <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
          <button class="close" type="button" @click="error = ''">×</button>
        </div>

        <validation-observer ref="form" v-slot="{ handleSubmit }">

          <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(save)">

            <div class="row">

              <div class="col-xl-6">

                <div class="row">
                  <div class="col-xl-4">
                    <validation-provider name="messageCentre.advices.attributes.status" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group v-if="form.status" id="input-group-message-status" :label="$t('messageCentre.' + messageType + '.attributes.status')" label-for="input-message-status" label-cols-sm="4">
                        <span class="label" v-if="messageType==='issues' || type=='view'" :class="[form.status == 'BLOCKED' ? 'label-danger' : 'label-primary']">{{ $i18n.t('enums.MessageStatus.' + form.status) }}</span>
                        <b-form-input :plaintext="true" v-show="false" v-if="messageType==='issues' || type==='view'" :value="$t('enums.MessageStatus.' + form.status)" />
                        <b-form-select
                            v-if="messageType==='advices' && type!=='view'"
                            id="input-message-status"
                            :plain="true"
                            name="input-message-status"
                            v-model="form.status"
                            :state="getValidationState(validationContext)"
                            :options="$t('messageCentre.advices.details.statuses')"
                            list="input-message-status-list"
                            aria-describedby="input-feedback-message-status"
                        ></b-form-select>
                        <b-form-invalid-feedback id="input-feedback-message-status">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </div>
                  <div class="col-xl-4">
                    <validation-provider name="messageCentre.advices.attributes.category" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group id="input-group-message-category" :label="$t('messageCentre.' + messageType + '.attributes.category')" label-for="input-message-category" label-cols-sm="4">
                        <b-form-input :plaintext="true" v-if="form.category && (messageType==='issues' || type==='view')" :value="$t('enums.MessageCategory.' + form.category)" />
                        <b-form-select
                            v-if="messageType==='advices' && type!=='view'"
                            id="input-message-category"
                            :plain="true"
                            name="input-message-category"
                            v-model="form.category"
                            :state="getValidationState(validationContext)"
                            :options="$t('enums.MessageCategory')"
                            list="input-message-category-list"
                            aria-describedby="input-feedback-message-category"
                        ></b-form-select>
                        <b-form-invalid-feedback id="input-feedback-message-category">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </div>
                  <div class="col-xl-4">
                    <validation-provider name="messageCentre.advices.attributes.severity" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group id="input-group-message-category" :label="$t('messageCentre.' + messageType + '.attributes.severity')" label-for="input-message-severity" label-cols-sm="4">
                        <b-form-input :plaintext="true" v-if="form.severity && type==='view'" :value="$t('enums.MessageSeverity.' + form.severity)" />
                        <b-form-select
                            v-if="type!=='view'"
                            id="input-message-severity"
                            :plain="true"
                            name="input-message-severity"
                            v-model="form.severity"
                            :state="getValidationState(validationContext)"
                            :options="$t('enums.MessageSeverity')"
                            list="input-message-severity-list"
                            aria-describedby="input-feedback-message-severity"
                        ></b-form-select>
                        <b-form-invalid-feedback id="input-feedback-message-severity">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </div>

                <div class="row mt-3 mb-5">
                  <div class="col-sm-4">
                    <b-form-checkbox
                        switch
                        size="lg"
                        :disabled="type === 'view'"
                        v-model="form.priority"
                        value="PINNED"
                        unchecked-value="NORMAL"
                    >{{ $t('enums.MessagePriority.PINNED') }}</b-form-checkbox>
                  </div>
                  <div class="col-sm-4">
                    <b-form-checkbox
                        switch
                        size="lg"
                        :disabled="type === 'view'"
                        v-model="form.priority"
                        value="IGNORED"
                        unchecked-value="NORMAL"
                    >{{ $t('enums.MessagePriority.IGNORED') }}</b-form-checkbox>
                  </div>
                </div>

                <validation-provider name="messageCentre.advices.attributes.device" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group id="input-group-device" :label="$t('messageCentre.' + messageType + '.attributes.device')" label-for="input-device" label-cols-sm="4">
                    <v-select
                        id="input-device"
                        name="input-device"
                        :disabled="messageType==='issues' || type=='view'"
                        label="alias"
                        v-model="form.device"
                        :options="buildingDevices"
                        :class="{'is-invalid': validationContext.errors.length > 0}"
                        :clearable="false"
                        aria-describedby="input-feedback-device"
                    />
                    <b-form-invalid-feedback id="input-feedback-device">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider name="messageCentre.advices.attributes.description" :rules="{ required: true, max: 1000 }" v-slot="validationContext">
                  <b-form-group id="input-group-message-description" :label="$t('messageCentre.' + messageType + '.attributes.description')" label-for="input-message-description" label-cols-sm="4">
                    <b-form-textarea
                        :plaintext="messageType==='issues' || type === 'view'"
                        rows="3"
                        max-rows="10"
                        id="input-message-description"
                        alias="input-message-description"
                        v-model="form.description"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-feedback-message-description"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="input-feedback-message-description">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider name="messageCentre.advices.attributes.notes" v-if="messageType==='advices'" :rules="{ max: 5000 }" v-slot="validationContext">
                  <b-form-group id="input-group-message-notes" :label="$t('messageCentre.' + messageType + '.attributes.notes')" label-for="input-message-notes" label-cols-sm="4">
                    <b-form-textarea
                        :plaintext="messageType==='issues' || type === 'view'"
                        rows="3"
                        max-rows="10"
                        id="input-message-notes"
                        alias="input-message-notes"
                        v-model="form.notes"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-feedback-message-notes"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="input-feedback-message-notes">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

              </div>

            </div>

            <div class="row">
              <div class="col-6">
                <button  type="button" class="btn btn-sm btn-primary m-r-sm" v-if="type=='view'" @click.prevent="type='edit'">
                  <i class="fa fa-edit"></i>&nbsp;{{ $t('messageCentre.' + messageType + '.actions.edit') }}
                </button>
                <button type="submit" v-if="type=='create'" class="btn btn-sm btn-primary m-r-sm">{{ $t('common.actions.create') }}</button>
                <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary m-r-sm">{{ $t('common.actions.save') }}</button>
                <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-secondary m-r-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
                <button type="button" class="btn btn-sm btn-white" @click="back">{{ $t('common.actions.back') }}</button>
                <button type="button" v-if="type!='create' && hasRole('ADMIN')" class="btn btn-sm btn-danger float-right" @click="deleteMessage">{{ $t('common.actions.delete') }}</button>
              </div>
            </div>

          </b-form>
        </validation-observer>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import Vue from "vue"
import * as commons from "@/commons"
import i18n from "@/i18n"

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      error: null,
      info: null,
      type: 'view',
      messageType: 'advices',
      form: {
        id: null,
        status: null,
        severity: null,
        priority: null,
        category: null,
        description: '',
        notes: '',
        device: null
      }
    }
  },
  created(){
    console.log('MessageDetails $route.name: ' + this.$route.name)
    this.type = this.$route.name == 'adviceNew' ? 'create' : 'view'
    if(this.type == 'view') {
      this.$store.dispatch('messageCentre/fetchMessageDetails', this.$route.params.id)
    } else {
      this.$store.commit('messageCentre/UPDATE_MESSAGE_DETAILS_ENTITY', null)
      this.reset()
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      message: state => state.messageCentre.messageDetails.entity,
      buildingDevices: state => state.messageCentre.selectedBuildingDevices
    })
  },
  watch: {
    message: function(newVal, oldVal) { // watch it
      console.log('Prop message changed: ', newVal, ' | was: ', oldVal)
      if(newVal){
        this.messageType = (newVal.type === 'ADVICE' ? 'advices' : 'issues')
        let viewHeaderTitle = document.getElementById("viewHeaderTitle")
        if(viewHeaderTitle){
          viewHeaderTitle.innerHTML = this.$i18n.t('messageCentre.' + this.messageType + '.details.title')
        }
      }
      this.reset()
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    reset() {

      this.error = ""
      this.info = ""

      if(this.$route.name == 'adviceNew'){
        this.form.device = null
        this.form.id = null
        this.form.type = 'ADVICE'
        this.form.category = ''
        this.form.status = 'TODO'
        this.form.severity = 'MINOR'
        this.form.priority = 'NORMAL'
        this.form.description = ''
        this.form.notes = ''
      } else {
        this.form.device = this.message.device
        this.form.id =  this.message.id
        this.form.type =  this.message.type
        this.form.category =  this.message.category
        this.form.status =  this.message.status
        this.form.severity =  this.message.severity
        this.form.priority =  this.message.priority
        this.form.description =  this.message.description
        this.form.notes =  this.message.notes
        this.type = 'view'
      }
      if(this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    back(){
      this.goBack()
    },
    deleteMessage(){
      let self = this;
      this.confirmDialog({
        text: this.$t('messageCentre.' + this.messageType + '.confirm.delete'),
        callbackYes: function(){
          Vue.axios.delete('/messages/' + self.form.id)
              .then(response => {
                self.$toastr.i(i18n.t('messageCentre.' + this.messageType + '.info.deleted'))
                self.goBack()
              })
              .catch(error => {
                commons.processRestError(error)
              })
        }
      })
    },
    save() {

      this.error = ""
      this.info = ""
      let self = this

      if(this.$route.name == 'adviceNew') {

        Vue.axios.post('/messages', JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          self.$toastr.i(i18n.t('messageCentre.' + this.messageType + '.info.created'))
          self.$router.replace({
            name: 'messageDetails',
            params: { id: response.data.id }
          })
          this.$store.dispatch('messageCentre/fetchMessageDetails', this.$route.params.id)
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      } else {
        Vue.axios.put('/messages/' + this.message.id, JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {
          self.$toastr.i(i18n.t('messageCentre.' + this.messageType + '.info.saved'))
          self.$store.dispatch('messageCentre/fetchMessageDetails', self.$route.params.id)
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      }
    }
  }
}
</script>