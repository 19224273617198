import Vue from "vue";
import * as commons from '@/commons'

// default state
const getDefaultState = () => {
    return {
        locationProfiles: [],
        locationsWithSelectedProfile: [],
        locationProfileDetails: {
            entity: {}
        }
    }

}

// initial state
const state = getDefaultState()

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('profiles/appInit')
    },

    fetchOrganisationLocationProfiles({commit, state, dispatch, rootState}, id) {

        console.log('profiles/fetchOrganisationLocationProfiles')

        Vue.axios.get('/admin/organisations/'+ id +'/location-profiles').then(response => {
            commit('SAVE_LOCATION_PROFILES', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchOrganisationLocationsWithSelectedProfile({commit, state, dispatch, rootState}, id) {

        console.log('profiles/fetchOrganisationLocationsWithSelectedProfile')

        Vue.axios.get('/admin/organisations/' + id + '/locations-with-selected-profile').then(response => {
            commit('SAVE_LOCATIONS_WITH_SELECTED_PROFILE', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchBuildingLocationsWithSelectedProfile({commit, state, dispatch, rootState}, id) {

        console.log('profiles/fetchBuildingLocationsWithSelectedProfile')

        Vue.axios.get('/admin/buildings/' + id + '/locations-with-selected-profile').then(response => {
            commit('SAVE_LOCATIONS_WITH_SELECTED_PROFILE', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchLocationProfileDetails({commit, state, dispatch, rootState}, id) {

        console.log('profiles/fetchLocationProfileDetails')

        //load map
        Vue.axios.get('/admin/location-profiles/' + id).then(response => {
            commit('SAVE_LOCATION_PROFILE_DETAILS_ENTITY', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    }

}


// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['SAVE_ALL_ACTIVE_TAB']: (state, data) => {
        state.allActiveTab = data
    },

    ['SAVE_LOCATION_PROFILES']: (state, data) => {
        state.locationProfiles = data
    },

    ['SAVE_LOCATIONS_WITH_SELECTED_PROFILE']: (state, data) => {
        state.locationsWithSelectedProfile = data
    },

    ['SAVE_LOCATION_PROFILE_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.locationProfileDetails, 'entity', data)
    },
}

export default {
    namespaced: true, state, actions, mutations
}