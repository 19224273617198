<template>
  <div class="animated fadeInRight">

    <b-tabs @input="tabSelected" v-model="activeTab" content-class="px-5 py-5 white-bg">

      <b-tab :title="$t('systemAdmin.deviceModel.title')">

        <div class="row">
          <div class="col-lg-12">
            <device-model-list />
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('systemAdmin.device.title')" lazy>
        <div class="row">
          <div class="col-lg-12">
            <device-list route-prefix="/admin"/>
          </div>
        </div>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import DeviceList from "@/components/views/admin/system/DeviceList"
import DeviceModelList from "@/components/views/admin/system/DeviceModelList"

export default {
  components: {
    DeviceList,
    DeviceModelList
  },
  data() {
    return {
      error: null,
      info: null,
      activeTab: this.$store.state.systemAdmin.activeTab,
    }
  },
  methods: {
    tabSelected() {
      this.$store.commit('systemAdmin/SAVE_ACTIVE_TAB', this.activeTab)
    }
  }
}
</script>

