<template>
  <div class="animated fadeInRight">

    <div class="row">

      <div class="col-xxxl-8">

        <div class="ibox">
          <div class="ibox-content p-5">

            <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
              <button class="close" type="button" @click="error = ''">×</button>
            </div>

            <validation-observer ref="form" v-slot="{ handleSubmit }">

              <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(submit)">

                <div class="row">

                  <div class="col-12">

                    <validation-provider name="orgAdmin.form.attributes.name" :rules="{ required: true, min: 3, max: 100 }" v-slot="validationContext">
                      <b-form-group id="input-group-form-name" :label="$t('orgAdmin.form.attributes.name')" label-for="input-form-name" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type === 'view'"
                            id="input-form-name"
                            alias="input-form-name"
                            v-model="form.name"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-form-name"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-form-name">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider name="orgAdmin.form.attributes.status" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group id="input-group-form-status" :label="$t('orgAdmin.form.attributes.status')" label-for="input-form-status" label-cols-sm="2">
                        <v-select
                            :disabled="type === 'view'"
                            id="input-form-status"
                            name="input-form-status"
                            v-model="form.status"
                            :options="formStatus"
                            :reduce="type => type.id"
                            label="text"
                            :clearable="false"
                            :class="{'is-invalid': validationContext.errors.length > 0}">
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <!-- add settings -->

                    <div class="row">
                      <div class="col-md-7">
                        <b-tabs content-class="px-2 py-2 white-bg">
                          <b-tab :title="$t('orgAdmin.form.details.title')">

                            <div class="row">

                              <div class="col-12">

                                <div v-for="(field, index) in formFieldsSorted" v-bind:key="index" class="field-wrapper" :class="selectedFieldIndex === index ? 'selected': ''">

                                  <span>{{ field.title }}</span>
                                  <div class="field-editor">
                                    <div v-if="field.type === 'text'" class="field">
                                      <input type="text" class="form-control">
                                    </div>
                                    <div v-else-if="field.type === 'textarea'" class="field">
                                      <textarea class="form-control"></textarea>
                                    </div>
                                    <div v-else-if="field.type === 'radio'" class="field">
                                      <div v-for="(option, option_index) in field.options" v-bind:key="option_index">
                                        <input type="radio" v-bind:name="field.name" :id="'option'+ option_index" >
                                        <label :for="'option' + option_index">{{option.label}}</label>
                                      </div>
                                    </div>
                                    <div v-else-if="field.type === 'checkbox'" class="field">
                                      <div v-for="(option, option_index) in field.options" v-bind:key="option_index">
                                        <input type="checkbox" v-bind:name="field.name" :id="'option'+ option_index" >
                                        <label :for="'option' + option_index">{{option.label}}</label>
                                      </div>
                                    </div>
                                    <div class="controls" v-if="type === 'edit'">
                                      <!--i aria-hidden="true" class="fa-solid fa-up-down-left-right"></i-->
                                      <i aria-hidden="true" class="fa-solid fa-pen-to-square" @click="editField(index)"></i>
                                      <i aria-hidden="true" class="fa-solid fa-down-long" @click="moveDown(index)" v-if="index < formFieldsSorted.length - 1"></i>
                                      <i aria-hidden="true" class="fa-solid fa-up-long" @click="moveUp(index)" v-if="index > 0"></i>
                                      <i aria-hidden="true" class="fa-solid fa-trash" @click="removeField(index)"></i>
                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>

                          </b-tab>
                        </b-tabs>
                      </div>
                      <div class="col-md-5">
                        <b-tabs content-class="px-3 py-3 white-bg" v-model="activeTab" v-if="type === 'edit'">

                          <b-tab :title="$t('orgAdmin.form.details.addField')" >
                            <div class="row">
                              <div class="col-6 d-flex flex-column">
                                <button type="button" @click="addField('text')" class="btn btn-sm btn-white m-l-sm mb-2">{{ $t('orgAdmin.form.details.field.text') }}</button>
                                <button type="button" @click="addField('textarea')" class="btn btn-sm btn-white m-l-sm mb-2">{{ $t('orgAdmin.form.details.field.textArea') }}</button>
                                <button type="button" @click="addField('radio')" class="btn btn-sm btn-white m-l-sm mb-2">{{ $t('orgAdmin.form.details.field.radio') }}</button>
                                <button type="button" @click="addField('checkbox')" class="btn btn-sm btn-white m-l-sm mb-2">{{ $t('orgAdmin.form.details.field.checkbox') }}</button>
                              </div>
                            </div>
                          </b-tab>

                          <b-tab :title="$t('orgAdmin.form.details.editField')">
                            <div class="row">
                              <div class="col-12" v-if="selectedField">

                                <div class="form-group">
                                  <label class="control-label">{{ $t('orgAdmin.form.details.fieldOptions.title') }}</label>
                                  <input type="text" v-model="selectedField.title" class="form-control">
                                </div>

                                <div class="form-group">
                                  <label class="control-label">
                                    <input type="checkbox" v-model="selectedField.required">{{ $t('orgAdmin.form.details.fieldOptions.required') }}
                                  </label>
                                </div>

                                <div class="form-group" v-if="selectedField.type === 'radio' || selectedField.type === 'checkbox'">
                                  <label class="control-label">{{ $t('orgAdmin.form.details.fieldOptions.choices') }}</label>
                                  <div v-for="(option, option_index) in selectedField.options" v-bind:key="option_index" class="option-wrapper">
                                    <input type="text" v-model="option.label" class="form-control option-label">
                                    <div class="option-control">
                                      <i class="fa-solid fa-square-minus" @click="removeOption(option_index)"></i>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="option-control add">
                                      <i class="fa-solid fa-square-plus" @click="addOption"></i>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </b-tab>

                        </b-tabs>
                      </div>
                    </div>

                    <!-- Reminder -->
                    <div v-if="type !== 'create'">
                      <h4>{{ $t('orgAdmin.form.details.reminder') }}</h4>

                      <validation-provider name="orgAdmin.form.attributes.sendReminder" v-slot="validationContext" vid="sendReminder">
                        <b-form-group id="input-group-form-sendReminder" :label="$t('orgAdmin.form.attributes.sendReminder')" label-for="input-form-sendReminder" label-cols-sm="2">
                          <b-form-checkbox
                              :disabled="type === 'view'"
                              id="input-form-sendReminder"
                              alias="input-form-sendReminder"
                              v-model="form.sendReminder"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-form-sendReminder"
                          ></b-form-checkbox>
                          <b-form-invalid-feedback id="input-feedback-form-sendReminder">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="orgAdmin.form.attributes.reminderTitle" :rules="{ min: 3, max: 100, required: true }" v-slot="validationContext" :disabled="!form.sendReminder">
                        <b-form-group id="input-group-form-reminderTitle" :label="$t('orgAdmin.form.attributes.reminderTitle')" label-for="input-form-reminderTitle" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type === 'view' || !form.sendReminder"
                              id="input-form-reminderTitle"
                              alias="input-form-reminderTitle"
                              v-model="form.reminderTitle"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-form-reminderTitle"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-form-reminderTitle">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="orgAdmin.form.attributes.reminderContent" :rules="{ max:500, required: true }" v-slot="validationContext" :disabled="!form.sendReminder">
                        <b-form-group id="input-group-form-reminderContent" :label="$t('orgAdmin.form.attributes.reminderContent')" label-for="input-form-reminderContent" label-cols-sm="2">
                          <b-form-textarea
                              :plaintext="type === 'view' || !form.sendReminder"
                              id="input-form-reminderContent"
                              alias="input-form-reminderContent"
                              v-model="form.reminderContent"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-form-reminderContent"
                          ></b-form-textarea>
                          <b-form-invalid-feedback id="input-feedback-form-reminderContent">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="orgAdmin.form.attributes.reminderTime" :rules="{ regex: '[0-2][0-9];', required: true }" v-slot="validationContext" :disabled="!form.sendReminder">
                        <b-form-group id="input-group-form-reminderTime" :label="$t('orgAdmin.form.attributes.reminderTime')" label-for="input-form-reminderTime" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type === 'view' || !form.sendReminder"
                              id="input-form-reminderTime"
                              alias="input-form-reminderTime"
                              v-model="form.reminderTime"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-form-reminderTime"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-form-reminderTime">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          <div class="mt-2" v-if="type === 'edit' && form.sendReminder">{{ $t('orgAdmin.form.info.reminderTimeNote') }}</div>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-12">
                    <button  type="button" class="btn btn-sm btn-primary" v-if="type === 'view'" @click.prevent="editForm">
                      <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.form.actions.edit') }}
                    </button>
                    <button type="submit" v-if="type === 'create'" class="btn btn-sm btn-primary">{{ $t('common.actions.create') }}</button>
                    <button type="submit" v-if="type === 'edit'" class="btn btn-sm btn-primary">{{ $t('common.actions.save') }}</button>
                    <button type="button" v-if="type === 'create' || type === 'edit'" class="btn btn-sm btn-secondary m-l-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
                    <button type="button" class="btn btn-sm btn-white m-l-sm" @click="cancel" v-if="type === 'edit'">{{ $t('common.actions.cancel') }}</button>
                    <button type="button" class="btn btn-sm btn-white m-l-sm" @click="back">{{ $t('common.actions.back') }}</button>
                    <!--button type="button" v-if="type!='create'" class="btn btn-sm btn-danger float-right" @click="deleteForm">{{ $t('common.actions.delete') }}</button-->
                  </div>
                </div>

                <div v-if="form.id">
                  <div class="row">
                    <div class="col-12">
                      <hr/>
                      <div>
                        <h4>{{ $t('orgAdmin.form.details.assignedTo') }}:</h4>
                        <div class="form-row form-group">
                          <div v-for="(building, buildingId) in groupedLocations" :key="buildingId" class="building-wrapper">
                              <h2>{{ building.name }}</h2>
                              <div v-for="(map, mapId) in building.maps" :key="mapId">
                                  <h3>{{ map.name + ":" }}</h3>
                                  <button v-for="location in map.locations" :key="location.id" class="btn btn-default mr-1" type="button">
                                      {{ location.name }} <i class="fa-solid fa-xmark" @click="unassignForm(location.id)"></i>
                                  </button>
                              </div>
                          </div>
                      </div>
                      </div>
                      <button type="button" v-if="!showAssign && type === 'view'" class="btn btn-sm btn-primary" @click="showAssign=true">{{ $t('common.actions.assign') }}</button>
                    </div>
                  </div>

                  <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated" appear>
                    <div class="row" v-if="showAssign">
                      <div class="col-12">
                        <form-resource-assign :resource-selected-callback="resourceSelectedCallback" :cancel-callback="cancelAssignCallback" :form="form" />
                        <hr/>
                      </div>
                    </div>
                  </transition>
                </div>

              </b-form>
            </validation-observer>

            <div id="form" style="margin-top: 200px;"></div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Vue from "vue";
import * as commons from "@/commons";
import i18n from "@/i18n";
import FormResourceAssign from "@/components/views/feedback/FormResourceAssign";

export default {
  components: {
    FormResourceAssign,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      error: null,
      type: 'view',
      showAssign: false,
      form: {
        name: '',
        status: '',
        settings: null,
        fields: [],
        sendReminder: false,
        reminderTitle: '',
        reminderContent: '',
        reminderTime: ''
      },
      selectedField: null,
      selectedFieldIndex: null,
      activeTab: 0
    }
  },
  created() {
    console.log('formDetails $route.name: ' + this.$route.name)
    this.type = this.$route.name == 'formNew' ? 'create' : 'view'
    if(this.type === 'view') {
      this.$store.dispatch('feedback/fetchFormDetails', this.$route.params.id)
    } else {
      this.$store.commit('feedback/SAVE_FORM_DETAILS_ENTITY', null)
      this.reset()
    }
  },
  computed: {
    ...mapState({
      formObj: state => state.feedback.formDetails.entity,
      currentOrganisation: state => state.feedback.formDetails.entity.organisation,
      currentLocations: state => state.feedback.formDetails.entity.locations
    }),
    organisation() {
      console.log('organisation() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.organisation.organisationDetails.entity : this.$store.state.contextOrg
    },
    formStatus() {
      return [
        { id: "ACTIVE", text: i18n.t("orgAdmin.form.formStatus.active") },
        { id: "INACTIVE", text: i18n.t("orgAdmin.form.formStatus.inactive") }
      ]
    },
    formFieldsSorted() {
      let fieldsTemp = this.form.fields;
      return fieldsTemp.sort((a,b) => (b.order > a.order ? -1 : 1))
    },
    groupedLocations() {
      let grouped = {};

      this.currentLocations.forEach(location => {
        const buildingId = location.map.building.id;
        const buildingName = location.map.building.name;
        const mapId = location.map.id;
        const mapName = location.map.name;

        if (!grouped[buildingId]) {
          grouped[buildingId] = {
            name: buildingName,
            maps: {}
          };
        }

        if (!grouped[buildingId].maps[mapId]) {
          grouped[buildingId].maps[mapId] = {
            name: mapName,
            locations: []
          };
        }

        grouped[buildingId].maps[mapId].locations.push(location);
      });

      return grouped;
    }
  },
  watch: {
    formObj: function(newVal, oldVal) {
      console.log('Prop form changed: ', newVal, ' | was: ', oldVal)
      this.reset()
    },
    'form.sendReminder': function(newVal, oldVal) {
      this.$refs.form.reset();
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    reset() {
      //console.log("reset: " + JSON.stringify(this.formObj))
      if(this.formObj){
        this.form.id = this.formObj.id
        this.form.name = this.formObj.name
        this.form.status = this.formObj.status
        this.form.settings = this.formObj.settings
        this.form.sendReminder = this.formObj.sendReminder
        this.form.reminderTitle = this.formObj.reminderTitle
        this.form.reminderContent = this.formObj.reminderContent
        this.form.reminderTime = this.formObj.reminderTime
        this.convertJsonSchemaToModel();
      } else {
        this.form.name = ''
        this.form.status = null
        this.form.settings = {}
        this.form.sendReminder = false
        this.form.reminderTitle = ''
        this.form.reminderContent = ''
        this.form.reminderTime = ''
      }
      if(this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    back(){
      this.goBack()
    },
    cancel(){
      this.$refs.form.reset();
      this.reset();
      this.type='view'
    },
    editForm(){
      this.type='edit'
      this.convertJsonSchemaToModel();
    },
    addField(fieldType){
      //console.log("addField: " + fieldType)
      let fieldOrder = this.form.fields.length;
      let fieldName = fieldType + '_' + fieldOrder
      if(fieldType === 'text') {
        this.form.fields.push({ type: fieldType, name: fieldName, label: 'Text', order: fieldOrder })
      } else if (fieldType === 'textarea') {
        this.form.fields.push({ type: fieldType, name: fieldName, label: 'Text area', order: fieldOrder })
      } else if(fieldType === 'radio') {
        let value = fieldName + '_opt_0'
        this.form.fields.push({ type: fieldType, name: fieldName, label: 'Radio', order: fieldOrder, options: [{ value: value, label:'' }] })
      } else if(fieldType === 'checkbox') {
        let value = fieldName + '_opt_0'
        this.form.fields.push({ type: fieldType, name: fieldName, label: 'Checkbox', order: fieldOrder, options: [{ value: value, label:'' }] })
      }
      this.selectedField = this.form.fields.at(-1)
      this.selectedFieldIndex = this.form.fields.length -1
      this.activeTab = 1
    },
    addFieldFromSchema(fieldType, fieldName, fieldTitle, fieldRequired){
      console.log("addFieldFromSchema: " + fieldType)
      let newField;
      let fieldOrder = this.form.fields.length;
      if(fieldType === 'text' || fieldType === 'textarea') {
        newField = { type: fieldType, name: fieldName, title: fieldTitle, required: fieldRequired, order: fieldOrder }
      } else if(fieldType === 'radio') {
        newField = { type: fieldType, name: fieldName, title: fieldTitle, order: fieldOrder }
      } else if(fieldType === 'checkbox') {
        newField = { type: fieldType, name: fieldName, title: fieldTitle, order: fieldOrder }
      }
      return newField
    },
    editField(index){
      console.log("editField: " + index)
      this.selectedField = this.form.fields[index]
      this.selectedFieldIndex = index
      this.activeTab = 1
    },
    removeField(index){
      console.log("removeField: " + index)
      this.form.fields.splice(index, 1)
      this.selectedField = null
    },
    moveUp(index){
      console.log("moveUp: " + index)
      console.log("order before: " + this.form.fields[index].order)
      if(this.form.fields[index].order > 0){
        this.form.fields[index].order--
        this.form.fields[index - 1].order++
      }
      console.log("order after: " + this.form.fields[index].order)
    },
    moveDown(index){
      console.log("moveDown: " + index)
      console.log("order before: " + this.form.fields[index].order)
      if(this.form.fields[index].order < this.form.fields.length) {
        this.form.fields[index].order++
        this.form.fields[index + 1].order--
      }
      console.log("order after: " + this.form.fields[index].order)
    },
    addOption(){
      console.log("addOption")
      const value = this.selectedField.name + '_opt_' + this.selectedField.options.length
      this.selectedField.options.push({ value: value, label:'' })
    },
    removeOption(index){
      console.log("removeOption: " + index)
      this.selectedField.options.splice(index, 1)
    },
    resourceSelectedCallback(data){
      this.$store.dispatch('feedback/fetchFormDetails', this.$route.params.id)
      this.showAssign = false
    },
    cancelAssignCallback(){
      this.showAssign = false
    },
    unassignForm(locationId) {

      let self = this

      Vue.axios.post('/admin/feedback/forms/' + self.form.id + '/unassign', null, { params: {
            building_id: null,
            location_id: locationId
          }
        },
        {headers: {'Content-Type': 'application/json'}
        })
        .then(response => {
          self.$toastr.i(self.$i18n.t('orgAdmin.form.info.unassigned'))
          this.$store.dispatch('feedback/fetchFormDetails', this.$route.params.id)
        })
        .catch(error => {
          if (error.response.status === 403) {
            self.error = self.$i18n.t('orgAdmin.form.error.assignNotAuthorized')
          } else {
            commons.processRestError(error)
          }
        })
    },
    submit() {
      this.error = ""
      this.info = ""
      let self = this

      let config = this.convertModelToJsonSchema();
      //console.log("this.building:" + JSON.stringify(this.building));
      //let org = (typeof this.building.organisation !== 'undefined') ? this.building.organisation : this.organisation;
      let org = this.organisation;

      const payload = {
        name: this.form.name,
        status: this.form.status,
        settings: config,
        sendReminder: this.form.sendReminder,
        reminderTitle: this.form.reminderTitle,
        reminderContent: this.form.reminderContent,
        reminderTime: this.form.reminderTime,
        organisation: org
      };

      if(this.type === 'create') {
        Vue.axios.post('/admin/feedback/forms', JSON.stringify(payload),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          self.$toastr.i(i18n.t('orgAdmin.form.info.created'))
          self.$router.replace({
            name: 'formDetails',
            params: { id: response.data.id }
          })
          self.$store.dispatch('feedback/fetchFormDetails', this.$route.params.id)
          self.type = 'view'
          self.selectedFieldIndex = null
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      } else if(this.type === "edit") {
        Vue.axios.put('/admin/feedback/forms/' + this.form.id, JSON.stringify(payload),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {
          self.$toastr.i(i18n.t('orgAdmin.form.info.saved'))
          self.$store.dispatch('feedback/fetchFormDetails', this.$route.params.id)
          self.type = 'view'
          self.selectedFieldIndex = null
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      }
    },
    deleteForm(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.form.confirm.delete'),
        callbackYes: function(){
          Vue.axios.delete('/admin/feedback/forms/' + self.form.id)
              .then(response => {
                self.$toastr.i(i18n.t('orgAdmin.form.info.deleted'))
                self.goBack()
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
    convertModelToJsonSchema(){
      console.log("convertModelToJsonSchema")

      let config = {
        schema: {
          title: this.form.name,
          description: "",
          type: "object",
          properties: {}
        },
        options: {
          helper: '',
          fields: {}
        }
      }

      let i = 0;
      for (const field of this.formFieldsSorted) {
          let schemaType = null;
          if(field.type === 'text' || field.type === 'textarea') {
            schemaType = "string"
          } else if (field.type === 'radio') {
            schemaType = "radio"
          } else if ( field.type === 'checkbox') {
            schemaType = "checkbox"
          }
          config.schema.properties[field.name] = { type: schemaType, title: field.title, required: field.required }
          config.options.fields[field.name] = { type: field.type, order: i++ }

          if (field.options && field.options.length > 0) {
              config.schema.properties[field.name]["enum"] = field.options.map(option => option.value)
              config.options.fields[field.name]["optionLabels"] = field.options.map(option => option.label)
          }
      }

      return config;
    },
    convertJsonSchemaToModel(){
      console.log("convertJsonSchemaToModel")

      this.form.fields = []

      for (const [property, value] of Object.entries(this.form.settings.schema.properties)) {

        let fieldName = property
        let fieldType = value.type
        let fieldTitle = value.title
        let fieldRequired = value.required

        let fieldOptions = this.form.settings.options.fields[fieldName]
        if (fieldOptions) {
          fieldType = fieldOptions.type
        }

        let newField = this.addFieldFromSchema(fieldType, fieldName, fieldTitle, fieldRequired)

        if (value.enum) {
          newField.options = []

          for (let index = 0; index < value['enum'].length; ++index) {
            let value = this.form.settings.schema.properties[fieldName]['enum'][index]
            let label = this.form.settings.options.fields[fieldName].optionLabels[index]
            newField.options.push({ value: value, label: label })
          }
        }

        this.form.fields.push(newField)
      }
    }
  }
}
</script>

<style scoped>
.building-wrapper {
  margin-bottom: 20px;
  margin-right: 20px;
}

.field-wrapper {
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.field-wrapper.selected {
  background-color: #c3f6e6;
}
.field-wrapper label {
  padding: 0 5px;
}
.field-editor {
  display: flex;
}
.field {
  width: 70%;
  float: left;
  align-items: stretch;
  padding: 10px;
}
.controls {
  width: 30%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  align-items: center;
}
.controls i {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}
.option-wrapper {
  display: table;
  width: 100%;
  margin-bottom: 5px;
}
.option-helper {
  display: table-cell;
  width: 28%;
  text-align: center;
  font-style: italic;
}
.option-label {
  display: table-cell;
  padding-right: 10px;
  margin-right: 10px;
  width: 90%;
}
.option-value {
  display: table-cell;
  padding-right: 10px;
  margin-right: 10px;
  width: 40%;
}
.option-control {
  display: table-cell;
  padding-right: 10px;
  width: 20%;
}
.option-control.add {
  float: right;
}
</style>