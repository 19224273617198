<template>
  <div>

    <div class="form-group row m-b-sm" v-if="profiles.length != 0">
      <div class="col-lg-6">
        <div class="input-group">
          <span class="input-group-prepend">
            <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length == 0" @click="clearFilter"> {{
                $t('common.actions.clear')
              }}</b-button>
          </span>
          <b-form-input type="text"
                v-model="table.filter"
                debounce="300"
                max="50"
                :placeholder="$t('enterForSearch')"
                class="form-control form-control-sm"/>
        </div>
      </div>
      <div class="col-lg-2 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="profiles.length"></row-count>
      </div>
      <div class="col-lg-4 text-right" v-if="profiles.length && profiles.length > table.size">
        <b-pagination v-if="profiles.length" size="sm" class="m-t-xs" align="right"
                      :total-rows="profiles.length"
                      v-model="table.page"
                      :per-page="table.size"></b-pagination>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-table responsive hover
                 v-if="profiles.length != 0"
                 class="text-nowrap"
                 :filter="table.filter"
                 :fields="table.fields"
                 :current-page="table.page"
                 :per-page="table.size"
                 :items="profiles"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc">
          <template #cell(name)="data">
            <b-link :to="{ path: routePrefix + '/scheduler-profiles/' + data.item.id }">{{ data.value }}</b-link>
          </template>
          <template #cell(settings)="data">
            <span :title="prettifyTitle(data.value)">{{ data.value }}</span>
          </template>
        </b-table>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

        <div class="row" v-if="canAdd">
          <div class="col-lg-12">
            <router-link :to="routePrefix + '/scheduler-profiles/new'">
              <button class="btn btn-sm btn-primary">
                <i class="fa fa-plus"></i>&nbsp;{{ $t('orgAdmin.schedulerProfile.actions.new') }}
              </button>
            </router-link>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import RowCount from '@/components/common/RowCount'

export default {
  components: {
    RowCount
  },
  props: {
    profiles: {
      type: Array
    },
    canAdd: {
      type: Boolean,
      required: false,
      default: false
    },
    routePrefix: {
      default: ''
    }
  },

  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: 'name',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50}
        ],
        fields: [
          {
            key: 'name',
            label: this.$i18n.t('orgAdmin.schedulerProfile.attributes.name'),
            sortable: true
          },
          /*{
            key: 'settings',
            label: this.$i18n.t('orgAdmin.schedulerProfile.attributes.settings'),
            sortable: false
          }*/
        ]
      }
    }
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    },
    prettifyTitle(json){
      if (typeof json === 'string') {
        json = JSON.parse(json)
      }
      let output = JSON.stringify(json, function(k,v) {
        if(v instanceof Array) {
          return JSON.stringify(v)
        }
        return v
      }, 2).replace(/\\/g, '')
          .replace(/"\[/g, '[')
          .replace(/\]"/g,']')
          .replace(/"\{/g, '{')
          .replace(/\}"/g,'}')

      return output
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo
    })
  }
}
</script>

<style scoped>
td.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}
</style>
