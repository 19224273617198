<template>
  <div class="cursor-pointer widget lazur-bg card-class building text-center" @click="open">
    <span v-if="item.status !=='ACTIVE'" class="card-status badge badge-danger">{{ $t('enums.ResourceStatus.' + item.status) }}</span>
    <div class="m-b-sm">{{ $t('orgAdmin.building.name') }}</div>
    <h3 class="font-bold">{{ item.name }}</h3>
    <div class="m-t-sm" v-if="item.address">{{ item.address }}</div>
    <div v-if="item.city">{{ item.city  }}</div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    item: {
      type: Object
    },
  },
  data() {
    return {
      error: null
    }
  },
  methods: {
    open(){
      this.$router.push({
        path: `/admin/buildings/${this.item.id}`,
      })
      this.$store.commit('orgAdmin/SAVE_BUILDING_DETAILS_ACTIVE_TAB', 0)
    },
  }
}
</script>