<template>
  <div class="parameters-wrapper">
    <b-form @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col md="6" class="p-2">
          <h4>{{ $t('orgAdmin.building.parameters.generalInformation') }}</h4>

          <b-form-group>
            <strong>{{ $t('orgAdmin.building.parameters.mainFunction') }}</strong>
            <b-form-select v-model="form.mainFunction" :options="mainFunctionOptions" :disabled="!isEditing"></b-form-select>
          </b-form-group>

          <b-form-group>
            <strong>{{ $t('orgAdmin.building.parameters.constructionYear') }}</strong>
            <b-form-input v-model="form.constructionYear" type="number" :readonly="!isEditing" :min="1500" :max="new Date().getFullYear()"></b-form-input>
          </b-form-group>

          <b-form-group>
            <strong>{{ $t('orgAdmin.building.parameters.lastRenovationYear') }}</strong>
            <b-form-input v-model="form.lastRenovationYear" type="number" :readonly="!isEditing" :min="1800" :max="new Date().getFullYear()"></b-form-input>
          </b-form-group>

          <b-form-group>
            <strong>{{ $t('orgAdmin.building.parameters.structureMaterial') }}</strong>
            <b-button v-b-popover.hover="getExplanation('structureMaterial')" variant="link" class="p-0">
              <i class="fas fa-question-circle ml-2"></i>
            </b-button>
            <b-form-radio-group v-model="form.structureMaterial" :options="structureMaterialOptions" :disabled="!isEditing"></b-form-radio-group>
          </b-form-group>

          <b-form-group>
            <strong>{{ $t('orgAdmin.building.parameters.outerWallMaterial') }}</strong>
            <b-form-select v-model="form.outerWallMaterial" :options="outerWallMaterialOptions" :disabled="!isEditing"></b-form-select>
          </b-form-group>

          <b-form-group>
            <strong>{{ $t('orgAdmin.building.parameters.energyLabel') }}</strong>
            <b-button v-b-popover.hover="getExplanation('energyLabel')" variant="link" class="p-0 ml-auto">
              <i class="fas fa-question-circle ml-2"></i>
            </b-button>
            <b-form-select v-model="form.energyLabel" :options="energyLabelOptions" :disabled="!isEditing"></b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="6" class="p-4">
          <h4>{{ $t('orgAdmin.building.parameters.buildingSystems') }}</h4>
          <b-form-group>
            <strong>{{ $t('orgAdmin.building.parameters.ventilationType') }}</strong>
            <b-form-radio-group v-model="form.ventilationType" :options="ventilationOptions" :disabled="!isEditing"></b-form-radio-group>
          </b-form-group>
          <b-form-group>
            <strong>{{ $t('orgAdmin.building.parameters.heatingType') }}</strong>
            <b-form-radio-group v-model="form.heatingType" :options="heatingTypeOptions" :disabled="!isEditing"></b-form-radio-group>
          </b-form-group>

          <b-form-group>
            <strong>{{ $t('orgAdmin.building.parameters.heatSource') }}</strong>
            <b-button v-b-popover.hover="getExplanation('heatSource')" variant="link" class="p-0">
              <i class="fas fa-question-circle ml-2"></i>
            </b-button>
            <b-form-radio-group v-model="form.heatSource" :options="heatSourceOptions" :disabled="!isEditing"></b-form-radio-group>
          </b-form-group>

          <b-form-group>
            <strong>{{ $t('orgAdmin.building.parameters.airConditioning') }}</strong>
            <b-form-checkbox v-model="form.airConditioning" :disabled="!isEditing"></b-form-checkbox>
          </b-form-group>

          <b-form-group>
            <strong>{{ $t('orgAdmin.building.parameters.renewableEnergySources') }}</strong>
            <b-form-checkbox v-model="form.solarPanels" :disabled="!isEditing">{{ $t('orgAdmin.building.parameters.solarPanels') }}</b-form-checkbox>
            <b-form-checkbox v-model="form.windTurbine" :disabled="!isEditing">{{ $t('orgAdmin.building.parameters.windTurbine') }}</b-form-checkbox>
            <b-form-checkbox v-model="form.geothermalHeating" :disabled="!isEditing">{{ $t('orgAdmin.building.parameters.geothermalHeating') }}</b-form-checkbox>
            <b-form-checkbox v-model="form.batteryStorage" :disabled="!isEditing">{{ $t('orgAdmin.building.parameters.batteryStorage') }}</b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="p-2">
          <h4>{{ $t('orgAdmin.building.parameters.windows') }}</h4>
          <b-form-group>
            <b-row>
              <b-col md="4">
                <label>{{ $t('orgAdmin.building.parameters.windowType') }}</label>
                <b-form-select v-model="form.windowType" :options="windowTypeOptions" :disabled="!isEditing"></b-form-select>
              </b-col>
              <b-col md="4">
                <label>{{ $t('orgAdmin.building.parameters.windowFrameMaterial') }}</label>
                <b-form-select v-model="form.windowFrameMaterial" :options="windowFrameMaterialOptions" :disabled="!isEditing"></b-form-select>
              </b-col>
              <b-col md="4">
                <label>{{ $t('orgAdmin.building.parameters.windowInstallationYear') }}</label>
                <b-form-input v-model="form.windowInstallationYear" type="number" :readonly="!isEditing" :min="1800" :max="new Date().getFullYear()"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="p-2">
          <h4>{{ $t('orgAdmin.building.parameters.floorAreas') }}</h4>
          <b-form-group>
            <b-row>
              <b-col md="4">
                <strong>{{ $t('orgAdmin.building.parameters.grossFloorArea') }}</strong>
                <b-button v-b-popover.hover="getExplanation('grossFloorArea')" variant="link" class="p-0">
                  <i class="fas fa-question-circle ml-2"></i>
                </b-button>
                <b-form-input v-model="form.grossFloorArea" type="number" :readonly="!isEditing"></b-form-input>
              </b-col>
              <b-col md="4">
                <strong>{{ $t('orgAdmin.building.parameters.netFloorArea') }}</strong>
                <b-button v-b-popover.hover="getExplanation('netFloorArea')" variant="link" class="p-0">
                  <i class="fas fa-question-circle ml-2"></i>
                </b-button>
                <b-form-input v-model="form.netFloorArea" type="number" :readonly="!isEditing"></b-form-input>
              </b-col>
              <b-col md="4">
                <strong>{{ $t('orgAdmin.building.parameters.heatedFloorArea') }}</strong>
                <b-button v-b-popover.hover="getExplanation('heatedFloorArea')" variant="link" class="p-0">
                  <i class="fas fa-question-circle ml-2"></i>
                </b-button>
                <b-form-input v-model="form.heatedFloorArea" type="number" :readonly="!isEditing"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button type="button" variant="primary" v-if="isEditing" @click="onSubmit" class="mr-2">{{ $t('common.actions.save') }}</b-button>
      <b-button type="button" variant="secondary" v-if="isEditing" @click="cancelEdit" class="mr-2">{{ $t('common.actions.cancel') }}</b-button>
      <b-button type="button" variant="primary" v-if="!isEditing" @click="toggleEdit" class="mr-2">{{ $t('common.actions.edit') }}</b-button>
      <b-button type="button" variant="danger" v-if="parametersExist && !isEditing" @click="confirmDelete" class="ml-auto">{{ $t('common.modal.actions.delete') }}</b-button>
    </b-form>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  props: {
    buildingId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isEditing: false,
      parametersExist: false,
      initialForm: {},
      form: {
        ventilationType: '',
        heatingType: '',
        heatSource: '',
        grossFloorArea: null,
        netFloorArea: null,
        heatedFloorArea: null,
        structureMaterial: '',
        outerWallMaterial: '',
        constructionYear: null,
        lastRenovationYear: null,
        energyLabel: null,
        mainFunction: '',
        windowType: '',
        windowFrameMaterial: '',
        windowInstallationYear: null,
        airConditioning: false,
        solarPanels: false,
        windTurbine: false,
        geothermalHeating: false,
        batteryStorage: false
      },
      ventilationOptions: [
        { text: this.$t('orgAdmin.building.parameters.ventilationOptions.naturalPassive'), value: 'Natural / Passive' },
        { text: this.$t('orgAdmin.building.parameters.ventilationOptions.mechanicalExhaust'), value: 'Mechanical Exhaust' },
        { text: this.$t('orgAdmin.building.parameters.ventilationOptions.mechanicalExhaustAndSupply'), value: 'Mechanical Exhaust and Supply' },
        { text: this.$t('orgAdmin.building.parameters.ventilationOptions.mechanicalExhaustAndSupplyHeatExchanger'), value: 'Mechanical Exhaust and Supply (with Heat Exchanger)' },
        { text: this.$t('orgAdmin.building.parameters.ventilationOptions.mechanicalSupplyOnly'), value: 'Mechanical Supply only' },
      ],
      heatingTypeOptions: [
        { text: this.$t('orgAdmin.building.parameters.heatingTypeOptions.districtHeating'), value: 'District heating/block heating' },
        { text: this.$t('orgAdmin.building.parameters.heatingTypeOptions.centralHeatingOne'), value: 'Central heating with one heating unit' },
        { text: this.$t('orgAdmin.building.parameters.heatingTypeOptions.solidLiquidFuelStove'), value: 'Solid and liquid fuel stove' },
        { text: this.$t('orgAdmin.building.parameters.heatingTypeOptions.heatPump'), value: 'Heat pump' },
        { text: this.$t('orgAdmin.building.parameters.heatingTypeOptions.centralHeatingTwo'), value: 'Central heating with two heating units' },
        { text: this.$t('orgAdmin.building.parameters.heatingTypeOptions.electricHeating'), value: 'Electric heating' },
        { text: this.$t('orgAdmin.building.parameters.heatingTypeOptions.gasRadiator'), value: 'Gasradiator' },
        { text: this.$t('orgAdmin.building.parameters.heatingTypeOptions.noHeating'), value: 'No heating installation' },
        { text: this.$t('orgAdmin.building.parameters.heatingTypeOptions.mixed'), value: 'Mixed' }
      ],
      heatSourceOptions: [
        { text: this.$t('orgAdmin.building.parameters.heatSourceOptions.districtHeating'), value: 'District Heating' },
        { text: this.$t('orgAdmin.building.parameters.heatSourceOptions.heatPump'), value: 'Heat Pump' },
        { text: this.$t('orgAdmin.building.parameters.heatSourceOptions.electric'), value: 'Electric' },
        { text: this.$t('orgAdmin.building.parameters.heatSourceOptions.gasBoiler'), value: 'Gas Boiler' },
        { text: this.$t('orgAdmin.building.parameters.heatSourceOptions.fuelBiomassFurnace'), value: 'Fuel (Biomass) Furnace' }
      ],
      structureMaterialOptions: [
        { text: this.$t('orgAdmin.building.parameters.structureMaterialOptions.lightweight'), value: 'Lightweight' },
        { text: this.$t('orgAdmin.building.parameters.structureMaterialOptions.mediumweight'), value: 'Mediumweight' },
        { text: this.$t('orgAdmin.building.parameters.structureMaterialOptions.heavyweight'), value: 'Heavyweight' }
      ],
      windowTypeOptions: [
        { text: this.$t('orgAdmin.building.parameters.windowTypeOptions.singleGlazing'), value: 'Single Glazing' },
        { text: this.$t('orgAdmin.building.parameters.windowTypeOptions.doubleGlazing'), value: 'Double Glazing' },
        { text: this.$t('orgAdmin.building.parameters.windowTypeOptions.tripleGlazing'), value: 'Triple Glazing' }
      ],
      windowFrameMaterialOptions: [
        { text: this.$t('orgAdmin.building.parameters.windowFrameMaterialOptions.wood'), value: 'Wood' },
        { text: this.$t('orgAdmin.building.parameters.windowFrameMaterialOptions.pvc'), value: 'PVC' },
        { text: this.$t('orgAdmin.building.parameters.windowFrameMaterialOptions.aluminum'), value: 'Aluminum' }
      ],
      mainFunctionOptions: [
        { label: this.$t('orgAdmin.building.parameters.buildingFunction.residential'), options: [
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.residentialOptions.detachedSingleFamilyHouse'), value: '120' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.residentialOptions.semiDetachedSingleFamilyHouse'), value: '121' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.residentialOptions.multiFamilyApartmentBuilding'), value: '140' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.residentialOptions.dormitory'), value: '150' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.residentialOptions.residentialBuilding24HourInstitution'), value: '160' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.residentialOptions.annex'), value: '185' }
          ]
        },
        { label: this.$t('orgAdmin.building.parameters.buildingFunction.commercial'), options: [
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.officeBuilding'), value: '321' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.retailBuilding'), value: '322' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.warehouseBuilding'), value: '323' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.shoppingCenter'), value: '324' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.otherOfficeTradeStorage'), value: '329' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.hotel'), value: '331' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.bedBreakfast'), value: '332' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.restaurant'), value: '333' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.privateServiceBusiness'), value: '334' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.otherServiceBusiness'), value: '339' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.cinema'), value: '411' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.museum'), value: '412' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.library'), value: '413' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.church'), value: '414' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.assemblyHall'), value: '415' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.otherCulturalPurpose'), value: '419' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.primarySchool'), value: '421' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.university'), value: '422' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.otherEducationResearch'), value: '429' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.hospital'), value: '431' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.otherHealthPurpose'), value: '439' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.daycareInstitution'), value: '441' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.serviceFunction24HourInstitution'), value: '442' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.holidayCenter'), value: '521' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.otherHolidayPurpose'), value: '529' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.swimmingPool'), value: '532' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.sportsHall'), value: '533' },
            { text: this.$t('orgAdmin.building.parameters.buildingFunction.commercialOptions.otherSportsPurpose'), value: '539' }
          ]
        }
      ],
      energyLabelOptions: [
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'C', value: 'C' },
        { text: 'D', value: 'D' },
        { text: 'E', value: 'E' },
        { text: 'F', value: 'F' },
        { text: 'G', value: 'G' }
      ],
      outerWallMaterialOptions: [
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.brick'), value: 1 },
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.fiberCementWithoutAsbestos'), value: 10 },
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.plasticMaterials'), value: 11 },
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.glass'), value: 12 },
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.lightweightConcreteStone'), value: 2 },
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.fiberCementWithAsbestos'), value: 3 },
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.bindingWork'), value: 4 },
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.wood'), value: 5 },
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.concreteElements'), value: 6 },
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.metal'), value: 8 },
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.none'), value: 80 },
        { text: this.$t('orgAdmin.building.parameters.outerWallMaterialOptions.otherMaterial'), value: 90 }
      ]
    }
  },
  created() {
    this.fetchBuildingParameters();
  },
  watch: {
    buildingId: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.resetForm(); // Reset form when buildingId changes
          this.fetchBuildingParameters(); // Fetch new parameters
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchBuildingParameters() {
      Vue.axios.get(`/admin/buildings/${this.buildingId}/parameters`)
        .then(response => {
          this.form = response.data;
          this.initialForm = { ...response.data }; // Store the initial form state
          this.parametersExist = true;
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            this.parametersExist = false;
            this.resetForm();
          } else {
            console.error('Error fetching building parameters:', error);
          }
        });
    },
    toggleEdit() {
      this.isEditing = true;
    },
    cancelEdit() {
      this.form = { ...this.initialForm }; // Revert the form state to the initial state
      this.isEditing = false;
    },
    onSubmit() {
      if (this.parametersExist) {
        Vue.axios.put(`/admin/buildings/${this.buildingId}/parameters`, this.form)
          .then(() => {
            this.isEditing = false;
            this.$bvToast.toast(this.$t('orgAdmin.building.parameters.updatedSuccessfully'), {
              title: this.$t('orgAdmin.building.parameters.success'),
              variant: 'success',
              solid: true
            });
            this.initialForm = { ...this.form }; // Update the initial form state
          })
          .catch(error => {
            console.error('Error updating building parameters:', error);
            this.$bvToast.toast(this.$t('orgAdmin.building.parameters.updateFailed'), {
              title: this.$t('orgAdmin.building.parameters.error'),
              variant: 'danger',
              solid: true
            });
          });
      } else {
        Vue.axios.post(`/admin/buildings/${this.buildingId}/parameters`, this.form)
          .then(() => {
            this.isEditing = false;
            this.parametersExist = true;
            this.$bvToast.toast(this.$t('orgAdmin.building.parameters.createdSuccessfully'), {
              title: this.$t('orgAdmin.building.parameters.success'),
              variant: 'success',
              solid: true
            });
            this.initialForm = { ...this.form }; // Update the initial form state
          })
          .catch(error => {
            console.error('Error creating building parameters:', error);
            this.$bvToast.toast(this.$t('orgAdmin.building.parameters.creationFailed'), {
              title: this.$t('orgAdmin.building.parameters.error'),
              variant: 'danger',
              solid: true
            });
          });
      }
    },
    confirmDelete() {
      this.$bvModal.msgBoxConfirm(this.$t('common.modal.confirmDeletion.text', { itemName: this.$t('orgAdmin.building.parameters.buildingParameters') }), {
        title: this.$t('common.modal.confirmDeletion.title'),
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: this.$t('common.modal.confirmDeletion.yes'),
        cancelTitle: this.$t('common.modal.confirmDeletion.no'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.onDelete();
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    onDelete() {
      Vue.axios.delete(`/admin/buildings/${this.buildingId}/parameters`)
        .then(() => {
          this.parametersExist = false;
          this.resetForm(); // Clear the form and reset states
          this.$bvToast.toast(this.$t('common.modal.deletion.success', { itemName: this.$t('orgAdmin.building.parameters.buildingParameters') }), {
            title: this.$t('orgAdmin.building.parameters.success'),
              variant: 'success',
              solid: true
            });
          })
          .catch(error => {
            console.error('Error deleting building parameters:', error);
            this.$bvToast.toast(this.$t('common.modal.deletion.error', { itemName: this.$t('orgAdmin.building.parameters.buildingParameters') }), {
              title: this.$t('orgAdmin.building.parameters.error'),
              variant: 'danger',
              solid: true
            });
          });
    },
    resetForm() {
      this.form = {
        ventilationType: '',
        heatingType: '',
        heatSource: '',
        grossFloorArea: null,
        netFloorArea: null,
        heatedFloorArea: null,
        structureMaterial: '',
        outerWallMaterial: '',
        constructionYear: null,
        lastRenovationYear: null,
        energyLabel: null,
        mainFunction: '',
        windowType: '',
        windowFrameMaterial: '',
        windowInstallationYear: null,
        airConditioning: false,
        solarPanels: false,
        windTurbine: false,
        geothermalHeating: false,
        batteryStorage: false
      };
      this.initialForm = {};
    },
    getExplanation(parameter) {
      return this.$t(`orgAdmin.building.parameters.explanations.${parameter}`);
    }
  }
}
</script>

<style scoped>
.parameters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 1400px;
  padding: 20px;
}

.form-column {
  display: flex;
}

.strong {
  font-weight: bold;
}

.p-0 {
  padding: 0;
}

.mr-2 {
  margin-right: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}
</style>
