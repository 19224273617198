import Vue from "vue";
import router from '@/router'
import * as commons from '@/commons'

// default state
const getDefaultState = () => {
    return {
        buildingShareLinks: [],
        shareLinkDetails: {
            entity: {},
            locations: [],
        }
    }
}

// initial state
const state = getDefaultState()

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('shareData/appInit')
    },

    fetchBuildingShareLinks({commit, state, dispatch, rootState}, id) {

        console.log('shareData/fetchBuildingShareLinks')

        // load user resource roles
        Vue.axios.get('/admin/buildings/' + id + '/share-links').then(response => {
            commit('SAVE_BUILDING_SHARE_LINKS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })

    },

    fetchBuildingLocations({commit, state, dispatch, rootState}, id) {

        console.log('shareData/fetchBuildingLocations')

        Vue.axios.get('/admin/buildings/' + id + '/locations').then(response => {
            commit('SAVE_SHARE_LINK_DETAILS_LOCATIONS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchShareLinkDetails({commit, state, dispatch, rootState}, id) {

        console.log('shareData/fetchShareLinkDetails')

        Vue.axios.get('/admin/share-links/' + id).then(response => {
            commit('SAVE_SHARE_LINK_DETAILS_ENTITY', response.data)

            dispatch("fetchBuildingLocations", state.shareLinkDetails.entity.building.id)

        }).catch(error => {
            commons.processRestError(error)
        })

        //if (state.buildingDetails.entity.id == null && router.currentRoute.query.buildingId) {
        if (router.currentRoute.query.buildingId) {
            Vue.axios.get('/admin/buildings/' + router.currentRoute.query.buildingId).then(response => {
                commit('SAVE_BUILDING_DETAILS_ENTITY', response.data)
            }).catch(error => {
                commons.processRestError(error)
            })
        }
    }

}


// getters
const getters = {}

// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['SAVE_BUILDING_SHARE_LINKS']: (state, data) => {
        state.buildingShareLinks = data
    },

    ['SAVE_SHARE_LINK_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.shareLinkDetails, 'entity', data)
    },

    ['SAVE_SHARE_LINK_DETAILS_LOCATIONS']: (state, data) => {
        Vue.set(state.shareLinkDetails, 'locations', data)
    }
}

export default {
    namespaced: true, state, getters, actions, mutations
}