<template>
  <div class="animated fadeInRight">

    <div class="row">

      <div class="col-xxxl-6">

        <div class="ibox">
          <div class="ibox-content p-5">

            <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
              <button class="close" type="button" @click="error = ''">×</button>
            </div>

            <validation-observer ref="form" v-slot="{ handleSubmit }">

              <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(save)">

                <div class="row">

                  <div class="col-12">

                    <b-form-group v-if="form.status" id="input-group-user-status" :label="$t('orgAdmin.user.attributes.status')" label-for="input-user-status" label-cols-sm="2">
                      <span class="label" :class="[form.status == 'BLOCKED' ? 'label-danger' : 'label-primary']">{{ $i18n.t('enums.UserStatus.' + form.status) }}</span>
                    </b-form-group>

                    <validation-provider vid="username" name="orgAdmin.user.attributes.username" :rules="{ required: true, alpha_dash: true, min: 3, max: 50 }" v-slot="validationContext">
                      <b-form-group id="input-group-user-username" :label="$t('orgAdmin.user.attributes.username')" label-for="input-user-username" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type!='create'"
                            id="input-user-username"
                            name="input-user-username"
                            v-model="form.username"
                            @input="form.username=$event.toLowerCase().trim()"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-user-username"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-user-username">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="firstName" name="orgAdmin.user.attributes.firstName" :rules="{ required: true, max: 50 }" v-slot="validationContext">
                      <b-form-group id="input-group-user-firstName" :label="$t('orgAdmin.user.attributes.firstName')" label-for="input-user-firstName" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-user-firstName"
                            name="input-user-firstName"
                            v-model="form.firstName"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-user-firstName"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-user-firstName">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="lastName" name="orgAdmin.user.attributes.lastName" :rules="{ required: true, max: 50 }" v-slot="validationContext">
                      <b-form-group id="input-group-user-lastName" :label="$t('orgAdmin.user.attributes.lastName')" label-for="input-user-lastName" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-user-lastName"
                            name="input-user-lastName"
                            v-model="form.lastName"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-user-lastName"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-user-lastName">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="email" name="orgAdmin.user.attributes.email" :rules="{ required: true, email: true, max: 100 }" v-slot="validationContext">
                      <b-form-group id="input-group-user-email" :label="$t('orgAdmin.user.attributes.email')" label-for="input-user-email" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type!='create'"
                            id="input-user-email"
                            name="input-user-email"
                            v-model="form.email"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-user-email"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-user-email">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="phone" name="orgAdmin.user.attributes.phone" :rules="{ max: 20 }" v-slot="validationContext">
                      <b-form-group id="input-group-user-phone" :label="$t('orgAdmin.user.attributes.phone')" label-for="input-user-phone" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-user-phone"
                            name="input-user-phone"
                            v-model="form.phone"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-user-phone"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-user-phone">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <b-form-group id="input-group-user-language" :label="$t('orgAdmin.user.attributes.language')" label-for="input-user-language" label-cols-sm="2">
                      <span class="badge badge-primary text-uppercase" v-if="type!='create'">{{ form.language }}</span>
                      <div class="pt-1" v-if="type=='create'">
                        <button type="button" class="btn btn-primary btn-outline btn-xs m-r-xs"
                                :class="{active:form.language=='en'}" @click.prevent="form.language='en'">EN
                        </button>
                        <button type="button" class="btn btn-primary btn-outline btn-xs m-r-xs"
                                :class="{active:form.language=='da'}" @click.prevent="form.language='da'">DA
                        </button>
                      </div>
                    </b-form-group>

                    <b-form-group v-if="form.resource" :label="$t('orgAdmin.registerUserRequest.attributes.resource')" label-for="input-user-resource" label-cols-sm="2">
                      <b-form-input
                          :plaintext="true"
                          id="input-user-resource"
                          :value="form.resource.name"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group v-if="form.resourceRole" :label="$t('orgAdmin.registerUserRequest.attributes.resourceRole')" label-for="input-user-resourceRole" label-cols-sm="2">
                      <b-form-input
                          :plaintext="true"
                          id="input-user-resourceRole"
                          :value="$t('enums.UserRole.' + form.resourceRole)"
                      ></b-form-input>
                    </b-form-group>

                  </div>

                </div>

                <div class="row" v-if="type=='create' && form.resource == null">
                  <div class="col-12">
                    <hr/>
                    <user-resource-role-assign :success-callback="selectResourceRoleCallback" :preselect-org="organisation"  />
                    <hr/>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <button  type="button" class="btn btn-sm btn-primary m-r-sm" v-if="type=='view' && hasRole('ADMIN')" @click.prevent="type='edit'">
                      <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.user.actions.edit') }}
                    </button>
                    <button type="submit" v-if="type=='create'" class="btn btn-sm btn-primary m-r-sm">{{ $t('common.actions.create') }}</button>
                    <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary m-r-sm">{{ $t('common.actions.save') }}</button>
                    <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-secondary m-r-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
                    <button type="button" class="btn btn-sm btn-white" @click="back">{{ $t('common.actions.back') }}</button>
                    <!--button type="button" v-if="type!='create'" class="btn btn-sm btn-danger float-right" @click="block">{{ $t('orgAdmin.user.actions.block') }}</button-->
                  </div>
                </div>

              </b-form>
            </validation-observer>

          </div>
        </div>

      </div>

      <div class="col-xxxl-6" v-if="user && type!='create'">
        <user-resources-list :user="user" />
      </div>

    </div>

  </div>
</template>

<script>
import {mapState} from "vuex"
import UserResourcesList from '@/components/views/userManagement/UserResourcesList.vue'
import UserResourceRoleAssign from "@/components/views/userManagement/UserResourceRoleAssign.vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue from "vue"
import * as commons from "@/commons"
import i18n from "@/i18n"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    UserResourcesList,
    UserResourceRoleAssign
  },
  data() {
    return {
      error: null,
      type: 'view',
      form: {
        id: null,
        status: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        username: '',
        language: '',
        resource: null,
        resourceRole: null
      }
    }
  },
  created(){
    console.log('userDetails $route.name: ' + this.$route.name)
    this.type = this.$route.name == 'userNew' ? 'create' : 'view'
    if(this.type == 'view') {
      this.$store.dispatch('userManagement/fetchUserDetails', this.$route.params.id)
    } else {
      this.$store.commit('userManagement/SAVE_USER_DETAILS_ENTITY', null)
      this.reset()
    }
  },
  computed: {
    ...mapState({
      user: state => state.userManagement.userDetails.entity
    }),
    organisation() {
      console.log('organisation() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.organisation.organisationDetails.entity : this.$store.state.contextOrg
    }
  },
  watch: {
    user: function(newVal, oldVal) { // watch it
      console.log('Prop user changed: ', newVal, ' | was: ', oldVal)
      this.reset()
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    reset() {

      this.error = ""
      this.info = ""

      if(this.$route.name == 'userNew'){
        this.form.resource = null
        this.form.resourceRole = null
        this.form.id = null
        this.form.firstName = ''
        this.form.lastName = ''
        this.form.email = ''
        this.form.phone = ''
        this.form.username = ''
        this.form.language = 'en'
      } else {
        this.form.id = this.user.id
        this.form.firstName = this.user.firstName
        this.form.lastName = this.user.lastName
        this.form.email = this.user.email
        this.form.phone = this.user.phone
        this.form.username = this.user.username
        this.form.language = this.user.settings ? this.user.settings.lang : 'en'
        this.form.status = this.user.status
        this.type = 'view'
      }
      if(this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    back(){
      this.goBack()
    },
    block(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.user.confirm.block', { '0': self.form.name }),
        callbackYes: function(){
          Vue.axios.put('/admin/users/' + self.form.id + '/block')
              .then(response => {
                self.$toastr.i(i18n.t('orgAdmin.user.info.blocked'))
                self.$store.dispatch('userManagement/fetchUserDetails', self.$route.params.id)
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
    selectResourceRoleCallback(data){
      this.form.resource = data.resource
      this.form.resourceRole = data.role
    },
    save() {

      this.error = ""
      this.info = ""

      let self = this
      if(this.$route.name == 'userNew') {

        if(this.form.resource == null){
          this.error = this.$t('orgAdmin.registerUserRequest.error.noResourceSelected')
          return
        }

        this.form.settings = { lang: this.form.language }
        this.form.resourceId = self.form.resource.id
        Vue.axios.post('/admin/users', JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          self.$toastr.i(i18n.t('orgAdmin.user.info.created'))
          self.$router.replace({
            name: 'userDetails',
            params: { id: response.data.id }
          })
          self.$store.dispatch('userManagement/fetchUserDetails', self.$route.params.id)
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      } else {
        Vue.axios.put('/admin/users/' + this.user.id, JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {
          self.$toastr.i(i18n.t('orgAdmin.user.info.saved'))
          self.$store.dispatch('userManagement/fetchUserDetails', self.$route.params.id)
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      }
    }
  }
}
</script>
