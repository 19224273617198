<template>
  <div class="widget yellow-bg cursor-pointer card-class location" @click="open">
    <div class="row m-b-sm">
      <div class="col-12">
        <span v-if="item.status !=='ACTIVE'" class="card-status badge badge-danger">{{ $t('enums.ResourceStatus.' + item.status) }}</span>
        <b>{{ $t('orgAdmin.location.name') }}<div class="hidden">{{ item.id }}</div></b>
      </div>
    </div>

    <div class="row m-b-sm">
      <div class="col-4">
        {{ $t('orgAdmin.location.attributes.name') }}:
      </div>
      <div class="col-8">
        <b>{{ item.name}}</b>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        {{ $t('orgAdmin.location.attributes.map') }}:
      </div>
      <div class="col-8">
        <b>{{ item.map.name}}</b>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    item: {
      type: Object
    },
  },
  data() {
    return {
      error: null
    }
  },
  computed: {
  },
  updated() {
  },
  methods: {
    open(){
      this.$router.push({
        path: `/admin/maps/${this.item.map.id}`,
      })
    },
  }
}
</script>
