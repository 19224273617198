// Import necessary dependencies
import Vue from "vue"
import * as commons from '@/commons'

// Default state
const getDefaultState = () => {
    return {
        selectedOrg: null,
        selectedForm: null,
        selectedLocation: null,
        selectedBuilding: null,
        feedbackConfig: {
            dateRange: {
                startDate: null,
                endDate: null
            }
        },
        availableForms: [],
        availableLocations: [],
        buildingDetails: {
                maps: []
        },
        generatedReports: []
    }
}

// Initial state
const state = getDefaultState()

// Actions
const actions = {

    appInit(){
        console.log("reporting/appInit");
    },

    // Load forms for the selected organization
    loadReportsFromServer({ commit }){
        if (state.selectedOrg) {
            const queryParams = {   "orgId": JSON.stringify(state.selectedOrg.id), 
                                    "buildingId": JSON.stringify(state.selectedBuilding.id) };
            Vue.axios.get('/building/reports/', { params: queryParams }).then(response => {
                commit('UPDATE_GENERATED_REPORTS', response.data)
            })
            .catch(error => {
                commons.processRestError("ERROR WITH REQUEST")
                commons.processRestError(error)
            })
        } else {
            console.log("No selected ORG to send!")
        }
    },

    // Fetch building details for the selected building
    fetchBuildingDetails({ commit }, buildingId){
        commit("UPDATE_BUILDING_DETAILS", { maps: [] });
        Vue.axios.get('/admin/buildings/' + buildingId, { params: { withMapImages: false } }).then(response => {
            commit('UPDATE_BUILDING_DETAILS', response.data)
        })
        .catch(error => {
            console.log('ERROR IN BUILDING DETAILS');
            console.log(error);
            commons.processRestError(error);
        })
    },

    requestReportGeneration({ commit }, config){
        const requestData = JSON.parse(JSON.stringify(config));
        return new Promise((resolve, reject) => {
            Vue.axios.post('/reports/create', requestData)
                .then((response) => resolve(response))
                .catch(error => {
                    console.log(error);
                    reject(error);
                })

        })
    },

    // Update the selected form
    updateSelectedOrg({ commit, }, org){
        commit('UPDATE_SELECTED_ORG', org)
    },

    // Update the selected location
    updateSelectedLocation({ commit }, location){
        commit('UPDATE_SELECTED_LOCATION', location)
    },

    // Update the selected location
    updateSelectedBuilding({ commit }, building){
        commit('UPDATE_SELECTED_BUILDING', building)
    },

}

// Mutations
const mutations = {
    // Update selected organization
    ['UPDATE_SELECTED_ORG']: (state, data) => {
        state.selectedOrg = data
    },
        
    // Update selected building
    ['UPDATE_SELECTED_BUILDING']: (state, data) => {
        state.selectedBuilding = data
    },

    // Update selected form
    ['UPDATE_GENERATED_REPORTS']: (state, data) => {
        state.generatedReports = data
    },

    // Update selected location
    ['UPDATE_SELECTED_LOCATION']: (state, data) => {
        state.selectedLocation = data
    },

    // Update building details
    ['UPDATE_BUILDING_DETAILS']: (state, data) => {
        state.buildingDetails = data
    },

    ['UPDATE_REPORT_CONFIG']: (state, data) => {
        state.reportConfig = data
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
