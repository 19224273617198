/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.8
 *
 *   rewritten as vue plugin
 */

export const initialize = function () {

	var body = $("body");
	//body.addClass("mini-navbar");

	// Add body-small class if window less than 768px
	if ($(window).width() < 769) {
		body.addClass('body-small')
	} else {
		body.removeClass('body-small')
	}

	// Minimalize menu when screen is less than 768px
	$(window).bind("resize", function () {
		if ($(this).width() < 769) {
			body.addClass('body-small')
		} else {
			body.removeClass('body-small')
		}
	});

	$(window).bind("load resize scroll", function () {
		// Full height of sidebar
		setTimeout(function () {
			if (!$("body").hasClass('body-small')) {
				// this.fix_height();
			}
		})

	});

	//intitialization for tooltipster plugin

	$('body').on('click', '.tooltip:not(.tooltipstered)', function () {
		$(this)
			.tooltipster({
				theme: 'tooltipster-punk',
				delay: 100,
				maxWidth: 500,
				speed: 300,
				interactive: true,
				side: 'left',
				animation: 'grow',
				trigger: 'click'
			})
			.tooltipster('open');
	});

	//initialization for vue-bootstrap-datetimepicker
	//Bootstrap v4 does not come with any icon fonts

	jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
		icons: {
			time: 'fa fa-clock-o',
			date: 'fa fa-calendar',
			up: 'fa fa-arrow-up',
			down: 'fa fa-arrow-down',
			previous: 'fa fa-chevron-left',
			next: 'fa fa-chevron-right',
			today: 'fa fa-calendar-check',
			clear: 'fa fa-trash',
			close: 'fa fa-times-circle-o'
		}
	});
}

export const localStorageSupport = function () {
	return (('localStorage' in window) && window['localStorage'] !== null)
}

export const SmoothlyMenu = function () {
	if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
		// Hide menu in order to smoothly turn on when maximize menu
		$('#side-menu').hide();
		// For smoothly turn on menu
		setTimeout(function () {
			$('#side-menu').fadeIn(400);
		}, 200);
	} else if ($('body').hasClass('fixed-sidebar')) {
		$('#side-menu').hide();
		setTimeout(function () {
			$('#side-menu').fadeIn(400);
		}, 100);
	} else {
		// Remove all inline style from jquery fadeIn function to reset menu
		// state
		$('#side-menu').removeAttr('style');
	}
}

export const fix_height = function () {

	var heightWithoutNavbar = $("body > #wrapper").height() - 62;
	$(".sidebar-panel").css("min-height", heightWithoutNavbar + "px");

	var navbarheight = $('nav.navbar-default').height();
	var wrapperHeight = $('#page-wrapper').height();

	if (navbarheight > wrapperHeight) {
		$('#page-wrapper').css("min-height", navbarheight + "px");
	}

	if (navbarheight < wrapperHeight) {
		$('#page-wrapper').css("min-height", $(window).height() + "px");
	}

	if ($('body').hasClass('fixed-nav')) {
		if (navbarheight > wrapperHeight) {
			$('#page-wrapper').css("min-height", navbarheight + "px");
		} else {
			$('#page-wrapper').css("min-height",
				$(window).height() - 60 + "px");
		}
	}
}

