import Vue from "vue"
import * as commons from '@/commons'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import moment from 'moment-timezone'

// default state
const getDefaultState = () => {
  return {
    selectedMap: null,
    selectedLocation: null,
    availableSensorAttributes: null,
    mapConfig: {
      activeFetchType: null,
      dateTime: null,
      selectedSensorAttributes: []
    },
    graphConfig: {
      fullSize: false,
      disableThresholds: false
    },
    graphData: null,
    polling: null
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  mapConfigDateTimeFormatted: state => {
    if(state.selectedMap && state.selectedMap.building.timeZone && state.mapConfig.activeFetchType=='live'){
      return moment.tz(new Date(), state.selectedMap.building.timeZone).format(i18n.t('dateTimeShortFormatMoment'))
    } if(state.mapConfig.activeFetchType=='datetime' && state.mapConfig.dateTime) {
      return moment(state.mapConfig.dateTime).format(i18n.t('dateTimeShortFormatMoment'))
    } else {
      return ''
    }
  }
}

// action
const actions = {

  appInit({ commit, state, dispatch, rootState }) {
    console.log('indoorClimate/appInit')
    dispatch('resetMapAndGraphConfig')
  },

  resetMapAndGraphConfig({ commit, state }, data) {
    return new Promise((resolve) => {
      console.log('indoorClimate/resetMapAndGraphConfig')
      commit('UPDATE_MAP_CONFIG', {
        activeFetchType: 'live',
        dateTime: null,
        selectedLocations: []
      })
      resolve()
    })
  },

  loadLocationFromRouteQueryParam({ commit, state, dispatch }){

    if (router.currentRoute.query.locationId) {
      if (state.selectedMap && state.selectedMap.locations && state.selectedMap.locations.some(location => location.id == router.currentRoute.query.locationId)) {
        commit('UPDATE_SELECTED_LOCATION', state.selectedMap.locations.find(location => location.id == router.currentRoute.query.locationId))
        dispatch('loadSelectedLocationSensorValues')
      } else {
        //load location from server
        Vue.axios.get('/locations/' + router.currentRoute.query.locationId).then(response => {
          dispatch('loadMapFromServer', response.data.map.id)
          commit('UPDATE_SELECTED_LOCATION', response.data)
          dispatch('loadSelectedLocationSensorValues')
        }).catch(error => {
          commons.processRestError(error)
        })
      }
    }else{
      commit('UPDATE_SELECTED_LOCATION', null)
    }
  },

  loadMapFromServer({ commit, state, dispatch }, mapId){
    //load map with locations and image
    Vue.axios.get('/maps/' + mapId).then(response => {
      commit('UPDATE_SELECTED_MAP', response.data)
      commit('UPDATE_MAP_CONFIG', {
        selectedLocations: []
      })
      dispatch('loadSelectedMapSensorValues').then(response => {
        commit('UPDATE_MAP_CONFIG', {
          selectedSensorAttributes: state.availableSensorAttributes
        })
      })
    }).catch(error => {
      commons.processRestError(error)
    })
  },

  loadSelectedMapSensorValues({ commit, state, dispatch, rootState }){

    console.log('loadSelectedMapSensorValues dateTime ' + state.mapConfig.dateTime)
    // get map sensor-values
    return new Promise((resolve, reject) => {

      let params = { duration : "PT30M"}
      if(state.mapConfig.dateTime){
        params.dateTimeTo = moment(state.mapConfig.dateTime).format('YYYY-MM-DDTHH:mm:ss')
      }
      params.pivot = true;
      params.activeDevices = true;

      Vue.axios.get('/maps/' + state.selectedMap.id + '/sensor-values', { params: params }).then(response => {
        commit('UPDATE_SELECTED_MAP_SENSOR_VALUES', response.data)
        commit('UPDATE_SELECTED_MAP_LOCATION_STATES')
        resolve(response)
      }).catch(error => {
        commons.processRestError(error)
        reject(error)
      })
    })
  },

  loadSelectedLocationSensorValues({ commit, state, dispatch, rootState }){

    let params = {
      duration: "P1D",
      aggregateWindowDuration: "PT15M",
      meanValue: true,
    }

    // get location sensor-values
    Vue.axios.get('/locations/' + state.selectedLocation.id + '/sensor-values', { params: params }).then(response => {
      commit('UPDATE_SELECTED_LOCATION_SENSOR_VALUES', response.data)
    }).catch(error => {
      commons.processRestError(error)
    })
  },

  mapConfigLiveStreamSelected({ commit, state, dispatch }){
    commit('UPDATE_MAP_CONFIG',  { dateTime:  null, selectedSensorAttributes: [] })
    dispatch('loadSelectedMapSensorValues').then(response => {
      commit('UPDATE_MAP_CONFIG',  { activeFetchType: 'live' })
    })
    dispatch('startPolling')
  },

  mapConfigDateTimeSelected({ commit, state, dispatch }, data){
    commit('UPDATE_MAP_CONFIG',  { dateTime:  data.dateTime,  selectedSensorAttributes: [] })
    dispatch('stopPolling')
    dispatch('loadSelectedMapSensorValues').then(response => {
      commit('UPDATE_MAP_CONFIG',  { activeFetchType: 'datetime' })
    })
  },

  mapConfigSensorAttributesChanged({ commit, state, dispatch }, data){
    commit('UPDATE_MAP_CONFIG',  { selectedSensorAttributes:  data })
    commit('UPDATE_SELECTED_MAP_LOCATION_STATES',  { selectedSensorAttributes:  data })
  },

  graphConfigGraphOptionsChanged({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  data)
  },

  selectedTemperatureChanged({ commit, state, dispatch }, data){
    let params = { devEUI: data.devEUI, temperature : data.temperature }
    Vue.axios.post('/locations/' + state.selectedLocation.id + '/change-temperature', params).then(response => {
      console.log("response: " + response);
    }).catch(error => {
      commons.processRestError(error)
    })
  },

  startPolling({ commit, state, dispatch }, data) {
    console.log('startPolling')
    if(!state.polling) {
      let handler = setInterval(() => {
        dispatch('loadSelectedMapSensorValues')
      }, 300000)
      commit('UPDATE_POLLING_HANDLER', handler)
    }
  },

  stopPolling({ commit, state, dispatch }, data) {
    console.log('stopPolling - handler: ' + state.polling)
    if (state.polling) {
      clearInterval(state.polling)
      commit('UPDATE_POLLING_HANDLER', null)
    }
  }

}


// mutations
const mutations = {

  ['GLOBAL_RESET']: (state, data) => {
    Object.assign(state, getDefaultState())
  },

  ['UPDATE_SELECTED_MAP']: (state, data) => {
    state.selectedMap = data
  },

  ['UPDATE_SELECTED_MAP_SENSOR_VALUES']: (state, data) => {
    processSelectedMapSensorData(state, data)
  },

  ['UPDATE_SELECTED_MAP_LOCATION_STATES']: (state, data) => {
    processSelectedMapLocations()
    Vue.set(state.selectedMap, 'locations',  [...state.selectedMap.locations])
  },

  ['UPDATE_MAP_CONFIG']: (state, data) => {
    state.mapConfig = Object.assign({}, state.mapConfig, data)
  },

  ['UPDATE_SELECTED_LOCATION']: (state, data) => {
    state.selectedLocation = data
  },

  ['UPDATE_SELECTED_LOCATION_SENSOR_VALUES']: (state, data) => {
    state.graphData = commons.processSensorData(data)
  },

  ['UPDATE_GRAPH_CONFIG']: (state, data) => {
    state.graphConfig = Object.assign({}, state.graphConfig, data)
  },

  ['UPDATE_POLLING_HANDLER']: (state, data) => {
    state.polling = data
  },
}

function processSelectedMapSensorData(state, sensorValues) {
  console.log('processSelectedMapSensorData')

  // determine available sensor attributes and sort them
  state.availableSensorAttributes = state.mapConfig.selectedSensorAttributes = commons.getAvailableSensorAttributesFromSensorData(sensorValues)

  // parse all sensor values and set them to map locations
  if (sensorValues && state.selectedMap) {
    state.selectedMap.locations.forEach(location => {
      // clear current data
      location.sensorValues = []
      location.sensorValuesAvgMap = new Map()
      sensorValues.forEach(sensorValue => {
        if (sensorValue.locationId && location.id == sensorValue.locationId) {
          location.sensorValues.push(sensorValue)
        }
      })
    })
  }
}

function processSelectedMapLocations(){
  console.log('processSelectedMapLocations')

  // calculate average values for each location
  state.selectedMap.locations.forEach(location => {
    let sensorValuesMap = new Map()
    let sensorValuesAvgMap = new Map()

    if(location.sensorValues && location.sensorValues.length > 0) {

      sensorValuesMap = commons.processSensorData(location.sensorValues)

      //TODO: avg or only last value?
      sensorValuesMap.forEach((valueArray, field) => {
        if (['temperature', 'humidity', 'co2', 'barometric_pressure', 'noise', 'voc', 'tvoc', 'light', 'light_level', 'people_counter_total', 'region_count_total', 'motion'].includes(field)) {
          const sum = valueArray.reduce((a, b) => a + parseFloat(b.value), 0)
          const avg = sum / valueArray.length
          const state = calculateSensorState(location, field, avg)
          sensorValuesAvgMap.set(field, [avg, state])
        } else if (['closed', 'target_temperature'].includes(field)) {
          sensorValuesAvgMap.set(field, valueArray)
        } else if (['door_closed'].includes(field)) {
          sensorValuesAvgMap.set(field, valueArray)
        } else if (['active_devices'].includes(field)) {
          const val = valueArray[0]["value"]
          const state = calculateSensorState(location, field, val)
          sensorValuesAvgMap.set(field, [val, state])
        }
      })

      location.sensorValuesAvgMap = sensorValuesAvgMap
      location.state = calculateLocationState(location, true)

      // PRESENCE
      if(state.mapConfig.selectedSensorAttributes.includes('motion')) {
        location.presence = location.sensorValuesAvgMap.get('motion') && location.sensorValuesAvgMap.get('motion')[0] > 0
        location.showPresence = true
      } else {
        location.showPresence = false
      }

      // WINDOWS
      if(state.mapConfig.selectedSensorAttributes.includes('closed') && location.sensorValuesAvgMap.get('closed')) {
        const windowMap = new Map()

        location.sensorValues.forEach(sensorValue => {
          let devEUI = sensorValue.devEUI
          if (sensorValue.field === "closed") {
              windowMap.set(devEUI, sensorValue.value)
          }
        })

        location.showWindow = true
        location.windowMap = windowMap
        location.window = Array.from(location.windowMap.values()).filter(item => item == "false" || item === "0.0").length > 0
      } else {
        location.showWindow = false
      }

      // DOORS
      if (state.mapConfig.selectedSensorAttributes.includes('door_closed') && location.sensorValuesAvgMap.get('door_closed')) {
        const doorMap = new Map()

        location.sensorValues.forEach(sensorValue => {
          let devEUI = sensorValue.devEUI
          if (sensorValue.field === "door_closed") {
            doorMap.set(devEUI, sensorValue.value)
          }
        })
        
        location.showDoor = true
        location.doorMap = doorMap      
        location.door = Array.from(location.doorMap.values()).filter(item => item === "0.0").length > 0;
      } else {
        location.showDoor = false
      }

      // THERMOSTAT
      if (location.sensorValuesAvgMap.get('target_temperature')) {
        // location.targetTemperature = parseInt(location.sensorValuesAvgMap.get('target_temperature').pop())
        // location.showThermostat = true

        const trvMap = new Map()

        let sensorValuesSorted = location.sensorValues.sort(function(a,b){
          return new Date(b.time) - new Date(a.time);
        });

        sensorValuesSorted.reverse().forEach(sensorValue => {
          let devEUI = sensorValue.devEUI
          if (sensorValue.field === "target_temperature") {
            trvMap.set(devEUI, sensorValue.value)
          }
        })
        location.trvMap = trvMap
        location.showThermostat = true
      } else {
        location.showThermostat = false
      }
    } else {
      location.state = 'grey'
    }
  })
}

function calculateLocationState(location, checkSelectedSensorAttributes) {

  let hasRed, hasYellow, hasGreen = false

  let profileParameters = location.locationProfile ? location.locationProfile.parameters : store.state.systemParams.defaultLocationProfileParams

  if (location.hasOwnProperty('sensorValuesAvgMap')) {
    location.sensorValuesAvgMap.forEach((valueArray, field) => {

      //if field not in selected sensor attributes skip it

      if(checkSelectedSensorAttributes === false || state.mapConfig.selectedSensorAttributes.includes(field)) {

        let value = valueArray[0]

        if (field == 'temperature' || field == 'humidity') {
          if (value < profileParameters[field][0] || value > profileParameters[field][3]) {
            hasRed = true
            return
          } else if ((value >= profileParameters[field][0] && value <= profileParameters[field][1])
              || (value >= profileParameters[field][2] && value <= profileParameters[field][3])) {
            hasYellow = true
          } else {
            hasGreen = true
          }
        } else if (field == 'co2' || field == 'noise') {
          if (value < profileParameters[field][0]) {
            hasGreen = true
          } else if (value >= profileParameters[field][0] && value <= profileParameters[field][1]) {
            hasYellow = true
          } else {
            hasRed = true
            return
          }
        }
      }

    })
  }
  return hasRed ? "red" : (hasYellow ? "yellow" : (hasGreen ? "green" : "grey"))
}

function calculateSensorState(location, field, value) {

  let color = 'black'
  let profileParameters = location.locationProfile ? location.locationProfile.parameters : store.state.systemParams.defaultLocationProfileParams

  if(field == 'temperature' || field == 'humidity'){
    if(value < profileParameters[field][0] || value > profileParameters[field][3]){
      color = 'red'
    } else if ((value >= profileParameters[field][0] && value <= profileParameters[field][1])
        || (value >= profileParameters[field][2] && value <= profileParameters[field][3])){
      color = 'yellow'
    } else {
      color = 'green'
    }
  } else if(field == 'co2' || field == 'noise'){
    if(value < profileParameters[field][0]){
      color = 'green'
    } else if (value >= profileParameters[field][0] && value <= profileParameters[field][1]){
      color = 'yellow'
    } else {
      color = 'red'
    }
  } else if (field == 'active_devices') {
    if (value[0] == "0") {
      color = "red"
    } else if (value[0] == value[2]) {
      color = "green"
    } else {
      color = "yellow"
    }
  }
  return color
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
