<template>
  <div>

    <div class="alert alert-info" v-if="info">{{ info }}</div>

    <validation-observer ref="form" v-slot="{ handleSubmit }">

      <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(submit)">

        <div class="row">

          <div class="col-12">

            <b-form-group v-if="organisation && organisation.status" id="input-group-user-status" :label="$t('orgAdmin.user.attributes.status')" label-for="input-resource-status"  label-cols-sm="4">
              <span class="label" :class="[(organisation.status == 'BLOCKED' || organisation.status == 'PARENT_BLOCKED') ? 'label-danger' : 'label-primary']">{{ $i18n.t('enums.ResourceStatus.' + organisation.status) }}</span>
            </b-form-group>

            <validation-provider name="orgAdmin.organisation.attributes.name" :rules="{ required: true, min: 3, max: 50 }" v-slot="validationContext">
              <b-form-group id="input-group-org-name" :label="$t('orgAdmin.organisation.attributes.name')" label-for="input-org-name" label-cols-sm="4">
                <b-form-input
                    :plaintext="type=='view'"
                    id="input-org-name"
                    name="input-org-name"
                    v-model="form.name"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-feedback-org-name"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-org-name">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider vid="registrationCode" name="orgAdmin.organisation.attributes.registrationCode" :rules="{ required: true, min: 3, max: 12 }" v-slot="validationContext">
              <b-form-group id="input-group-org-name" :label="$t('orgAdmin.organisation.attributes.registrationCode')" label-for="input-org-registrationCode" label-cols-sm="4">
                <b-input-group>
                  <b-form-input
                      class="d-inline"
                      :plaintext="type=='view'"
                      id="input-org-registrationCode"
                      name="input-org-registrationCode"
                      v-model="form.registrationCode"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-feedback-org-registrationCode"
                  ></b-form-input>
                  <b-input-group-append>
                    <button type="button" v-if="type!='view'" class="btn btn-sm btn-primary" @click="generateCode"><i class="fa fa-random"></i> {{ $t('orgAdmin.organisation.actions.generateRegistrationCode') }}</button>
                  </b-input-group-append>
                  <b-form-invalid-feedback id="input-feedback-org-registrationCode">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.organisation.attributes.address" :rules="{ required: true, max: 50 }" v-slot="validationContext">
              <b-form-group id="input-group-org-name" :label="$t('orgAdmin.organisation.attributes.address')" label-for="input-org-address" label-cols-sm="4">
                <b-form-input
                    :plaintext="type=='view'"
                    id="input-org-address"
                    name="input-org-address"
                    v-model="form.address"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-feedback-org-address"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-org-address">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.organisation.attributes.address2" :rules="{ max: 100 }" v-slot="validationContext">
              <b-form-group id="input-group-org-name" :label="$t('orgAdmin.organisation.attributes.address2')" label-for="input-org-address2" label-cols-sm="4">
                <b-form-input
                    :plaintext="type=='view'"
                    id="input-org-address2"
                    name="input-org-address2"
                    v-model="form.address2"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-feedback-org-address2"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-org-address2">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.organisation.attributes.city" :rules="{ required: true, max: 100 }" v-slot="validationContext">
              <b-form-group id="input-group-org-name" :label="$t('orgAdmin.organisation.attributes.city')" label-for="input-org-city" label-cols-sm="4">
                <b-form-input
                    :plaintext="type=='view'"
                    id="input-org-city"
                    name="input-org-city"
                    v-model="form.city"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-feedback-org-city"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-org-city">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.organisation.attributes.zipCode" :rules="{ required: true, max: 10 }" v-slot="validationContext">
              <b-form-group id="input-group-org-name" :label="$t('orgAdmin.organisation.attributes.zipCode')" label-for="input-org-zipCode" label-cols-sm="4">
                <b-form-input
                    :plaintext="type=='view'"
                    id="input-org-zipCode"
                    name="input-org-zipCode"
                    v-model="form.zipCode"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-feedback-org-zipCode"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-org-zipCode">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.organisation.attributes.country" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group id="input-group-org-country"
                            :label="$t('orgAdmin.organisation.attributes.country')"
                            label-for="input-org-country"
                            label-cols-sm="4"
                            :state="getValidationState(validationContext)">
                <b-form-input :plaintext="true" v-if="type=='view'" :value="countryValueLabel" />
                <v-select
                    v-if="type!='view'"
                    id="input-org-country"
                    name="input-org-country"
                    v-model="form.country"
                    :options="$t('enums.countries')"
                    :reduce="type => type.code"
                    label="name"
                    :clearable="true"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </div>

        </div>

        <div class="row">
          <div class="col-12">
            <button  type="button" class="btn btn-sm btn-primary" v-if="canEdit && type=='view' && organisation && organisation.status=='ACTIVE'" @click.prevent="type='edit'">
              <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.organisation.actions.edit') }}
            </button>
            <button type="submit" v-if="type=='create'" class="btn btn-sm btn-primary">{{ $t('common.actions.create') }}</button>
            <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary">{{ $t('common.actions.save') }}</button>
            <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-secondary m-l-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
            <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-white m-l-sm" @click="cancel">{{ $t('common.actions.cancel') }}</button>
            <button type="button" v-if="type=='edit' && organisation && organisation.status=='ACTIVE'" class="btn btn-sm btn-danger float-right" @click="blockOrganisation">{{ $t('common.actions.block') }}</button>
            <button type="button" v-if="type!='create' && organisation && organisation.status=='BLOCKED'" class="btn btn-sm btn-danger float-right" @click="unblockOrganisation">{{ $t('common.actions.unblock') }}</button>
          </div>
        </div>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue from "vue"
import * as commons from "@/commons"
import i18n from "@/i18n";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    organisation: {
      type: Object
    },
    canEdit: {
      type: Boolean
    },
    initialType: {
      validator: function (value) {
        return ['view', 'edit', 'create'].includes(value)
      }
    },
    successCallback: {
      type: Function
    },
    cancelCallback: {
      type: Function
    }
  },
  data() {
    return {
      error: null,
      info: null,
      type: this.initialType,
      form: {
        name: '',
        address: '',
        address2: '',
        zipCode: '',
        city: '',
        country: '',
        registrationCode: ''
      },
    }
  },
  watch: {
    organisation: function(newVal, oldVal) { // watch it
      console.log('Prop organisation changed: ', newVal, ' | was: ', oldVal)
      this.reset()
    }
  },
  computed: {
    countryValueLabel(){
      let value = i18n.t('enums.countries').filter(country => { return country.code == this.form.country })
      return value[0] ? value[0].name : ''
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    generateCode() {
      let codeLength = this.getConfigParamValue('registrationCode.length', 6)
      let codeCharacters = this.getConfigParamValue('registrationCode.characters', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789')
      let result = ''
      let charactersLength = codeCharacters.length;
      for (let i = 0; i < codeLength; i++) {
        result += codeCharacters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.form.registrationCode = result
    },
    reset() {
      if(this.organisation){
        this.form = Object.assign({}, this.organisation)
      } else {
        this.form.name = ''
        this.form.address = ''
        this.form.address2 = ''
        this.form.zipCode = ''
        this.form.city = ''
        this.form.country = ''
        this.form.registrationCode = ''
      }
      this.$refs.form.reset()
    },
    cancel(){
      this.reset()
      this.type = this.initialType
      if(this.cancelCallback){
        this.cancelCallback()
      }
    },
    blockOrganisation(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.organisation.confirm.block', { '0': self.form.name }),
        callbackYes: function(){
          Vue.axios.put('/admin/organisations/' + self.form.id + '/block')
              .then(response => {
                self.reset()
                self.type = self.initialType
                self.$toastr.i(i18n.t('orgAdmin.organisation.info.blocked', self.form.name))
                if (self.successCallback) {
                  self.successCallback()
                }
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
    unblockOrganisation(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.organisation.confirm.unblock', { '0': self.form.name }),
        callbackYes: function(){
          Vue.axios.put('/admin/organisations/' + self.form.id + '/unblock')
              .then(response => {
                self.reset()
                self.type = self.initialType
                self.$toastr.i(i18n.t('orgAdmin.organisation.info.unblocked', self.form.name))
                if (self.successCallback) {
                  self.successCallback()
                }
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
    submit() {
      this.error = ""
      this.info = ""
      let vm = this
      if(this.type=="create") {
        Vue.axios.post('/admin/organisations', JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          vm.reset()
          vm.type = vm.initialType
          vm.$toastr.i(i18n.t('orgAdmin.organisation.info.created'))

          if (vm.successCallback) {
            vm.successCallback()
          }
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      } else if(this.type=="edit") {
        Vue.axios.put('/admin/organisations/' + this.form.id, JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          vm.reset()
          vm.type = vm.initialType
          vm.$toastr.i(i18n.t('orgAdmin.organisation.info.saved'))

          if (vm.successCallback) {
            vm.successCallback()
          }
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      }
    }
  }
}
</script>