<template>
  <div>
    <div>
      <l-map id="leafletMap"
             ref="map"
             :options="mapOptions"
             @ready="onLeafletReady"
             v-show="showMap"
             :maxZoom="maxZoom"
             :minZoom="minZoom">
        <template v-if="leafletReady">
          <l-tile-layer :url="url"></l-tile-layer>
          <l-marker v-for="(m,i) in markers" :key="i" :lat-lng="[m.lat, m.lng]" @click="markerClicked(m)">
            <l-tooltip :options="{ permanent: false, interactive: true }">
              <div>
                {{ m.label }}
              </div>
            </l-tooltip>
          </l-marker>
        </template>
      </l-map>
    </div>
    <div class="alert alert-info" v-if="info">{{ info }}</div>
    <div class="alert alert-warning" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import eventBus from "@/eventBus"
import { LatLngBounds } from "leaflet"
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet"

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  props: {
    markers: {
      type: Array
    },
    demo: {
      type: Boolean
    }
  },
  data() {
    return {
      info: this.$i18n.t('geoLocation.unknown'),
      error: null,
      leafletReady: false,
      showMap: false,
      mapObject: null,
      mapOptions: {
        attributionControl: true,
        zoomSnap: 0.1,
        closePopupOnClick: false
      },
      zoom: 7,
      maxZoom: 17,
      minZoom: 4,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      markersToDraw: [],
      testMarkers: [{
          lat: 21.1594627,
          lng: 72.6822083,
          label: 'Surat'
        },
        {
          lat: 23.0204978,
          lng: 72.4396548,
          label: 'Ahmedabad'
        },
        {
          lat: 22.2736308,
          lng: 70.7512555,
          label: 'Rajkot'
        }]
    }
  },
  created(){
    eventBus.$on("refresh-geolocation", this.parseMarkers);
  },
  destroyed() {
    eventBus.$off("refresh-geolocation", this.parseMarkers);
  },
  watch: {
    markers: function(newVal, oldVal) { // watch it
      if(!this.demo && newVal !== null) {
        //console.log('Prop markers changed: ', JSON.stringify(newVal), ' | was: ', JSON.stringify(oldVal))
        this.parseMarkers()
      }
    }
  },
  methods: {

    async onLeafletReady(_map) {
      console.log('onLeafletReady start ...')
      await this.$nextTick()
      this.mapObject = this.$refs.map.mapObject
      this.leafletReady = true
      //console.log('onLeafletReady end ...')
    },

    parseMarkers() {

      //console.log("(GeoLocationLeaflet) parseMarkers prop: " + JSON.stringify(this.markers))
      console.log("(GeoLocationLeaflet) parseMarkers")

      this.showMap = false
      this.info = this.$i18n.t('geoLocation.unknown')
      this.error = null

      // work as expected
      this.markersToDraw = this.demo ? this.testMarkers : this.markers

      if(this.markersToDraw && this.markersToDraw.length > 0) {

        this.showMap = true

        this.$nextTick(() => {

          this.mapObject.invalidateSize(true)

          let bounds = new LatLngBounds()
          this.markersToDraw.forEach(marker => {
            bounds.extend([ marker.lat, marker.lng ])
          })

          this.mapObject.fitBounds(bounds, { padding: [100, 100], animate: true  })
          console.log('Center ' + this.mapObject.getCenter() + ', zoom ' + this.mapObject.getZoom())
        })

        this.info = null
      }
    },
    markerClicked(marker){
      console.log('markerClicked ' + marker)
      if(marker.selectCallback) {
        marker.selectCallback()
      }
    }

  }
}
</script>

<style scoped>
  #leafletMap {
    width: 100%;
    height: 400px;
  }
</style>

