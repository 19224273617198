import Vue from "vue";
import * as commons from '@/commons';

const getDefaultState = () => ({
  logs: [],
  selectedLog: null,
  buildingDetails: {},
  orgBuildings: [],
  maps: {},
  buildingMaps: {}, // This will map buildingId to an array of mapIds
  meteringPoints: [],
  lastOrgId: null,
  lastBuildingId: null,
  lastFetchTime: null,
  backRoute: null
});

const state = getDefaultState();

const getters = {
  areMapsLoaded: (state) => (buildingId) => !!state.buildingMaps[buildingId],
  getBuildingDetails: (state) => (buildingId) => state.buildingDetails[buildingId],
  getMapDetails: (state) => (mapId) => state.maps[mapId],
  getMapsByBuildingId: (state) => (buildingId) => {
    const mapIds = state.buildingMaps[buildingId] || [];
    return mapIds.map(mapId => state.maps[mapId]);
  },
};

const actions = {
  appInit() {
    console.log("buildingLogs/appInit");
  },

  loadLogsForOrg({ commit, state }, orgId) {
    Vue.axios.get(`/organisation/${orgId}/logs`)
      .then(response => {
        commit('SET_LOGS', response.data);
        commit('SET_LAST_ORG_ID', orgId);
      })
      .catch(error => {
        commons.processRestError(error);
        console.error("Error loading logs for org:", error);
      });
  },

  loadLogsForBuilding({ commit, state }, buildingId) {
    if (buildingId && state.lastBuildingId !== buildingId) {
      Vue.axios.get(`/building/${buildingId}/logs`)
        .then(response => {
          commit('SET_LOGS', response.data);
          commit('SET_LAST_BUILDING_ID', buildingId);
        })
        .catch(error => {
          commons.processRestError(error);
          console.error("Error loading logs for building:", error);
        });
    }
  },

  loadBuildingDetails({ commit, state, dispatch }, buildingId) {
    const currentTime = Date.now();
    const shouldFetch = !state.buildingDetails[buildingId] || (currentTime - state.lastFetchTime > 300000);
    
    if (shouldFetch && buildingId) {
      Vue.axios.get(`/admin/buildings/${buildingId}`)
        .then(response => {
          const buildingData = response.data;
          commit('SET_BUILDING_DETAILS', buildingData);
          commit('SET_LAST_FETCH_TIME', currentTime);

          if (buildingData.maps) {
            commit('SET_BUILDING_MAPS', { buildingId, mapIds: buildingData.maps.map(map => map.id) });
            buildingData.maps.forEach(map => {
              dispatch('loadMapDetails', map.id);
            });
          }
        })
        .catch(error => {
          commons.processRestError(error);
          console.error("Error loading building details:", error);
        });
    }
  },

  loadOrgDetails({ commit, dispatch }, orgId) {
    Vue.axios.get(`/admin/organisations/${orgId}`)
      .then(response => {
        const buildings = response.data.buildings;
        commit('SET_ORG_BUILDINGS', buildings);
        buildings.forEach(building => {
          dispatch('loadBuildingDetails', building.id);
        });
      })
      .catch(error => {
        commons.processRestError(error);
        console.error("Error loading organization details:", error);
      });
  },

  setBackRoute({ commit, dispatch }, path) {
    console.log("SETTING BACK ROUTE...")
    commit('SET_BACK_ROUTE', path);
  },

  loadMapDetails({ commit, state }, mapId) {
    if (!state.maps[mapId]) {
      Vue.axios.get(`/admin/maps/${mapId}`)
        .then(response => {
          commit('ADD_MAP', response.data);
        })
        .catch(error => {
          commons.processRestError(error);
          console.error("Error loading map details:", error);
        });
    }
  },

  addLog({ commit }, log) {
    return Vue.axios.post(`/building/logs`, log)
      .then(response => {
        commit('ADD_LOG', response.data);
      })
      .catch(error => {
        commons.processRestError(error);
        console.error("Error adding log:", error);
        throw error;
      });
  },

  updateLog({ commit }, log) {
    return Vue.axios.put(`/building/logs/${log.id}`, log)
      .then(response => {
        commit('UPDATE_LOG', response.data);
      })
      .catch(error => {
        commons.processRestError(error);
        console.error("Error updating log:", error);
        throw error;
      });
  },

  deleteLog({ commit }, { buildingId, logId }) {
    return Vue.axios.delete(`/building/${buildingId}/logs/${logId}`)
      .then(() => {
        commit('DELETE_LOG', logId);
      })
      .catch(error => {
        commons.processRestError(error);
        console.error("Error deleting log:", error);
      });
  },

  fetchMeteringPoints({ commit }, buildingId) {
    return Vue.axios.get(`/admin/buildings/${buildingId}/metering-points`)
      .then(response => {
        commit('SET_METERING_POINTS', response.data);
      })
      .catch(error => {
        console.error("Error fetching metering points:", error);
      });
  },

  fetchLogById({ commit, state }, { buildingId, logId }) {
    if (!buildingId || !logId) return Promise.resolve(null);
    if (state.selectedLog?.id === logId) {
      return Promise.resolve(state.selectedLog);
    }
    return Vue.axios.get(`/building/${buildingId}/logs/${logId}`)
      .then(response => {
        commit('SET_SELECTED_LOG', response.data);
        return response.data;
      })
      .catch(error => {
        commons.processRestError(error);
        console.error("Error fetching log by ID:", error);
        return null;
      });
  },

  clearLogs({ commit }) {
    commit('CLEAR_LOGS');
  },

  clearSelectedLog({ commit }) {
    commit('CLEAR_SELECTED_LOG');
  },

  clearMaps({ commit }) {
    commit('CLEAR_MAPS');
  }
};

const mutations = {
  SET_LOGS(state, logs) {
    state.logs = logs;
  },

  ADD_LOG(state, log) {
    state.logs.push(log);
  },

  UPDATE_LOG(state, updatedLog) {
    const index = state.logs.findIndex(log => log.id === updatedLog.id);
    if (index !== -1) {
      Vue.set(state.logs, index, updatedLog);
    }
  },

  DELETE_LOG(state, logId) {
    state.logs = state.logs.filter(log => log.id !== logId);
  },

  SET_BUILDING_DETAILS(state, building) {
    Vue.set(state.buildingDetails, building.id, building);
  },

  SET_ORG_BUILDINGS(state, buildings) {
    state.orgBuildings = buildings;
  },

  ADD_MAP(state, map) {
    Vue.set(state.maps, map.id, map);
  },

  SET_BUILDING_MAPS(state, { buildingId, mapIds }) {
    Vue.set(state.buildingMaps, buildingId, mapIds);
  },

  SET_METERING_POINTS(state, meteringPoints) {
    state.meteringPoints = meteringPoints;
  },

  SET_SELECTED_LOG(state, log) {
    state.selectedLog = log;
  },

  SET_LAST_ORG_ID(state, orgId) {
    state.lastOrgId = orgId;
  },

  SET_LAST_BUILDING_ID(state, buildingId) {
    state.lastBuildingId = buildingId;
  },

  SET_LAST_FETCH_TIME(state, fetchTime) {
    state.lastFetchTime = fetchTime;
  },

  CLEAR_LOGS(state) {
    state.logs = [];
  },

  CLEAR_SELECTED_LOG(state) {
    state.selectedLog = null;
  },

  CLEAR_MAPS(state) {
    state.maps = {};
    state.buildingMaps = {};
  },

  SET_BACK_ROUTE(state, path) {
    state.backRoute = path;
  },

  CLEAR_BACK_ROUTE(state) {
    state.backRoute = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
