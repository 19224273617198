<template>
  <div class="ibox">
    <div class="ibox-title">
      <h5>{{ title }}</h5>
    </div>
    <div class="ibox-content p-4">

      <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
        <button class="close" type="button" @click="error = ''">×</button>
      </div>

      <div class="form-group row m-b-md" v-if="resourceRoles.length !== 0">
        <div class="col-lg-6">
          <div class="input-group">
                <span class="input-group-prepend">
                  <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length === 0" @click="clearFilter"> {{
                      $t('common.actions.clear')
                    }}</b-button>
                </span>
            <b-form-input type="text"
                          v-model="table.filter"
                          debounce="300"
                          max="50"
                          :placeholder="$t('enterForSearch')"
                          class="form-control form-control-sm"/>
          </div>
        </div>
        <div class="col-lg-3 m-t-sm text-right">
          <row-count :size="table.size" :page="table.page" :total="resourceRoles.length"></row-count>
        </div>
        <div class="col-lg-3 text-right" v-if="resourceRoles.length && resourceRoles.length > table.size">
          <b-pagination v-if="resourceRoles.length" size="sm" class="m-t-xs" align="right"
                        :total-rows="resourceRoles.length"
                        v-model="table.page"
                        :per-page="table.size"></b-pagination>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <b-table responsive hover striped
                   v-if="resourceRoles && resourceRoles.length != 0"
                   class="text-nowrap"
                   :fields="table.fields"
                   :filter="table.filter"
                   :current-page="table.page"
                   :per-page="table.size"
                   :items="resourceRoles"
                   :sort-by.sync="table.sortBy"
                   :sort-desc.sync="table.sortDesc">
            <template #cell(actions)="data">
              <button class="btn btn-xs btn-default m-l-xs" @click="deleteItem(data.item)">
                <i class="fa fa-trash"></i>
              </button>
            </template>
            <template #cell(resource)="data">
              {{ data.value }}
            </template>
            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">{{ data.value }}</template>
          </b-table>
          <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

          <div class="col-lg-12" v-if="!addNewOpened">
            <button class="btn btn-sm btn-primary mr-1" @click="addNewOpened = true">
              <i class="fa fa-plus"></i>&nbsp;{{ $t('orgAdmin.userResource.actions.new') }}
            </button>
          </div>
          <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated" appear>
            <div class="col-12" v-if="addNewOpened">
              <user-resource-role-assign :user="user" :success-callback="addNewSuccessCallback" :cancel-callback="addNewCancelCallback"  :preselect-org="organisation" />
            </div>
          </transition>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n'
import UserResourceRoleAssign from "@/components/views/userManagement/UserResourceRoleAssign.vue"
import Vue from "vue"
import * as commons from "@/commons"
import RowCount from "@/components/common/RowCount.vue";

export default {
  components: {
    RowCount,
    UserResourceRoleAssign
  },
  props: {
    title: {
      required: false,
      default: i18n.t('orgAdmin.userResource.title')
    },
    user: {
      type: Object
    }
  },
  data() {
    return {
      error: null,
      addNewOpened: false,
      table: {
        filter: '',
        sortBy: 'name',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50},
          {'value': 100}
        ],
        fields: [
          {
            key: 'authorizationTarget.role',
            label: this.$i18n.t('orgAdmin.userResource.attributes.role'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return i18n.t('enums.UserRole.' + item.role)
            }
          },
          {
            key: 'resource',
            label: this.$i18n.t('orgAdmin.userResource.attributes.resourceName'),
            formatter: (value, key, item) => {
              let resource;
              const atp = item.authorizationTargetPath;
              if(atp.organisation){
                resource = atp.organisation.name
              }
              if (atp.building) {
                resource += " / " + atp.building.name
              }
              if (atp.map) {
                resource += " / " + atp.map.name
              }
              if (atp.location) {
                resource += " / " + atp.location.name
              }
              return resource;
            },
            sortable: true,
          },
          {
            key: 'actions',
            label: '',
            sortable: false,
            class: ["text-center"]
          }
        ]
      }
    }
  },
  computed: {
    organisation() {
      console.log('organisation() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.organisation.organisationDetails.entity : this.$store.state.contextOrg
    },
    resourceRoles(){
      return this.user.authorities
    }
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    },
    deleteItem(item){
      this.error = null
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.userResource.confirm.delete', { '0': i18n.t('enums.UserRole.' + item.role) + ' - ' + item.authorizationTarget.name }),
        callbackYes: function(){
          Vue.axios.delete('/admin/user-resource-role', { data: { userId: self.user.id, resourceId: item.authorizationTarget.id, role: item.role } })
              .then(response => {
                self.$toastr.i(i18n.t('orgAdmin.userResource.info.deleted'))
                self.$store.dispatch('userManagement/fetchUserDetails', self.user.id)
              })
              .catch(error => {
                if (error.response.status === 403) {
                  self.error = self.$i18n.t('orgAdmin.userResource.error.deleteNotAuthorized')
                } else {
                  commons.processRestError(error)
                }
              });
        }
      })
    },
    addNewSuccessCallback(){
      this.addNewOpened = false
      this.$store.dispatch('userManagement/fetchUserDetails', this.$route.params.id)
    },
    addNewCancelCallback(){
      this.addNewOpened = false
    }
  }
}
</script>
