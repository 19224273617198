<template>
  <div>
    <div class="row equal-height">
      <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="slideInLeft animated" leave-active-class="slideOutLeft animated">
        <div class="col-xxl-9 col-xl-8 col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ $t('indoorClimate.selectedMap.title') }}<template v-if="selectedMap"> - {{ selectedMap.name }} </template></h5>
              <span v-if="selectedMap" class="_label _label-primary float-right">{{ $t('analytics.dateTimeRangeInfo', { 0: selectedBuilding.timeZone }) }} <strong>{{ $store.getters["analytics/mapConfigDateTimeRangeFormatted"] }}</strong></span>
            </div>
            <div class="ibox-content">
              <div class="pl-2 pr-2">
                <map-view :map="selectedMap" />
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="col-xxl-3 col-xl-4 col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5><template v-if="selectedMap">{{ $t('indoorClimate.mapConfig.titleMapSelected') }}</template>
              <template v-else>{{ $t('indoorClimate.mapConfig.titleMapNotSelected') }}</template>
            </h5>
          </div>
          <div class="ibox-content">

            <resource-selector :resourceSelectedCallback="mapSelectedCallback" selectDepth="map" :preselect-resource="selectedMap ? { map: selectedMap} : null"/>

            <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
              <map-config v-if="selectedMap"/>
            </transition>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import {mapState} from "vuex"
import ResourceSelector from "@/components/common/ResourceSelector"
import MapView from "@/components/views/analytics/heatmaps/MapView.vue"
import MapConfig from "@/components/views/analytics/heatmaps/MapConfig.vue"

export default {
  components:{
    ResourceSelector,
    MapView,
    MapConfig
  },
  data() {
    return {
      error: null,
      info: null
    }
  },
  created() {
    // if state contains selectedMap load it
    console.log("Heatmaps created() selectedMap: " + this.selectedMap)
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedMap: state => state.analytics.selectedMap,
      selectedBuilding: state => state.analytics.selectedMap.building,
      mapConfig: state => state.analytics.mapConfig
    }),
  },
  methods: {
    mapSelectedCallback(payload){
      console.log("selectedMap " + payload.resource.id + " - " + payload.resource.name)

      this.$store.dispatch('analytics/loadMapFromServer', payload.resource.id)
    }
  },
  watch: {
    '$route' (to, from) { // watch it
      console.log('URL changed: ', to, ' | was: ', from, ' | query ', this.$route.query)
    },
  }
}
</script>
