<template>
  <div>
    <div class="form-group row m-b-sm" v-if="shareLinks && shareLinks.length != 0">
      <div class="col-lg-6">
        <div class="input-group">
          <span class="input-group-prepend">
            <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length == 0" @click="clearFilter"> {{
                $t('common.actions.clear')
              }}</b-button>
          </span>
          <b-form-input type="text"
                v-model="table.filter"
                debounce="300"
                max="50"
                :placeholder="$t('enterForSearch')"
                class="form-control form-control-sm"/>
        </div>
      </div>
      <div class="col-lg-2 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="shareLinks.length"></row-count>
      </div>
      <div class="col-lg-4 text-right" v-if="shareLinks.length && shareLinks.length > table.size">
        <b-pagination v-if="shareLinks.length" size="sm" class="m-t-xs" align="right"
                      :total-rows="shareLinks.length"
                      v-model="table.page"
                      :per-page="table.size"></b-pagination>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-table responsive hover
                 v-if="shareLinks.length != 0"
                 class="text-nowrap"
                 :filter="table.filter"
                 :fields="table.fields"
                 :current-page="table.page"
                 :per-page="table.size"
                 :items="shareLinks"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc">
          <template #cell(name)="data">
            <b-link :to="{ name: 'shareLinkDetails', params: { id: data.item.id } }">{{ data.value }}</b-link>
          </template>
          <template #cell(status)="data">
              <span class="label" :class="[data.item.status == 'INACTIVE' ? 'label-default' : 'label-primary']">{{ $i18n.t('enums.ShareLinkStatus.' + data.value)}}</span>
          </template>
            <template #cell(hash)="data">
                <b-link :to="{ name: 'shareLiveView', params: { hash: data.value } }" target="_blank">#/share-live-view/{{ data.value }}</b-link>
            </template>
          <template #cell(parameters)="data">
            {{ $t('orgAdmin.shareLink.paramType.temperature') }}: {{ roundedTemperatures(data.value.temperature) }} &nbsp;&nbsp;
            {{ $t('orgAdmin.shareLink.paramType.humidity') }}: {{ data.value.humidity.toString().replaceAll(',', ', ') }} &nbsp;&nbsp;
            {{ $t('orgAdmin.shareLink.paramType.co2') }}: {{ data.value.co2.toString().replaceAll(',', ', ') }} &nbsp;&nbsp;
            {{ $t('orgAdmin.shareLink.paramType.noise') }}: {{ data.value.noise.toString().replaceAll(',', ', ') }}
          </template>
        </b-table>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

        <div class="row" v-if="canAdd">
          <div class="col-lg-12">
            <router-link :to="{ path: routePrefix + '/share-links/new', query: this.$route.name === 'buildingDetails' ? { buildingId: this.$route.params.id } : { organisationId: this.$route.params.id } }">
              <button class="btn btn-sm btn-primary">
                <i class="fa fa-plus"></i>&nbsp;{{ $t('orgAdmin.shareLink.actions.new') }}
              </button>
            </router-link>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import RowCount from '@/components/common/RowCount'

export default {
  components: {
    RowCount
  },
  props: {
    shareLinks: {
      type: Array
    },
    canAdd: {
      type: Boolean,
      required: false,
      default: false
    },
    refreshCallback: {
      type: Function,
      required: false
    },
    routePrefix: {
      default: ''
    }
  },

  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: 'name',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50}
        ],
        fields: [
          {
            key: 'name',
            label: this.$i18n.t('orgAdmin.shareLink.attributes.name'),
            sortable: true
          },
          {
              key: 'status',
              label: this.$i18n.t('orgAdmin.shareLink.attributes.status'),
              sortable: true
          },
          {
            key: 'hash',
            label: this.$i18n.t('orgAdmin.shareLink.attributes.link'),
            sortable: false
          },
          {
            key: 'attributes',
            label: this.$i18n.t('orgAdmin.shareLink.attributes.attributes'),
            sortable: false
          }
        ]
      }
    }
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    },
    roundedTemperatures(temperatures) {
        if (Array.isArray(temperatures)) {
            return temperatures.map(temp => {
                if (!isNaN(temp)) {
                    return (Math.round(temp * 2) / 2).toString();
                }
                return 'N/A';
            }).join(', ');
        }
        return 'N/A';
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo
    })
  }
}
</script>
