<template>
  <div>
    <div class="summary-section">
      <div v-if="!editMode" class="details-section">
        <b-row v-for="(field, index) in displayFields" :key="index" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">

          <b-col cols="4">
            <strong>{{ field.label }}:</strong>
          </b-col>
          <b-col cols="8">
            <span v-if="field.label === $t('energySavings.meteringPoints')">
              <span v-if="filteredMeteringPoints.length === 0">{{ $t('common.noData') }}</span>
              <span v-else v-html="meteringPointsFormatted"></span>
            </span>
            <span v-else-if="field.type === 'ieqParameters'">
              <span v-if="analysis.IEQParameters.length === 0">{{ $t('common.noData') }}</span>
              <span v-else>{{ IEQParametersFormatted }}</span>
            </span>
            <span v-else-if="field.type === 'weatherParameters'">
              <span v-if="analysis.weatherParameters.length === 0">{{ $t('common.noData') }}</span>
              <span v-else>{{ weatherParametersFormatted }}</span>
            </span>
            <span v-else>{{ field.value }}</span>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-form-group :label="$t('energySavings.analysisName')">
          <b-form-input v-model="editableAnalysis.name" />
        </b-form-group>
        <b-form-group :label="$t('energySavings.description')">
          <b-form-input v-model="editableAnalysis.description" />
        </b-form-group>
        <b-form-group :label="$t('energySavings.responsiblePerson')">
          <b-form-input v-model="editableAnalysis.responsible" />
        </b-form-group>
      </div>
    </div>

    <div v-if="!editMode">
      <h4>{{ $t('energySavings.title') }}</h4>
      <div class="results-section mt-4" v-if="!editMode && analysis.results && analysis.results.length > 0">
        <b-table :items="tableResults" :fields="tableFields" responsive striped hover class="text-nowrap">
          <template #cell(reportingFrom)="data">
            {{ formatDate(data.item.reportingFrom) }}
          </template>
          <template #cell(reportingTo)="data">
            {{ formatDate(data.item.reportingTo) }}
          </template>
          <template #cell(actual)="data">
            {{ data.item.actual.toFixed(2) }} kWh
          </template>
          <template #cell(prediction)="data">
            {{ data.item.prediction.toFixed(2) }} kWh
          </template>
          <template #cell(lowerBound)="data">
            -{{ 100 - ((data.item.lowerBound / data.item.prediction) * 100).toFixed(2)}} %
          </template>
          <template #cell(upperBound)="data">
            +{{ (data.item.upperBound / data.item.prediction * 100).toFixed(2)  }} %
          </template>
          <template #cell(savings)="data">
            {{ calculateEnergySavingsPercentage(data.item.prediction, data.item.actual) }} %
          </template>
        </b-table>
      </div>
      <span v-else class="alert alert-info d-inline-block mt-2">{{ $t('common.noData') }}</span>
    </div>

    <div class="plot-section mt-4" v-if="!editMode">
      <h4>{{ $t('energySavings.meteringPointUsage') }}</h4>
      <div :class="graphConfig.fullSize ? 'col-xxxl-12' : 'col-xxxl-6'" class="col-xxl-12 p-xl-2 p-md-0">
        <highcharts :options="chartOptions" ref="analysisChart"></highcharts>
      </div>
    </div>

    <b-card no-body class="mt-4" v-if="!editMode && Object.keys(this.analysis.model).length">
      <b-card-header header-tag="header" role="tab" v-b-toggle.model-details class="cursor-pointer">
        <div class="pt-2 pb-2">
          {{ $t('energySavings.modelDetails.title') }}
        </div>
      </b-card-header>
      <b-collapse id="model-details" accordion="my-accordion">
        <b-card-body>
          <div class="model-details-section">
            <!-- Model Info Grid -->
             <div class="model-details-title-container">
               <div class="model-details-title">
                 <strong>{{ $t('energySavings.modelDetails.KNN.predictionInterval') }}:</strong>
               </div>
               <div class="model-details-title">
                 <strong>{{ $t('energySavings.modelDetails.KNN.kernel') }}:</strong>
               </div>
               <div class="model-details-title">
                 <strong>{{ $t('energySavings.modelDetails.KNN.K') }}:</strong>
               </div>
               <div class="model-details-title">
                 <strong>{{ $t('energySavings.modelDetails.KNN.bandwidth') }}:</strong>
               </div>
             </div>
            <div class="model-details-data-container">
              <div class="model-details-data">
                {{ analysis.model.prediction_interval }}
              </div>
              <div class="model-details-data">
                {{ analysis.model.kernel }}
              </div>
              <div class="model-details-data">
                {{ analysis.model.K }}
              </div>
              <div class="model-details-data">
                {{ analysis.model.bandwidth }}
              </div>
            </div>
            <!-- Spider Plot for Feature Weights -->
            <div class="model-plot">
              <highcharts v-if="spiderChartOptions" :options="spiderChartOptions" ref="spiderChart"></highcharts>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <div class="button-group mt-4 button-wrapper">
      <b-button v-if="!editMode" @click="enableEditMode" class="mb-4 mr-2" variant="primary">
        {{ $t('common.actions.edit') }}
      </b-button>
      <b-button v-if="editMode" @click="saveChanges" class="mb-4 mr-2" variant="success">
        {{ $t('common.actions.save') }}
      </b-button>
      <b-button v-if="editMode" @click="cancelEdit" class="mb-4 mr-2" variant="danger">
        {{ $t('common.actions.cancel') }}
      </b-button>
      <b-button @click="$emit('close')" class="mb-4" variant="secondary">
        {{ $t('common.actions.close') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Vue from "vue";

HighchartsMore(Highcharts); // Needed for spider chart

export default {
  data() {
    return {
      editMode: false, // Tracks whether we're in edit mode
      editableAnalysis: {}, // Holds the editable fields
      chartOptions: this.getDefaultChartOptions(),
      graphConfig: {
        fullSize: true,
        disableThresholds: false,
      },
      baselineData: [],  // Holds the data for the baseline period
      tableFields: [
        { key: 'reportingFrom', label: this.$t('energySavings.reportingStartDate') },
        { key: 'reportingTo', label: this.$t('energySavings.reportingEndDate') },
        { key: 'actual', label: this.$t('energySavings.totalActual') },
        { key: 'prediction', label: this.$t('energySavings.totalPrediction') },
        { key: 'lowerBound', label: this.$t('energySavings.lowerBound') },
        { key: 'upperBound', label: this.$t('energySavings.upperBound') },
        { key: 'savings', label: this.$t('energySavings.estimatedSavings') },
      ],
      spiderChartOptions: null,
      filteredMeteringPoints: [],
      weatherParametersMap: {
        'mean_temp': 'energySavings.params.ambientTemperature',
        'mean_relative_hum': 'energySavings.params.ambientHumidity',
        'mean_wind_speed': 'energySavings.params.windSpeed',
        'mean_wind_dir': 'energySavings.params.windDirection',
        'mean_pressure': 'energySavings.params.ambientPressure',
        'mean_radiation': 'energySavings.params.solarRadiation',
        'mean_cloud_cover': 'energySavings.params.cloudCover',
        "acc_precip": "energySavings.params.accPrecip",
        "snow_depth": "energySavings.params.snowDepth"
      },
      ieqParametersMap: {
        'motion': 'energySavings.params.indoorMotion',
        'co2': 'energySavings.params.indoorCO2',
        'light': 'energySavings.params.indoorIlluminance',
        'temperature': 'energySavings.params.indoorTemperature',
        'humidity': 'energySavings.params.indoorHumidity'
      },
    };
  },
  computed: {
    ...mapState({
      analysis: state => state.energySavings.selectedAnalysis,
      buildingMeteringPoints: state => state.devices.meters,
    }),
    displayFields() {
      return [
        { label: this.$t('energySavings.analysisName'), value: this.analysis.name },
        { label: this.$t('energySavings.description'), value: this.analysis.description },
        { label: this.$t('energySavings.responsiblePerson'), value: this.analysis.responsible },
        { label: this.$t('energySavings.savingsKwhYear'), value: this.calculatedEnergySavingsPerYear },
        { label: this.$t('energySavings.baselinePeriod'), value: this.formattedBaselinePeriod },
        { label: this.$t('energySavings.reportingPeriod'), value: this.formattedReportingPeriod },
        { label: this.$t('energySavings.meteringPoints'), value: this.meteringPointsFormatted },
        { label: this.$t('energySavings.ieqParameters'), type: 'ieqParameters' },
        { label: this.$t('energySavings.weatherParameters'), type: 'weatherParameters' }
      ];
    },
    meteringPointsFormatted() {
      if (!this.filteredMeteringPoints || this.filteredMeteringPoints.length === 0) return this.$t('common.noData');
      // Format the filtered metering points as clickable links
      return this.filteredMeteringPoints
        .map(mp => `<span class="metering-point-bubble"><a href="#/devices/${mp.id}">${mp.alias}</a></span>`)
        .join(', ');
    },
    IEQParametersFormatted() {
      if (this.analysis.IEQParameters && this.analysis.IEQParameters.length > 0) {
        return this.analysis.IEQParameters
          .map(param => this.$t(this.ieqParametersMap[param]) || "-")
          .join(', ');
      }
      return this.$t('common.noData');
    },
    weatherParametersFormatted() {
      if (this.analysis.weatherParameters && this.analysis.weatherParameters.length > 0) {
        return this.analysis.weatherParameters
          .map(param => this.$t(this.weatherParametersMap[param]) || "-")
          .join(', ');
      }
      return this.$t('common.noData');
    },
    tableResults() {
      return this.analysis.results.map(result => ({
        reportingFrom: result.reportingFrom,
        reportingTo: result.reportingTo,
        actual: result.actual,
        prediction: result.prediction,
        lowerBound: result.lowerBound,
        upperBound: result.upperBound
      }));
    },
    calculatedEnergySavingsPerYear() {
      if (this.analysis && this.analysis.results && this.analysis.results.length > 0) {
        const totalActual = this.analysis.results.reduce((sum, result) => sum + result.actual, 0);
        const totalPrediction = this.analysis.results.reduce((sum, result) => sum + result.prediction, 0);
        const energySavings = totalPrediction - totalActual;
        const reportingPeriodMonths = this.getReportingPeriodMonths();
        const extrapolatedSavings = (energySavings / reportingPeriodMonths) * 12;
        return `${extrapolatedSavings.toFixed(2)} kWh`;
      }
      return 'N/A';
    },
    formattedBaselinePeriod() {
      if (this.analysis.baselineFrom && this.analysis.baselineTo) {
        return `${new Date(this.analysis.baselineFrom).toLocaleDateString("en-GB")} - ${new Date(this.analysis.baselineTo).toLocaleDateString("en-GB")}`;
      }
      return 'N/A';
    },
    formattedReportingPeriod() {
      if (this.analysis.reportingFrom && this.analysis.reportingTo) {
        return `${new Date(this.analysis.reportingFrom).toLocaleDateString("en-GB")} - ${new Date(this.analysis.reportingTo).toLocaleDateString("en-GB")}`;
      }
      return 'N/A';
    }
  },
  watch: {
    analysis: {
      immediate: true,
      handler() {
        if (this.analysis && this.analysis.results) {
          this.fetchBaselineData();
          this.fetchMeteringPoints();
        }
      }
    },
    buildingMeteringPoints: {
      immediate: true,
      handler(newVal) {
        const meteringPointIds = this.analysis.meteringPointIds;
        if (meteringPointIds && meteringPointIds.length > 0) {
          this.filteredMeteringPoints = newVal.filter(mp => meteringPointIds.includes(mp.id));
        }
      }
    }
  },
  methods: {
    enableEditMode() {
      this.editMode = true;
      this.editableAnalysis = { ...this.analysis }; // Make a copy of the current analysis
    },
    cancelEdit() {
      this.editMode = false;
      this.editableAnalysis = {}; // Clear the editable fields
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-GB");
    },
    getSpiderChartOptions() {
      if (!this.analysis || !this.analysis.model || !this.analysis.model.feature_weights) return null;
      const categories = Object.keys(this.analysis.model.feature_weights);
      const data = Object.values(this.analysis.model.feature_weights);

      return {
        chart: {
          polar: true,
          type: 'line',
        },
        title: {
          text: this.$t('energySavings.modelDetails.KNN.plotTitle'),
        },
        pane: {
          size: '80%',
        },
        xAxis: {
          categories: categories,
          tickmarkPlacement: 'on',
          lineWidth: 0,
          labels: {
            style: {
              fontSize: '10px'  // Reduce font size for xAxis labels
            }
          }
        },
        yAxis: {
          gridLineInterpolation: 'polygon',
          lineWidth: 0,
          min: 0,
        },
        series: [{
          data: data,
          pointPlacement: 'on',
        }],
        legend: { enabled: false },
        tooltip: {
          formatter: function () {
            return `<strong>${this.x}</strong>: ${this.y.toFixed(2)}`;
          },
        },
      };
    },
    saveChanges() {
      // Save the changes to the analysis
      this.$store.dispatch('energySavings/updateAnalysis', {buildingId: this.analysis.buildingId, analysis: this.editableAnalysis})
        .then(() => {
          this.editMode = false;          
        })
        .catch(error => {
          console.error('Error saving analysis:', error);
          this.$bvToast.toast(this.$t('energySavings.toast.createError'), {
            title: this.$t('common.popupTitle.warning'),
            variant: 'danger',
            solid: true
          });
        });
    },
    getReportingPeriodMonths() {
      const start = new Date(this.analysis.reportingFrom);
      const end = new Date(this.analysis.reportingTo);
      const diffInMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth()) + 1;
      return diffInMonths;
    },
    getDefaultChartOptions() {
      return {
        credits: false,
        title: {
          text: "",
          style: { fontSize: '1px', fontStyle: 'bold' }
        },
        chart: {
          type: 'column',
          height: '350px',
          spacingTop: 40,
          style: { fontFamily: 'open sans, Helvetica Neue, Helvetica, Arial, sans-serif' }
        },
        xAxis: {
          type: 'datetime',
          labels: {
            formatter: function () {
              return Highcharts.dateFormat('%b %Y', this.value);
            },
            rotation: -45,
            align: 'right'
          },
          tickInterval: 24 * 3600 * 1000 * 30  // One tick per month
        },
        plotOptions: {
          column: {
            borderWidth: 0,
          }
        },
        yAxis: { title: { text: this.$t('energySavings.energyUsage') } },
        legend: { enabled: true },
        series: []
      };
    },
    updateChart() {
      // Series for predicted energy (savings if compared with actual)
      const predictionData = this.analysis.results.map(result => ({
        x: Date.parse(result.reportingFrom),
        y: result.prediction
      }));
      predictionData.sort((a, b) => a.x- b.x);

      // Series for actual energy usage
      const actualData = this.analysis.results.map(result => ({
        x: Date.parse(result.reportingFrom),
        y: result.actual
      }));
      actualData.sort((a, b) => a.x- b.x);
      
      // Baseline data already ready for display from fetch method
      const baselineData = this.baselineData;
      baselineData.sort((a, b) => a.x- b.x);
      this.chartOptions.series = [
        { name: this.$t('energySavings.predicted'), data: predictionData, color: '#1f77b4' },
        { name: this.$t('energySavings.measured'), data: actualData, color: '#ffff11' },
        { name: this.$t('energySavings.baselinePeriod'), data: baselineData, color: '#ff7f0e' }
      ];

      this.spiderChartOptions = this.getSpiderChartOptions();

      this.$nextTick(() => {
        const chartRef = this.$refs.analysisChart;
        if (chartRef && chartRef[0]) {
          chartRef[0].chart.series[0].setData(predictionData, true);
          chartRef[0].chart.series[1].setData(actualData, true);
          chartRef[0].chart.series[2].setData(baselineData, true);
        }
      });
    },
    fetchBaselineData() {
      const meteringPointIds = this.analysis.meteringPointIds;

      if (!meteringPointIds || meteringPointIds.length === 0) {
        console.error('No metering points available');
        return;
      }

      const requests = meteringPointIds.map(meteringPointId =>
        Vue.axios.get(`/devices/${meteringPointId}/metering-point-values`, {
          params: {
            dateTimeFrom: this.analysis.baselineFrom,
            dateTimeTo: this.analysis.baselineTo,
            aggregateWindowDuration: "1",
            aggregateWindowDurationUnit: "MONTHS" // Ensures we fetch monthly data
          }
        })
      );

      // Execute all requests
      Promise.all(requests)
        .then(responses => {
          const combinedData = {};

          responses.forEach(response => {
            // Filter only the relevant field: "meter_energy_consumption"
            const filteredData = response.data.filter(item => item.field === "meter_energy_consumption");

            // Sum the readings for each time point (monthly aggregation)
            filteredData.forEach(item => {
              const time = Date.parse(item.time); // Time represents the month start
              const value = parseFloat(item.value); // Energy consumption value for the month

              // Sum values across different metering points for the same time period (month)
              if (!combinedData[time]) {
                combinedData[time] = value;
              } else {
                combinedData[time] += value; // Sum the values for the same month
              }
            });
          });

          // Convert combined data into array for the chart
          this.baselineData = Object.keys(combinedData).map(time => ({
            x: parseFloat(time),  // time (month start) in milliseconds
            y: combinedData[time]  // summed energy consumption for that month
          }));
          this.updateChart();
        })
        .catch(error => {
          console.error('Error fetching baseline data:', error);
        });
    },
    fetchMeteringPoints() {
      const meteringPointIds = this.analysis.meteringPointIds;
      if (!meteringPointIds || meteringPointIds.length === 0) return;

      // Fetch all metering points for the building
      this.$store.dispatch('devices/fetchBuildingMeteringPoints', this.analysis.buildingId)
        .then(() => {
          // Filter the fetched metering points to only include the ones in the analysis
          this.filteredMeteringPoints = this.buildingMeteringPoints.filter(mp => meteringPointIds.includes(mp.id));
        })
        .catch(error => {
          console.error('Error fetching metering points:', error);
        });
  },
    calculateEnergySavingsPercentage(prediction, actual) {
      if (prediction === 0) return 'N/A';
      const savingsPercentage = ((prediction - actual) / prediction) * 100;
      return savingsPercentage.toFixed(2);
    }
  }
};
</script>

<style scoped>
.summary-section {
  padding-bottom: 20px;
  border-radius: 5px;
}

.plot-section { 
  display: flex;
  flex-direction: column
}

.plot-section h4 {
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}

.plot-section highcharts {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.details-section{
  display: grid;
  grid-template-columns: 1 1;
}

/* Styling for alternating rows */
.even-row {
  margin: 0 0 0 0;
  background-color: #f0f0f0;
  padding: 10px 0;
}

.odd-row {
  margin: 0 0 0 0;
  background-color: #ffffff;
  padding: 10px 0;
}

.details-section {
  margin-bottom: 20px;
  align-items: flex-end;
}

.button-wrapper {
  display: flex;
  flex-direction: row;
}

.model-details-section {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: 1fr 8fr 1fr;
  max-height: 1000px;
}

.model-details-title-container{
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  align-self: center;
}

.model-details-data-container{
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  align-self: center;
  max-height: 100vh;
}

.model-details-title{
  padding: 1rem 0 0 0;
}

.model-details-data{
  padding: 1rem 0 0 0;
}

.model-plot{
  max-width: 100%;
  overflow: hidden;
  grid-column: 3 / 4;
  grid-row: 1 / span 10;
}

::v-deep .metering-point-bubble {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 15px;
  background-color: #61AEB7;
  color: white;
  margin-right: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 0.75rem;
}

::v-deep .metering-point-bubble a {
  color: white;
  text-decoration: none;
}

::v-deep .metering-point-bubble a:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  
  .model-details-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .model-details-data{
    margin-left: 1rem;
  }
  .model-plot {
    margin-top: 0.5rem;
    flex-grow: 1;
  }
}

.custom-toast {
  z-index: 2000 !important;  /* Ensure it's above the modal */
}

</style>
