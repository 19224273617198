<template>
  <div>

    <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
      <button class="close" type="button" @click="error = ''">×</button>
    </div>

    <div class="form-group row m-b-md" v-if="userRegistrationRequests.length != 0">
      <div class="col-lg-6">
        <div class="input-group">
          <span class="input-group-prepend">
            <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length == 0" @click="clearFilter"> {{
                $t('common.actions.clear')
              }}</b-button>
          </span>
          <b-form-input type="text"
                v-model="table.filter"
                debounce="300"
                max="50"
                :placeholder="$t('enterForSearch')"
                class="form-control form-control-sm"/>
        </div>
      </div>
      <div class="col-lg-3 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="userRegistrationRequests.length"></row-count>
      </div>
      <div class="col-lg-3 text-right" v-if="userRegistrationRequests.length && userRegistrationRequests.length > table.size">
        <b-pagination v-if="userRegistrationRequests.length" size="sm" class="m-t-xs" align="right"
                      :total-rows="userRegistrationRequests.length"
                      v-model="table.page"
                      :per-page="table.size"></b-pagination>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-table responsive _hover _striped
                 v-if="userRegistrationRequests.length != 0"
                 _class="text-nowrap"
                 thead-class="text-nowrap"
                 :filter="table.filter"
                 :fields="table.fields"
                 :current-page="table.page"
                 :per-page="table.size"
                 :items="userRegistrationRequests"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc">
          <template #cell(username)="data">
            <b-link :to="{ path: routePrefix + '/register-user-requests/' + data.item.id }">{{ data.value }}</b-link>
          </template>
          <template #cell(message)="data">
            <truncate :text="data.value" :length="50" action-class="truncate-more-less-link text-info" :clamp="$t('common.actions.showMore')" :less="$t('common.actions.showLess')"></truncate>
          </template>
          <template #cell(status)="data">
            <span class="label" :class="{'label-danger':data.item.status == 'DECLINED','label-primary':data.item.status == 'APPROVED','label-warning':data.item.status == 'PENDING'}">{{ data.value}}</span>
          </template>
          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">{{ data.value }}</template>
        </b-table>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n'
import truncate from 'vue-truncate-collapsed'
import {mapState} from 'vuex'
import RowCount from '@/components/common/RowCount.vue'

export default {
  components: {
    RowCount,
    truncate
  },
  props: {
    routePrefix: {
      default: ''
    }
  },
  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: '',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50},
          {'value': 100}
        ],
        fields: [
          {
            key: 'username',
            label: this.$i18n.t('orgAdmin.registerUserRequest.attributes.username'),
            sortable: true,
          },
          {
            key: 'firstName',
            label: this.$i18n.t('orgAdmin.registerUserRequest.attributes.firstName'),
            sortable: true,
          },
          {
            key: 'lastName',
            label: this.$i18n.t('orgAdmin.registerUserRequest.attributes.lastName'),
            sortable: true,
          },
          {
            key: 'email',
            label: this.$i18n.t('orgAdmin.registerUserRequest.attributes.email'),
            sortable: true,
          },
          {
            key: 'matchedOrganisation',
            label: this.$i18n.t('orgAdmin.registerUserRequest.attributes.matchedOrganisation'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.matchedOrganisation.name
            }
          },
          {
            key: 'message',
            label: this.$i18n.t('orgAdmin.registerUserRequest.attributes.message'),
            sortable: true,
          },
          {
            key: 'status',
            label: this.$i18n.t('orgAdmin.registerUserRequest.attributes.status'),
            sortable: true,
            class: ["text-center"],
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return i18n.t('enums.RegisterOrgRequestStatus.' + item.status)
            }
          }
        ]
      }
    }
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    }
  },
  computed: {
    ...mapState({
      userRegistrationRequests: state => state.userManagement.registerUserRequests
    })
  }
}
</script>
