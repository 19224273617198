<template>
<div id="wrapper" :class="theme">
  <img src="@/assets/custom/img/climify_nav_bg.png" class="nav-background-image"/>
  <nav-bar></nav-bar>
    <div id="page-wrapper" class="gray-bg" :class="this.$route.name">
        <page-top/>
        <view-header v-if="$route.meta.headerComponent == null && $route.meta.hideHeader !== true"/>
        <component v-if="$route.meta.headerComponent && $route.meta.hideHeader !== true" :is="$route.meta.headerComponent" />
        <div class="wrapper wrapper-content fadeInRight custom-for-right-sidebar" :class="{'collapsed':!rightSidebarCollapsed}">
            <loading :active.sync="showGlobalLoader" color="#1ab394" :is-full-page="false" :container="$refs.loadingContainer"></loading>
            <div class="alert alert-danger alert-dismissible" v-show="$store.state.error">{{ $store.state.error }}
                <button class="close" type="button" @click="clearErrorMessage()">×</button>
            </div>
            <transition mode="out-in" enter-active-class="fadeIn animated" _leave-active-class="fadeOut animated" appear ref="loadingContainer">
                <router-view ref="loadingContainer"/>
            </transition>
        </div>
        <page-footer/>
    </div>
</div>
</template>

<script>
import NavBar from '../pages/parts/NavBar'
import PageTop from '../pages/parts/TopBar'
import PageFooter from '../pages/parts/PageFooter'
import ViewHeader from '../pages/parts/ViewHeader'
import Loading from 'vue-loading-overlay'
import {mapState} from 'vuex'

export default {
  components: {
    NavBar,
    PageTop,
    PageFooter,
    ViewHeader,
    Loading
  },

  data() {
    return {}
  },
  methods: {
    clearErrorMessage() {
      this.$store.commit('SET_ROOT_ERROR', null)
    }
  },
  computed: {
    ...mapState({
      theme: state => state.userInfo.settings ? state.userInfo.settings.theme : 'default-theme'
    }),
    showGlobalLoader() {
      return this.$store.state.showGlobalLoader && !this.$store.state.showInPageLoader
    },
    rightSidebarCollapsed() {
      return true
    }
  }
}
</script>

<style>
.custom-for-right-sidebar {
  _padding-right: 60px;
}

.custom-for-right-sidebar.collapsed {
  _padding-right: 215px;
}
</style>