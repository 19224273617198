<template>
  <div class="cursor-pointer widget navy-bg card-class organisation text-center" @click="open">
    <span v-if="item.status !=='ACTIVE'" class="card-status badge badge-danger">{{ $t('enums.ResourceStatus.' + item.status) }}</span>
    <div class="m-b-sm">{{ $t('orgAdmin.organisation.name') }}</div>
    <h3 class="font-bold">{{ item.name }}</h3>
    <div class="m-t-sm" v-if="item.city">{{ item.city }}</div>
    <div v-if="item.country">{{ item.country | countryNameFromCode  }}</div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    item: {
      type: Object
    },
    onClick:{
      type: Function,
      required: false
    }
  },
  data() {
    return {
      error: null
    }
  },
  methods: {
    open(){
      if(this.onClick){
        this.onClick(this.item)
      }else {
        this.$store.commit('orgAdmin/SAVE_ORGANISATION_DETAILS_ACTIVE_TAB', 0)
        this.$router.push({
          path: `/admin/organisations/${this.item.id}`,
        })
      }
    },
  }
}
</script>
