<template>
  <div>

    <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
      <button class="close" type="button" @click="error = ''">×</button>
    </div>

    <div class="row">

      <div class="col-12">

        <b-form-group id="input-group-assign-org" :label="$t('resourceSelector.organisation')" label-for="input-assign-org" label-cols-sm="3">
            <v-select
                :disabled="selector.organisations.length == 1 && selector.organisationSelected && !selector.organisationSelected.includeAllChildren"
                id="input-assign-org"
                name="input-assign-org"
                v-if="selector.organisations.length > 0"
                v-model="selector.organisationSelected"
                :options="selector.organisations"
                label="name"
                @input="orgSelected"
                :clearable="false"
            />
        </b-form-group>

        <b-form-group v-if="selector.buildings.length > 0" id="input-group-assign-building" :label="$t('resourceSelector.building')" label-for="input-assign-building" label-cols-sm="3">
          <v-select
              :disabled="selector.buildings.length == 1 && selector.buildingSelected && !selector.buildingSelected.includeAllChildren"
              id="input-assign-building"
              name="input-assign-building"
              v-model="selector.buildingSelected"
              :options="selector.buildings"
              label="name"
              @input="buildingSelected"
              :clearable="false"
          />
        </b-form-group>

        <b-form-group v-if="selector.maps.length > 0" id="input-group-assign-map" :label="$t('resourceSelector.map')" label-for="input-assign-map" label-cols-sm="3">
          <v-select
              :disabled="selector.maps.length == 1 && selector.mapSelected && !selector.mapSelected.includeAllChildren"
              id="input-assign-map"
              name="input-assign-map"
              v-model="selector.mapSelected"
              :options="selector.maps"
              label="name"
              @input="mapSelected"
              :clearable="false"
          />
        </b-form-group>

        <b-form-group v-if="selector.locations.length > 0" id="input-group-assign-location" :label="$t('resourceSelector.location')" label-for="input-assign-location" label-cols-sm="3">
          <v-select
              :disabled="selector.locations.length == 1 && selector.locationSelected && !selector.locationSelected.includeAllChildren"
              id="input-assign-location"
              name="input-assign-location"
              v-model="selector.locationSelected"
              :options="selector.locations"
              label="label"
              @input="locationSelected"
              :clearable="false"
          />
        </b-form-group>

      </div>

    </div>

    <div class="row">
      <div class="col-12">
        <button type="button" class="btn btn-sm btn-primary" @click.prevent="assign">{{ $t('common.actions.assign') }}</button>
        <button type="button" class="btn btn-sm btn-secondary m-l-sm" @click="initialFetch">{{ $t('common.actions.reset') }}</button>
        <button v-if="cancelCallback" type="button" class="btn btn-sm btn-white m-l-sm" @click="cancel">{{ $t('common.actions.cancel') }}</button>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from "vue"
import * as commons from "@/commons"
import { mapState } from "vuex"
import { getUserAuthorities } from "@/commons"
import _ from "lodash"

export default {
    props: {
        preselectedResources: {
            defaultValue: () => {},
            type: Object
        },
        fixPreselectedResources: {
            type: Boolean
        },
        resourceSelectedCallback: {
            type: Function
        },
        cancelCallback: {
            type: Function
        }
    },
    data() {
        return {
            error: null,
            info: null,
            selector: {
                organisations: [],
                buildings: [],
                maps: [],
                locations: [],
                organisationSelected: null,
                buildingSelected: null,
                mapSelected: null,
                locationSelected: null,
            }
        }
    },
    created() {
        this.initialFetch()
    },
    computed: {
        ...mapState({
            currentUser: state => state.userInfo
        }),
    },
    methods: {
        cancel() {
            if (this.cancelCallback) {
                this.cancelCallback()
            }
        },
        initialFetch() {

            this.selector.organisations = []
            this.selector.buildings = []
            this.selector.maps = []
            this.selector.locations = []

            this.selector.organisationSelected = null
            this.selector.buildingSelected = null
            this.selector.mapSelected = null
            this.selector.locationSelected = null


            new Promise((resolve, reject) => {

                //first get logged user authorities to calculate what can he assign to target user
                if (this.hasRole('ADMIN')) {

                    // can assign literally anything -  get all organisations first
                    Vue.axios.get('/admin/organisations').then(response => {
                        this.selector.organisations = _.sortBy(response.data.map(org => {
                            org.includeAllChildren = true
                            return org
                        }), [function (o) {
                            return o.name;
                        }])
                        resolve(response)
                    }).catch(error => {
                        commons.processRestError(error)
                        reject(error)
                    })

                } else {

                    this.selector.organisations = getUserAuthorities().filter(auth => {
                        return auth.authorizationTargetPath.organisation
                    }).map(auth => {
                        if (auth.role == 'ORGANISATION_MANAGER') {
                            auth.authorizationTargetPath.organisation.includeAllChildren = true
                        }
                        return auth.authorizationTargetPath.organisation
                    })
                    this.selector.organisations = _.sortBy(this.getUniqueListBy(this.selector.organisations, "id"), [function (o) {
                        return o.name;
                    }])
                    resolve()
                }
            }).then(() => {

                if (this.selector.organisations.length == 1) {
                    this.selector.organisationSelected = this.selector.organisations[0]
                    this.orgSelected(true)
                } else if (this.preselectedResources.organisation && this.selector.organisations.some(organisation => organisation.id == this.preselectedResources.organisation.id)) {
                    this.selector.organisationSelected = this.selector.organisations.find(organisation => organisation.id == this.preselectedResources.organisation.id)
                    if (this.fixPreselectedResources) {
                        this.selector.organisations = [this.selector.organisationSelected]
                    }
                    this.orgSelected(true)
                }
            })

        },

        orgSelected() {

            console.log('orgSelected ' + this.selector.organisationSelected.id)

            this.error = ""
            this.selector.buildings = []
            this.selector.maps = []
            this.selector.locations = []
            this.selector.buildingSelected = null
            this.selector.mapSelected = null
            this.selector.locationSelected = null

            new Promise((resolve, reject) => {

                if (this.selector.organisationSelected.includeAllChildren) {

                    //load organisation with buildings
                    Vue.axios.get('/admin/organisations/' + this.selector.organisationSelected.id).then(response => {
                        this.selector.buildings = _.sortBy(response.data.buildings.map(building => {
                            building.includeAllChildren = true
                            return building
                        }), [function (o) {
                            return o.name;
                        }])
                        resolve(response)
                    }).catch(error => {
                        commons.processRestError(error)
                        reject(error)
                    })

                } else {

                    // load only explicitly assigned buildings
                    this.selector.buildings = getUserAuthorities().filter(auth => {
                        return auth.authorizationTargetPath.building && auth.authorizationTargetPath.organisation.id == this.selector.organisationSelected.id
                    }).map(auth => {
                        if (auth.role == 'BUILDING_MANAGER') {
                            auth.authorizationTargetPath.building.includeAllChildren = true
                        }
                        return auth.authorizationTargetPath.building
                    })
                    this.selector.buildings = _.sortBy(this.getUniqueListBy(this.selector.buildings, "id"), [function (o) {
                        return o.name;
                    }])
                    resolve()
                }
            }).then(() => {

                if (this.selector.buildings.length == 1) {
                    this.selector.buildingSelected = this.selector.buildings[0]
                    this.buildingSelected(true)
                } else if (this.preselectedResources.building && this.selector.buildings.some(building => building.id == this.preselectedResources.building.id)) {
                    this.selector.buildingSelected = this.selector.buildings.find(building => building.id == this.preselectedResources.building.id)
                    if (this.fixPreselectedResources) {
                        this.selector.buildings = [this.selector.buildingSelected]
                    }
                    this.buildingSelected(true)
                }
            })
        },

        buildingSelected() {

            console.log('buildingSelected ' + this.selector.buildingSelected.id)

            this.error = ""
            this.selector.maps = []
            this.selector.locations = []
            this.selector.mapSelected = null
            this.selector.locationSelected = null

            new Promise((resolve, reject) => {

                if (this.selector.buildingSelected.includeAllChildren) {

                    //load building with maps
                    Vue.axios.get('/admin/buildings/' + this.selector.buildingSelected.id).then(response => {
                        this.selector.maps = _.sortBy(response.data.maps.map(map => {
                            map.includeAllChildren = true
                            return map
                        }), [function (o) {
                            return o.name;
                        }])
                        resolve(response)
                    }).catch(error => {
                        commons.processRestError(error)
                        reject(error)
                    })

                } else {

                    // load only explicitly assigned location maps
                    this.selector.maps = getUserAuthorities().filter(auth => {
                        return auth.authorizationTargetPath.map && auth.authorizationTargetPath.building.id == this.selector.buildingSelected.id
                    }).map(auth => {
                        return auth.authorizationTargetPath.map
                    })
                    this.selector.maps = _.sortBy(this.getUniqueListBy(this.selector.maps, "id"), [function (o) {
                        return o.name;
                    }])
                    resolve()
                }
            }).then(() => {
                if (this.preselectedResources.map && this.selector.maps.some(map => map.id == this.preselectedResources.map.id)) {
                    this.selector.mapSelected = this.selector.maps.find(map => map.id == this.preselectedResources.map.id)
                    if (this.fixPreselectedResources) {
                        this.selector.maps = [this.selector.mapSelected]
                    }
                    this.mapSelected(true)
                }
            })
        },

        mapSelected() {

            console.log('mapSelected ' + this.selector.mapSelected.id)

            this.error = ""
            this.selector.locations = []
            this.selector.locationSelected = null

            new Promise((resolve, reject) => {

                if (this.selector.mapSelected.includeAllChildren) {

                    //load map with locations
                    Vue.axios.get('/admin/maps/' + this.selector.mapSelected.id).then(response => {
                        this.selector.locations = _.map(
                            _.sortBy(response.data.locations, [function (o) { return o.name; }]),
                            location => ({
                                ...location,
                                label: location.nameAlternate ? `${location.name} (${location.nameAlternate})` : location.name
                            })
                        );
                        resolve(response)
                    }).catch(error => {
                        commons.processRestError(error)
                        reject(error)
                    })

                } else {

                    // load only explicitly assigned locations
                    this.selector.locations = getUserAuthorities().filter(auth => {
                        return auth.authorizationTargetPath.location && auth.authorizationTargetPath.map.id == this.selector.mapSelected.id
                    }).map(auth => {
                        return auth.authorizationTargetPath.location
                    })
                    let uniqueSortedLocations = _.sortBy(this.getUniqueListBy(this.selector.locations, "id"), [function (o) { return o.name; }]);
                    this.selector.locations = uniqueSortedLocations.map(location => ({
                        ...location,
                        label: location.nameAlternate ? `${location.name} (${location.nameAlternate})` : location.name
                    }));
                    resolve()
                }
            }).then(() => {
                if (this.selector.locations.length == 1) {
                    this.selector.locationSelected = this.selector.locations[0]
                    this.locationSelected(true)
                } else if (this.preselectedResources.location && this.selector.locations.some(location => location.id == this.preselectedResources.location.id)) {
                    this.selector.locationSelected = this.selector.locations.find(location => location.id == this.preselectedResources.location.id)
                    if (this.fixPreselectedResources) {
                        this.selector.locations = [this.selector.locationSelected]
                    }
                    this.locationSelected(true)
                }
            })
        },

        locationSelected() {
            console.log('locationSelected ' + this.selector.locationSelected.id)
            //return this.select()
        },

        assign() {

            this.error = ""

            let resolvedResourceObject = null
            let resolvedResourceType = null
            if (this.selector.locationSelected) {
                resolvedResourceObject = this.selector.locationSelected
                resolvedResourceType = 'location'
            } else if (this.selector.mapSelected) {
                resolvedResourceObject = this.selector.mapSelected
                resolvedResourceType = 'map'
            } else if (this.selector.buildingSelected) {
                resolvedResourceObject = this.selector.buildingSelected
                resolvedResourceType = 'building'
            } else if (this.selector.organisationSelected) {
                resolvedResourceObject = this.selector.organisationSelected
                resolvedResourceType = 'organisation'
            }

            if (this.selector.organisationSelected == null) {
                this.error = this.$i18n.t('systemAdmin.device.info.resourceRequired')
                return
            }

            if (this.resourceSelectedCallback) {
                this.resourceSelectedCallback({
                    organisation: this.selector.organisationSelected,
                    building: this.selector.buildingSelected,
                    map: this.selector.mapSelected,
                    location: this.selector.locationSelected,
                })
            }
        }
    }
}
</script>