<template>
<div class="footer custom-footer">
  <span class="d-none d-lg-inline">{{ $store.state.userInfo.firstName }} {{ $store.state.userInfo.lastName }} / {{ $store.state.userInfo.username }}</span>
    <div class="float-right">
        <strong></strong>{{ $t('credits') }}&nbsp;&nbsp;&nbsp;v{{ $store.state.systemParams['app.version'] }}
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style scoped>
    .custom-footer {
        z-index: 6;
    }
</style>