  <template>
  <div class="row wrapper border-bottom white-bg page-heading" v-if="organisation && $route.meta.titleKey">
    <div class="col-lg-10">
      <h2>{{ $t($route.meta.titleKey) }}</h2>
      <h4 v-if="$route.meta.subtitleKey">{{ $t($route.meta.subtitleKey) }}</h4>
      <ol class="breadcrumb">
        <li class="breadcrumb-item" v-if="hasRole('ADMIN')">
          <router-link to="/admin/organisations">{{ $t('menu.organisationAdmin') }}</router-link>
        </li>
        <li class="breadcrumb-item active">
          <strong>{{ organisation.name }}</strong>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      organisation: state => state.organisation.organisationDetails.entity
    }),
  },
  methods: {
  },
}
</script>
