<template>
    <li class="dropdown">
        <a class="dropdown-toggle pl-0" data-toggle="dropdown" :title="$t('navbar.userProfile')">
            <i class="fa fa-user user-icon" style="color: #676a6c"></i>
        </a>
        <transition name="fade">
        <ul class="dropdown-menu dropdown-alerts">
            <li>
              <router-link to="/user-settings" class="dropdown-item">
                <i class="fa fa-sliders"></i>
                {{ $t('navbar.userSettings') }}
              </router-link>
            </li>
          <li>
            <a :href="resetPasswordLink" class="dropdown-item">
              <i class="fa fa-key"></i>
              {{ $t('navbar.resetPassword') }}
            </a>
          </li>
            <li class="dropdown-divider"></li>
            <li>
                <a href="#" @click.prevent="logout" class="dropdown-item">
                    <i class="fa fa-sign-out"></i> {{ $t('logout')}}
                </a>
            </li>
        </ul>
        </transition>
    </li>
</template>

<script>

export default {
  data() {
      return {
      }
  },
  methods: {
      logout() {
          this.$store.dispatch('logout', this.$data)
      }
  },
  computed: {
    resetPasswordLink() {
      return this.getConfigParamValue('keycloak.auth-server-url', null) + "/realms/" + this.getConfigParamValue('keycloak.realm', 'climify') + "/login-actions/reset-credentials?client_id=" + this.getConfigParamValue('kc.frontend.client-id', null)
    }
  }
}
</script>
<style scoped>
    .dropdown .dropdown-item i {
        margin-right: 10px;
    }
    .dropdown a.dropdown-item {
        padding: 0px 10px;
        min-height: inherit;
    }
    .user-icon {
        font-size: 1.2em;
    }
</style>
