import Vue from "vue";
import * as commons from '@/commons';

const getDefaultState = () => ({
  analyses: [],
  selectedAnalysis: null,
  lastFetchTime: null,
  backRoute: null,
});

const state = getDefaultState();

const getters = {
  getAnalysisById: (state) => (analysisId) => state.analyses.find(analysis => analysis.id === analysisId),
};

const actions = {
  appInit() {
    console.log("energySavings/appInit");
  },

  loadAnalysesForBuilding({ commit, state }, buildingId) {
    const currentTime = Date.now();
  
    if (buildingId) {
      // Prepare query parameters for the request
      const params = {
        buildingId: buildingId
      };
  
      // Make the API request to the updated endpoint
      Vue.axios.get('/energy-savings/analyses', { params })
        .then(response => {
          if (response.status === 204) {
            // No content, clear analyses if necessary
            commit('SET_ANALYSES', []); 
            console.warn('No analyses found for the given building.');
          } else {
            // Store the retrieved analyses
            commit('SET_ANALYSES', response.data.data);
            commit('SET_LAST_FETCH_TIME', currentTime);
          }
        })
        .catch(error => {
          commons.processRestError(error);
          console.error("Error loading analyses for building:", error);
        });
    }
  },

  fetchAnalysisById({ commit, state }, {buildingId, analysisId}) {
    return Vue.axios.get(`/energy-savings/${buildingId}/analysis/${analysisId}`)
      .then(response => {
        commit('SET_SELECTED_ANALYSIS', response.data.data);
        return response.data;
      })
      .catch(error => {
        commons.processRestError(error);
        console.error("Error fetching analysis by ID:", error);
        return null;
      });
  },

  addAnalysis({ dispatch }, { buildingId, analysis }) {
    const body = { data: analysis };
    
    return Vue.axios.post(`/energy-savings/${buildingId}/create`, body)
      .then(response => {
        // Trigger the action to reload the analyses after a successful creation
        return dispatch('loadAnalysesForBuilding', buildingId);
      })
      .catch(error => {
        commons.processRestError(error);
        console.error("Error adding analysis:", error);
        throw error;
      });
  },

  updateAnalysis({ commit }, {buildingId, analysis}) {
    const payload = {data: {description: analysis.description, name: analysis.name, responsible: analysis.responsible}}
    return Vue.axios.put(`/energy-savings/${buildingId}/analysis/${analysis.id}`, payload)
      .then(response => {
        commit('SET_SELECTED_ANALYSIS', response.data.data);
      })
      .catch(error => {
        commons.processRestError(error);
        console.error("Error updating analysis:", error);
        throw error;
      });
  },

  deleteAnalysis({ commit }, {buildingId, analysisId}) {
    return Vue.axios.delete(`/energy-savings/${buildingId}/analysis/${analysisId}`)
      .then(() => {
        commit('DELETE_ANALYSIS', analysisId);
      })
      .catch(error => {
        commons.processRestError(error);
        console.error("Error deleting analysis:", error);
      });
  },

  clearAnalyses({ commit }) {
    commit('CLEAR_ANALYSES');
  },

  clearSelectedAnalysis({ commit }) {
    commit('CLEAR_SELECTED_ANALYSIS');
  },

  setBackRoute({ commit }, path) {
    commit('SET_BACK_ROUTE', path);
  },
};

const mutations = {
  SET_ANALYSES(state, analyses) {
    state.analyses = analyses;
  },

  ADD_ANALYSIS(state, analysis) {
    state.analyses.push(analysis);
  },

  UPDATE_ANALYSIS(state, updatedAnalysis) {
    const index = state.analyses.findIndex(analysis => analysis.id === updatedAnalysis.id);
    if (index !== -1) {
      Vue.set(state.analyses, index, updatedAnalysis);
    }
  },

  DELETE_ANALYSIS(state, analysisId) {
    state.analyses = state.analyses.filter(analysis => analysis.id !== analysisId);
  },

  SET_SELECTED_ANALYSIS(state, analysis) {
    state.selectedAnalysis = analysis;
  },

  SET_LAST_FETCH_TIME(state, fetchTime) {
    state.lastFetchTime = fetchTime;
  },

  CLEAR_ANALYSES(state) {
    state.analyses = [];
  },

  CLEAR_SELECTED_ANALYSIS(state) {
    state.selectedAnalysis = null;
  },

  SET_BACK_ROUTE(state, path) {
    state.backRoute = path;
  },

  CLEAR_BACK_ROUTE(state) {
    state.backRoute = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
