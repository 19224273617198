<template>
  <div>
    <div class="col equal-height">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ $t('reporting.config.title') }}</h5>
          </div>
          <div class="ibox-content">
            <resource-selector
              :resourceSelectedCallback="resourceSelectedCallback"
              selectDepth="building"
            />
          </div>
        </div>
      </div>
      <transition :duration="{ enter: 0, leave: 0 }" enter-active-class="slideInLeft animated" leave-active-class="slideOutLeft animated">
        <div class="col-lg-12" v-if="buildingDetails.maps.length">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ $t('reporting.reports.title') }}</h5>
            </div>
            <div class="ibox-content">
              <div class="pl-2 pr-2">
                <reporting-config :buildingDetails="buildingDetails"></reporting-config>
              </div>
            </div>
          </div>
          <div class="ibox" v-if="buildingDetails.maps.length">
            <div class="ibox-title">
              <h3>{{ $t('reporting.reports.title') }}</h3>
            </div>
            <div class="ibox-content">
              <div class="form-group row m-b-md m-t-md align-items-start">
                <div class="input-group col-lg-6 align-content-center">
                  <span class="input-group-prepend">
                    <b-button class="btn btn-sm btn-primary" @click="table.filter = ''">{{ $t('reporting.reports.clear') }}</b-button>
                  </span>
                  <b-form-input
                    type="text"
                    debounce="300"
                    max="50"
                    :placeholder="$t('enterForSearch')"
                    v-model="table.filter"
                    class="form-control form-control-sm"
                  />
                  <span class="input-group-append">
                    <b-button class="btn btn-sm btn-primary" @click="refreshReports()">
                      <i class="fas fa-refresh mr-1"></i>
                      <span>{{ $t('reporting.reports.refresh') }}</span>
                    </b-button>
                  </span>
                </div>
                <div class="col-lg-3 m-t-sm text-right">
                  <row-count :size="table.size" :page="table.page" :total="items.length"></row-count>
                </div>
                <div class="col-lg-3 text-right" v-if="items.length && items.length > table.size">
                  <b-pagination
                    v-if="items.length"
                    size="sm"
                    class="m-t-xs"
                    align="right"
                    :total-rows="items.length"
                    v-model="table.page"
                    :per-page="table.size"
                  ></b-pagination>
                </div>
              </div>
              <div class="p-1"></div>
              <div class="row mt-2">
                <div class="col-lg-12">
                  <b-table
                    responsive
                    hover
                    striped
                    v-if="generatedReports && generatedReports.length"
                    class="text-nowrap"
                    thead-class="text-nowrap"
                    :filter="table.filter"
                    :fields="table.fields"
                    :current-page="table.page"
                    :per-page="table.size"
                    :items="generatedReports"
                    :sort-by.sync="table.sortBy"
                    :sort-desc.sync="table.sortDesc"
                  >
                    <template #cell(reportId)="data">
                      <span v-if="data.item.status === 'Done'" class="icon-wrapper">
                        <span @click="downloadReport(data.item)" class="download-link">
                          <i class="fas fa-download mr-1"></i>
                        </span>
                        <span @click="deleteReport(data.item)" class="delete-link">
                          <i class="fa-regular fa-trash-can ml-5"></i>
                        </span>
                      </span>
                      <span v-if="data.item.status === 'Failed'" class="icon-wrapper">
                        <span @click="deleteReport(data.item)" class="delete-link">
                          <i class="fa-regular fa-trash-can ml-5"></i>
                        </span>
                      </span>
                      <span v-else> </span>
                    </template>
                    <template #cell(generatedTimestamp)="data">
                      <span v-if="data.value">
                        {{ formatDate(data.value) }}
                      </span>
                      <span v-else>-</span>
                    </template>
                    <template #cell(periodStart)="data">
                      <span v-if="data.value">
                        {{ data.item.periodStart + ", " + data.item.periodEnd }}
                      </span>
                      <span v-else>-</span>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex"
import ResourceSelector from "@/components/common/ResourceSelector"
import RowCount from '@/components/common/RowCount';
import ReportingConfig from "./ReportingConfig";
import Vue from "vue";

export default {
  components:{
    ResourceSelector,
    RowCount,
    ReportingConfig,
  },
  props: {},
  data() {
    return {
      items: [], 
      error: null,
      info: null,
      form: null,
      location: null,
      configuration: {
        selected: [],
        selectedReportPeriod: null,
        selectedDataAnalysis: [],
        selectedHourlyAnalysis: null,
      },
      reportType: ['Thermal Comfort', 'Indoor air quality', 'Presence', 'Visual Comfort', 'Acoustic Comfort'],
      table: {
        filter: '',
        sortBy: '',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50},
          {'value': 100}
        ],
        fields: [
          {
            key: 'generatedTimestamp',
            label: this.$t('reporting.reports.created'),
            sortable: true,
          },
          {
            key: 'periodStart',
            label: this.$t('reporting.reports.period'),
            sortable: true,
          },
          {
            key: 'floorplan',
            label: this.$t('reporting.reports.floorplan'),
            sortable: true,
          },
          {
            key: 'status',
            label: this.$t('reporting.reports.status'),
            sortable: true,
          },
          {
            key: 'reportId',
            label: this.$t(''), // Intentionally left blank or you can provide a suitable key if needed
            sortable: false,
          },
        ],
      },
    }
  },

  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedOrg: state => state.reporting.selectedOrg,
      selectedLocation: state => state.feedback.selectedLocation,
      buildingDetails: state => state.reporting.buildingDetails,
      selectedBuilding: state => state.reporting.selectedBuilding,
      generatedReports: state => state.reporting.generatedReports,
    }),
  },

  methods: {
    resourceSelectedCallback(payload){
      this.$store.dispatch('reporting/updateSelectedOrg', payload.organisationSelected)
      this.$store.dispatch('reporting/updateSelectedBuilding', payload.buildingSelected)
      this.$store.dispatch('reporting/fetchBuildingDetails', payload.resource.id)
      this.$store.dispatch('reporting/loadReportsFromServer', payload.organisationSelected)
    },

    refreshReports(){
      this.$store.dispatch('reporting/loadReportsFromServer', this.selectedOrg)
    },

    clearFilter() {
      this.table.filter = null
    },

    formatDate(timestamp) {
      const date = new Date(timestamp);

      // Pad the month, date, hours, and minutes with leading zeros if necessary
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${year}-${month}-${day}, ${hours}:${minutes}`;
    },

    downloadReport(report) {
      const reportId = report.reportId;
      const buildingId = report.buildingId;
      Vue.axios.get(`/reports/${buildingId}/${reportId}`, { responseType: 'blob'}).then(response => {
        // Check if the response is successful and the content type indicates a PDF document
        if (response.status === 200 && response.headers['content-type'] === 'application/pdf') {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);

          // Open the PDF in a new tab
          window.open(blobUrl, '_blank');
        } else {
          // Handle non-PDF responses or unsuccessful status codes
          console.error('Received a non-PDF response or an error status');
          alert('Failed to download the report. The file is not available.');
        }
      }).catch(error => {
        console.log(error);
        alert('An error occurred while downloading the report.');
      });
    },

    deleteReport(report){
      const reportId = report.reportId;
      const buildingId = report.buildingId;
      let self = this;
      this.confirmDialog({
        text: this.$t('reporting.modal.confirmDeletion.text', {itemName: this.$t("report")}),
        title: this.$t('reporting.modal.confirmDeletion.title'),
        callbackYes: ( () => {
          Vue.axios.delete(`/reports/${buildingId}/${reportId}`)
              .then(response => {
                console.log(response)
                self.type = self.initialType
                if (self.successCallback) {
                  self.successCallback()
                }
              })
              .catch(error => {
                console.log(error)
              }).finally(() => { 
                  setTimeout(() => { 
                    this.refreshReports();
                  }, 1000);
                });
        })
        }
      )
    },
  },

  watch: {
    generatedReports: function(newVal, oldVal) {
      this.items = newVal || [];
    },
    
    buildingDetails: {
      handler(newValue) {
          // Only clear selectedFloorplans if buildingDetails is defined and has a different value
          if (newValue && newValue.maps && (!this.oldBuildingId || this.oldBuildingId !== newValue.id)) {
            this.configuration.selectedFloorplans = [];
          }
          // Store the building id to compare it on the next update
          this.oldBuildingId = newValue ? newValue.id : null;
        },
        deep: true // Watch for nested changes in the object
      }
  }
}
</script>

<style scoped>
  .download-link{
    cursor: pointer;
    color: #61AEB7;
  }

  .delete-link{
    cursor: pointer;
    color: #E94F37;
  }

  .icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }


</style>
