<template>
  <div>
    <div class="row" v-if="selectedDevice && sensorValuesMap && sensorValuesMap.size > 0">
      <div class="col-12">
        <highcharts :options="state_graphData" ref="chart"></highcharts>
      </div>
    </div>
    <div v-else class="text-center mt-5 mb-5">
      <div class="font-bold">{{ $t('graphs.meteringPoints.graphView.placeholder')}}</div>
    </div>
  </div>
</template>


<script>
import {mapState} from "vuex"
import moment from 'moment-timezone'
import Highcharts, { chart } from "highcharts"

//TO-DO: this needs to be somehow fetched dynamically, from where and how?
let avg_fields = [
  "system_voltage",
  "system_line_voltage",
  "system_current",
  "system_power",
  "system_apparent_power",
  "system_reactive_power",
  "system_power_factor",
  "system_frequency",
  "system_neutral_current",
  "meter_return_temperature",
  "meter_forward_temperature",
  "meter_flow",
  "meter_power",
  "meter_co2_emission"
];

let sum_fields = [
  "system_energy",
  "system_reactive_energy",
  "system_reactive_energy_export",
  "system_apparent_energy",
  "system_export_energy"
];

let chartOptionsBase = {
  credits: false,
  title: {
    useHTML: true,
    style: {
      fontSize: '14px',
      fontStyle: 'bold'
    }
  },
  tooltip: {
    split: false
  },
  chart: {
    zoomType: 'x',
    type: 'spline',
    height: '700px',
    spacingTop: 40,
    style: {
      fontFamily: 'open sans, Helvetica Neue, Helvetica, Arial, sans-serif',
    }
  },
  xAxis: {
    type: 'datetime',
    labels: {
      autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
    },
    minPadding: 0,
    maxPadding: 0,
  },
  plotOptions: {
    area: {
      fillOpacity: 0.3,
    },
    spline: {
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3
        }
      },
      marker: {
        enabled: false
      }
    }
  },
  yAxis: [],
  legend: {
    enabled: true
  },
  series: []
}

export default {
  data() {
    return {
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.removeWeekendSeries();
        });
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedDevice: state => state.graphMeteringPoints.selectedDevice,
      selectedSensorAttributes: state => state.graphMeteringPoints.graphConfig.selectedSensorAttributes,
      selectedGraphType: state => state.graphMeteringPoints.graphConfig.selectedGraphType,
      sensorValuesMap: state => state.graphMeteringPoints.selectedDevice.sensorValuesMap,
      sensorMeanValuesMap: state => state.graphMeteringPoints.selectedDevice.sensorMeanValuesMap,
      sensorAverageValuesMap: state => state.graphMeteringPoints.selectedDevice.sensorAverageValuesMap,
      graphConfig: state => state.graphMeteringPoints.graphConfig
    }),

    state_graphData() {

      console.log('state_graphData sensorValuesMap.size ' + this.sensorValuesMap.size + (this.sensorMeanValuesMap ? ', sensorMeanValuesMap.size ' + this.sensorMeanValuesMap.size : 0))

      let vm = this

      if (vm.sensorValuesMap && vm.sensorValuesMap.size > 0) {

        let yAxisIdx = 0
        let electricityYAxisIdx = -1
        let count = 0        
        let chartDef = JSON.parse(JSON.stringify(chartOptionsBase))
        let plotBandAr = [];

        let plotBandSeries = {
          id: 'weekend',
          name: 'Weekend Highlight',
          color: '#E9D8DB',
          marker: {
            symbol: "square",
            symbolName: "square",
            lineColor: null,
            lineWidth: 8,
          }
        };

        chartDef.title.text = vm.$i18n.t('graphs.meteringPoints.graphView.title')
        chartDef.exporting = {
          chartOptions: {
            title: {
              text: this.selectedDevice.building.name + " / " + this.selectedDevice.alias
            }
          }
        }
        chartDef.time = {}
        chartDef.time.getTimezoneOffset = function (timestamp) {
          return -moment.tz(timestamp, vm.selectedDevice.building.timeZone).utcOffset();
        }

        if (this.graphConfig.enableWeekends !== false) {

          // Calculate the plot bands for weekends
          plotBandAr = this.calculateWeekendPlotBands(this.graphConfig.dateRange.startDate, this.graphConfig.dateRange.endDate, chartDef.time.getTimezoneOffset());
          
          chartDef.plotOptions = {
            series: {
              events: {
                legendItemClick: function () {
                  if (this.options.id === 'weekend') {
                    if (this.visible) {
                      this.chart.xAxis[0].update({
                        plotBands: []
                      });
                    } else {
                      this.chart.xAxis[0].update({
                        plotBands: plotBandAr
                      });
                    }
                  }
                },
              }
            }
          };
        }

        let totals = {};
        vm.selectedSensorAttributes.forEach(field => {
          if (vm.sensorValuesMap.has(field) && vm.sensorValuesMap.get(field).length > 0) {
            let sensorData = vm.sensorValuesMap.get(field);
            let sensorDef = vm.$i18n.t('enums.sensorType.' + field);

            let yAxisDef = {
              maxPadding: 0.1,
              allowDecimals: true,
              title: "",
              _title: {
                text: '<i class="' + sensorDef.icon + ' mr-2"></i>' + sensorDef.name,
                style: {
                  color: sensorDef.color
                }
              },
              labels: {
                format: '{value} ' + sensorDef.unit,
                style: {
                  color: sensorDef.color
                },
              }
            };

            if (count % 2 == 1) {
              yAxisDef.gridLineWidth = 0;
              yAxisDef.opposite = true;
            }

            if (field === 'meter_energy_consumption' || field === 'meter_supplied_energy' || field === 'meter_consumed_energy') {
              if (electricityYAxisIdx < 0) {
                chartDef.yAxis.push(yAxisDef);
                electricityYAxisIdx = chartDef.yAxis.length - 1;
              }
              yAxisIdx = electricityYAxisIdx;
            } else {
              chartDef.yAxis.push(yAxisDef);
              yAxisIdx = chartDef.yAxis.length - 1;
            }

            let dailyData = {};
            let dailySeriesData = [];
            let drilldownSeries = [];

            if (vm.selectedGraphType === 'HOURS') {
              // For 'HOURS', show raw data without summing
              sensorData.forEach(dataPoint => {
                let date = new Date(Date.parse(dataPoint.time));
                dailySeriesData.push({
                  x: date.getTime() + (chartDef.time.getTimezoneOffset() * 60000),
                  y: Math.round(dataPoint.value * 100) / 100
                });
              });

              dailySeriesData.sort((a, b) => a.x - b.x);

              let series = {
                type: 'column',
                unit: sensorDef.unit,
                name: sensorDef.name,
                data: dailySeriesData,
                tooltip: {
                  valueSuffix: ' ' + sensorDef.unit
                },
                yAxis: yAxisIdx,
                color: sensorDef.color,
                opacity: 0.95,
                field: field,
                drilldown: field
              };

              if (avg_fields.includes(field)) {
                series.type = 'line';
                series.dashStyle = 'shortdot';
              }

              if (field === 'meter_co2_emission_total') {
                series.type = 'line';
                series.zIndex = 3;
              }
                
              chartDef.series.push(series);

            } else if (vm.selectedGraphType === 'DAYS') {
              // For 'DAYS', sum up hourly data for each day and show drilldown
              sensorData.forEach(dataPoint => {
                let date = new Date(Date.parse(dataPoint.time));
                let day = date.toISOString().split('T')[0];
                if (!dailyData[day]) {
                  dailyData[day] = {
                    total: 0,
                    count: 0,
                    hourlyData: []
                  };
                }
                let value = dataPoint.value ? Math.round(+(dataPoint.value) * 100) / 100 : null;
                dailyData[day].total = parseFloat((dailyData[day].total + value).toFixed(2));
                dailyData[day].count += 1
                dailyData[day].hourlyData.push({
                  x: date.getTime() + (chartDef.time.getTimezoneOffset() * 60000),
                  y: Math.round(value * 100) / 100
                });
              });

              for (let day in dailyData) {
                let date = new Date(day);
                
                let value = avg_fields.includes(field) ? (dailyData[day].total / dailyData[day].count).toFixed(2) : dailyData[day].total;

                dailySeriesData.push({
                  name: day,
                  x: date.getTime(),
                  y: parseFloat(value),
                  drilldown: day
                });

                drilldownSeries.push({
                  id: day,
                  data: dailyData[day].hourlyData.sort((a, b) => a.x - b.x),
                  type: (avg_fields.includes(field) || (field == "meter_co2_emission_total")) ? 'line' : 'column',
                  unit: sensorDef.unit,
                  name: sensorDef.name,
                  tooltip: {
                    valueSuffix: ' ' + sensorDef.unit
                  },
                  color: sensorDef.color,
                  yAxis: yAxisIdx,
                  opacity: 0.95,
                  field: field,
                  drilldown: field
                });
              }

              dailySeriesData.sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime());

              let series = {
                type: 'column',
                unit: sensorDef.unit,
                name: sensorDef.name,
                data: dailySeriesData,
                tooltip: {
                  valueSuffix: ' ' + sensorDef.unit
                },
                yAxis: yAxisIdx,
                color: sensorDef.color,
                opacity: 0.95,
                field: field,
                drilldown: field
              };

              if (avg_fields.includes(field)) {
                series.type = 'line';
                series.dashStyle = 'shortdot';
              }

              if (field === 'meter_co2_emission_total') {
                series.type = 'line';
                series.zIndex = 3;
              }

              chartDef.series.push(series);

              if (!chartDef.drilldown) {
                chartDef.drilldown = {
                  series: []
                };
              }

              chartDef.drilldown.series = chartDef.drilldown.series.concat(drilldownSeries);

            } else if (vm.selectedGraphType === 'MONTHS') {
              // For 'MONTHS', sum up daily data for the month and show drilldown
              sensorData.forEach(dataPoint => {
                let date = new Date(Date.parse(dataPoint.time));
                let month = date.toISOString().slice(0, 7); // Get YYYY-MM format
                if (!dailyData[month]) {
                  dailyData[month] = {
                    total: 0,
                    count: 0,
                    dailyData: []
                  };
                }
                let value = dataPoint.value ? Math.round(+(dataPoint.value) * 100) / 100 : null;
                dailyData[month].total = parseFloat((dailyData[month].total + value).toFixed(2));
                dailyData[month].count += 1;
                dailyData[month].dailyData.push({
                  x: date.getTime() + (chartDef.time.getTimezoneOffset() * 60000),
                  y: Math.round(value * 100) / 100
                });
              });

              for (let month in dailyData) {
                let date = new Date(month + '-01');
                let value = avg_fields.includes(field) ? (dailyData[month].total / dailyData[month].count).toFixed(2) : dailyData[month].total;

                dailySeriesData.push({
                  name: month,
                  x: date.getTime(),
                  y: parseFloat(value),
                  drilldown: month
                });

                drilldownSeries.push({
                  id: month,
                  data: dailyData[month].dailyData.sort((a, b) => a.x - b.x),
                  type: (avg_fields.includes(field) || (field == "meter_co2_emission_total")) ? 'line' : 'column',
                  unit: sensorDef.unit,
                  name: sensorDef.name,
                  tooltip: {
                    valueSuffix: ' ' + sensorDef.unit
                  },
                  color: sensorDef.color,
                  opacity: 0.95,
                  yAxis: yAxisIdx,
                  field: field,
                  drilldown: field
                });
              }

              dailySeriesData.sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime());

              let series = {
                type: 'column',
                unit: sensorDef.unit,
                name: sensorDef.name,
                data: dailySeriesData,
                tooltip: {
                  valueSuffix: ' ' + sensorDef.unit
                },
                yAxis: yAxisIdx,
                color: sensorDef.color,
                opacity: 0.95,
                field: field,
                drilldown: field
              };
              
              if (avg_fields.includes(field)) {
                series.type = 'line';
                series.dashStyle = 'shortdot';
              }

              if (field === 'meter_co2_emission') {
                series.type = 'line';
                series.dashStyle = 'shortdot';
                series.zIndex = 2;
              }

              if (field === 'meter_co2_emission_total') {
                series.type = 'line';
                series.zIndex = 3;
              }

              chartDef.series.push(series);

              if (!chartDef.drilldown) {
                chartDef.drilldown = {
                  series: []
                };
              }

              chartDef.drilldown.series = chartDef.drilldown.series.concat(drilldownSeries);
            }

            totals[field] = {
              total: dailySeriesData.reduce((acc, point) => acc + point.y, 0).toFixed(2),
              unit: sensorDef.unit,
              color: sensorDef.color
            };

            count++;
          }
        });

        chartDef.xAxis = {
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%e %b %y',
            hour: '%H:%M'
          }
        };

        this.updateSubtitle(totals, chartDef);

        chartDef.chart.events = {
          drilldown: function (e) {
            e.preventDefault();

            let totals = {}

            if(vm.graphConfig.enableWeekends) {
              this.addSingleSeriesAsDrilldown(e.point, plotBandSeries);
              plotBandAr.forEach((plotBand, index) => {
                  plotBand.id = 'plot-band-' + index; // Assign a unique ID to each plot band
                  this.xAxis[0].addPlotBand(plotBand);
              });
            }
            
            // Find the date from the drilldown ID of the clicked point
            let drilldownDate = e.point.drilldown;

            // Filter the drilldown series to get all series with the same date
            let relatedDrilldownSeries = this.options.drilldown.series.filter(
                s => s.id === drilldownDate
            );

            // Loop through all related series and add them as drilldown
            relatedDrilldownSeries.forEach(series => {
              totals[series.field] = {
                total: series.data.reduce((acc, point) => acc + point.y, 0).toFixed(2),
                unit: series.unit,
                color: series.color
              };

              let subtitleText = '<div style="display: flex; flex-wrap: wrap; margin-bottom: 10px;">';

              for (const [field, { total, unit, color }] of Object.entries(totals)) {
                if (field !== "meter_co2_emission" && !avg_fields.includes(field)) {
                  subtitleText += `<div style="margin-right: 10px; color: ${color}">${vm.$i18n.t('enums.sensorType.' + field).name}: ${total} ${unit}</div>`;
                }
              }
              subtitleText += '</div>';

              this.setTitle(null, {
                text: subtitleText
              });

              this.addSingleSeriesAsDrilldown(e.point, series);
            });

            this.applyDrilldown();
            
          },
          drillup: function () {
            let subtitleText = ""
            this.series.forEach(function (s) {

              subtitleText = '<div style="display: flex; flex-wrap: wrap; margin-bottom: 10px;">';

              for (const [field, { total, unit, color }] of Object.entries(totals)) {
                if (field !== "meter_co2_emission" && !avg_fields.includes(field)) {
                  subtitleText += `<div style="margin-right: 10px; color: ${color}">${vm.$i18n.t('enums.sensorType.' + field).name}: ${total} ${unit}</div>`;
                }
              }
              subtitleText += '</div>';
              s.setVisible(true, false);
            });
            this.setTitle(null, {
              text: subtitleText
            });

            plotBandAr.forEach((plotBand, index) => {
                this.xAxis[0].removePlotBand('plot-band-' + index);
            });
          }
        };

        if(vm.selectedGraphType !== "MONTHS") {
          chartDef.series.push(plotBandSeries);
          chartDef.xAxis.plotBands = plotBandAr;
        }

        return chartDef
      }
      return null
    }
  },
  methods:{
    removeWeekendSeries() {
      $('g.highcharts-plot-bands-0').remove();
      const chartDef = this.chartDef;
      if(chartDef){
        chartDef.series.forEach((serie, index) => {
          if (serie.id === 'weekend') {
            chartDef.series.splice(index, 1);
          }
        });
      }
    },
    updateSubtitle(totals, chartDef) {
      let subtitleText = '<div style="display: flex; flex-wrap: wrap; margin-bottom: 10px;">';

      for (const [field, { total, unit, color }] of Object.entries(totals)) {
        if (field !== "meter_co2_emission" && !avg_fields.includes(field)) {
          subtitleText += `<div style="margin-right: 10px; color: ${color}">${this.$i18n.t('enums.sensorType.' + field).name}: ${total} ${unit}</div>`;
        }
      }
      subtitleText += '</div>';

      chartDef.subtitle = {
          text: subtitleText,
          align: 'left',
          verticalAlign: 'top',
          style: {
            fontSize: '1rem'
          },
          useHTML: true
      }
    },
    calculateWeekendPlotBands(startDate, endDate, timeZoneOffset) {
      let plotBands = [];
      let currentDate = new Date(startDate);
      endDate = new Date(endDate);

      // Adjust each date based on the timezone before checking the day
      while (currentDate <= endDate) {
        let adjustedDate = new Date(currentDate.getTime() + timeZoneOffset * 60 * 1000);

        if (adjustedDate.getDay() === 0 || adjustedDate.getDay() === 6 || adjustedDate.getDay() === 5) {
          let startOfDay = new Date(adjustedDate);
          let endOfDay = new Date(adjustedDate);

          startOfDay.setHours(0, 0, 0, 0);
          endOfDay.setHours(23, 59, 59);

          // Extend the endOfDay to cover Sunday if it's Saturday
          if (adjustedDate.getDay() === 6) {
            endOfDay.setDate(endOfDay.getDate() + 1);
            endOfDay.setHours(23, 59, 59);
          }

          if (adjustedDate.getDay() === 5) {
            startOfDay.setDate(startOfDay.getDate() + 1);
            startOfDay.setHours(23, 59, 59);
          }

          plotBands.push({
            color: '#E9D8DB',
            from: startOfDay.getTime(),
            to: endOfDay.getTime()
          });
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return plotBands;
    }
  },
}
</script>
