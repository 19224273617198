<template>
    <div v-if="summary" class="charts-row">
        <div v-if="summary.buildingSummary" class="chart">
            <highcharts :options="buildingOptions"></highcharts>
        </div>
        <div v-if="summary.locationSummary" class="chart">
            <highcharts :options="locationOptions"></highcharts>
        </div>
        <div v-if="summary.deviceSummary" class="chart">
            <highcharts :options="deviceOptions"></highcharts>
        </div>
    </div>
</template>


<script>
import Highcharts from 'highcharts';

export default {
    props: {
        summary: {
            type: Object
        },
    },
    watch: {
        summary: function (newVal, oldVal) {
            console.log('Prop summary changed: ', newVal, ' | was: ', oldVal);
            this.reset()
        }
    },
    computed: {
        buildingOptions() {
            return this.generateChartOptions(this.summary.buildingSummary, ['ACTIVE', 'BLOCKED', 'PARENT_BLOCKED'], 'summary.buildingSummary', 'enums.ResourceStatus');
        },
        locationOptions() {
            return this.generateChartOptions(this.summary.locationSummary, ['ACTIVE', 'BLOCKED', 'PARENT_BLOCKED'], 'summary.locationSummary', 'enums.ResourceStatus');
        },
        deviceOptions() {
            return this.generateChartOptions(this.summary.deviceSummary, ['METERING_POINT', 'SENSOR', 'BLE_BEACON'], 'summary.deviceSummary', 'enums.DeviceModelType');
        }
    },
    methods: {
        generateChartOptions(data, categories, title, translationPath) {
            let translatedCategories = categories.map(category => this.$t(`${translationPath}.${category}`));

            let categoryMapping = categories.reduce((acc, cat, index) => {
                acc[cat] = translatedCategories[index];
                return acc;
            }, {});

            let seriesData = categories.map(category => ({
                name: categoryMapping[category],
                y: 0
            }));

            data.forEach(item => {
                let categoryIndex = categories.indexOf(item[0]);
                if (categoryIndex !== -1) {
                    seriesData[categoryIndex].y = item[1];
                }
            });

            const colors = ['#61aeb7', '#ffc000', '#c3979f', '#e94f37'];

            const options = {
                credits: false,
                chart: {
                    type: 'pie',
                    height: '350px',
                    events: {
                        render: function () {
                            if (!this.series[0].center) return;

                            const total = this.series[0].data.reduce((sum, point) => sum + point.y, 0);
                            if (this.totalLabel) {
                                this.totalLabel.destroy();
                            }
                            this.totalLabel = this.renderer.text(
                                'Total: ' + total,
                                this.plotLeft + this.plotWidth * 0.5,
                                this.plotTop + this.plotHeight * 0.5
                            )
                                .css({
                                    color: '#333333',
                                    fontSize: '16px',
                                    fontWeight: 'bold'
                                })
                                .attr({
                                    align: 'center'
                                })
                                .add();
                        }
                    },
                    style: {
                        fontFamily: 'open sans, Helvetica Neue, Helvetica, Arial, sans-serif',
                    }
                },
                title: {
                    text: this.$t(`${title}`),
                    useHTML: true,
                    style: {
                        fontSize: '14px',
                        fontStyle: 'bold'
                    }
                },
                colors: colors,
                plotOptions: {
                    pie: {
                        innerSize: '50%',
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}',
                            distance: -30,
                            style: {
                                color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                            }
                        },
                        showInLegend: true
                    }
                },
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{series.name}<br>{point.name}: <b>{point.y}</b>'
                },
                series: [{
                    name: this.$t(`${title}`),
                    data: seriesData
                }]
            };

            return options;
        }
    }
};
</script>

<style scoped>
.charts-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.chart {
    flex: 1 1 33%;
    box-sizing: border-box;
    padding: 10px;
    min-width: 300px;
}
</style>
