<template>
  <div class="animated fadeInRight">
    <div class="row">

      <div class="col-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5 class="d-inline-block">{{ $t('orgAdmin.map.details.info') }}</h5>
          </div>
          <div class="ibox-content">
            <map-view-create-edit
                v-if="map"
                :map="{ building: this.building }"
                :can-edit="true"
                initial-type="create"
                :success-callback="createMapSuccessCallback"
                :cancelCallback = "createMapCancelCallback"
            />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import MapViewCreateEdit from "@/components/views/buildings/MapViewCreateEdit";

export default {
  components: {
    MapViewCreateEdit,
  },
  data() {
    return {
      error: null,
      filterText: '',
      locationList: {
        filter: null,
        page: 1,
        size: 10
      },
      map: {
        name: '',
        image: ''
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      building: state => state.buildings.buildingDetails.entity,
    }),
  },
  methods: {
    createMapSuccessCallback(){
      this.goBack()
    },
    createMapCancelCallback(){
      this.goBack()
    },
  }
}
</script>

<style scoped>

</style>