import moment from 'moment'
import i18n from '@/i18n'

export const filesize = function (bytes, precision) {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
    if (typeof precision === 'undefined') precision = 2;
    var units = ['B', 'KB', 'MB', 'GB', 'TB'],
        number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
}

export const round = function (value, decimals) {
    if (!value) {
        value = 0;
    }

    if (!decimals) {
        decimals = 0;
    }

    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    return value;
}

export const truncatechars = function (input, chars, breakOnWord) {
    if (isNaN(chars)) return input;
    if (chars <= 0) return '';
    if (input && input.length >= chars) {
        input = input.substring(0, chars);
        if (!breakOnWord) {
            var lastspace = input.lastIndexOf(' ');
            //get last space
            if (lastspace !== -1) {
                input = input.substr(0, lastspace);
            }
        } else {
            while (input.charAt(input.length - 1) == ' ') {
                input = input.substr(0, input.length - 1);
            }
        }
        return input + '...';
    }
    return input;
}

//formats duration in millis to h:mm:ss
export const formatMillisDuration = function (ms) {
    var duration = moment.duration(ms);
    return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(":mm:ss");
}

//formats duration in seconds to h:mm:ss
export const formatSecDuration = function (sec) {
    var duration = moment.duration(sec * 1000);
    return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(":mm:ss");
}

export const priceFormat = function (num, precision = 2, decimalSeparator = ',', separator = '.') {
    var parts;
    //precision = num < 1 ? 4: precision;
    // judge if it is a number
    if (!isNaN(parseFloat(num)) && isFinite(num)) {
        // The similar .5, 5. like the data into 0.5, 5, for the accuracy of data processing to do, as to why
        // Do not judge in the direct write if (! IsNaN (num = parseFloat (num)) && isFinite (num))
        // Is because parseFloat has a strange precision problem, such as parseFloat (12312312.1234567119)
        // The value becomes 12312312.123456713
        num = Number(num);
        // Handle decimal places
        num = typeof precision !== 'undefined' ? num.toFixed(precision) : num.toString();
        // Separate the fractional part and the integer part of the number
        parts = num.split('.');
        // The integer part is separated by [separator], borrowing a famous regular expression
        parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (separator || '.'));

        return parts.join(decimalSeparator);
    }
    return num;
}

export const numberFormat = function (num, precision = 2, decimalSeparator = ',', separator = '.') {
    var parts;
    //precision = num < 1 ? 4: precision;
    // judge if it is a number
    if (!isNaN(parseFloat(num)) && isFinite(num)) {
        // The similar .5, 5. like the data into 0.5, 5, for the accuracy of data processing to do, as to why
        // Do not judge in the direct write if (! IsNaN (num = parseFloat (num)) && isFinite (num))
        // Is because parseFloat has a strange precision problem, such as parseFloat (12312312.1234567119)
        // The value becomes 12312312.123456713
        num = Number(num);

        if (num % 1 != 0) {
            // decimal
            num = typeof precision !== 'undefined' ? num.toFixed(precision) : num.toString();
            // Separate the fractional part and the integer part of the number
            parts = num.split('.');
            // The integer part is separated by [separator], borrowing a famous regular expression
            parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (separator || '.'));
            return parts.join(decimalSeparator);
        } else {
            //whole number
            // The integer part is separated by [separator], borrowing a famous regular expression
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (separator || '.'));
        }
    }
    return num;
}

//get country from code
export const countryNameFromCode = function (code) {
    if(code) {
        let elem = i18n.t('enums.countries').find(country => country.code.toLowerCase() == code.toLowerCase())
        return elem ? elem.name : code
    }
    return code
}

export const formatDateByLocale = function (date) {
    return moment(date).format(i18n.t('dateFormatMoment'))
}

export const formatDateToDateTimeByLocale = function (date) {
    return moment(date).format(i18n.t('dateTimeFormatMoment'))
}

export const formatDateToDateTimeShortByLocale = function (date) {
    return moment(date).format(i18n.t('dateTimeShortFormatMoment'))
}

