<template>
  <div class="animated fadeInRight" v-if="contextOrg">
    <div class="ibox">
      <div class="ibox-content p-5">
        <integration-list :integrations="integrations"/>
      </div>
    </div>
  </div>
</template>

<script>
import IntegrationList from '@/components/views/integrations/IntegrationList'
import {mapState} from "vuex"

export default {
  components: {
    IntegrationList
  },
  data() {
    return {
      error: null,
      info: null
    }
  },
  created() {
    this.$store.dispatch('integrations/fetchIntegrations', this.contextOrg.id)
  },
  watch: {
    contextOrg: function (newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.$store.dispatch('integrations/fetchIntegrations', this.contextOrg.id)
      }
    }
  },
  computed: {
    ...mapState({
      contextOrg: state => state.contextOrg,
      integrations: state => state.integrations.integrations
    })
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    }
  }
}
</script>