<template>
  <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(save)" inline>

    <b-form-select
        class="mr-3 m-b-md"
        id="input-message-filter-status"
        :plain="true"
        v-model="filter.status"
        :options="$t('messageCentre.' + type + '.details.statuses')"
    >
      <template #first>
        <b-form-select-option :value="null">-- {{ $t('messageCentre.' + type + '.attributes.status') }} --</b-form-select-option>
      </template>
    </b-form-select>

    <b-form-select
        class="mr-3 m-b-md"
        id="input-message-filter-category"
        :plain="true"
        v-model="filter.category"
        :options="Object.entries($t('enums.MessageCategory')).map(([key, value]) => ({['value']:key, ['text']: value}))"
    >
      <template #first>
        <b-form-select-option :value="null">-- {{ $t('messageCentre.' + type + '.attributes.category') }} --</b-form-select-option>
      </template>
    </b-form-select>

    <b-form-select
        class="mr-3 m-b-md"
        id="input-message-filter-severity"
        :plain="true"
        v-model="filter.severity"
        :options="Object.entries($t('enums.MessageSeverity')).map(([key, value]) => ({['value']:key, ['text']: value}))"
    >
      <template #first>
        <b-form-select-option :value="null">-- {{ $t('messageCentre.' + type + '.attributes.severity') }} --</b-form-select-option>
      </template>
    </b-form-select>

    <v-select
        :placeholder="'-- ' + $t('messageCentre.' + type + '.attributes.device') + ' --'"
        class="mr-3 m-b-md"
        id="input-message-filter-device"
        label="alias"
        v-model="filter.deviceAlias"
        :options="buildingDevices"
        :reduce="(device) => device.alias"
        :clearable="true"
    />

    <!---b-form-checkbox
          switch
          size="lg"
          class="mr-3 m-b-md"
          v-model="filter.priority"
          value="PINNED"
          unchecked-value="NORMAL"
      >{{ $t('enums.MessagePriority.PINNED') }}</b-form-checkbox>

    <b-form-checkbox
          switch
          size="lg"
          class="mr-3 m-b-md"
          v-model="filter.priority"
          value="IGNORED"
          unchecked-value="NORMAL"
      >{{ $t('enums.MessagePriority.IGNORED') }}</b-form-checkbox-->

    <b-form-input
        id="input-message-filter-description"
        class="mr-3 m-b-md"
        v-model="filter.description"
        :placeholder="$t('messageCentre.' + type + '.attributes.description')"
    ></b-form-input>
    <button class="btn btn-sm btn-primary m-r-sm m-b-md" @click.prevent="search">{{ $t('common.actions.search') }}</button>
    <button class="btn btn-sm btn-default m-b-md " @click.prevent="reset">{{ $t('common.actions.reset') }}</button>
  </b-form>
</template>

<script>
import {mapState} from "vuex"
import eventBus from "@/eventBus";

export default {
  props: {
    type: {
      validator: function (value) {
        return ['issues', 'advices'].includes(value)
      }
    }
  },
  data() {
    return {
      filter: {
        status: null,
        severity: null,
        priority: null,
        category: null,
        description: null,
        deviceAlias: null
      }
    }
  },
  created(){
    console.log('MessageListFilter')
  },
  computed: {
    ...mapState({
      buildingDevices: state => state.messageCentre.selectedBuildingDevices,
      listsFilter: state => state.messageCentre.listsFilter
    })
  },
  methods: {
    reset() {
      this.filter.deviceAlias = null
      this.filter.category = null
      this.filter.status = null
      this.filter.severity = null
      this.filter.description = null
      this.$store.commit("messageCentre/UPDATE_LISTS_FILTER", this.filter)
      eventBus.$emit("refresh-message-lists")
    },
    search() {
      this.$store.commit("messageCentre/UPDATE_LISTS_FILTER", this.filter)
      eventBus.$emit("refresh-message-lists")
    }
  }
}
</script>

<style>
#input-message-filter-device input[placeholder], #input-message-filter-device.vs--single.vs--open input.vs__search {
  min-width: 120px;
  font-size: 0.9rem;
}
</style>