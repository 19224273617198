<template>
	<div class="row animated fadeInDown">
      <div class="col-12">
          <div class="ibox">
              <div class="ibox-content">
                  <div class="alert alert-success" v-show="info">
                      <button class="close" type="button" @click="info=null">×</button>{{ info }}
                  </div>
                  <div class="alert alert-danger" v-show="error">
                      <button class="close" type="button" @click="error=null">×</button>{{ error }}
                  </div>
                  <div class="form-group row">
                      <label class="col-lg-3 col-form-label col-form-label-sm">{{ $t('userSettings.userId')}}</label>
                      <div class="col-lg-9 form-control form-control-sm">{{ $store.state.userInfo.username }}</div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label col-form-label-sm">{{ $t('userSettings.firstName')}}</label>
                    <div class="col-lg-9 form-control form-control-sm">{{ $store.state.userInfo.firstName }}</div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label col-form-label-sm">{{ $t('userSettings.lastName')}}</label>
                    <div class="col-lg-9 form-control form-control-sm">{{ $store.state.userInfo.lastName }}</div>
                  </div>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label col-form-label-sm">{{ $t('userSettings.email')}}</label>
                    <div class="col-lg-9 form-control form-control-sm">{{ $store.state.userInfo.email }}</div>
                  </div>
                  <div class="form-group row">
                      <label class="col-lg-3 col-form-label col-form-label-sm">{{ $t('userSettings.language')}}</label>
                      <div class="col-lg-9 form-control form-control-sm">
                          <a @click.prevent="setLang('en')" class="inline" :class="{'font-bold text-navy':$store.getters.getLang=='en'}">en</a>&nbsp;
                          <a @click.prevent="setLang('da')" class="inline" :class="{'font-bold text-navy':$store.getters.getLang=='da'}">da</a>&nbsp;
                      </div>
                  </div>
                  <div class="form-group row">
                      <label class="col-lg-3 col-form-label col-form-label-sm">{{ $t('userSettings.desktopNotificationsEnabled')}}</label>
                      <div class="col-lg-9 form-control-static form-control-sm">
                          <div class="onoffswitch">
                              <input type="checkbox" class="onoffswitch-checkbox" v-model="desktopNotificationsEnabled" id="desktopNotificationsEnabled">
                              <label class="onoffswitch-label" for="desktopNotificationsEnabled">
                                  <span class="onoffswitch-inner"></span>
                                  <span class="onoffswitch-switch"></span>
                              </label>
                          </div>
                      </div>
                  </div>

                  <template v-if="hasOneOfRoles('ADMIN', 'ORGANISATION_MANAGER', 'BUILDING_MANAGER')">

                    <h4 class="mt-4">{{$t('userSettings.messages.title')}}</h4>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label col-form-label-sm">{{ $t('userSettings.messages.frequency')}}</label>
                      <div class="col-lg-3 col-sm-5 form-control-static form-control-sm">
                        <b-form-radio-group
                            id="radio-group-frequency"
                            v-model="messagesFrequency"
                            name="radio-group-frequency">
                          <b-form-radio value="DAILY">{{ $t('userSettings.messages.frequencyOptionDaily') }}</b-form-radio>
                          <b-form-radio value="WEEKLY">{{ $t('userSettings.messages.frequencyOptionWeekly') }}</b-form-radio>
                          <b-form-radio value="DISABLED">{{ $t('userSettings.messages.frequencyOptionDisabled') }}</b-form-radio>
                        </b-form-radio-group>
                      </div>
                    </div>

                    <div class="form-group row" v-show="messagesFrequency !== 'DISABLED'">
                      <label class="col-lg-3 col-form-label col-form-label-sm">{{ $t('userSettings.messages.severity')}}</label>
                      <div class="col-lg-3 col-sm-5 form-control-static form-control-sm">
                        <b-form-select
                            id="input-message-severity"
                            :plain="true"
                            name="input-message-severity"
                            v-model="messagesSeverityThreshold"
                            :options="$t('enums.MessageSeverity')"
                        ></b-form-select>
                      </div>
                    </div>
                  </template>

                  <button type="submit" class="btn btn-sm btn-danger" @click="deleteUser">{{ $t('userSettings.requestDelete') }}</button>

              </div>
          </div>
      </div>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex"
import * as commons from '@/commons';
import i18n from "@/i18n"

export default {
  data() {
    return {
        error: null,
        info: null,
        userSettingsJson: null,
        originalContent: null,
    }
  },
  methods: {
    reset(){
        this.userSettingsJson = this.originalContent
    },
    setLang(lang){
        this.$store.dispatch('set_lang', lang)
    },
    submit(){
        this.error = null
        let userSettingsToSave = null
        
        try {
            userSettingsToSave = JSON.parse(this.userSettingsJson)
        } catch (e) {
            this.error = this.$i18n.t('userSettings.errors.invalidJson') + ': ' + e.message
            return
        }

        let vm = this
        let userForUpdate = { settings: userSettingsToSave }

        Vue.axios.patch(`/users/${this.currentUser.id}/settings`, userForUpdate).then(response => {
            vm.$store.commit('REPLACE_USER_SETTINGS', userSettingsToSave)
            vm.info = this.$i18n.t('userSettings.infos.successSave') 
        }).catch(error => {
            vm.error = commons.processRestError(error)
        })
    },
    deleteUser() {
      let self = this;
      this.confirmDialog({
        text: this.$t('userSettings.requestDeleteConfirmation'),
        callbackYes: function () {
          Vue.axios.delete('/user/' + self.currentUser.id)
              .then(response => {
                self.$toastr.i(i18n.t('userSettings.requestDeleteSuccess'))
                self.goBack()
                self.forceLogout()
              })
              .catch(error => {
                commons.processRestError(error)
              });      
        }
      })
    },
    forceLogout() {
      this.$store.dispatch('forceLogout', this.$data)
    }
  },
  computed: {
    ...mapState({
        currentUser: state => state.userInfo,
        userSettings: state => state.userInfo.settings
    }),

    desktopNotificationsEnabled: {
        get () {
            return this.$store.state.userInfo.settings.desktopNotificationsEnabled
        },
        set (value) {
            this.$store.dispatch('updateUserSettings', {'desktopNotificationsEnabled': value, send: true}, { root: true })
        }
    },

    messagesFrequency: {
      get () {
        return this.$store.state.userInfo.settings.messagesFrequency
      },
      set (value) {
        this.$store.dispatch('updateUserSettings', {'messagesFrequency': value, send: true}, { root: true })
      }
    },

    messagesSeverityThreshold: {
      get () {
        return this.$store.state.userInfo.settings.messagesSeverityThreshold
      },
      set (value) {
        this.$store.dispatch('updateUserSettings', {'messagesSeverityThreshold': value, send: true}, { root: true })
      }
    },
  },
  watch: {
    userSettings: {
      handler(val, oldVal) {
        let newValJson = JSON.stringify(val, null, 2)
        if(newValJson != this.originalContent){
            this.originalContent = this.userSettingsJson = newValJson
        }
      },
      deep: true
    }
  },
  mounted() {
    this.originalContent = this.userSettingsJson = JSON.stringify(this.userSettings, null, 2)
  },
  /*beforeRouteLeave (to, from, next) {
    var self = this;
    if(this.originalContent === this.userSettingsJson) {
        next()
    } else {
        this.confirmDialog({
            title: this.$i18n.t('common.popupTitle.warning'),
            text: this.$i18n.t('dataChangedConfirmRouteChange'),
            yesLabel: this.$i18n.t('common.actions.save'),
            noLabel:this.$i18n.t('common.actions.leavePage'),
            callbackYes: function(){
                self.submit()
                next()
            },
            callbackNo: function(){
                next()
            }
        })
    }
  }*/
};
</script>