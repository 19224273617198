import Vue from "vue"
import * as commons from '@/commons'
import moment from 'moment'

// default state
const getDefaultState = () => {

    return {
        activeTab: 0,
        availableForms: [],
        availableLocations: [],
        selectedForm: null,
        selectedFormFields: null,
        selectedLocation: null,
        feedbackConfig: {
            dateRange: {
                startDate: null,
                endDate: null
            }
        },
        formCharts: [],
        graphData: [],
        wordcloudData: [],
        forms: [],
        formDetails: {
            entity: {}
        }
    }
}

// initial state
const state = getDefaultState()

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('feedback/appInit')
        dispatch('resetFeedbackConfig')
    },

    resetFeedbackConfig({ commit, state }, data) {
        console.log('feedback/resetFeedbackConfig')

        if(state.feedbackConfig.dateRange.startDate === null || state.feedbackConfig.dateRange.endDate === null){
            return new Promise((resolve) => {
                console.log('feedback/resetFeedbackConfig')
                let startDate = new Date()
                startDate.setDate(startDate.getDate() - 30)
                startDate.setHours(0, 0, 0, 0)
                let endDate = new Date()
                endDate.setHours(23, 59, 59, 999)
                commit('UPDATE_FEEDBACK_CONFIG', {
                    dateRange: { startDate: startDate, endDate: endDate },
                })
                resolve()
            })
        }
    },

    loadFormsFromServer({ commit, state, dispatch }, org){
        //load forms for selected organisation
        Vue.axios.get('/admin/organisations/' + org.id + '/forms').then(response => {
            commit('UPDATE_AVAILABLE_FORMS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })

        dispatch('resetFeedbackConfig')
    },

    loadFormLocationsFromServer({ commit, state, dispatch }, data){
        console.log("data: " + JSON.stringify(data))
        //load forms for selected organisation
        commit('UPDATE_SELECTED_FORM', data.form.id)
        Vue.axios.get('/admin/organisations/' + data.org.id + '/forms/' + data.form.id).then(response => {
            commit('UPDATE_AVAILABLE_LOCATIONS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })

        dispatch('resetFeedbackConfig')
    },

    updateSelectedForm({ commit, state, dispatch }, form){
        Vue.axios.get('/admin/feedback/forms/' + form.id).then(response => {
            commit('UPDATE_SELECTED_FORM', response.data)
            commit('UPDATE_SELECTED_LOCATION', null)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    updateSelectedLocation({ commit, state, dispatch }, location){
        commit('UPDATE_SELECTED_LOCATION', location)
    },

    feedbackConfigDateRangeSelected({ commit, state, dispatch }, data){
        console.log('feedback/mapConfigDateRangeSelected')
        commit('UPDATE_FEEDBACK_CONFIG', { dateRange: data.dateRange })
        dispatch('fetchGraphData')
    },

    fetchGraphData({ commit, state, dispatch }, data){
        console.log('feedback/fetchGraphData')

        Vue.axios.get('/admin/feedback/forms/responses-graphs', {
            params: {
                form_id: state.selectedForm.id,
                location_id: state.selectedLocation.id,
                dateTimeFrom: moment(state.feedbackConfig.dateRange.startDate).toISOString(),
                dateTimeTo: moment(state.feedbackConfig.dateRange.endDate).toISOString()
            }
        }).then(response => {
            commit('UPDATE_GRAPH_DATA', { graphData: response.data })
        }).catch(error => {
            commons.processRestError(error)
        })

        Vue.axios.get('/admin/feedback/forms/responses-texts-all', {
            params: {
                form_id: state.selectedForm.id,
                location_id: state.selectedLocation.id,
                dateTimeFrom: moment(state.feedbackConfig.dateRange.startDate).toISOString(),
                dateTimeTo: moment(state.feedbackConfig.dateRange.endDate).toISOString(),
              }
        }).then(response => {
            commit('UPDATE_WORDCLOUD_DATA', { wordcloudData: response.data })
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchOrganisationForms({commit, state, dispatch, rootState}, id) {

        console.log('feedback/fetchOrganisationForms')

        Vue.axios.get('/admin/organisations/' + id + '/forms').then(response => {
            commit('SAVE_ORGANISATION_DETAILS_FORMS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchFormDetails({commit, state, dispatch, rootState}, id) {

        console.log('feedback/fetchFormDetails')

        Vue.axios.get('/admin/feedback/forms/' + id).then(response => {
            commit('SAVE_FORM_DETAILS_ENTITY', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },
}

// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['SAVE_ACTIVE_TAB']: (state, data) => {
        state.activeTab = data
    },

    ['UPDATE_SELECTED_FORM']: (state, data) => {
        state.selectedForm = data
    },

    ['UPDATE_SELECTED_LOCATION']: (state, data) => {
        state.selectedLocation = data
    },

    ['UPDATE_AVAILABLE_FORMS']: (state, data) => {
        state.availableForms = data
    },

    ['UPDATE_AVAILABLE_LOCATIONS']: (state, data) => {
        state.availableLocations = data
    },

    ['UPDATE_GRAPH_DATA']: (state, data) => {
        state.graphData = data
    },

    ['UPDATE_WORDCLOUD_DATA']: (state, data) => {
        state.wordcloudData = data
    },

    ['UPDATE_FEEDBACK_CONFIG']: (state, data) => {
        console.log('feedback/UPDATE_FEEDBACK_CONFIG: ' + JSON.stringify(data))
        state.feedbackConfig = Object.assign({}, state.feedbackConfig, data)
    },

    ['SAVE_ORGANISATION_DETAILS_FORMS']: (state, data) => {
        state.forms = data
    },

    ['SAVE_FORM_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.formDetails, 'entity', data)
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}