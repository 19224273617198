<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form @submit.stop.prevent="submitForm" novalidate>
        <!-- Analysis Name -->
        <b-form-group :label="$t('energySavings.analysisName')" label-for="analysis-name">
          <ValidationProvider :name="$t('energySavings.analysisName')" rules="required|min:3" v-slot="{ errors, classes }">  
            <b-form-input
              :class="classes"
              id="analysis-name"
              v-model="form.name"
              required
              aria-describedby="input-live-feedback"
            ></b-form-input>
            <span v-if="errors.length != 0">
              {{ $t('energySavings.validationErrors.invalidName') }}
            </span>
          </ValidationProvider>
        </b-form-group>

        <!-- Metering Points -->
        <b-form-group :label="$t('energySavings.meteringPoints')" label-for="metering-points">
          <ValidationProvider
            :name="$t('energySavings.meteringPoints')"
            rules="required"
            v-slot="{ errors, validate, classes }"
          >
            <v-select
              :class="classes"
              id="metering-points"
              v-model="form.meteringPoints"
              :options="meteringPoints"
              multiple
              :searchable="true"
              label="text"
              :reduce="point => point.value"
              @input="validate" 
              required
              aria-describedby="metering-points-live-feedback"
            ></v-select>
            <span v-if="errors.length != 0">
              {{ $t('energySavings.validationErrors.invalidMeteringPointSelection') }}
            </span>
          </ValidationProvider>
        </b-form-group>

        <!-- IEQ Parameters with tooltip -->
        <b-form-group label-for="ieq-parameters">
          <div class="d-flex align-items-center mb-2">
            <span>{{ $t('energySavings.ieqParameters') }}</span>
            <span class="fa-solid fa-circle-question info-icon" v-b-tooltip.hover :title="$t('energySavings.ieqTooltip')"></span>
          </div>
          <b-form-checkbox-group v-model="form.ieqParameters" name="ieq-parameters" stacked class="additional-parameters-group">
            <b-form-checkbox v-for="(translationKey, paramValue) in ieqParametersMap" :key="paramValue" :value="paramValue">
              {{ $t(translationKey) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

        <!-- Weather Parameters -->
        <b-form-group :label="$t('energySavings.weatherParameters')" label-for="weather-parameters">
          <b-form-checkbox-group v-model="form.weatherParameters" name="weather-parameters" stacked class="additional-parameters-group">
            <b-form-checkbox v-for="(translationKey, paramValue) in weatherParametersMap" :key="paramValue" :value="paramValue">
              {{ $t(translationKey) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

        <b-button variant="primary" @click="selectAllParameters">
          {{ $t('common.actions.selectAll') }}
        </b-button>

        <div v-if="!isOneOptionSelected" class="text-danger">
          {{ $t('energySavings.validationErrors.atLeastOneParameterRequired') }}
        </div>

        <!-- Log Selection Table -->
        <div class="row mt-4 mb-4">
          <div class="input-group col-lg-6 align-content-center">
            <span class="input-group-prepend">
              <b-button class="btn btn-sm btn-primary" @click="clearFilter">{{ $t('reporting.reports.clear') }}</b-button>
            </span>
            <b-form-input
              type="text"
              debounce="300"
              max="50"
              :placeholder="$t('enterForSearch')"
              v-model="table.filter"
              class="form-control form-control-sm"
            />
            <span class="input-group-append">
              <b-button class="btn btn-sm btn-primary" @click="refreshLogs">
                <i class="fas fa-refresh mr-1"></i>
                <span>{{ $t('reporting.reports.refresh') }}</span>
              </b-button>
            </span>
          </div>
          <div class="col-lg-3 m-t-sm text-right">
            <row-count :size="table.size" :page="table.page" :total="logs.length"></row-count>
          </div>
          <div class="col-lg-3 text-right" v-if="logs.length && logs.length > table.size">
            <b-pagination
              v-if="logs.length"
              size="sm"
              class="m-t-xs"
              align="right"
              :total-rows="logs.length"
              v-model="table.page"
              :per-page="table.size"
            ></b-pagination>
          </div>
        </div>

        <!-- Log Table -->
        <div class="row mt-2 mb-2">
          <div class="col-lg-12">
            <b-table
              responsive
              hover
              striped
              v-if="logs && logs.length"
              class="text-nowrap"
              thead-class="text-nowrap"
              :filter="table.filter"
              :fields="table.fields"
              :current-page="table.page"
              :per-page="table.size"
              :items="processedLogs"
              :sort-by.sync="table.sortBy"
              :sort-desc.sync="table.sortDesc"
              @row-clicked="toggleLogSelection"
            >
              <template #cell(selected)="data">
                <b-form-checkbox v-model="data.item.selected" @change="toggleLogSelection(data.item)">
                </b-form-checkbox>
              </template>
              <template #cell(id)="data">
                <span>{{ data.item.id }}</span>
              </template>
              <template #cell(title)="data">
                <span>{{ data.item.title }}</span>
              </template>
              <template #cell(tags)="data">
                <div class="tags-circles">
                  <div 
                    v-for="tag in data.item.tags" 
                    :key="tag" 
                    class="tag-circle"
                    :title="getTagLabel(tag)"
                    :style="{ backgroundColor: getColorForTag(tag) }"
                  >
                    <div class="tag-tooltip">{{ getTagLabel(tag) }}</div>
                  </div>
                </div>
              </template>
              <template #cell(locations)="data">
                <b-tooltip :target="'location-tooltip-' + data.item.id">
                  <span v-if="data.item.locations.length">{{ formatLocationsTooltip(data.item.locations) }}</span>
                </b-tooltip>
                <span :id="'location-tooltip-' + data.item.id">
                  <i class="fa-solid fa-location-dot" :style="data.item.locations.length ? 'color: #61AEB7' : 'color: #676a6c'"></i>
                  x
                  {{ data.item.locations.length }} 
                </span>
              </template>
            </b-table>
            <b-pagination
              v-if="logs.length && logs.length > table.size"
              size="sm"
              class="m-t-xs"
              align="right"
              :total-rows="logs.length"
              v-model="table.page"
              :per-page="table.size"
            ></b-pagination>
          </div>
        </div>

        <!-- Advanced Configuration -->
        <b-button @click="toggleCollapse" variant="link" class="mb-2">
          <i :class="{'fas fa-chevron-down': !isCollapseOpen, 'fas fa-chevron-up': isCollapseOpen}" class="mr-1"></i>{{ $t('energySavings.advancedConfiguration') }}
        </b-button>
        <b-collapse id="config-collapse" v-model="isCollapseOpen">
          <!-- Baseline and Reporting Dates -->
          <div class="row">
            <div class="col-md-6">
              <b-form-group :label="$t('energySavings.baselineStartDate')" label-for="baseline-start-date">
                <b-form-datepicker
                  id="baseline-start-date"
                  v-model="form.baselineStartDate"
                  aria-describedby="baseline-start-date-live-feedback"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  locale="en-GB"
                  :state="this.validateDates()"
                  no-flip
                ></b-form-datepicker>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group :label="$t('energySavings.baselineEndDate')" label-for="baseline-end-date">
                <b-form-datepicker
                  id="baseline-end-date"
                  v-model="form.baselineEndDate"
                  aria-describedby="baseline-end-date-live-feedback"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  locale="en-GB"
                  :state="this.validateDates()"
                  no-flip
                ></b-form-datepicker>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group :label="$t('energySavings.reportingStartDate')" label-for="reporting-start-date">
                <b-form-datepicker
                  id="reporting-start-date"
                  v-model="form.reportingStartDate"
                  aria-describedby="reporting-start-date-live-feedback"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  locale="en-GB"
                  :state="this.validateDates()"
                  no-flip
                ></b-form-datepicker>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group :label="$t('energySavings.reportingEndDate')" label-for="reporting-end-date">
                <b-form-datepicker
                  id="reporting-end-date"
                  v-model="form.reportingEndDate"
                  aria-describedby="reporting-end-date-live-feedback"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  locale="en-GB"
                  :state="this.validateDates()"
                  no-flip
                ></b-form-datepicker>
              </b-form-group>
            </div>
          </div>
          <span v-if="this.dateError" class="invalid-date">
            {{ this.dateError }}
          </span>
          <b-form-group :label="$t('energySavings.description')" label-for="description">
            <b-form-textarea
              id="description"
              v-model="form.description"
              aria-describedby="description-live-feedback"
              rows="3"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group :label="$t('energySavings.responsiblePerson')" label-for="responsible-person">
            <b-form-input
              id="responsible-person"
              v-model="form.responsible"
              aria-describedby="responsible-person-live-feedback"
            ></b-form-input>
          </b-form-group>
        </b-collapse>

        <!-- Submit and Cancel Buttons in a Row with Padding -->
        <div class="row mt-2">
          <div class="col-md-6 d-flex align-items-center justify-content-start">
            <!-- Wrapper Div for Button and Tooltip -->
            <div class="submit-button-wrapper" id="submit-btn-wrapper">
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                id="submit-btn"
              >
                {{ $t('common.actions.save') }}
              </b-button>
            </div>

            <!-- Cancel Button -->
            <b-button
              type="button"
              variant="secondary"
              @click="$emit('close')"
            >
              {{ $t('common.actions.cancel') }}
            </b-button>
          </div>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapState } from 'vuex';
import RowCount from '@/components/common/RowCount';
import Vue from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    RowCount,
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        responsible: '',
        baselineStartDate: null,
        baselineEndDate: null,
        reportingStartDate: null,
        reportingEndDate: null,
        meteringPoints: [], // For metering points selection
        ieqParameters: [], // Initially an empty array, will populate with IEQ parameters
        weatherParameters: [], // Initially an empty array, will populate with weather parameters
        logs: [] // Logs related to the building
      },
      table: {
        filter: '',
        fields: [
          { key: 'selected', label: '', sortable: false },
          { key: 'id', label: this.$t('orgAdmin.building.logs.logId'), sortable: true },
          { key: 'title', label: this.$t('orgAdmin.building.logs.title'), sortable: true },
          { key: 'tags', label: this.$t('orgAdmin.building.logs.tags'), sortable: false },
          { key: 'description', label: this.$t('orgAdmin.building.logs.description'), sortable: false },
          { key: 'locations', label: this.$t('orgAdmin.building.logs.locations'), sortable: false },
          { key: 'startDate', label: this.$t('orgAdmin.building.logs.startDate'), sortable: true, formatter: this.formatDate },
          { key: 'endDate', label: this.$t('orgAdmin.building.logs.endDate'), sortable: true, formatter: this.formatDate },
        ],
        page: 1,
        size: 10,
        sortBy: 'id',
        sortDesc: true
      },
      meteringPoints: [], // Loaded dynamically from backend
      weatherParametersMap: {
        'mean_temp': 'energySavings.params.ambientTemperature',
        'mean_relative_hum': 'energySavings.params.ambientHumidity',
        'mean_wind_speed': 'energySavings.params.windSpeed',
        'mean_wind_dir': 'energySavings.params.windDirection',
        'mean_pressure': 'energySavings.params.ambientPressure',
        'mean_radiation': 'energySavings.params.solarRadiation',
        'mean_cloud_cover': 'energySavings.params.cloudCover',
        "acc_precip": "energySavings.params.accPrecip",
        "snow_depth": "energySavings.params.snowDepth"
      },
      ieqParametersMap: {
        'motion': 'energySavings.params.indoorMotion',
        'co2': 'energySavings.params.indoorCO2',
        'light': 'energySavings.params.indoorIlluminance',
        'temperature': 'energySavings.params.indoorTemperature',
        'humidity': 'energySavings.params.indoorHumidity'
      },
      tagDictionary: {
        Heating: this.$t('orgAdmin.building.logs.tagPresets.heating'),
        Water: this.$t('orgAdmin.building.logs.tagPresets.water'),
        Electric: this.$t('orgAdmin.building.logs.tagPresets.electricity'),
        Ventilation: this.$t('orgAdmin.building.logs.tagPresets.ventilation')
      },
      tagOptions: ['Heating', 'Water', 'Electric', 'Ventilation'],
      isCollapseOpen: false
    };
  },
  computed: {
    ...mapState({
      buildingId: state => state.buildings.buildingDetails.entity.id,
      logs: state => state.buildingLogs.logs
    }),
    isOneOptionSelected() {
      return this.form.ieqParameters.length > 0 || this.form.weatherParameters.length > 0;
    },
    processedLogs() {
      return this.logs.map(log => ({
        ...log,
        id: Number(log.id),
        startDate: new Date(log.startDate),
        endDate: new Date(log.endDate),
        selected: this.form.logs.includes(log.id)
      }));
    }
  },
  methods: {
    submitForm() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.$bvToast.toast(this.$t('energySavings.toast.validationError'), {
            title: 'Error',
            variant: 'danger',
            autoHide: false,
            solid: true
          });
          return;
        }

        if (!this.isOneOptionSelected) {
          this.$bvToast.toast(this.$t('energySavings.validationErrors.atLeastOneParameterRequired'), {
            title: 'Error',
            variant: 'danger',
            autoHide: false,
            solid: true
          });
          return;
        }

        if (!this.validateDates()) {
          this.$bvToast.toast(this.dateError, {
            title: 'Error',
            variant: 'danger',
            autoHide: false,
            solid: true
          });
          return;
        }

        if (success) {
          const analysisData = {
            name: this.form.name,
            description: this.form.description,
            responsible: this.form.responsible,
            baselineFrom: this.dateFormatter(this.form.baselineStartDate),
            baselineTo: this.dateFormatter(this.form.baselineEndDate),
            reportingFrom: this.dateFormatter(this.form.reportingStartDate),
            reportingTo: this.dateFormatter(this.form.reportingEndDate),
            buildingId: this.buildingId,
            meteringPointIds: this.form.meteringPoints,
            IEQParameters: this.form.ieqParameters,
            weatherParameters: this.form.weatherParameters
          };
          
          this.$store.dispatch('energySavings/addAnalysis', { buildingId: this.buildingId, analysis: analysisData })
            .then(() => {
              this.$bvToast.toast(this.$t('energySavings.toast.createSuccess'), {
                title: this.$t('common.actions.ok'),
                variant: 'success',
                solid: true,
                autoHide: false
              });
              this.$emit('success');
            })
            .catch(error => {
              console.error('Error creating analysis:', error);
              this.$bvToast.toast(this.$t('energySavings.toast.createError'), {
                title: this.$t('common.popupTitle.warning'),
                variant: 'danger',
                autoHide: false,
                solid: true
              });
              this.$emit('close');
            });
        }
      });
    },
    selectAllParameters() {
      this.form.ieqParameters = Object.keys(this.ieqParametersMap);
      this.form.weatherParameters = Object.keys(this.weatherParametersMap);
    },
    dateFormatter(date) {
      return date ? new Date(date).toISOString().substr(0, 10) : null;
    },
    formatLocationsTooltip(locations) {
      return locations.map(loc => loc?.name || "").join(', ');
    },
    formatCost(cost) {
      return cost ? `${cost.toFixed(2)}` : '-';
    },
    getColorForTag(tag) {
      const predefinedColors = {
        Heating: 'red',
        Water: 'blue',
        Electric: 'gold',
        Ventilation: 'indigo',
        default: 'secondary'
      };
      if (this.tagOptions.includes(tag)) {
        return predefinedColors[tag];
      }
      const hash = this.hashStringToNumber(tag);
      const index = Math.abs(hash) % this.colorList.length;
      return this.colorList[index];
    },
    getTagLabel(tag) {
      return this.tagDictionary[tag] || tag;
    },
    hashStringToNumber(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    formatDate(value) {
      return new Date(value).toLocaleDateString("en-GB");
    },
    validateDates() {
      const { baselineStartDate, baselineEndDate, reportingStartDate, reportingEndDate } = this.form;

      // Check if all date fields are filled
      if (!baselineStartDate || !baselineEndDate || !reportingStartDate || !reportingEndDate) {
        this.dateError = this.$t('energySavings.validationErrors.allDatesRequired');
        return false;
      }

      const baselineDuration = new Date(baselineEndDate) - new Date(baselineStartDate);
      const reportingDuration = new Date(reportingEndDate) - new Date(reportingStartDate);
      const oneYearInMs = 365 * 24 * 60 * 60 * 1000; // milliseconds in one year
      const oneMonthInMs = 30 * 24 * 60 * 60 * 1000; // milliseconds in one month

      // Check if baseline start date is before the end date
      if (new Date(baselineStartDate) >= new Date(baselineEndDate)) {
        this.dateError = this.$t('energySavings.validationErrors.baselineStartBeforeEnd');
        return false;
      }

      // Check if reporting start date is before the end date
      if (new Date(reportingStartDate) >= new Date(reportingEndDate)) {
        this.dateError = this.$t('energySavings.validationErrors.reportingStartBeforeEnd');
        return false;
      }

      // Ensure baseline period is at least 1 year long
      if (baselineDuration < oneYearInMs) {
        this.dateError = this.$t('energySavings.validationErrors.baselineTooShort');
        return false;
      }

      // Ensure reporting period is at least 1 month long
      if (reportingDuration < oneMonthInMs) {
        this.dateError = this.$t('energySavings.validationErrors.reportingTooShort');
        return false;
      }

      // Clear the error if no issues
      this.dateError = '';
      return true;
    },

    fetchMeteringPoints() {
      const deviceType = 'METERING_POINT';  // Enum value for metering points
      Vue.axios.get(`/admin/buildings/${this.buildingId}/devices`, {
        params: {
          type: deviceType
        }
      })
        .then(response => {
          this.meteringPoints = response.data.map(point => ({
            value: point.id,
            text: point.alias
          }));
        })
        .catch(error => {
          console.error('Error fetching metering points:', error);
        });
    },
    clearFilter() {
      this.table.filter = '';
    },
    refreshLogs() {
      if (this.buildingId) {
        this.$store.dispatch('buildingLogs/loadLogsForBuilding', this.buildingId);
      }
    },
    toggleLogSelection(log) {
      const index = this.form.logs.indexOf(log.id);
      if (index > -1) {
        this.form.logs.splice(index, 1);
      } else {
        this.form.logs.push(log.id);
      }
      this.getDateRange();
    },
    getDateRange() {
      if (!this.form.logs.length) {
        return;
      }

      // Extract the selected logs
      const selectedLogs = this.logs.filter(log => this.form.logs.includes(log.id));

      // Find the earliest and latest dates from the selected logs
      const earliestLogDate = selectedLogs.reduce((earliest, log) => {
        const startDate = new Date(log.startDate);
        return startDate < earliest ? startDate : earliest;
      }, new Date(selectedLogs[0].startDate));

      const latestLogDate = selectedLogs.reduce((latest, log) => {
        const endDate = new Date(log.endDate);
        return endDate > latest ? endDate : latest;
      }, new Date(selectedLogs[0].endDate));

      // Set the date ranges based on the selected logs
      const oneDayInMs = 24 * 60 * 60 * 1000;
      const oneYearInMs = 366 * oneDayInMs;

      // Baseline period: one year before the earliest log date
      this.form.baselineStartDate = new Date(earliestLogDate.getTime() - oneYearInMs);
      this.form.baselineEndDate = new Date(earliestLogDate.getTime() - oneDayInMs);

      // Reporting period: one year after the latest log date
      this.form.reportingStartDate = new Date(latestLogDate.getTime() + oneDayInMs);
      this.form.reportingEndDate = new Date(latestLogDate.getTime() + oneYearInMs);
    },
    toggleCollapse() {
      this.isCollapseOpen = !this.isCollapseOpen;
    }
  },
  created() {
    this.fetchMeteringPoints();
    // Fetch logs related to the building
    this.refreshLogs();
  }
};
</script>

<style>
.b-toaster {
  z-index: 9000 !important;
}
</style>

<style scoped>
.invalid-date {
  padding-bottom: 12px;
  display: inline-block;
  color: #E94F37;
  border-color: #A31621;
}

.text-nowrap {
  white-space: nowrap;
}

.icon-wrapper {
  display: flex;
  align-items: center;
}

.download-link,
.delete-link {
  cursor: pointer;
}

.download-link i,
.delete-link i {
  margin: 0 5px;
}

.tags-circles {
  display: flex;
  align-items: center;
}

.tag-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  position: relative;
}

.tag-circle:hover .tag-tooltip {
  visibility: visible;
  opacity: 1;
}

.tag-tooltip {
  visibility: hidden;
  opacity: 0;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the circle */
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center it */
  width: 120px;
  transition: opacity 0.3s;
}

.tag-tooltip::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.info-icon{
  margin-left: 0.5rem;
  color: #61AEB7;
  font-size: smaller;
}

</style>
