import Vue from "vue";
import * as commons from '@/commons'
import router from '@/router'
import {getUserBuildingAuthorities, getUserOrganisationAuthorities, hasRole} from '@/commons'

// default state
const getDefaultState = () => {
    return {
        organisations: [],
        buildings: [],
        maps: [],
        locations: [],
        orgAdminActiveTab: 0,
        organisationDetails: {
            activeTab: 0
        },
        buildingDetails: {
            activeTab: 0,
        }
    }
}

// initial state
const state = getDefaultState()

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('orgAdmin/appInit')
    },

    fetchUserResources({commit, state, dispatch, rootState}, data) {

        console.log('orgAdmin/fetchUserResources')

        if (hasRole('ADMIN')) {

            //load all organisations
            Vue.axios.get('/admin/organisations').then(response => {
                commit('SAVE_ORGANISATIONS', response.data.map(org => {
                    org.type = 'ORGANISATION'
                    return org
                }))
            }).catch(error => {
                commons.processRestError(error)
            })

        } else {

            let userOrganisationAuthorities = []
            let userBuildingAuthorities = []
            let userLocationAuthorities = []

            if (hasRole('ORGANISATION_MANAGER')) {

                //load only organisation that user is authorized (if any)
                userOrganisationAuthorities = getUserOrganisationAuthorities()
                if (userOrganisationAuthorities.length > 0) {
                    commit('SAVE_ORGANISATIONS', userOrganisationAuthorities.map(auth => {
                        auth.authorizationTarget.type = 'ORGANISATION'
                        return auth.authorizationTarget
                    }))

                }

            }

            if (hasRole('BUILDING_MANAGER')) {

                //load only buildings that user is autorised (if any)
                userBuildingAuthorities = getUserBuildingAuthorities()
                if (userBuildingAuthorities.length > 0) {
                    commit('SAVE_BUILDINGS', userBuildingAuthorities.map(auth => {
                        auth.authorizationTarget.type = 'BUILDING'
                        return auth.authorizationTarget
                    }))
                }

            }

            // if user has only one resource authority route him to that resource immediately
            if((userOrganisationAuthorities.length + userBuildingAuthorities.length + userLocationAuthorities.length) == 1) {

                if (userOrganisationAuthorities.length == 1) {
                    router.push({ path: `/admin/organisations/${userOrganisationAuthorities[0].authorizationTarget.id}` })
                } else if (userBuildingAuthorities.length == 1) {
                    router.push({ path: `/admin/buildings/${userBuildingAuthorities[0].authorizationTarget.id}` })
                } else {
                    router.push({ path: `/admin/locations/${userLocationAuthorities[0].authorizationTarget.id}` })
                }

            }
        }

        commit('SAVE_ORGANISATION_DETAILS_ACTIVE_TAB', 0)
        commit('SAVE_BUILDING_DETAILS_ACTIVE_TAB', 0)
    }

}


// getters
const getters = {}

// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['SAVE_ORGANISATIONS']: (state, data) => {
        state.organisations = data
    },

    ['SAVE_BUILDINGS']: (state, data) => {
        state.buildings = data
    },

    ['SAVE_ORG_ADMIN_ACTIVE_TAB']: (state, data) => {
        state.orgAdminActiveTab = data
    },

    ['SAVE_ORGANISATION_DETAILS_ACTIVE_TAB']: (state, data) => {
        Vue.set(state.organisationDetails, 'activeTab', data)
    },

    ['SAVE_BUILDING_DETAILS_ACTIVE_TAB']: (state, data) => {
        Vue.set(state.buildingDetails, 'activeTab', data)
    }

}

export default {
    namespaced: true, state, getters, actions, mutations
}