<template>
  <div v-if="selectedDevice">

    <div class="row text-center mt-2">
      <div class="col-12">
          <div class="text-center pt-4">
            <div class="row">
              <div class="col-12">
                <date-range-picker
                    ref="picker"
                    opens="left"
                    :locale-data="Object.assign({}, $t('dateRangePickerLabels'), { format: 'dd-mm-yyyy' })"
                    :maxDate="maxDate"
                    :autoApply="false"
                    :showDropdowns="true"
                    :ranges="ranges"
                    v-model="dateRange"
                    @update="selectedDateRangeChanged"
                >
                  <template v-slot:input="picker">
                    <div style="max-width: 350px;">
                      {{ picker.startDate | formatDateByLocale }} - {{ picker.endDate | formatDateByLocale}}
                    </div>
                  </template>
                </date-range-picker>
              </div>
            </div>
          </div>
      </div>
    </div>

    <template v-if="availableSensorAttributes && availableSensorAttributes.length > 0">
      <div class="row mt-3">
        <div class="col-12">
          <h4>{{ $t('graphs.meteringPoints.graphConfig.showData') }}</h4>
            <b-form-group id="input-group-sensor-attributes">
              <b-form-checkbox-group
                  switches
                  stacked
                  size="lg"
                  id="input-sensor-attributes"
                  v-model="selectedSensorAttributes"
                  :options="availableSensorAttributesOptions"
                  name="input-sensor-attribute"
                  @change="selectedSensorAttributesChanged"
              ></b-form-checkbox-group>
            </b-form-group>
        </div>
      </div>

      <div class="row mt-3" v-if="false">
        <div class="col-6">
          <h4>{{ $t('graphs.meteringPoints.graphConfig.meanSelector.title') }}</h4>
          <b-form-select
              :plain="true"
              name="input-mean-value"
              v-model="meanDuration"
              :options="availableMeanValues"
              @change="selectedMeanDurationChanged"
          ></b-form-select>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <h4>{{ $t('graphs.meteringPoints.graphConfig.graphOptions.titleGraphOptions') }}</h4>
          <b-form-checkbox v-model="graphOptions.enableWeekends" name="enableWeekends" switch size="lg" @change="selectedOptionsChanged">
            {{  $t('graphs.locationComparison.graphConfig.graphOptions.enableWeekends') }}
          </b-form-checkbox>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import {mapState} from "vuex"
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    DateRangePicker
  },
  data() {
    const today = new Date();
    return {
      info: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedSensorAttributes: [],
      meanDuration: "P1D",
      graphOptions: {
        enableWeekends: true
      },
      ranges: {
        [this.$t('graphs.meteringPoints.graphConfig.dateRangeSelector.options.today')]: [new Date(today.getFullYear(), today.getMonth(), today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
        [this.$t('graphs.meteringPoints.graphConfig.dateRangeSelector.options.yesterday')]: [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1), new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)],
        [this.$t('graphs.meteringPoints.graphConfig.dateRangeSelector.options.last7days')]: [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
        [this.$t('graphs.meteringPoints.graphConfig.dateRangeSelector.options.last30days')]: [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
        [this.$t('graphs.meteringPoints.graphConfig.dateRangeSelector.options.currentMonth')]: [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
        [this.$t('graphs.meteringPoints.graphConfig.dateRangeSelector.options.lastMonth')]: [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        [this.$t('graphs.meteringPoints.graphConfig.dateRangeSelector.options.thisYear')]: [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        [this.$t('graphs.meteringPoints.graphConfig.dateRangeSelector.options.lastYear')]: [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)]
      }
    }
  },
  created(){
    console.log('GraphConfig created() $route.name: ' + this.$route.name)
    this.selectedSensorAttributes = [...this.graphConfig.selectedSensorAttributes]
    this.dateRange = this.graphConfig.dateRange
    this.graphOptions.enableWeekends = this.graphConfig.enableWeekends
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedDevice: state => state.graphMeteringPoints.selectedDevice,
      availableSensorAttributes: state => state.graphMeteringPoints.availableSensorAttributes,
      graphConfig: state => state.graphMeteringPoints.graphConfig,
      selectedSensorAttributesFromState: state => state.graphMeteringPoints.graphConfig.selectedSensorAttributes
    }),
    maxDate(){
      let nowMoved = this.$moment.tz(new Date(), this.selectedDevice.building.timeZone)
      let newMaxDate = nowMoved.format("YYYY-MM-DD HH:mm")
      console.log('maxDate resolved date ' + newMaxDate)
      return newMaxDate
    },
    availableSensorAttributesOptions() {
      if(this.availableSensorAttributes && this.availableSensorAttributes.length > 0) {
        return this.availableSensorAttributes.map( item => { return { text: this.$t('enums.sensorType.' + item + '.name'), value: item } })
      }
      return []
    },
    availableMeanValues(){
      let options = [...this.$t('graphs.meteringPoints.graphConfig.meanSelector.options')]
      const diffMillis = Math.abs(this.dateRange.endDate - this.dateRange.startDate)
      if(diffMillis < (1000 * 60 * 60 * 24 * 15)){
        options.pop()
        options.pop()
      } else if(diffMillis < (1000 * 60 * 60 * 24 * 61)){
        options.pop()
      }
      return options
    }
  },
  watch: {
    selectedSensorAttributesFromState: function(newVal, oldVal) {
      console.log('selectedSensorAttributesFromState changed: ', JSON.stringify(newVal), ' | was: ', oldVal)
      this.selectedSensorAttributes = [...newVal]
      console.log('selectedSensorAttributes set ' + JSON.stringify(this.selectedSensorAttributes))
    }
  },
  methods: {
    selectedDateRangeChanged(){
      this.$store.dispatch('graphMeteringPoints/graphConfigDateRangeSelected', { dateRange: this.dateRange })
    },
    selectedSensorAttributesChanged() {
      console.log('selectedSensorAttributes changed: ' + this.selectedSensorAttributes)
      this.$store.dispatch('graphMeteringPoints/graphConfigSensorAttributesChanged', this.selectedSensorAttributes)
    },
    selectedMeanDurationChanged(){
      this.$store.dispatch('graphMeteringPoints/selectedMeanDurationSelected', this.meanDuration)
    },
    selectedOptionsChanged() {
      console.log('graphOptions changed: ' + this.graphOptions)
      this.$store.dispatch('graphMeteringPoints/graphConfigOptionsChanged', this.graphOptions)
    }
  }
}
</script>

<style>
.b-time  .form-control {
  padding: 6px;
}
.custom-switch.b-custom-control-lg .custom-control-label, .input-group-lg .custom-switch .custom-control-label {
  font-size: 1rem;
  padding-top: 3px;
  line-height: 1.5;
}
</style>