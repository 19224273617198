import Vue from "vue"
import * as commons from '@/commons'
import router from '@/router'
import moment from 'moment'
import i18n from "@/i18n";

// default state
const getDefaultState = () => {

  return {
    results: '',
  }
}

// initial state
const state = getDefaultState()

// action
const actions = {

  appInit({ commit, state, dispatch, rootState }) {
    console.log('testExternalApi/appInit')
  },

  doTests({ commit, state, dispatch, rootState }){

    commit('RESULT_RESET')

    Vue.axios.get('/resources', { baseURL: process.env.VUE_APP_ROOT_API + '/api/v1' }).then(response => {
      commit('RESULT_APPEND', '\n\n/api/v1/resources\n\n')
      commit('RESULT_APPEND_JSON', response.data)

      let resources = response.data

      if(resources && resources.length > 0) {

        if (resources.some(resource => resource.role == 'ORGANISATION_MANAGER')) {

          resources.filter(resource => resource.role == 'ORGANISATION_MANAGER').forEach(resource => {

            Vue.axios.get('/organisations/' + resource.resource.id, { baseURL: process.env.VUE_APP_ROOT_API + '/api/v1' }).then(responseNested => {
              commit('RESULT_APPEND', '\n\n/api/v1/organisations/' + resource.resource.id + '\n\n')
              commit('RESULT_APPEND_JSON', responseNested.data)
            }).catch(error => {
              commit('RESULT_APPEND', '\n\n/api/v1/organisations' + resource.resource.id + '\n\n')
              commit('RESULT_APPEND', commons.processRestError(error))
            })

            Vue.axios.get('/organisations/' + resource.resource.id + '/devices', { baseURL: process.env.VUE_APP_ROOT_API + '/api/v1' }).then(responseNested => {
              commit('RESULT_APPEND', '\n\n/api/v1/organisations/' + resource.resource.id + '/devices\n\n')
              commit('RESULT_APPEND_JSON', responseNested.data)
            }).catch(error => {
              commit('RESULT_APPEND', '\n\n/api/v1/organisations/' + resource.resource.id + '/devices\n\n')
              commit('RESULT_APPEND', commons.processRestError(error))
            })
          })
        }

        if (resources.some(resource => resource.role == 'BUILDING_MANAGER')) {

          resources.filter(resource => resource.role == 'BUILDING_MANAGER').forEach(resource => {

            Vue.axios.get('/buildings/' + resource.resource.id, { baseURL: process.env.VUE_APP_ROOT_API + '/api/v1' }).then(responseNested => {
              commit('RESULT_APPEND', '\n\n/api/v1/buildings/' + resource.resource.id + '\n\n')
              commit('RESULT_APPEND_JSON', responseNested.data)
            }).catch(error => {
              commit('RESULT_APPEND', '\n\n/api/v1/buildings/' + resource.resource.id + '\n\n')
              commit('RESULT_APPEND', commons.processRestError(error))
            })

            Vue.axios.get('/buildings/' + resource.resource.id + '/devices', { baseURL: process.env.VUE_APP_ROOT_API + '/api/v1' }).then(responseNested => {
              commit('RESULT_APPEND', '\n\n/api/v1/buildings/' + resource.resource.id + '/devices\n\n')
              commit('RESULT_APPEND_JSON', responseNested.data)
            }).catch(error => {
              commit('RESULT_APPEND', '\n\n/api/v1/buildings/' + resource.resource.id + '/devices\n\n')
              commit('RESULT_APPEND', commons.processRestError(error))
            })
          })
        }

        if (resources.some(resource => resource.role == 'LOCATION_MANAGER')) {

          resources.filter(resource => resource.role == 'LOCATION_MANAGER').forEach(resource => {

            Vue.axios.get('/locations/' + resource.resource.id, { baseURL: process.env.VUE_APP_ROOT_API + '/api/v1' }).then(responseNested => {
              commit('RESULT_APPEND', '\n\n/api/v1/locations/' + resource.resource.id + '\n\n')
              commit('RESULT_APPEND_JSON', responseNested.data)
            }).catch(error => {
              commit('RESULT_APPEND', '\n\n/api/v1/locations' + resource.resource.id + '\n\n')
              commit('RESULT_APPEND', commons.processRestError(error))
            })

            Vue.axios.get('/locations/' + resource.resource.id + '/devices', { baseURL: process.env.VUE_APP_ROOT_API + '/api/v1' }).then(responseNested => {
              commit('RESULT_APPEND', '\n\n/api/v1/locations/' + resource.resource.id + '/devices\n\n')
              commit('RESULT_APPEND_JSON', responseNested.data)
            }).catch(error => {
              commit('RESULT_APPEND', '\n\n/api/v1/locations/' + resource.resource.id + '/devices\n\n')
              commit('RESULT_APPEND', commons.processRestError(error))
            })
          })
        }

      }

    }).catch(error => {
      commit('RESULT_APPEND', '\n\n/api/v1/resources\n\n')
      commit('RESULT_APPEND', commons.processRestError(error))
    })

  }

}


// mutations
const mutations = {

  ['RESULT_RESET']: (state, data) => {
    state.results = ''
  },

  ['RESULT_APPEND']: (state, data) => {
    state.results += data
  },

  ['RESULT_APPEND_JSON']: (state, data) => {
    state.results += JSON.stringify(data, undefined, 2)
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}