<template>
  <div>
    <transition name="fade" appear>
      <div>
        <div class="row text-center mt-5">
          <div class="col-12">
            <b-button-group>
              <b-button :variant="selectedFetchType!=='datetime' ?  'primary': 'default'" @click="selectLiveStream"><template v-if="activeFetchType=='live'"><i class="fa fa-spinner fa-pulse"></i>&nbsp;&nbsp;</template>{{ $t('indoorClimate.mapConfig.displayTypeOption.live') }}</b-button>
              <b-button :variant="selectedFetchType=='datetime' ?  'primary': 'default'" @click="selectedFetchType = 'datetime'"><template v-if="activeFetchType!=='datetime'">{{ $t('indoorClimate.mapConfig.displayTypeOption.datetime') }}</template><template v-else>{{ $store.getters["indoorClimate/mapConfigDateTimeFormatted"] }}</template></b-button>
            </b-button-group>
          </div>
          <div class="col-12">
            <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
              <div class="text-center pt-4" v-if="selectedFetchType=='datetime'">
                <div class="row">
                  <div class="col-lg-7 col-md-12 text-right text-lg-right text-md-center text-sm-center">
                    <b-calendar
                        v-model="datePickerValue"
                        v-bind="$t('datePickerLabels')"
                        :locale="$store.state.userInfo.settings.lang"
                        :max="maxDate"
                        :start-weekday="1"
                        :hide-header="false"
                        :show-decade-nav="true"
                        nav-button-variant="secondary"
                        label-help=""
                    ></b-calendar>
                  </div>
                  <div class="col-lg-5 col-md-12 text-left text-lg-left text-md-center text-sm-center align-self-center mt-3">
                    <b-time
                        class="ml-3"
                        v-model="timePickerValue"
                        v-bind="$t('timePickerLabels')"
                        :locale="$store.state.userInfo.settings.lang"
                        :hide-header="true"
                        :hour12="false"
                        :minutes-step="5"
                    ></b-time>
                  </div>
                </div>

                <div class="col-12 mt-4">
                  <button type="button" class="btn btn-sm btn-primary" @click.prevent="selectDateTime">{{ $t('indoorClimate.mapConfig.actions.fetchSensorValue', { 0: selectedBuilding.timeZone}) }}</button>
                </div>

              </div>
            </transition>
          </div>
        </div>

        <div class="row mt-3" v-if="availableSensorAttributes && availableSensorAttributes.length > 0">
          <div class="col-12">
            <h4>{{ $t('indoorClimate.mapConfig.monitorSensorValues') }}</h4>
            <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
              <b-form-group id="input-group-device-model-attributes">
                <b-form-checkbox-group
                    switches
                    stacked
                    size="lg"
                    id="input-device-model-attributes"
                    v-model="selectedSensorAttributes"
                    :options="availableSensorAttributesSorted"
                    name="input-device-model-attribute"
                    @change="selectedSensorAttributesChanged"
                ></b-form-checkbox-group>
              </b-form-group>
            </transition>
          </div>
        </div>

      </div>
    </transition>

    <div v-if="showThermostat">
      <div>
        <div class="row justify-content-center">
          <div class="col-12 mt-2">
            <h4>{{ $t('indoorClimate.mapConfig.temperatureControl.title') }}</h4>
            <span>{{ $t('indoorClimate.mapConfig.temperatureControl.info') }}</span>
          </div>
        </div>
        <div class="row justify-content-center mt-4" v-for="(devEUI) in selectedLocation.trvMap.keys()" :key="devEUI">
          <div class="col-8">
            <b-form-spinbutton id="temp-control" min="12" max="30" :value="selectedTemperatures.get(devEUI)" @change="selectedTemperatureChanged($event, devEUI)"></b-form-spinbutton>
          </div>
          <div class="col-4" v-if="hasRole('ADMIN') || hasResourceRole('ORGANISATION_MANAGER', selectedBuilding.organisation.id)
                                                              || hasResourceRole('BUILDING_MANAGER', selectedBuilding.id)
                                                              || hasResourceRole('ROOM_MANAGER', selectedLocation.id)">
            <button type="button" class="btn btn-sm btn-primary" @click.prevent="setTemperature($event, devEUI)">{{ $t('indoorClimate.mapConfig.actions.setTemperature') }} </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex"
import i18n from "@/i18n"
import moment from "moment"

export default {
  data() {
    return {
      info: null,
      selectedFetchType: 'live',
      datePickerValue: null,
      timePickerValue: null,
      selectedSensorAttributes: [],
      selectedTemperatures: new Map()
    }
  },
  created(){
    console.log('MapConfig: created - $route.name: ' + this.$route.name)
    this.selectedFetchType = this.$store.state.indoorClimate.mapConfig.activeFetchType ? this.$store.state.indoorClimate.mapConfig.activeFetchType: 'live'
    if(this.$store.state.indoorClimate.mapConfig.dateTime) {
      let initialDateTime = this.$store.state.indoorClimate.mapConfig.dateTime
      this.datePickerValue = initialDateTime.toISOString().split('T')[0]
      this.timePickerValue = initialDateTime.getHours() + ":" + (Math.ceil(initialDateTime.getMinutes() / 5) * 5)
    }else{
      let initialDateTime = new Date()
      initialDateTime.setMinutes(Math.ceil(initialDateTime.getMinutes() / 5) * 5)
      let nowMoved = this.$moment.tz(initialDateTime, this.selectedBuilding.timeZone)
      this.datePickerValue = nowMoved.format("YYYY-MM-DD")
      this.timePickerValue = nowMoved.format("HH:mm")
    }
    this.selectedSensorAttributes = this.$store.state.indoorClimate.mapConfig.selectedSensorAttributes
    if(this.selectedFetchType === 'live'){
      this.$store.dispatch('indoorClimate/startPolling')
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedBuilding: state => state.indoorClimate.selectedMap.building,
      availableSensorAttributes: state => state.indoorClimate.availableSensorAttributes,
      mapConfig: state => state.indoorClimate.mapConfig,
      activeFetchType: state => state.indoorClimate.mapConfig.activeFetchType,
      selectedLocation: state => state.indoorClimate.selectedLocation
    }),
    maxDate(){
        let nowMoved = this.$moment.tz(new Date(), this.selectedBuilding.timeZone)
        let newMaxDate = nowMoved.format("YYYY-MM-DD HH:mm")
        console.log('maxDate resolved date ' + newMaxDate)
        return newMaxDate
    },
    availableSensorAttributesSorted() {
      return this.availableSensorAttributes.map( item => {
        return { text: i18n.t('enums.sensorType.' + item + '.name'), value: item } 
      }).filter(item => item.value != "active_devices")
    },
    showThermostat() {
      if(this.selectedLocation != null && this.selectedLocation.showThermostat){
        return true;
      }
      return false;
    }
  },
  watch: {
    availableSensorAttributes(newVal, oldVal) {
      console.log('availableSensorAttributes changed: ', newVal, ' | was: ', oldVal)
      if (this.selectedSensorAttributes.length !== 0){
        this.selectedSensorAttributes = newVal.filter(value => this.selectedSensorAttributes.includes(value));
      } else {
        this.selectedSensorAttributes = [...newVal]
      }
      this.selectedSensorAttributesChanged()
    },
    selectedLocation: function(newVal, oldVal) {
      console.log('selectedLocation changed: ', newVal, ' | was: ', oldVal)
      if(this.selectedLocation != null && this.selectedLocation.showThermostat){
        this.selectedTemperatures = new Map();
        this.selectedLocation.trvMap.forEach((value, key) => {
          this.selectedTemperatures.set(key, parseFloat(value))
        });
      }
    }
  },
  methods: {
    selectLiveStream() {
      this.selectedFetchType = 'live'
      if(this.activeFetchType !== 'live') {
        this.$store.dispatch('indoorClimate/mapConfigLiveStreamSelected')
      }
    },
    selectDateTime(){
      this.$store.dispatch('indoorClimate/mapConfigDateTimeSelected', { dateTime: this.$moment(this.datePickerValue + ' ' + this.timePickerValue).toDate() })
    },
    selectedSensorAttributesChanged() {
      console.log('selectedSensorAttributes changed: ' + this.selectedSensorAttributes)
      this.$store.dispatch('indoorClimate/mapConfigSensorAttributesChanged', this.selectedSensorAttributes)
    },
    selectedTemperatureChanged(temp, devEUI){
        console.log("temp changed for " + devEUI + " to " +  temp)
        this.selectedTemperatures.set(devEUI, temp)
    },
    setTemperature(event, devEUI) {
      console.log('setTemperature for: ' + devEUI)
      this.$store.dispatch('indoorClimate/selectedTemperatureChanged', {devEUI: devEUI, temperature: this.selectedTemperatures.get(devEUI)})
    }
  },
  beforeDestroy () {
    console.log('MapConfig: beforeDestroy')
    this.$store.dispatch('indoorClimate/stopPolling')
  }
}
</script>

<style>
.b-time .form-control {
  padding: 6px;
}
.b-form-spinbutton.form-control {
  padding: 0;
  width: 70%;
}
.custom-switch.b-custom-control-lg .custom-control-label, .input-group-lg .custom-switch .custom-control-label {
  font-size: 1rem;
  padding-top: 3px;
  line-height: 1.5;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>