<template>
  <div class="animated fadeInRight">

    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="ibox-content p-5">

            <validation-observer ref="form" v-slot="{ handleSubmit }">

              <b-form role="form">

                <div class="row">

                  <div class="col-12">

                    <validation-provider name="orgAdmin.schedulerProfile.attributes.name" :rules="{ required: true, min: 3, max: 50 }" v-slot="validationContext">
                      <b-form-group id="input-group-schedulerProfile-name" :label="$t('orgAdmin.schedulerProfile.attributes.name')" label-for="input-schedulerProfile-name" label-cols-sm="1">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-schedulerProfile-name"
                            name="input-schedulerProfile-name"
                            v-model="form.name"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-schedulerProfile-name"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-schedulerProfile-name">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <hr />

                    <div class="scheduler-profile-sliders" v-if="form.settings">

                      <b-form-group v-for="(dayOfWeek, index) in $t('orgAdmin.schedulerProfile.daysOfWeek')" :key="dayOfWeek"
                                    :id="'input-group-schedulerProfile-parameters-temperature-day-' + (index + 1)"
                                    :label="$t('orgAdmin.schedulerProfile.daysOfWeek')[index]"
                                    label-for="sliderTemperature"
                                    label-cols-sm="1"
                                    class="mb-5">
                        <Veeno
                            :key="dayOfWeek"
                            :disabled="type=='view'"
                            :ref="'slider_day_' + (index + 1)"
                            behaviour="drag-all"
                            :tooltips="Array(4).fill(hourFormatter)"
                            :handles = "form.settings[index + 1].startHours"
                            :connect = "Array(5).fill(true)"
                            :step = "1"
                            :pipsy = "{ mode: 'steps', density: 2 }"
                            :range = "{ 'min': 0, 'max': 24 }"
                            :options = "{
                              format: valueFormatter,
                              margin: 1
                            }"
                        />

                        <template v-for="(n, index2) in 5">
                          <div :id="'temperature_' + (index + 1) + '_' + index2" class="connect-label" :key="'connect-label-' + (index + 1) + '-' + index2">
                            <b-form-input
                                :id="'input_temperature_' + (index + 1) + '_' + index2"
                                :name="'input_temperature_' + (index + 1) + '_' + index2"
                                :disabled="type=='view'"
                                v-model="form.settings[index + 1].temperatures[index2]"
                                :formatter="formatInputTemperatureValue"
                                :lazy-formatter="true"
                                @change="setConnectsBackgroundColor(index+1)"
                                type="number"
                                step="1"
                                min="12"
                                max="30"
                                class="input-temperature"/>
                            <span
                                :id="'input_temperature_suffix_' + (index + 1) + '_' + index2"
                                class="input-temperature-suffix">°C</span>
                          </div>
                        </template>

                      </b-form-group>

                    </div>

                  </div>

                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <button  type="button" class="btn btn-sm btn-primary" v-if="schedulerProfile && type=='view' && hasOneOfRoles('ADMIN', 'ORGANISATION_MANAGER', 'BUILDING_MANAGER')" @click.prevent="type='edit'">
                      <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.schedulerProfile.actions.edit') }}
                    </button>
                    <button type="button" v-if="type=='create'" class="btn btn-sm btn-primary" @click.prevent="handleSubmit(submit)">{{ $t('common.actions.create') }}</button>
                    <button type="button" v-if="type=='edit'" class="btn btn-sm btn-primary" @click.prevent="handleSubmit(submit)">{{ $t('common.actions.save') }}</button>
                    <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-secondary m-l-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
                    <button type="button" class="btn btn-sm btn-info m-l-sm" v-if="type!='create' && building" @click="assignToBuildingLocations">{{ $t('orgAdmin.schedulerProfile.actions.assignToBuildingLocations') }}</button>
                    <button type="button" class="btn btn-sm btn-info m-l-sm" v-if="type!='create' && building" @click="clearFromBuildingLocations">{{ $t('orgAdmin.schedulerProfile.actions.removeFromBuildingLocations') }}</button>
                    <button type="button" class="btn btn-sm btn-white m-l-sm" @click="cancel">{{ $t('common.actions.back') }}</button>
                    <button type="button" v-if="schedulerProfile && type!='create'" class="btn btn-sm btn-danger float-right" @click="deleteProfile">{{ $t('orgAdmin.schedulerProfile.actions.delete') }}</button>
                  </div>
                </div>

              </b-form>
            </validation-observer>


          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {mapState} from "vuex"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Veeno from 'veeno'
import 'nouislider/distribute/nouislider.min.css'
import Vue from "vue"
import * as commons from "@/commons"
import i18n from "@/i18n"

let colorByTemperatureMap = {
  12: '#023E64',
  13: '#023E64',
  14: '#023E64',
  15: '#023E64',
  16: '#025286',
  17: '#6CC4FD',
  18: '#A0CED4',
  19: '#61AEB7',
  20: '#66C28F',
  21: '#FFD966',
  22: '#FFC000',
  23: '#E94F37',
  24: '#C32D15',
  25: '#A31621',
  26: '#A31621',
  27: '#A31621',
  28: '#A31621',
  29: '#A31621',
  30: '#A31621',
}

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Veeno
  },
  data() {
    return {
      error: null,
      type: 'view',
      form: {
        id: null,
        name: '',
        settings: null
      }
    }
  },
  created() {
    console.log('schedulerProfileDetails $route.name: ' + this.$route.name)
    this.type = this.$route.name == 'schedulerProfileNew' ? 'create' : 'view'
    if(this.type == 'view') {
      this.$store.dispatch('schedulers/fetchScheduleProfileDetails', this.$route.params.id)
    } else {
      this.$store.commit('schedulers/SAVE_SCHEDULER_PROFILE_DETAILS_ENTITY', null)
      this.init()
    }
  },
  computed: {
    ...mapState({
      schedulerProfile: state => state.schedulers.schedulerProfileDetails.entity,
      defaultSchedulerProfileSettings: state => state.systemParams.defaultSchedulerProfileSettings
    }),
    organisation() {
      console.log('organisation() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.organisation.organisationDetails.entity : this.$store.state.contextOrg
    },
    building() {
      console.log('building() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.orgAdmin.buildingDetails.entity : this.$store.state.contextBuilding
    },
    hourFormatter() {
      return {
        from: function (formattedValue) {
          console.log("hourFormatter.from " + formattedValue + ' -> ' + Number(formattedValue.split(':')[0]))
          return Number(formattedValue.split(':')[0])
        },
        to: function(numericValue) {
          console.log("hourFormatter.to " + numericValue)
          // veeno bug fiy
          return Math.round(numericValue) + ':00'
        }
      }
    },
    valueFormatter() {
      return {
        from: function (formattedValue) {
          return Number(formattedValue)
        },
        to: function (numericValue) {
          return Math.round(numericValue)
        }
      }
    }
  },
  watch: {
    schedulerProfile: function(newVal, oldVal) { // watch it
      console.log('Prop schedulerProfile changed: ', newVal, ' | was: ', oldVal)
      this.init()
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    formatInputTemperatureValue(value, event){
      console.log('formatInputTemperatureValue')
      let fixedValue = value
      if (value < 12) {
        fixedValue = 12
      } else if (value > 30) {
        fixedValue = 30
      }
      return fixedValue
    },
    setConnectsBackgroundColor(sliderIndex){
      let vm = this
      this.$nextTick(() => {
        console.log('setConnectsBackgroundColor')
        for (let j = 0; j < 5; j++) {
          let connectLabelNode = document.getElementById("temperature_" + sliderIndex + "_" + j)
          let temperatureInputControl = document.getElementById("input_temperature_" + sliderIndex + "_" + j)
          connectLabelNode.style.backgroundColor = colorByTemperatureMap[temperatureInputControl.value]
        }
      })
    },
    reset() {
      console.log("reset()")
      if(this.type == 'create'){
        this.form.id = null
        this.form.name = ''
        this.form.settings = JSON.parse(JSON.stringify(this.defaultSchedulerProfileSettings))
      } else {
        this.form.id = this.schedulerProfile.id
        this.form.name = this.schedulerProfile.name
        this.form.settings = JSON.parse(JSON.stringify(this.schedulerProfile.settings))
        this.type = 'view'
      }

      if(this.$refs.form) {
        this.$refs.form.reset()
      }

      if(eval("this.$refs.slider_day_1")) {
        for (let i = 1; i <= 7; i++) {
          let refElementEval = eval("this.$refs.slider_day_" + i)
          refElementEval[0].$el.noUiSlider.set(this.form.settings[i].startHours)
        }
      }
    },
    init() {

      console.log('init()')

      this.reset()

      let vm = this

      this.$nextTick(() => {

        for (let i = 1; i <= 7; i++) {

          let refElementEval = eval("vm.$refs.slider_day_" + i)

          if(refElementEval.length) {

            let refElement = refElementEval[0]
            let connectsContainer = refElement.$el.querySelector('.noUi-connects')

            console.log("set temperature custom elements color append them to veeno component")

            for(let j = 0; j < 5; j++){
              let connectLabelNode = document.getElementById("temperature_" + i + "_" + j)
              connectsContainer.append(connectLabelNode)
              vm.setConnectsBackgroundColor(i)
            }

            refElement.$el.noUiSlider.on('update', function (values, handle, positions) {

              console.log('updating connect labels positions ' + JSON.stringify(values))
              vm.form.settings[i].startHours = values

              let temperatureNode = document.getElementById("temperature_" + i + "_" + 0)
              temperatureNode.style.left = '0'
              temperatureNode.style.right = (100 - (positions[0] * 100 / 24)) + '%'

              temperatureNode = document.getElementById("temperature_" + i + "_" + 1)
              temperatureNode.style.left = (positions[0] * 100 / 24) + '%'
              temperatureNode.style.right = (100 - positions[1] * 100 / 24) + '%'

              temperatureNode = document.getElementById("temperature_" + i + "_" + 2)
              temperatureNode.style.left = (positions[1] * 100 / 24) + '%'
              temperatureNode.style.right = (100 - positions[2] * 100 / 24) + '%'

              temperatureNode = document.getElementById("temperature_" + i + "_" + 3)
              temperatureNode.style.left = (positions[2] * 100 / 24) + '%'
              temperatureNode.style.right = (100 - positions[3] * 100 / 24) + '%'

              temperatureNode = document.getElementById("temperature_" + i + "_" + 4)
              temperatureNode.style.left = (positions[3] * 100 / 24) + '%'
              temperatureNode.style.right = '0'

            }.bind(vm))

          }
        }
      })
    },
    cancel(){
      this.goBack()
    },
    deleteProfile(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.schedulerProfile.confirm.delete', { '0': self.form.name }),
        callbackYes: function(){
          Vue.axios.delete('/admin/scheduler-profiles/' + self.form.id)
              .then(response => {
                self.$toastr.i(i18n.t('orgAdmin.schedulerProfile.info.deleted'))
                self.goBack()
              })
              .catch(error => {
                commons.processRestError(error)
              })
        }
      })
    },
    submit() {
      this.error = ""
      this.info = ""
      let vm = this
      if(this.type == 'create'){
        let formToSend =  Object.assign({}, this.form)
        formToSend.organisation = this.organisation
        Vue.axios.post('/admin/scheduler-profiles', JSON.stringify(formToSend),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          this.$toastr.i(i18n.t('orgAdmin.schedulerProfile.info.created'))
          this.goBack()
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      } else {
        let formToSend =  Object.assign({}, this.form)
        formToSend.organisation = this.schedulerProfile.organisation
        Vue.axios.put('/admin/scheduler-profiles/' + formToSend.id, JSON.stringify(formToSend),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          this.$toastr.i(i18n.t('orgAdmin.schedulerProfile.info.saved'))
          this.goBack()
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      }
    },
    assignToBuildingLocations(){

      let vm = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.schedulerProfile.confirm.assignToBuildingLocations', { '0': vm.building.name }),
        callbackYes: function(){
          let formToSend =  Object.assign({}, vm.schedulerProfile)
          Vue.axios.put('/admin/buildings/' + vm.building.id + '/assign-scheduler-profile', JSON.stringify(formToSend),
              {
                headers: {'Content-Type': 'application/json'}
              }).then(response => {
            vm.$toastr.i(i18n.t('orgAdmin.schedulerProfile.info.assignedToBuildingLocations', { '0': vm.building.name }))
          }).catch(error => {
            if (error.response.status === 422) {
              vm.$refs.form.setErrors(error.response.data)
            } else {
              commons.processRestError(error)
            }
          })
        }
      })
    },
    clearFromBuildingLocations(){

      let vm = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.schedulerProfile.confirm.clearFromBuildingLocations', { '0': vm.building.name }),
        callbackYes: function(){
          Vue.axios.delete('/admin/buildings/' + vm.building.id + '/clear-scheduler-profile', { params: { schedulerProfileId: vm.schedulerProfile.id }
          }).then(response => {
            vm.$toastr.i(i18n.t('orgAdmin.schedulerProfile.info.removedFromBuildingLocations', { '0': vm.building.name }))
          }).catch(error => {
            commons.processRestError(error)
          })
        }
      })
    }
  }
}
</script>

<style>

.scheduler-profile-sliders .connect-label {
}

.scheduler-profile-sliders .noUi-value-horizontal {
  transform: translate(-50%,90%);
  font-size: smaller;
}

.scheduler-profile-sliders .noUi-horizontal {
  height: 30px;
}

.scheduler-profile-sliders .noUi-tooltip {
  height: 40px;
  width: 40px;
  padding: 10px 2px 2px;
}

.scheduler-profile-sliders .noUi-horizontal .noUi-tooltip {
  transform: translate(-50%,0);
  left: 50%;
  bottom: -12px;
}

.scheduler-profile-sliders .noUi-handle{
  border: none;
  background: none;
  box-shadow: none;
}

.scheduler-profile-sliders .noUi-handle::before, .scheduler-profile-sliders .noUi-handle::after {
  display: none;
}

.scheduler-profile-sliders .noUi-target:not([disabled]) .noUi-handle {
  cursor: ew-resize;
}

.scheduler-profile-sliders .noUi-pips-horizontal {
  padding: 15px 0 5px;
}

.scheduler-profile-sliders .connect-label {
  padding-top: 0px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  position: absolute;
  z-index: 10;
}

.scheduler-profile-sliders .input-temperature, .scheduler-profile-sliders .input-temperature-suffix {
  margin-left: 20px;
  display: inline-block;
  width: 3em;
  color: #fff;
  background: transparent;
  font-weight: bold;
  padding: 3px 0px;
  border: none;
  height: 2em;
  cursor: not-allowed;
}

.scheduler-profile-sliders .input-temperature:disabled {
  -moz-appearance:textfield;
}

.scheduler-profile-sliders .input-temperature-suffix {
  width: 1em;
  padding: 0;
  margin-left: -24px;
}

.scheduler-profile-sliders .noUi-target:not([disabled]) .input-temperature {
  cursor: pointer;
}
.scheduler-profile-sliders .noUi-target:not([disabled]) .input-temperature-suffix {
  display: none;
}

</style>
