<template>
  <div>
    <div class="row equal-height">
      <div class="col-xxl-9 col-xl-8 col-lg-12">
          <div>
            <b-tabs ref="tabs" @input="tabSelected" v-model="activeTab" content-class="px-5 py-5 white-bg" v-if="selectedBuilding">

              <b-tab :title="$t('messageCentre.issues.title')" lazy>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="btn-group float-right">
                      <button class="btn btn-white btn-sm ml-2" @click="refreshLists"><i class="fa fa-refresh"></i> {{ $t('common.actions.refresh') }}</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <message-list-filter type="issues"/>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-lg-12">
                    <message-list
                        id="issuesTop"
                        type="issues"
                        title="messageCentre.issues.listTop.title"
                        :query-params="{ types: ['ALERT', 'WARNING'], priorities: ['PINNED']}"
                        noEntriesInfo="messageCentre.issues.listTop.noMessages"
                        :paging="false" :pageSize="5" />
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-lg-12">
                    <message-list
                        id="issuesOngoing"
                        type="issues"
                        title="messageCentre.issues.listOngoing.title"
                        :query-params="{ types: ['ALERT', 'WARNING'], statuses: ['ONGOING'], priorities: ['NORMAL', 'PINNED'] }"
                        noEntriesInfo="messageCentre.issues.listOngoing.noMessages" />
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-lg-12">
                    <message-list
                        id="issuesResolved"
                        type="issues"
                        title="messageCentre.issues.listResolved.title"
                        :query-params="{ types: ['ALERT', 'WARNING'], statuses: ['RESOLVED'], priorities: ['NORMAL', 'PINNED']}"
                        noEntriesInfo="messageCentre.issues.listResolved.noMessages" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <message-list
                        id="issuesIgnored"
                        type="issues"
                        title="messageCentre.issues.listIgnored.title"
                        :query-params="{ types: ['ALERT', 'WARNING'], statuses: ['ONGOING', 'RESOLVED'], priorities: ['IGNORED']}"
                        noEntriesInfo="messageCentre.issues.listIgnored.noMessages" />
                  </div>
                </div>
              </b-tab>

              <b-tab :title="$t('messageCentre.advices.title')" lazy>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="btn-group float-right">
                      <router-link to="/advices/new">
                        <button class="btn btn-primary btn-sm"><i class="fa fa-plus"></i> {{ $t('messageCentre.advices.actions.new') }}</button>
                      </router-link>
                      <button class="btn btn-white btn-sm ml-2" @click="refreshLists"><i class="fa fa-refresh"></i> {{ $t('common.actions.refresh') }}</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <message-list-filter type="advices"/>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-lg-12">
                    <message-list
                        id="advicesTop"
                        type="advices"
                        title="messageCentre.advices.listTop.title"
                        :query-params="{ types: ['ADVICE'], statuses: ['TODO', 'INVESTIGATING', 'DONE'], priorities: ['PINNED']}"
                        noEntriesInfo="messageCentre.advices.listTop.noMessages" :paging="false" :pageSize="5"/>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-lg-12">
                    <message-list
                        id="advicesAll"
                        type="advices"
                        title="messageCentre.advices.listAll.title"
                        :query-params="{ types: ['ADVICE'], priorities: ['NORMAL', 'PINNED'] }"
                        noEntriesInfo="messageCentre.advices.listAll.noMessages" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <message-list
                        type="advices"
                        id="advicesIgnored"
                        title="messageCentre.advices.listIgnored.title"
                        :query-params="{ types: ['ADVICE'], priorities: ['IGNORED']}"
                        noEntriesInfo="messageCentre.advices.listIgnored.noMessages" />
                  </div>
                </div>
              </b-tab>

            </b-tabs>
            <div v-else class="text-center mt-5 mb-5">
              <div class="font-bold">{{ $t('messageCentre.placeholder')}}</div>
            </div>
          </div>
      </div>
      <div class="col-xxl-3 col-xl-4 col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5><template v-if="selectedBuilding">{{ $t('messageCentre.resourceSelector.titleSelected') }}</template>
              <template v-else>{{ $t('messageCentre.resourceSelector.titleNotSelected') }}</template>
            </h5>
          </div>
          <div class="ibox-content">
            <resource-selector :resourceSelectedCallback="buildingSelectedCallback" :preselect-resource="selectedBuilding ? { building: selectedBuilding } : null" selectDepth="building" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import {mapState} from "vuex"
import ResourceSelector from "@/components/common/ResourceSelector"
import MessageList from "@/components/views/messageCentre/MessageList"
import MessageListFilter from "@/components/views/messageCentre/MessageListFilter"
import eventBus from "@/eventBus"

export default {
  components:{
    ResourceSelector,
    MessageList,
    MessageListFilter
  },
  data() {
    return {
      info: null,
      activeTab: this.$store.state.messageCentre.allActiveTab,
    }
  },
  created() {
    console.log("MessageCentre created() selectedBuilding: " + (this.selectedBuilding ? this.selectedBuilding.id : null))
    if (this.$route.query.activeTab != null) {
      console.log("ActiveTAb: " + this.$route.query.activeTab)
      this.activeTab = parseInt(this.$route.query.activeTab)
      this.tabSelected()
    }
    this.$store.dispatch('messageCentre/loadBuildingFromRouteQueryParam')
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedBuilding: state => state.messageCentre.selectedBuilding
    })
  },
  methods: {
    tabSelected() {
      this.$store.commit('messageCentre/SAVE_ALL_ACTIVE_TAB', this.activeTab)
      this.$store.commit('messageCentre/UPDATE_LISTS_FILTER', {})
    },
    refreshLists(){
      eventBus.$emit("refresh-message-lists")
    },
    buildingSelectedCallback(payload){
      console.log("buildingSelectedCallback " + payload.resource.id + " - " + payload.resource.name)
      if(this.$router.currentRoute.query.buildingId == null || this.$router.currentRoute.query.buildingId != payload.resource.id) {
        this.$router.replace({...this.$router.currentRoute, query: {buildingId: payload.resource.id}})
      }
    }
  },
  watch: {
    '$route' (to, from) { // watch it
      console.log('URL changed: ', to, ' | was: ', from, ' | query ', this.$route.query)
      this.$store.dispatch('messageCentre/loadBuildingFromRouteQueryParam')
    }
  }
}
</script>
