<template>
  <popup-window :title="$t('orgAdmin.building.openingHourExceptions.openingHourExceptions')" :closeCallback="closeCallback">
    <div class="content-wrapper">
      <div class="form-wrapper">
        <!-- Form for creating/editing exceptions -->
        <b-form @submit.prevent="submit" @reset="resetForm">
          <b-form-group :label="$t('orgAdmin.building.openingHourExceptions.reason')" :state="validationState.reason" :invalid-feedback="$t('common.errors.requiredField')">
            <b-form-input v-model="form.reason" :state="validationState.reason"></b-form-input>
          </b-form-group>
          <!-- Date Range Picker -->
          <b-form-group label="Date Range:" label-for="date-range">
            <date-range-picker
              ref="picker"
              opens="right"
              :locale-data="Object.assign({}, $t('dateRangePickerLabels'), { format: 'dd-mm-yyyy' })"
              :autoApply="false"
              :showDropdowns="true"
              v-model="form.dateRange"
            >
            <template v-slot:input="picker">
              <div style="max-width: 350px;">
                {{ picker.startDate | formatDateByLocale }} - {{ picker.endDate | formatDateByLocale }}
              </div>
            </template>
            </date-range-picker>
          </b-form-group>
          <!-- Opening and Closing Time Pickers -->
          <div class="time-picker-container">
            <div class="time-picker">
              <label for="opening-time">{{$t('orgAdmin.building.openingHourExceptions.openingTime')}}</label>
              <b-form-timepicker
                v-model="form.openTime"
                id="opening-time"
                :hour12="false"
                placeholder="--:--"
                :disabled="!form.active"
              ></b-form-timepicker>
            </div>
            <div class="time-picker">
              <label for="closing-time">{{$t('orgAdmin.building.openingHourExceptions.closingTime')}}</label>
              <b-form-timepicker
                v-model="form.closeTime"
                id="closing-time"
                :hour12="false"
                placeholder="--:--"
                :disabled="!form.active"
              ></b-form-timepicker>
            </div>
          </div>
          <b-form-group>
            <b-form-radio-group v-model="form.active" name="open-closed" class="d-flex align-items-center mt-4 mb-2">
              <b-form-radio :value="true">{{ $t('orgAdmin.building.openingHourExceptions.open') }}</b-form-radio>
              <b-form-radio :value="false">{{ $t('orgAdmin.building.openingHourExceptions.closed') }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div class="mb-4">
            <b-button type="submit" variant="primary">{{ $t('common.actions.save')}}</b-button>
            <b-button type="reset" variant="btn btn-secondary m-l-sm">{{ $t('common.actions.reset')}}</b-button>
            <b-button @click="cancel" variant="btn btn-white m-l-sm">{{ $t('common.actions.cancel')}}</b-button>
          </div>
        </b-form>
      </div>
      <div class="exception-list-container">
        <!-- List existing exceptions -->
        <b-list-group>
          <b-list-group-item
            v-for="exception in exceptions"
            :key="exception.id"
            class="exception-list-entry"
            :class="exception.id === form.id ? 'selected' : null"
          >
            <p>
              {{ exception.reason }}<br>
              <small>{{ exception.dateFrom | formatDateByLocale }} - {{ exception.dateTo | formatDateByLocale }}</small><br>
              <small v-if="exception.active">
                {{ $t('orgAdmin.building.openingHourExceptions.open') }}: {{ exception.openTime }} - {{ exception.closeTime }}
              </small>
              <small v-else>
                {{ $t('orgAdmin.building.openingHourExceptions.closed') }}
              </small>
            </p>
            <b-button-group class="exception-button-wrapper">
              <b-button variant="info" @click="editException(exception)"> {{$t('common.actions.edit')}}</b-button>
              <b-button variant="danger" @click="confirmDeleteException(exception.id, exception.reason)"> {{$t('common.actions.delete')}}</b-button>
            </b-button-group>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </popup-window>
</template>

<script>
import PopupWindow from '@/components/common/PopupWindow.vue';
import DateRangePicker from 'vue2-daterange-picker';
import i18n from "@/i18n";
import * as commons from "@/commons";

export default {
  components: {
    PopupWindow,
    DateRangePicker
  },
  props: {
    building: {
      type: Object
    },
    canEdit: {
      type: Boolean
    },
    initialType: {
      validator: function (value) {
        return ['view', 'edit', 'create'].includes(value)
      }
    },
    successCallback: {
      type: Function
    },
    cancelCallback: {
      type: Function
    },
    closeCallback: {
      type: Function
    },
    openCallback: {
      type: Function
    }
  },
  data() {
    return {
      error: null,
      info: null,
      type: this.initialType,
      form: {
        reason: "",
        dateRange: { startDate: new Date(), endDate: new Date() },
        active: false,
        openTime: "09:00",
        closeTime: "17:00"
      },
      exceptions: [],
      validationState: {
        reason: null
      }
    };
  },
  computed: {
    exceptionsFromStore() {
      return this.$store.state.buildings.buildingDetails.entity.buildingOperatingHourExceptions || [];
    }
  },
  watch: {
    exceptionsFromStore: {
      immediate: true,
      handler(newVal) {
        this.exceptions = JSON.parse(JSON.stringify(newVal)) || [];
      }
    }
  },
  methods: {
    resetForm() {
      this.reset();
      this.type = 'create';
    },
    reset() {
      this.form = {
        id: null,
        dateRange: { startDate: new Date(), endDate: new Date() },
        active: false,
        openTime: "09:00",
        closeTime: "17:00",
        reason: ''
      };
      this.validationState.reason = null;
    },
    cancel() {
      this.reset();
      this.type = this.initialType;
      if (this.cancelCallback) {
        this.cancelCallback();
      }
      this.isOpen = false;
      this.closeCallback();
    },
    validateForm() {
      this.validationState.reason = this.form.reason ? true : false;
      return this.validationState.reason;
    },
    submit() {
      if (!this.validateForm()) {
        return;
      }
      if (this.type === 'edit') {
        this.updateException();
      } else {
        this.createException();
      }
    },
    createException() {
      let vm = this;
      const newException = {
        reason: this.form.reason,
        dateFrom: this.formatDate(this.form.dateRange.startDate),
        dateTo: this.formatDate(this.form.dateRange.endDate),
        openTime: this.formatTime(this.form.openTime),
        closeTime: this.formatTime(this.form.closeTime),
        active: this.form.active
      };
      this.$store.dispatch('buildings/addException', { buildingId: this.building.id, exception: newException })
        .then(() => {
          vm.reset();
          vm.type = vm.initialType;
          vm.$toastr.i(i18n.t('orgAdmin.building.info.saved'));
          if (vm.successCallback) {
            vm.successCallback();
          }
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data);
          } else {
            commons.processRestError(error);
          }
        });
    },
    updateException() {
      let vm = this;
      const updatedException = {
        id: this.form.id,
        reason: this.form.reason,
        dateFrom: this.formatDate(this.form.dateRange.startDate),
        dateTo: this.formatDate(this.form.dateRange.endDate),
        openTime: this.formatTime(this.form.openTime),
        closeTime: this.formatTime(this.form.closeTime),
        active: this.form.active,
        version: this.form.version
      };
      this.$store.dispatch('buildings/updateException', { buildingId: this.building.id, exception: updatedException })
        .then(() => {
          vm.reset();
          vm.type = vm.initialType;
          vm.$toastr.i(i18n.t('orgAdmin.building.info.saved'));
          if (vm.successCallback) {
            vm.successCallback();
          }
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data);
          } else {
            commons.processRestError(error);
          }
        });
    },
    confirmDeleteException(id, reason) {
      let vm = this;
      vm.closeCallback();
      this.confirmDialog({
        text: this.$t('reporting.modal.confirmDeletion.text', { itemName: reason }),
        callbackYes: function () {
          vm.deleteException(id, reason);
          vm.openCallback();
        },
        callbackNo: function () {
          vm.openCallback();
        }
      });
    },
    deleteException(id, reason) {
      let vm = this;
      this.$store.dispatch('buildings/deleteException', { buildingId: this.building.id, exceptionId: id })
        .then(() => {
          vm.$toastr.i(i18n.t('reporting.modal.deletion.success', { itemName: reason }));
        }).catch(error => {
          vm.$toastr.i(i18n.t('reporting.modal.deletion.error', { itemName: reason }));
          commons.processRestError(error);
        });
    },
    editException(exception) {
      // Fill form with exception data for editing
      this.form = {
        id: exception.id,
        dateRange: { startDate: new Date(exception.dateFrom), endDate: new Date(exception.dateTo) },
        active: exception.active,
        openTime: exception.openTime,
        closeTime: exception.closeTime,
        reason: exception.reason,
        version: exception.version
      };
      this.type = 'edit';
      this.isOpen = true;
    },
    formatDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },
    formatTime(time) {
      const [hours, minutes] = time.split(':');
      return `${hours}:${minutes}`;
    }
  }
}
</script>

<style scoped>
.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.form-wrapper {
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
}

.exception-list-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none;
  max-height: 50vh;
  width: 50%;
  min-width: 300px;
  flex-grow: 1;
}

.exception-list-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.exception-list-entry > p {
  margin: auto 1rem auto 0;
}

.exception-button-wrapper {
  height: 60%;
}

.time-picker-container {
  display: flex;
  flex-direction: row;
  min-width: 300px;
}

.time-picker {
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  flex-grow: 1;
}

.selected {
  border: solid;
  border-width: 1px;
  border-color: #61AEB7;
}
</style>
