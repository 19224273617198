import Vue from "vue"
import * as commons from '@/commons'
import router from '@/router'
import moment from 'moment-timezone'
import i18n from "@/i18n";

// default state
const getDefaultState = () => {

  return {
    selectedMap: null,
    sensorValuesPerAttributesPerLocation: null,
    graphConfig: {
      dateRange: {
        startDate: null,
        endDate: null
      },
      dateRangeFormatted: null,
      fullSize: true,
      selectedLocations: [],
      enableWeekends: true
    }
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  graphConfigDateTimeRangeFormatted: state => {
    if(state.graphConfig.dateRange.startDate && state.graphConfig.dateRange.endDate) {
      return moment(state.graphConfig.dateRange.startDate).format(i18n.t('dateTimeShortFormatMoment'))
          + ' - '
          + moment(state.graphConfig.dateRange.endDate).format(i18n.t('dateTimeShortFormatMoment'))
    } else {
      return ''
    }
  }
}

// action
const actions = {

  appInit({ commit, state, dispatch, rootState }) {
    console.log('graphLocationComparison/appInit')
    dispatch('resetGraphConfig')
  },

  resetGraphConfig({ commit, state }, data) {
    console.log('graphLocationComparison/resetGraphConfig')

    return new Promise((resolve) => {
      let startDate = new Date()
      startDate.setDate(startDate.getDate() - 1)
      startDate.setHours(0, 0, 0, 0)
      let endDate = new Date()
      endDate.setHours(23, 59, 59, 999)
      commit('UPDATE_GRAPH_CONFIG', {
        dateRange: {startDate: startDate, endDate: endDate},
        selectedLocations: []
      })
      resolve()
    })
  },

  loadMapFromRouteQueryParam({ commit, state, dispatch }){

    console.log('loadMapFromRouteQueryParam ' + router.currentRoute.query.mapId)

    if (router.currentRoute.query.mapId) {
      if (state.selectedMap && state.selectedMap.locations && state.selectedMap.id == router.currentRoute.query.mapId) {
        //commit('UPDATE_GRAPH_CONFIG', { selectedLocations: [] })
        dispatch('loadSelectedMapSensorValues')
      } else {
        //load map from server
        Vue.axios.get('/maps/' + router.currentRoute.query.mapId).then(response => {
          commit('UPDATE_SELECTED_MAP', response.data)
          commit('UPDATE_GRAPH_CONFIG', { selectedLocations: [] })
          dispatch('loadSelectedMapSensorValues')
        }).catch(error => {
          commons.processRestError(error)
        })
      }
    }else{
      commit('UPDATE_SELECTED_MAP', null)
      dispatch('resetGraphConfig')
    }
  },

  loadSelectedMapSensorValues({ commit, state, dispatch, rootState }){

    console.log('loadSelectedMapSensorValues graphConfig ' + JSON.stringify(state.graphConfig))

    return new Promise((resolve, reject) => {

      if(state.selectedMap.locations.length == 0) {
        resolve()
      }

      let params = {}

      if(state.graphConfig.dateRange){
        params.dateTimeFrom = state.graphConfig.dateRange.startDate
        params.dateTimeFrom.setHours(0, 0, 0, 0)
        params.dateTimeFrom = moment(state.graphConfig.dateRange.startDate).format('YYYY-MM-DDTHH:mm:ss')
        let nowInBuildingTimezone = moment.tz(moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), state.selectedMap.building.timeZone)
        let endDateInBuildingTimezone = moment.tz(moment(state.graphConfig.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss'), state.selectedMap.building.timeZone)
        if(!endDateInBuildingTimezone.isSame(nowInBuildingTimezone,'day')) {
          params.dateTimeTo = state.graphConfig.dateRange.endDate
          params.dateTimeTo.setHours(23, 59, 59, 999)
          params.dateTimeTo = moment(state.graphConfig.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss')
        }

        const diffMillis = Math.abs(state.graphConfig.dateRange.endDate - state.graphConfig.dateRange.startDate)
        if(diffMillis < (1000 * 60 * 60 * 24 * 5)){
          params.aggregateWindowDuration = "PT15M"
        } else if(diffMillis < (1000 * 60 * 60 * 24 * 30)){
          params.aggregateWindowDuration = "PT1H"
        } else if(diffMillis < (1000 * 60 * 60 * 24 * 90)){
            params.aggregateWindowDuration = "PT6H"
        } else {
          params.aggregateWindowDuration = "P1D"
        }
      }

      Vue.axios.get('/maps/' + state.selectedMap.id + '/sensor-values', { params: params }).then(response => {
        commit('UPDATE_SELECTED_MAP_SENSOR_VALUES', response.data)
        resolve(response)
      }).catch(error => {
        commons.processRestError(error)
        reject(error)
      })
    })
  },

  graphConfigDateRangeSelected({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  { dateRange: data.dateRange })
    dispatch('loadSelectedMapSensorValues').then(response => {
      //commit('UPDATE_GRAPH_CONFIG',  { activeFetchType: 'datetime' })
    })
  },

  graphConfigSelectedLocationsChanged({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  { selectedLocations:  data })
  },

  graphConfigOptionsChanged({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  data)
  }

}

// mutations
const mutations = {

  ['GLOBAL_RESET']: (state, data) => {
    Object.assign(state, getDefaultState())
  },

  ['UPDATE_SELECTED_MAP']: (state, data) => {
    state.selectedMap = data
  },

  ['UPDATE_SELECTED_MAP_SENSOR_VALUES']: (state, data) => {
    Vue.set(state.selectedMap, 'sensorValues', data)
    processSelectedMapSensorData(state, data)
  },

  ['UPDATE_GRAPH_CONFIG']: (state, data) => {
    state.graphConfig = Object.assign({}, state.graphConfig, data)
  }
}

function processSelectedMapSensorData(state, sensorValues){

  console.log("processing sensor data, size " + sensorValues.length)

  // determine available sensor attributes
  let sensorAttributesMap = new Map()
  if (sensorValues) {
    sensorValues.forEach(sensorValue => {
      if(!sensorAttributesMap.has(sensorValue.field)) {
        sensorAttributesMap.set(sensorValue.field, new Map())
      }
    })
  }

  // parse all sensor values and set them to map locations
  if(sensorValues && state.selectedMap) {
    state.selectedMap.locations.forEach(location => {
      // clear current data
      location.sensorValues = []
      sensorValues.forEach(sensorValue => {
        if(sensorValue.locationId && location.id == sensorValue.locationId){
          location.sensorValues.push(sensorValue)
        }
      })
    })
  }

  // create per attribute per location map
  state.selectedMap.locations.forEach(location => {

    let sensorValuesMap = new Map()

    if(location.hasOwnProperty('sensorValues') && location.sensorValues.length > 0) {
      location.sensorValues.forEach(sensorValue => {

        if (sensorValuesMap.has(sensorValue.field)) {
          sensorValuesMap.get(sensorValue.field).push(sensorValue.value)
        } else {
          sensorValuesMap.set(sensorValue.field, [sensorValue.value])
        }

        if (['temperature', 'humidity', 'noise', 'light', 'co2', 'motion'].includes(sensorValue.field)) {
          if (sensorAttributesMap.get(sensorValue.field).has(location.id)) {
            sensorAttributesMap.get(sensorValue.field).get(location.id).push(sensorValue)
          } else {
            sensorAttributesMap.get(sensorValue.field).set(location.id, [sensorValue])
          }
        }
      })
      location.sensorValuesMap = sensorValuesMap
    }
  })

  state.sensorValuesPerAttributesPerLocation = sensorAttributesMap
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
