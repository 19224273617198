import { configure, extend, localize } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";
import i18n from '@/i18n'

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

configure({
    // this will be used to generate messages.
    defaultMessage: (field, values) => {
        values._field_ = i18n.t(`${field}`);
        return i18n.t(`validations.messages.${values._rule_}`, values);
    },
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
    }
});
