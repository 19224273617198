import Vue from "vue";
import * as commons from '@/commons'

// default state
const getDefaultState = () => {
    return {
        organisationDetails: {
            activeTab: 0,
            entity: {}
        },
        organisationSummary: null,
        registerOrganisationRequests: [],
        registerOrganisationRequestDetails: {
            entity: {}
        },
    }
}

// initial state
const state = getDefaultState()

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('organisation/appInit')
    },

    fetchOrganisationDetails({commit, state, dispatch, rootState}, id) {

        console.log('organisation/fetchOrganisationDetails')

        Vue.axios.get('/admin/organisations/' + id).then(response => {
            commit('SAVE_ORGANISATION_DETAILS_ENTITY', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchOrganisationSummary({commit, state, dispatch, rootState}, id) {

        console.log('organisation/fetchOrganisationSummary')

        Vue.axios.get('/admin/organisations/' + id + '/summary').then(response => {
            commit('SAVE_ORGANISATION_SUMMARY', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchRegisterOrganisationRequests({commit, state, dispatch, rootState}) {

        console.log('organisation/fetchRegisterOrganisationRequests')

        Vue.axios.get('/admin/register-organisation-requests').then(response => {
            commit('SAVE_REGISTER_ORGANISATION_REQUESTS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },


    fetchRegisterOrganisationRequestDetails({commit, state, dispatch, rootState}, id) {

        console.log('organisation/fetchRegisterOrganisationRequestDetails')

        //load map
        Vue.axios.get('/admin/register-organisation-requests/' + id).then(response => {
            commit('SAVE_REGISTER_ORGANISATION_REQUEST_DETAILS_ENTITY', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    }

}


// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['SAVE_ALL_ACTIVE_TAB']: (state, data) => {
        state.allActiveTab = data
    },

    ['SAVE_ORGANISATION_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.organisationDetails, 'entity', data)
    },

    ['SAVE_ORGANISATION_SUMMARY']: (state, data) => {
        state.organisationSummary = data
    },

    ['SAVE_REGISTER_ORGANISATION_REQUESTS']: (state, data) => {
        state.registerOrganisationRequests = data
    },

    ['SAVE_REGISTER_ORGANISATION_REQUEST_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.registerOrganisationRequestDetails, 'entity', data)
    },
}

export default {
    namespaced: true, state, actions, mutations
}