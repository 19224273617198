<template>
  <div>
    <keep-alive>
      <div class="building-log-details" v-if="log || isNewLog">
        <div class="ibox">
          <div class="ibox-title">
            <h5 v-if="isViewMode">{{ $t('orgAdmin.building.name') }}</h5>
            <h5 v-else>{{ $t('orgAdmin.building.name') }}</h5>
          </div>
          <div v-if="isViewMode && log && log.building" class="ibox-content inline-flex mb-3 rounded justify-content-center">
            <strong class="log-subtitle">{{ log.building.name }}</strong>
          </div>
          <div v-else class="ibox-content inline-flex mb-3 rounded justify-content-center">
            <resource-selector
              :resourceSelectedCallback="resourceSelectedCallback"
              selectDepth="building"
              :preselectResource="{ 'building': { 'id': this.$route.params.buildingId } }"
            />
          </div>
        </div>
        <div class="ibox">
          <div class="ibox-title">
            <h5 v-if="isViewMode">{{ $t('orgAdmin.building.logs.detailsTitle') }}</h5>
            <h5 v-else>{{ $t('orgAdmin.building.logs.newLogTitle') }}</h5>
          </div>
          <div class="ibox-content">
            <validation-observer ref="formObserver" v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(handleSubmitForm)" class="border-white">
                <b-list-group class="bg-white border-white">
                  <!-- Title -->
                  <b-list-group-item>
                    <validation-provider
                      name="orgAdmin.building.logs.title"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <strong class="log-subtitle">{{ $t('orgAdmin.building.logs.title') }}:</strong>
                      <div v-if="isViewMode && log" class="log-info-entry">{{ log.title }}</div>
                      <b-form-input
                        v-else
                        v-model="form.title"
                        :state="errors.length ? false : null"
                      ></b-form-input>
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-list-group-item>
                  <!-- Description -->
                  <b-list-group-item>
                    <validation-provider
                      name="orgAdmin.building.logs.description"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <strong class="log-subtitle">{{ $t('orgAdmin.building.logs.description') }}:</strong>
                      <div v-if="isViewMode && log" class="log-info-entry" style="white-space: pre-wrap;">{{ log.description }}</div>
                      <b-form-textarea
                        v-else
                        v-model="form.description"
                        :state="errors.length ? false : null"
                      ></b-form-textarea>
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-list-group-item>
                  <!-- Start Date and End Date -->
                  <b-list-group-item>
                    <div class="row">
                      <div class="col-md-6">
                        <validation-provider
                          name="orgAdmin.building.logs.startDate"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <strong class="log-subtitle">{{ $t('orgAdmin.building.logs.startDate') }}</strong>
                          <div v-if="isViewMode && log" class="log-info-entry">{{ formatDate(log.startDate) }}</div>
                          <b-form-datepicker
                            v-else
                            v-model="form.startDate"
                            :state="errors.length ? false : null"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                             locale="en-GB"
                          ></b-form-datepicker>
                          <b-form-invalid-feedback v-if="errors.length">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                      <div class="col-md-6">
                        <validation-provider
                          name="orgAdmin.building.logs.endDate"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <strong class="log-subtitle">{{ $t('orgAdmin.building.logs.endDate') }}</strong>
                          <div v-if="isViewMode && log" class="log-info-entry">{{ formatDate(log.endDate) }}</div>
                          <b-form-datepicker
                            v-else
                            v-model="form.endDate"
                            :state="errors.length ? false : null"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="en-GB"
                            ></b-form-datepicker>
                          <b-form-invalid-feedback v-if="errors.length">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                    </div>
                  </b-list-group-item>
                  <!-- Cost -->
                  <b-list-group-item>
                    <validation-provider
                      name="orgAdmin.building.logs.cost"
                      :rules="{ 
                        required: form.cost !== 0,
                        numeric: true,
                        min_value: 0
                      }"
                      v-slot="{ errors }"
                    >
                      <strong class="log-subtitle">{{ $t('orgAdmin.building.logs.cost') }}:</strong>
                      <div v-if="isViewMode && log" class="log-info-entry">{{ formatCost(log.cost, log.currency) }}</div>
                      <div v-else class="d-flex">
                        <b-form-input
                          v-model="form.cost"
                          type="number"
                          step="0.01"
                          :state="errors.length ? false : null"
                          class="flex-grow-1"
                        ></b-form-input>
                        <b-form-select
                          v-model="form.currency"
                          :options="currencyOptions"
                          class="ml-2"
                          :state="errors.length ? false : null"
                        ></b-form-select>
                      </div>
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-list-group-item>
                  <!-- Responsible Person -->
                  <b-list-group-item>
                    <validation-provider
                      name="orgAdmin.building.logs.responsiblePerson"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <strong class="log-subtitle">{{ $t('orgAdmin.building.logs.responsiblePerson') }}:</strong>
                      <div v-if="isViewMode && log" class="log-info-entry">{{ log.responsiblePerson }}</div>
                      <b-form-input
                        v-else
                        v-model="form.responsiblePerson"
                        :state="errors.length ? false : null"
                      ></b-form-input>
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-list-group-item>
                 <!-- Tags -->
                <b-list-group-item>
                  <strong class="log-subtitle" v-if="(log && log.tags) || !isViewMode">{{ $t('orgAdmin.building.logs.tags') }}:</strong>
                  <div class="tags-container">
                    <!-- Edit Mode: Show all tag options and the custom tag input -->
                    <template v-if="!isViewMode">
                      <b-button
                        v-for="tag in tagOptions"
                        :key="tag"
                        :class="form.tags.includes(tag) ? tagClass(tag) : 'outline-' + tagClass(tag)"
                        class="tag-pill"
                        @click="toggleTag(tag)"
                        :style="!form.tags.includes(tag) && !tagOptions.includes(tag) ? { backgroundColor: getColorForTag(tag), color: 'white', borderColor: getColorForTag(tag) } : {}"
                      >
                        {{ getTagLabel(tag) }}
                      </b-button> 
                      <b-button
                        v-for="tag in form.tags.filter(tag => !tagOptions.includes(tag))"
                        :key="tag"
                        class="tag-pill"
                        @click="removeTag(tag)"
                        :class="form.tags.includes(tag) ? tagClass(tag) : 'outline-' + tagClass(tag)"
                        :style="{ backgroundColor: getColorForTag(tag), color: 'white', borderColor: getColorForTag(tag) }"
                      >
                        {{ getTagLabel(tag) }} <span class="ml-2">x</span>
                      </b-button>
                      <div class="custom-tag-wrapper">
                        <b-form-input
                          v-model="customTag"
                          @keydown.enter.prevent="addCustomTag"
                          class="custom-tag-input"
                          :placeholder="'+ ' + $t('orgAdmin.building.logs.custom')"
                        ></b-form-input>
                      </div>
                    </template>
                    <template v-else>
                      <b-button
                        v-for="tag in (log && log.tags) || []"
                        :key="tag"
                        :class="(log && log.tags.includes(tag)) ? tagClass(tag) : 'outline-' + tagClass(tag)"
                        :style="!tagOptions.includes(tag) ? { backgroundColor: getColorForTag(tag), color: 'white', borderColor: getColorForTag(tag) } : {}"
                        class="tag-pill"
                        disabled
                      >
                        {{ getTagLabel(tag) }}
                      </b-button>
                    </template>
                  </div>
                </b-list-group-item>

                  <!-- Locations -->
                  <b-list-group-item>
                    <strong class="log-subtitle">{{ $t('orgAdmin.building.logs.locations') }}:</strong>
                    <div class="row">
                      <div class="col-xxxl-3 col-xxl-4 col-lg-6" v-for="(value, propertyName) in state_groupLocationsByMap" :key="propertyName">
                        <b-form-checkbox
                          v-if="state_groupLocationsByMap[propertyName]"
                          :disabled="isViewMode"
                          switch
                          size="lg"
                          v-model="allSelected[propertyName]"
                          :aria-describedby="'group_' + propertyName"
                          :aria-controls="'group_' + propertyName"
                          @change="toggleMapSelectAllLocations($event, propertyName)"
                        >
                          {{ state_groupLocationsByMap[propertyName].nameWithStatus }}
                        </b-form-checkbox>
                        <b-form-checkbox-group
                          v-if="state_groupLocationsByMap[propertyName] && state_groupLocationsByMap[propertyName].locations"
                          :disabled="isViewMode"
                          switches
                          stacked
                          size="lg"
                          :id="'group_' + propertyName"
                          :name="'group_' + propertyName"
                          v-model="form.locations"
                          value-field="id"
                          text-field="nameWithStatus"
                          :options="state_groupLocationsByMap[propertyName].locations"
                          class="ml-5 m-t-sm m-b-lg"
                          aria-describedby="input-feedback-shareLink-locations"
                        ></b-form-checkbox-group>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <div class="mt-3 mb-3">
                  <b-button v-if="isViewMode" type="button" variant="primary" @click.prevent="switchToEditMode">{{ $t('common.actions.edit') }}</b-button>
                  <b-button v-if="!isViewMode" type="submit" variant="primary">{{ $t('common.actions.save') }}</b-button>
                  <b-button type="button" variant="secondary" class="ml-2" @click="closeDetails">{{ $t('common.actions.cancel') }}</b-button>
                  <b-button v-if="isViewMode" type="button" variant="danger" class="ml-2" @click="confirmDelete">{{ $t('common.actions.delete') }}</b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
      <div v-else>
        <p>{{ $t('common.errors.404.description') }}</p>
      </div>
    </keep-alive>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { BFormCheckbox, BFormCheckboxGroup, BFormDatepicker, BFormSelect } from 'bootstrap-vue';
import ResourceSelector from '@/components/common/ResourceSelector.vue';

export default {
  components: {
    ResourceSelector,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    ValidationObserver,
    ValidationProvider,
    BFormSelect
  },

  data() {
    return {
      allSelected: {},
      form: {
        buildingId: this.$route.params.buildingId || null,
        title: '',
        description: '',
        startDate: new Date(),
        endDate: new Date(),
        cost: 0,
        currency: 'DKK',
        responsiblePerson: '',
        locations: [],
        tags: [],
      },
      isViewMode: true,
      isNewLog: false,
      currencyOptions: [
        { value: 'DKK', text: 'DKK' },
        { value: 'EUR', text: 'EUR' },
        { value: 'USD', text: 'USD' },
        { value: 'SEK', text: 'SEK' },
        { value: 'NOK', text: 'NOK' },
        { value: 'GBP', text: 'GBP' }
      ],
      customTag: '',
      tagOptions: ['Heating', 'Water', 'Electric', "Ventilation"],
      tagDictionary: {
        Heating: this.$t('orgAdmin.building.logs.tagPresets.heating'),
        Water: this.$t('orgAdmin.building.logs.tagPresets.water'),
        Electric: this.$t('orgAdmin.building.logs.tagPresets.electricity'),
        Ventilation: this.$t('orgAdmin.building.logs.tagPresets.ventilation')
      },
      colorList: ['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9A6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080'],
    };
  },
  computed: {
    ...mapState({
      buildingDetails: state => state.buildingLogs.buildingDetails,
      storeMaps: state => state.buildingLogs.maps,
      orgId: state => state.contextOrg.id,
      log: state => state.buildingLogs.selectedLog,
      backRoute: state=>state.buildingLogs.backRoute
    }),
    state_groupLocationsByMap() {
      const maps = this.$store.getters['buildingLogs/getMapsByBuildingId'](this.form.buildingId) || [];
      let result = {};
      if (maps.length > 0) {
        result = maps.reduce((acc, map) => {
          if (map && map.locations && map.locations.length > 0) {
            const key = map.id;
            map.locations.forEach(location => {
              location.nameWithStatus = location.name + (location.status !== 'ACTIVE' ? ' (' + this.$t("enums.ResourceStatus.BLOCKED") + ')' : '');
            });
            acc[key] = {
              name: map.name,
              nameWithStatus: map.name + (map.status !== 'ACTIVE' ? ' (' + this.$t("enums.ResourceStatus.BLOCKED") + ')' : ''),
              locations: map.locations,
            };
          }
          return acc;
        }, {});
      }
      return result;
    },
  },
  watch: {
    log: {
      handler(newLog) {
        if (newLog) {
          this.initializeForm(newLog);
        }
      },
      immediate: true,
    },
    state_groupLocationsByMap: {
      handler(newVal) {
        if (newVal) {
          for (const [key, value] of Object.entries(newVal)) {
            this.allSelected[key] = value.locations.every(val => this.form.locations.includes(val.id));
          }
        }
      },
      immediate: true,
    },
    'form.locations': {
      handler() {
        if (this.state_groupLocationsByMap) {
          for (const [key, value] of Object.entries(this.state_groupLocationsByMap)) {
            this.allSelected[key] = value.locations.every(val => this.form.locations.includes(val.id));
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    resourceSelectedCallback(payload) {
      if (payload.buildingSelected.id !== this.form.buildingId) {
        this.form.locations = []; // Clear selected locations
        this.fetchBuildingDetails(payload.buildingSelected.id);
        this.updateURL(payload.buildingSelected.id);
      }
    },
    async fetchBuildingDetails(buildingId, initial = false) {
      if (buildingId == "undefined") return;
      if (buildingId !== this.form.buildingId || initial) {
        this.form.buildingId = buildingId;
        await this.$store.dispatch('buildingLogs/loadBuildingDetails', buildingId);
        this.form.locations = []; // Clear selected locations when building changes
      }
    },
    updateURL(buildingId) {
      const newURL = this.$route.params.logId === 'new'
        ? `/buildings/${buildingId}/logs/new`
        : `/buildings/${buildingId}/logs/${this.$route.params.logId}`;

      if (newURL !== this.$route.path) {
        this.$router.push(newURL);
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    dateFormatter(date) {
      return date ? new Date(date).toISOString().substr(0, 10) : null;
    },
    initializeForm(log) {
      this.form = {
        buildingId: log.building.id,
        title: log.title,
        description: log.description,
        startDate: log.startDate,
        endDate: log.endDate,
        cost: log.cost,
        currency: log.currency || 'DKK',
        responsiblePerson: log.responsiblePerson,
        locations: log.locations ? log.locations.map(location => location.id) : [],
        id: log.id ? log.id : null,
        tags: log.tags ? [...log.tags] : []
      };
    },
    hashStringToNumber(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    getColorForTag(tag) {
      const hash = this.hashStringToNumber(tag);
      const index = Math.abs(hash) % this.colorList.length;
      return this.colorList[index];
    },
    tagStyles(tag) {
        return this.getColorForTag(tag);
    },
    formatDate(value) {
      return value ? value : '-';
    },
    formatCost(value, currency) {
      return value ? `${value.toFixed(2)} ${currency}` : '-';
    },
    closeDetails() {
      const route = this.backRoute || `/buildings/${this.form.buildingId || this.$route.params.buildingId}`;
      this.$store.commit('buildingLogs/CLEAR_BACK_ROUTE');
      this.$store.commit('buildingLogs/SET_SELECTED_LOG', null);
      this.$router.push(route);
    },
    async handleSubmitForm() {
      const newLog = { ...this.form, organisationId: this.orgId, locations: this.form.locations.map(loc_id => ({ id: loc_id })) };
      newLog.startDate = this.dateFormatter(newLog.startDate);
      newLog.endDate = this.dateFormatter(newLog.endDate);
      newLog.building = { id: this.form.buildingId || this.$route.params.buildingId };
      if (this.log && this.log.id) {
        try {
          await this.$store.dispatch('buildingLogs/updateLog', newLog);
          this.$toastr.i(this.$t('orgAdmin.building.logs.updated'));
          this.clearForm();
          this.closeDetails();
        } catch (error) {
          this.$toastr.e(this.$t('orgAdmin.building.logs.updatedError'));
          console.error(error);
        }
      } else {
        try {
          await this.$store.dispatch('buildingLogs/addLog', newLog);
          this.$toastr.i(this.$t('orgAdmin.building.logs.savedSuccess'));
          this.clearForm();
          this.closeDetails();
        } catch (error) {
          this.$toastr.e(this.$t('orgAdmin.building.logs.savedError'));
          console.error(error);
        }
      }
    },
    switchToEditMode() {
      this.isViewMode = false;
    },
    toggleMapSelectAllLocations(checked, mapId) {
      const mapLocations = this.state_groupLocationsByMap[mapId] ? this.state_groupLocationsByMap[mapId].locations : null;
      if (mapLocations) {
        if (checked) {
          mapLocations.forEach(location => {
            if (this.form.locations.findIndex(id => id === location.id) === -1) {
              this.form.locations.push(location.id);
            }
          });
        } else {
          mapLocations.forEach(location => {
            const indexToRemove = this.form.locations.findIndex(id => id === location.id);
            if (indexToRemove !== -1) {
              this.form.locations.splice(indexToRemove, 1);
            }
          });
        }
      }
    },
    clearForm() {
      this.form = {
        buildingId: this.$route.params.buildingId || null,
        title: '',
        description: '',
        startDate: new Date(),
        endDate: new Date(),
        cost: 0,
        currency: 'DKK',
        responsiblePerson: '',
        locations: [],
        tags: []
      };
      this.$store.commit('buildingLogs/SET_SELECTED_LOG', null);
    },
    confirmDelete() {
      this.confirmDialog({
        text: this.$t('reporting.modal.confirmDeletion.text', { itemName: this.$t("log") }),
        title: this.$t('reporting.modal.confirmDeletion.title'),
        callbackYes: async () => {
          const buildingId = this.$route.params.buildingId;
          const logId = this.$route.params.logId;
          try {
            await this.$store.dispatch('buildingLogs/deleteLog', { buildingId, logId });
            this.$toastr.i(this.$t('reporting.modal.deletion.success', { itemName: this.$t("Log") }));
            this.closeDetails();
          } catch (error) {
            this.$toastr.e(this.$t('reporting.modal.deletion.error'));
            console.error(error);
          }
        },
      });
    },
    toggleTag(tag) {
      const index = this.form.tags.indexOf(tag);
      if (index === -1) {
        this.form.tags.push(tag);
      } else {
        this.form.tags.splice(index, 1);
      }
    },
    addCustomTag() {
      if (this.customTag && !this.form.tags.includes(this.customTag)) {
        this.form.tags.push(this.customTag);
      }
      this.customTag = '';
    },
    removeTag(tag) {
      const index = this.form.tags.indexOf(tag);
      if (index !== -1) {
        this.form.tags.splice(index, 1);
      }
    },
    tagClass(tag) {
      const colors = {
        Heating: 'heating',
        Water: 'water',
        Electric: 'electric',
        Ventilation: 'ventilation',
        default: 'default'
      };
      return colors[tag] || colors.default;
    },
    getTagLabel(tag) {
      return this.tagDictionary[tag] || tag;
    },
  },
  async created() {
    const buildingId = this.$route.params.buildingId;
    const logId = this.$route.params.logId ?? "new";

    this.form.buildingId = buildingId;
    this.isNewLog = logId === 'new';

    await this.fetchBuildingDetails(buildingId, true);

    try {
      if (logId === 'new') {
        this.clearForm();
        this.isViewMode = false; // Set to edit mode by default for new log
      } else {
        const log = await this.$store.dispatch('buildingLogs/fetchLogById', { buildingId, logId });
        if (!log) {
          this.$store.commit('buildingLogs/SET_SELECTED_LOG', null);
        }
      }
    } catch (error) {
      console.error(error);
      this.$store.commit('buildingLogs/SET_SELECTED_LOG', null);
    }
  },
};
</script>

<style scoped>
.building-log-details {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
.map-locations {
  margin-top: 10px;
}
.ml-4 {
  margin-left: 1.5rem;
}

.list-group-item{
  border: none;
}

.log-subtitle {
  font-weight: bold;
}

.log-info-entry {
  margin: 0.75rem 0;
}

.collapse-toggle {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: bold;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.tag-pill {
  margin: 5px;
  border-radius: 20px;
  width: 7rem;
  height: 2rem;
}
.custom-tag-input {
  width: auto;
  border: none;
  outline: none;
  color: blue;
}

.custom-tag-input::placeholder {
  color: blue;
  opacity: 1; /* Firefox */
}

.custom-tag-wrapper {
  display: flex;
  align-items: center;
  margin: 5px;
  border-radius: 20px;
  background-color: white;
  color: black;
  padding: 5px 10px;
  width: 7rem;
  height: 2rem;
  border-color: blue;
  border-width: 1px;
  border-style: solid;
}
.custom-tag-wrapper .custom-tag-input {
  color: blue;
  background-color: transparent;
  width: 100%;
}
.active {
  background-color: #007bff;
  color: white;
}

.heating {
  background-color: red;
  border-color: red;
  color: white;
}

.outline-heating{
  border-color: red;
  color: red;
  border: red 1px solid;
  background-color: white;
}

.water {
  background-color: blue;
  border-color: blue;
  color: white;
}

.outline-water{
  border-color: blue;
  color: blue;
  border: blue 1px solid;
  background-color: white;
}

.electric {
  background-color: gold;
  border-color: gold;
  color: white;
}

.outline-electric{
  border-color: gold;
  color: gold;
  border: gold 1px solid;
  background-color: white;
}

.ventilation {
  background-color: indigo;
  border-color: indigo;
  color: white;
}

.outline-ventilation{
  border-color: indigo;
  color: indigo;
  border: indigo 1px solid;
  background-color: white;
}


.default {
  background-color: aqua;
  border-color: aqua;
  color: white;
}

.outline-default{
  border-color: aqua;
  color: aqua;
  border: aqua 1px solid;
  background-color: white;
}

</style>
