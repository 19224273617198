<template>
  <div>
    <div class="row" v-if="sensorValuesPerAttributesPerLocation && sensorValuesPerAttributesPerLocation.size > 0 && selectedLocations.length > 0">
      <div :class="graphConfig.fullSize ? 'col-xxxl-12' : 'col-xxxl-6'" class="col-xxl-12 p-xl-2 p-md-0" v-for="chart in state_sensorValuesPerAttributesPerLocation" :key="chart.sensorDef.type">
        <highcharts :options="chart" :ref="chart.sensorDef.type"></highcharts>
      </div>
    </div>
    <div v-else class="text-center mt-5 mb-5">
      <div class="font-bold">
        <template v-if="sensorValuesPerAttributesPerLocation && sensorValuesPerAttributesPerLocation.size > 0 && graphConfig.selectedLocations.length==0">{{ $t('graphs.locationComparison.graphView.placeholderSelectRooms') }}</template>
        <template v-else>{{ $t('graphs.locationComparison.graphView.placeholder') }}</template>
      </div>
    </div>
  </div>
</template>


<script>
import {mapState} from "vuex"
import moment from 'moment-timezone'
import Highcharts from 'highcharts';

let chartOptionsBase = {
  credits: false,
  title: {
    useHTML: true,
    style: {
      fontSize: '14px',
      fontStyle: 'bold'
    }
  },
  chart: {
    zoomType: 'x',
    type: 'spline',
    height: '350px',
    spacingTop: 40,
    style: {
      fontFamily: 'open sans, Helvetica Neue, Helvetica, Arial, sans-serif',
    }
  },
  xAxis: {
    type: 'datetime',
    labels: {
      autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
      formatter: function () {
        return Highcharts.dateFormat('%H:%M', this.value);
      },
      _style: {
        color: '#333',
        fontSize: '12px'
      }
    },
    minPadding: 0,
    maxPadding: 0,
    tickPixelInterval: 50
  },
  plotOptions: {
    area: {
      fillOpacity: 0.3,
    },
    spline: {
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3
        }
      },
      marker: {
        enabled: false
      }
    }
  },
  yAxis: {
    maxPadding: 0.1,
    allowDecimals: false,
    title: {
      text: ''
    }
  },
  legend: {
    enabled: true
  },
  series: []
}

export default {
  data() {
    return {
    }
  },
  watch: {
    graphConfig(newVal, oldVal) {
      console.log('graphConfig changed: ',  JSON.stringify(newVal), ' | was: ', oldVal)
      if(newVal && oldVal && newVal.fullSize !== oldVal.fullSize) {
        if (this.sensorValuesPerAttributesPerLocation && this.sensorValuesPerAttributesPerLocation.size > 0) {
          let vm = this
          this.sensorValuesPerAttributesPerLocation.forEach((data, key) => {
            this.$nextTick(() => {
              let comp = vm.$refs[key]
              if (comp) {
                setTimeout(function () {
                  //comp[0].chart.reflow()
                  window.dispatchEvent(new Event('resize'));
                }, 1000)
              }
            })
          })
        }
      }
    },
    sensorValuesPerAttributesPerLocation: function(newVal, oldVal) {
      console.log('sensorValuesPerAttributesPerLocation changed: ',  newVal ? "size: " + newVal.size : null, ' | was: ', oldVal)
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedMap: state => state.graphLocationComparison.selectedMap,
      selectedBuilding: state => state.graphLocationComparison.selectedMap.building,
      graphConfig: state => state.graphLocationComparison.graphConfig,
      selectedLocations: state => state.graphLocationComparison.graphConfig.selectedLocations,
      sensorValuesPerAttributesPerLocation: state => state.graphLocationComparison.sensorValuesPerAttributesPerLocation
    }),

    state_sensorValuesPerAttributesPerLocation() {

      let charts = []

      console.log('state_sensorValuesPerAttributesPerLocation size ' + this.sensorValuesPerAttributesPerLocation.size + ', selectedLocations ' + this.selectedLocations)

      let vm = this

      if (this.sensorValuesPerAttributesPerLocation && this.sensorValuesPerAttributesPerLocation.size > 0) {

        this.sensorValuesPerAttributesPerLocation.forEach((entry, attribute) => {

          if(entry.size > 0 && vm.selectedLocations && vm.selectedLocations.some(locationId => entry.has(locationId))) {

            let chartDef = JSON.parse(JSON.stringify(chartOptionsBase))
            let plotBandAr = [];
            let plotBand = {color: '#E9D8DB'};

            chartDef.sensorDef = vm.$i18n.t('enums.sensorType.' + attribute)
            chartDef.sensorDef.type = attribute
            chartDef.title.text = '<i class="' + chartDef.sensorDef.icon + '  mr-2"></i>' + chartDef.sensorDef.name + ' (' + chartDef.sensorDef.unit + ')'
            chartDef.time = {}
            chartDef.time.getTimezoneOffset = function (timestamp) {
              return -moment.tz(timestamp, vm.selectedBuilding.timeZone).utcOffset();
            }

            if (this.graphConfig.enableWeekends !== false) {
              let plotBandSeries = {
                id: 'weekend',
                name: 'Weekend Highlight',
                color: '#E9D8DB',
                marker: {
                  symbol: "square",
                  symbolName: "square",
                  lineColor: null,
                  lineWidth: 8,
                }
              }

              chartDef.series.push(plotBandSeries)
              chartDef.plotOptions = {
                series: {
                  events: {
                    legendItemClick: function () {
                      if (this.options.id === 'weekend') {
                        if (this.visible) {
                          this.chart.xAxis[0].update({
                            plotBands: []
                          })
                        } else {
                          this.chart.xAxis[0].update({
                            plotBands: plotBandAr
                          })
                        }
                      }
                    },
                  }
                }
              }

            } else {
              this.$nextTick(() => {
                $('g.highcharts-plot-bands-0').remove()
                chartDef.series.forEach((serie, index) => {
                  if (serie.id === 'weekend') {
                    chartDef.series.splice(index, 1)
                  }
                })
              })
            }

            vm.selectedLocations.forEach(locationId => {
              if(entry.has(locationId)){

                //customizing yAxis according to attribute type
                if(attribute == 'temperature'){
                  chartDef.yAxis.softMin = 15
                  chartDef.yAxis.softMax = 30
                } else if(attribute == 'humidity'){
                  chartDef.yAxis.min = 0
                  chartDef.yAxis.max = 100
                }

                let sensorData = entry.get(locationId)
                let series = {
                  unit: chartDef.sensorDef.unit,
                  name: vm.selectedMap.locations.find(location => location.id == locationId).name,
                  // chartDef.yAxis.title.text: chartDef.sensorDef.name + ' (' + chartDef.sensorDef.unit + ')',
                  data: [],
                  tooltip: {
                    valueSuffix: ' ' + chartDef.sensorDef.unit
                  }
                }

                for (let i = 0; i < sensorData.length; i++) {
                  var date = new Date(Date.parse(sensorData[i].time))
                  if (date.getDay() === 6) {
                    plotBand.from = date.setHours(0, 0, 0, 0)
                    plotBand.to = date.setHours(23, 59, 59) + (24 * 60 * 60 * 1000)
                    plotBandAr.push(plotBand)
                    plotBand = {
                      color: '#E9D8DB',
                    };
                  }
                  series.data.push({
                    x: Date.parse(sensorData[i].time),
                    y: sensorData[i].value ? Math.round(+(sensorData[i].value) * 10) / 10 : null
                  })
                }
                series.data.sort((a, b) => {
                  return a.x - b.x
                })
                chartDef.series.push(series)
                chartDef.xAxis.plotBands = plotBandAr
              }
            })

            charts.push(chartDef)

            /*vm.$nextTick(() => {
              let comp = vm.$refs[attribute]
              if (comp) {
                chartDef.series.forEach((entry, index) => {
                  comp[0].chart.series[index].setData(chartDef.series[index].data, true)
                })
              }
            })*/

          }
        })

        let preferredOrder = vm.$store.state.systemParams.preferredSensorOrder
        charts.sort(function (a, b) {
          return preferredOrder.indexOf(a.sensorDef.type) - preferredOrder.indexOf(b.sensorDef.type)
        })

      }

      return charts
    }
  },
}
</script>
