import Vue from "vue";
import * as commons from '@/commons'
import router from "@/router";

// default state
const getDefaultState = () => {
    return {
        buildings: [],
        buildingDetails: {
            entity: {},
            activeTab: 0,
        },
        mapDetails: {
            entity: {}
        },
        locationDetails: {
            entity: {}
        }
    }
}

// initial state
const state = getDefaultState()

// actions
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('profiles/appInit')
    },

    fetchOrganisationBuildings({commit, state, dispatch, rootState}, id) {

        console.log('buildings/fetchOrganisationBuildings')

        Vue.axios.get('/admin/organisations/'+ id +'/buildings').then(response => {
            commit('SAVE_ORGANISATION_DETAILS_BUILDINGS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchBuildingDetails({commit, state, dispatch, rootState}, id) {

        console.log('buildings/fetchBuildingDetails')

        //load map
        Vue.axios.get('/admin/buildings/' + id,  { params: { withMapImages: true, withOpeningHours: true } }).then(response => {
            commit('SAVE_BUILDING_DETAILS_ENTITY', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchMapDetails({commit, state, dispatch, rootState}, id) {

        console.log('buildings/fetchMapDetails')

        //load map
        Vue.axios.get('/admin/maps/' + id).then(response => {
            commit('SAVE_MAP_DETAILS_ENTITY', response.data)

            dispatch('profiles/fetchOrganisationLocationProfiles', state.mapDetails.entity.building.organisation.id, {root:true})
            dispatch('schedulers/fetchOrganisationSchedulerProfiles', state.mapDetails.entity.building.organisation.id, {root:true})

        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchLocationDetails({commit, state, dispatch, rootState}, params) {

        console.log('buildings/fetchLocationDetails')

        Vue.axios.get('/admin/locations/' + params.locationId).then(response => {
            commit('SAVE_LOCATION_DETAILS_ENTITY', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    clearLocationDetails({commit, state, dispatch, rootState}) {
        console.log('orgAdmin/clearLocationDetails')
        commit('SAVE_LOCATION_DETAILS_ENTITY', {})
    },

    updateBuildingOpeningHours({commit, state, dispatch, rootState}, openingHours){
        console.log('buildings/updateBuildingOpeningHours')
        commit('SAVE_BUILDING_OPENING_HOURS', openingHours);
    },

    updateBuildingOperatingHourExceptions({commit, state, dispatch, rootState}, exceptions){
        console.log('buildings/updateBuildingOperatingHourExceptions')
        commit('SAVE_BUILDING_OPENING_HOURS_EXCEPTIONS', exceptions);
    },

    addException({ commit, state }, { buildingId, exception }) {
        Vue.axios.post(`/admin/buildings/${buildingId}/exceptions`, exception)
            .then(response => {
                commit('ADD_EXCEPTION', { buildingId, exception: response.data });
            }).catch(error => {
                commons.processRestError(error)
            })
    },

    updateException({ commit, state }, { buildingId, exception }) {
        Vue.axios.put(`/admin/buildings/${buildingId}/exceptions/${exception.id}`, exception)
            .then(response => {
                commit('UPDATE_EXCEPTION', { buildingId, exception: response.data });
            }).catch(error => {
                commons.processRestError(error)
            })
    },

    deleteException({ commit, state }, { buildingId, exceptionId }) {
        Vue.axios.delete(`/admin/buildings/${buildingId}/exceptions/${exceptionId}`)
            .then(() => {
                commit('DELETE_EXCEPTION', { buildingId, exceptionId });
            }).catch(error => {
                commons.processRestError(error)
            })
    }
}

// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['SAVE_ORGANISATION_DETAILS_BUILDINGS']: (state, data) => {
        state.buildings = data
    },

    ['SAVE_BUILDING_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.buildingDetails, 'entity', data)
    },

    ['SAVE_MAP_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.mapDetails, 'entity', data)
    },

    ['SAVE_LOCATION_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.locationDetails, 'entity', data)
    },

    ['SAVE_BUILDING_OPENING_HOURS']: (state, data) => {
        Vue.set(state.buildingDetails.entity, 'openingHours', data)
    },

    ['SAVE_BUILDING_OPENING_HOURS_EXCEPTIONS']: (state, data) => {
        Vue.set(state.buildingDetails.entity, 'buildingOperatingHoursExceptions', data)
    },

    ['ADD_EXCEPTION']: (state, { buildingId, exception }) => {
        const building = state.buildingDetails.entity;
        if (building) {
            building.buildingOperatingHourExceptions.push(exception);
        }
    },

    ['UPDATE_EXCEPTION']: (state, { buildingId, exception }) => {
        const building = state.buildingDetails.entity;
        if (building) {
            const index = building.buildingOperatingHourExceptions.findIndex(e => e.id === exception.id);
            if (index !== -1) {
                Vue.set(building.buildingOperatingHourExceptions, index, exception);
            }
        }
    },

    ['DELETE_EXCEPTION']: (state, { buildingId, exceptionId }) => {
        const building = state.buildingDetails.entity;
        if (building) {
            building.buildingOperatingHourExceptions = building.buildingOperatingHourExceptions.filter(e => e.id !== exceptionId);
        }
    },

    ['SAVE_BUILDING_DETAILS_ACTIVE_TAB']: (state, data) => {
        Vue.set(state.buildingDetails, 'activeTab', data)
    }

}

export default {
    namespaced: true, state, actions, mutations
}
