<template>
  <div v-if="selectedMap">

    <div class="row text-center">
      <div class="col-12">
        <div class="text-center pt-4">
          <div class="row">
            <div class="col-12">
              <date-range-picker
                  ref="picker"
                  opens="left"
                  :locale-data="Object.assign({}, $t('dateRangePickerLabels'), { format: 'dd-mm-yyyy' })"
                  :maxDate="maxDate"
                  :autoApply="false"
                  :showDropdowns="true"
                  :ranges="ranges"
                  v-model="dateRange"
                  @update="selectedDateRangeChanged"
              >
                <template v-slot:input="picker" >
                  <div style="max-width: 350px;">
                    {{ picker.startDate | formatDateByLocale }} - {{ picker.endDate | formatDateByLocale}}
                  </div>
                </template>
              </date-range-picker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="availableLocationsWithSensorValuesSorted.length > 0">
      <div class="row mt-3">
        <div class="col-12">
          <h4>{{ $t('graphs.locationComparison.graphConfig.locations') }}</h4>
          <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
            <b-form-group id="input-group-locations">
              <b-form-checkbox 
                id="select-all-checkbox"
                switch
                size="lg"
                v-model="selectAll"
                @change="toggleSelectAll">
                {{ $t('graphs.locationComparison.graphConfig.allLocations') }}
              </b-form-checkbox>
              <b-form-checkbox-group
                  switches
                  stacked
                  size="lg"
                  id="input-locations"
                  v-model="selectedLocations"
                  :options="availableLocationsWithSensorValuesSorted"
                  value-field="id"
                  text-field="name"
                  name="input-location"
                  @change="selectedLocationsChanged"
              ></b-form-checkbox-group>
            </b-form-group>
          </transition>
        </div>

        <!--div class="col-12 mt-4 text-center">
          <button type="button" class="btn btn-sm btn-primary" @click.prevent="selectedDateRangeChanged">{{ $t('graphs.location.graphConfig.actions.fetchGraphData') }}</button>
        </div-->

      </div>

      <div class="row mt-4">
        <div class="col-12">
          <h4>{{ $t('graphs.locationComparison.graphConfig.graphOptions.titleGraphOptions') }}</h4>
          <b-form-checkbox v-model="graphOptions.fullSize" name="fullSize" switch size="lg" @change="selectedOptionsChanged">
            {{  $t('graphs.locationComparison.graphConfig.graphOptions.fullSize') }}
          </b-form-checkbox>
          <b-form-checkbox v-model="graphOptions.enableWeekends" name="enableWeekends" switch size="lg" @change="selectedOptionsChanged">
            {{  $t('graphs.locationComparison.graphConfig.graphOptions.enableWeekends') }}
          </b-form-checkbox>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import {mapState} from "vuex"
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import _ from "lodash"

export default {
  components: {
    DateRangePicker
  },
  data() {
    const today = new Date();
    return {
      info: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedLocations: [],
      graphOptions: {
        fullSize: false,
        enableWeekends: true,
      },
      selectAll: false,
      ranges: {
        [this.$t('graphs.location.graphConfig.dateRangeSelector.options.today')]: [new Date(today.getFullYear(), today.getMonth(), today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
        [this.$t('graphs.location.graphConfig.dateRangeSelector.options.yesterday')]: [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1), new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)],
        [this.$t('graphs.location.graphConfig.dateRangeSelector.options.last7days')]: [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
        [this.$t('graphs.location.graphConfig.dateRangeSelector.options.last30days')]: [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
        [this.$t('graphs.location.graphConfig.dateRangeSelector.options.currentMonth')]: [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
        [this.$t('graphs.location.graphConfig.dateRangeSelector.options.lastMonth')]: [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        [this.$t('graphs.location.graphConfig.dateRangeSelector.options.thisYear')]: [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        [this.$t('graphs.location.graphConfig.dateRangeSelector.options.lastYear')]: [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)]
      }
    }
  },
  created(){
    console.log('GraphConfig $route.name: ' + this.$route.name)
    this.selectedLocations = this.graphConfig.selectedLocations
    this.dateRange = this.graphConfig.dateRange
    this.graphOptions.fullSize = this.graphConfig.fullSize
    this.graphOptions.enableWeekends = this.graphConfig.enableWeekends
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedMap: state => state.graphLocationComparison.selectedMap,
      selectedBuilding: state => state.graphLocationComparison.selectedMap.building,
      availableLocations: state => state.graphLocationComparison.selectedMap.locations,
      graphConfig: state => state.graphLocationComparison.graphConfig
    }),
    maxDate(){
        let nowMoved = this.$moment.tz(new Date(), this.selectedBuilding.timeZone)
        let newMaxDate = nowMoved.format("YYYY-MM-DD HH:mm")
        console.log('maxDate resolved date ' + newMaxDate)
        return newMaxDate
    },
    availableLocationsWithSensorValuesSorted(){
      // check if location has any sensor values
      console.log('availableLocationsWithSensorValuesSorted')
      if(this.availableLocations && this.availableLocations.length > 0){
        return _.sortBy(this.availableLocations.filter(location => { return location.sensorValues && location.sensorValues.length > 0 }), [function(o) { return o.name; }])
      }
      return []
    }
  },
  watch: {
    selectedMap: function(newVal, oldVal) {
      console.log('selectedMap changed: ',  newVal ? "(" + newVal.id + ") " + newVal.name: null, ' | was: ', oldVal)
      if(newVal && newVal.id != oldVal.id){
        this.selectedLocations = []
      }
    }
  },
  methods: {
    selectedDateRangeChanged(){
      this.$store.dispatch('graphLocationComparison/graphConfigDateRangeSelected', { dateRange: this.dateRange })
    },
    selectedLocationsChanged() {
      console.log('selectedLocations changed: ' + this.selectedLocations)
      this.$store.dispatch('graphLocationComparison/graphConfigSelectedLocationsChanged', this.selectedLocations)

      this.selectAll = this.selectedLocations.length === this.availableLocationsWithSensorValuesSorted.length;
    },
    selectedOptionsChanged() {
      console.log('graphOptions changed: ' + this.graphOptions)
      this.$store.dispatch('graphLocationComparison/graphConfigOptionsChanged', this.graphOptions)
    },
    toggleSelectAll() {
      if (this.selectAll) {
        this.selectedLocations = this.availableLocationsWithSensorValuesSorted.map(loc => loc.id);
      } else {
        this.selectedLocations = [];
      }
      this.$store.dispatch('graphLocationComparison/graphConfigSelectedLocationsChanged', this.selectedLocations)
    },
  }
}
</script>

<style>
.custom-switch.b-custom-control-lg .custom-control-label, .input-group-lg .custom-switch .custom-control-label {
  font-size: 1rem;
  padding-top: 3px;
  line-height: 1.5;
}
</style>