<template>
  <div>
    <b-form @submit.stop.prevent="onSubmit">
        <div class="plot-type-section">
          <h5>{{ $t('reporting.config.configTitle') }}</h5>
          <div class="form-section-wrapper">
            <b-form-radio-group v-model="configuration.reportPresetSelection" name="report-configuration-options" class="d-flex w-100 justify-content-around">
              <div class="button-wrapper">
                <p>{{ $t('reporting.config.fullReport') }}</p>
                <b-form-radio value="fullReport" class="radio-button"></b-form-radio>
              </div>
              <div class="button-wrapper">
                <p>{{ $t('reporting.config.minimalReport') }}</p>
                <b-form-radio value="minimalReport" class="radio-button"></b-form-radio>
              </div>
              <div class="button-wrapper">
                <p>{{ $t('reporting.config.manualSelect') }}</p>
                <b-form-radio value="manualSelectionReport" class="radio-button"></b-form-radio>
              </div>
            </b-form-radio-group>
          </div>
          <div class="form-section-wrapper report-options-wrapper">
            <div v-for="(option, index) in configuration.reportOptions" :key="index" class="d-flex align-center">
              <b-form-checkbox v-model="option.selected" @change="() => configuration.reportPresetSelection = 'manualSelectionReport'">
                {{ $t(option.label) }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <!-- Report Period Section -->
        <div class="report-period-section">
          <h5>{{ $t('reporting.config.period') }}</h5>
          <div class="report-date-picker-wrapper">
            <b-form-input type="month" v-model="configuration.reportPeriod.startDate" >
            </b-form-input>
            <b-form-input type="month" v-model="configuration.reportPeriod.endDate" > 
            </b-form-input>
          </div>
        </div>
        <!-- Hourly Analysis Section -->
        <div class="hourly-analysis-section">
          <h5>{{ $t('reporting.config.hourly') }}</h5>
          <div class="form-section-wrapper">
            <b-form-radio-group v-model="selectedHourlyAnalysis" name="hourly-analysis-options">
              <b-form-radio value="twentyFourHour">
                {{ $t('reporting.config.twentyFourHours') }}
              </b-form-radio>
              <b-form-radio value="openHours">
                {{ $t('reporting.config.openingHours') }} (8:00 - 17:00)
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
        <!-- Floor Plans Section -->
        <div class="floor-plans-section" v-if="buildingDetails && buildingDetails.maps">
          <h5>{{ $t('reporting.config.floorplans') }}</h5>
          <div class="form-section-wrapper">
            <!-- Select All Toggle -->
            <b-form-group>
              <b-form-checkbox :checked="allSelected" @change="toggleAll">
                {{ $t('reporting.config.selectAll') }}
              </b-form-checkbox>
            </b-form-group>
            <b-form-checkbox-group v-model="configuration.floorPlans">
              <b-form-checkbox v-for="(floorplan, index) in buildingDetails.maps" :key="index" :value="floorplan.id" class="ml-3">
                {{ floorplan.name }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </div>
        </div>
        <!-- Submit Button -->
        <b-button type="submit" variant="primary" :disabled="!formValid" class="mt-2 mb-4 ml-1">
          {{ $t('reporting.config.generate') }}
        </b-button>
    </b-form>
  </div>
</template>


<script>
import { mapState } from "vuex"


export default {
  props:{     
    buildingDetails: {
      type: Object,
      required: true
    }
  },

  components: {
  },

  data() {
    const lastYear = new Date().getFullYear() - 1;
    const januaryLastYear = `${lastYear}-01`; // January of last year
    const decemberLastYear = `${lastYear}-12`; // December of last year
    return {
      configuration: {
        reportPresetSelection: 'fullReport',
        reportType: {
          thermalComfort: true,
          indoorAirQuality: true,
          presence: false,
          visualComfort: false,
          acousticComfort: false
        },
        reportOptions: [
          { label: 'reporting.config.ieqTAIL', selected: true, key: "ieq" },
          { label: 'reporting.config.buildingSummary', selected: true, key: "building_summary" },
          { label: 'reporting.config.floorplansSummary', selected: true, key: "floorplan_summary" },
          { label: 'reporting.config.roomAnalysis', selected: true, key: "room_analysis" },
          { label: 'reporting.config.br18Compliance', selected: true, key: "br18" },
          { label: 'reporting.config.atdkCompliance', selected: true, key: "at_dk" },
          { label: 'reporting.config.mouldRiskAnalysis', selected: true, key: "mould_analysis" },
        ],
        plotType: {
          thresholds: true,
          meanValues: false
        },
        reportPeriod: {
          startDate: januaryLastYear, // Adjust according to how you want to handle dates
          endDate: decemberLastYear
        },
        hourlyAnalysis: {
          twentyFourHour: false,
          openHours: false
        },
        floorPlans: [],
        buildingId: this.buildingDetails.id ?? null , // Make sure this is set appropriately
      },
      reportTypes: ['Thermal Comfort', 'Indoor air quality', 'Presence', 'Visual Comfort', 'Acoustic Comfort']
    };
  },

  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedOrg: state => state.reporting.selectedOrg,
      selectedBuilding: state => state.reporting.selectedBuilding,
    }),

    formValid() {
      if (!this.configuration || !this.configuration.reportType) return false;

      const { reportType, plotType, reportPeriod, hourlyAnalysis, floorPlans } = this.configuration;
      const { thermalComfort, indoorAirQuality, presence, visualComfort, acousticComfort } = reportType;
      const { thresholds, meanValues } = plotType;
      const { startDate, endDate } = reportPeriod;
      const { twentyFourHour, openHours } = hourlyAnalysis;

      const isAnyReportOptionSelected = this.configuration.reportOptions.some(option => option.selected);

      return (thermalComfort || indoorAirQuality || presence || visualComfort || acousticComfort) &&
            (thresholds || meanValues) &&
            startDate && endDate && (new Date(endDate) > new Date(startDate)) &&
            (twentyFourHour || openHours) &&
            floorPlans && (floorPlans.length > 0) &&
            isAnyReportOptionSelected;
    },

    allSelected: {
      get() {
        return this.configuration.floorPlans.length === this.buildingDetails.maps.length;
      },
      set(value) {
        let selected = [];
        if (value) {
          selected = this.buildingDetails.maps.map(floorplan => floorplan.id);
        }
        this.configuration.floorPlans = selected;
      }
    },
    selectedHourlyAnalysis: {
      get() {
        // Return the key of the attribute that is true, or null if none are true
        if (this.configuration.hourlyAnalysis.twentyFourHour) return 'twentyFourHour';
        if (this.configuration.hourlyAnalysis.openHours) return 'openHours';
        return null; // No selection
      },
      set(value) {
        // Set all attributes to false, then set the selected attribute to true
        this.configuration.hourlyAnalysis.twentyFourHour = false;
        this.configuration.hourlyAnalysis.openHours = false;
        if (value) {
          this.configuration.hourlyAnalysis[value] = true;
        }
      }
  }
  },

  methods: {
    onSubmit() {
      this.$store.dispatch('reporting/requestReportGeneration', this.configuration)
        .then(() => {
          this.resetForm();
          setTimeout(() => {
            this.refreshReports();
          }, 1000);
        })
        .catch(error => {
          if (error.response && error.response.status === 409) {
            // If the status code is 409, alert the user that the report already exists
            alert('A report with these parameters already exists.');
          } else {
            console.error('Error during report generation:', error);
            alert('Failed to submit report generation request.');
          }
          // Do not clear the form or refresh reports in case of error
        });
    },

    handleConfigurationPresets(presetName){
      const minimalKeys = ["ieq", "building_summary", "floorplan_summary"]
      if (this.configuration.reportOptions) {
        if (presetName === 'fullReport') {
          // Set all options to selected
          this.configuration.reportOptions.forEach(option => {
            option.selected = true;
          });
        } else if (presetName === 'minimalReport') {
          // Set only minimal options to selected
          this.configuration.reportOptions.forEach(option => {
            option.selected = minimalKeys.includes(option.key);
          });
        }
      } 
    },

    toggleSelection(selectedPlotType) {
      // Reset both options
      this.configuration.plotType.thresholds = false;
      this.configuration.plotType.meanValues = false;
      
      // Set the selected option to true
      this.configuration.plotType[selectedPlotType] = true;
    },

    refreshReports(){
      this.$store.dispatch('reporting/loadReportsFromServer', this.selectedOrg)
    },

    toggleAll(value) {
      this.allSelected = value;
    },

    resetForm() {
      const lastYear = new Date().getFullYear() - 1;
      const januaryLastYear = `${lastYear}-01`; // January of last year
      const decemberLastYear = `${lastYear}-12`; // December of last year
      this.configuration = {
        ...this.configuration,
        reportPresetSelection: 'fullReport', // Reset the preset to "fullReport"
        reportType: {
          thermalComfort: true,
          indoorAirQuality: true,
          presence: false,
          visualComfort: false,
          acousticComfort: false
        },
        plotType: {
          thresholds: true,
          meanValues: false
        },
        reportPeriod: {
          startDate: januaryLastYear, // Reset to initial state or a default value
          endDate: decemberLastYear
        },
        hourlyAnalysis: {
          twentyFourHour: false,
          openHours: false
        },
        floorPlans: [],
        buildingId: this.selectedBuilding.id, // Reset to initial state or a default value
        callbackUrl: '' 
      };
      if (this.configuration.reportOptions) {
        this.configuration.reportOptions.forEach(option => {
          option.selected = true; // Assuming full report means all options are selected
        });
      }
    }
  },

  watch: {
    buildingDetails(newValue, oldValue) {
      if (newValue && oldValue && newValue.id !== oldValue.id) {
        // If the buildingDetails object has changed, clear the selected floorplans
        this.configuration.selectedFloorplans = [];
      }
    }, 
    'configuration.reportPresetSelection': function(newPreset) {
      this.handleConfigurationPresets(newPreset);
    }
  },

  
};

</script>

<style scoped>
.button-wrapper{
  display: flex;
}
.button-with-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin: 1rem;
  border-radius: 15px;
}

.button-with-image img {
  max-width: 100%;
  max-height: 100px; 
  margin-top: 0.5rem;
  border-radius: 5px;
}

.report-date-picker-wrapper{
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-between;
}

.form-section-wrapper{
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-content: center;
}

.report-options-wrapper {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border-color: #56a9b2;
  border-style: solid;
  border-width: 2px;
  flex-wrap: wrap;
  width: 75%;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.report-options-wrapper > *{
  width: 50%;
  margin: 0.2rem 0;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.radio-button{
  margin: 0;
  margin-bottom: 0.5rem;
}


</style>
