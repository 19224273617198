<template>
  <div class="gray-bg min-vh-100">
  <div class="middle-box text-center animated fadeInDown">
    <div class="mb-4">
      <img :src="require('@/assets/custom/img/CLIMIFY.svg')" width="200px"/>
    </div>
    <h1 v-if="errorPage.bigTitle">{{ errorPage.bigTitle }}</h1>
    <h3 class="font-bold">{{ errorPage.title }}</h3>

    <div class="error-desc">
      {{ errorPage.description }}<br/>
    </div>
  </div>
  </div>
</template>

<script>
import {mapState} from "vuex"

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      errorPage: state => state.errorPage,
    }),

  }
}
</script>

<style scoped>
</style>


