<template>
  <div class="container-fluid pr-5 pt-2 _text-center" v-if="contextOrg">
    <div class="row equal-height" v-if="apps">
      <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="slideInLeft animated" leave-active-class="slideOutLeft animated">
        <div class="col-12">

          <div class="row">
            <div class="col-12">
              <button class="filter-buttons" :class="{'selected-filter': currentFilter === 'all'}" @click="currentFilter = 'all'">{{ $t('apps.allApps') }}</button>
              <button class="filter-buttons" :class="{'selected-filter': currentFilter === 'pinned'}" @click="currentFilter = 'pinned'">{{ $t('apps.section.pinnedApps') }}</button>
              <div v-for="(appGroup, name) in apps" :key="name+'-filter'" class="filter-buttons" :class="{'selected-filter': currentFilter === name}" @click="currentFilter = name">
                {{ $t('apps.section.' + name) }}
              </div>
            </div>
          </div>

          <template v-if="pinnedApps.length > 0 && (currentFilter === 'all' || currentFilter === 'pinned' )">
            <h3 class="appGroupTitle">{{ $t('apps.section.pinnedApps') }}</h3>
            <div class="row pt-2 pb-2 pl _justify-content-center">
                <div v-for="(app, index) in pinnedAppsArray" :key="index">
                  <router-link :to="app.route" :title="app.name" v-if="app.show">
                    <div :class="'widget d-flex align-items-center white'">
                      <div class="icon-container">
                        <img :src=app.icon style="max-width: 100%; max-height: 100%;"/>
                      </div>
                      <div class="app-container text-container ml-3">
                        <i class="fa fa-lg fa-star align-self-end" @click.prevent="unpinApp(app.key)"></i>
                        <h3 style="margin-bottom: 0.5em;">{{ app.name }}</h3>
                        <p style="margin: 0; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis;">{{ app.description }}</p>
                      </div>
                    </div>
                  </router-link>
                </div>
            </div>
          </template>

          <div v-for="(appGroup, name, index) in filteredApps" :key="index">
            <template v-if="apps[name].length > 0 && apps[name].filter(app => app.show).length > 0">
              <h3 class="appGroupTitle">{{ $t('apps.section.' + name) }}</h3>
              <div class="row pt-2 pb-2 pl _justify-content-center">
                <div v-for="(app, index) in appGroup" :key="index">
                  <router-link :to="app.route" :title="app.name" v-if="app.show" >
                    <div :class="'widget d-flex align-items-center white'">
                      <div class="icon-container">
                        <img :src=app.icon style="max-width: 100%; max-height: 100%;"/>
                      </div>
                      <div class="app-container text-container ml-3">
                        <i class="align-self-end" @click.prevent="pinApp(app.key)" :class="(isAppPinned(app.key)) ? 'visible fa fa-lg fa-star' : 'visible fa-lg fa-regular fa-star'" v-if="true"></i>
                        <h3 style="margin-bottom: 0.5em;">{{ app.name }}</h3>
                        <p style="margin: 0; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis;">{{ app.description }}</p>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </template>
          </div>

        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"

export default {
  data() {
    return {
      error: null,
      info: null,
      currentFilter: 'all'
    }
  },
  created() {
    console.log("Apps created()")
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      contextOrg: state => state.contextOrg
    }),
    filteredApps() {
      if (this.currentFilter === 'all') {
        return this.apps;
      } else if (this.currentFilter === 'pinned') {
        return null;
      } else {
        return { [this.currentFilter]: this.apps[this.currentFilter] };
      }
    },
    pinnedApps() {
      let pinnedApps = this.$store.state.userInfo.settings.pinnedApps;
      return (pinnedApps) ? pinnedApps : []
    },
    pinnedAppsArray() {
      let result = []
      for (const [key, appGroup] of Object.entries(this.apps)) {
        for (const app of appGroup) {
          if (this.pinnedApps.includes(app.key)){
            result.push(app);
          }
        }
      }
      return result;
    },
    apps() {
      return {
        measurements: [
          { key: "live", name: this.$t('apps.widget.indoorClimate'), description: this.$t('apps.widget.indoorClimateDescription'), color: "white", icon: require('@/assets/custom/icons/live.svg'), route: '/indoor-climate', show: true },
          { key: "track", name: this.$t('apps.widget.graphLocation'), description: this.$t('apps.widget.graphLocationDescription'), color: "white", icon: require('@/assets/custom/icons/track.svg'), route: '/graphs/location', show: true },
          { key: "compare", name: this.$t('apps.widget.graphLocationComparison'), description: this.$t('apps.widget.graphLocationComparisonDescription'), color: "white", icon: require('@/assets/custom/icons/compare.svg'), route: '/graphs/location-comparison', show: true },
          { key: "meters", name: this.$t('apps.widget.graphMeteringPoints'), description: this.$t('apps.widget.graphMeteringPointsDescription'), color: "white", icon: require('@/assets/custom/icons/energy.svg'), route: '/graphs/metering-points', show: (this.isContextOrgAdmin || this.isBuildingAdminInContextOrg) }
        ],
        advancedAnalytics: [
          { key: "statistics", name: this.$t('apps.widget.statisticsOnFloorPlan'), description: this.$t('apps.widget.statisticsOnFloorPlanDescription') ,color: "white", icon: require('@/assets/custom/icons/slicer.svg'), route: '/analytics/statistics', show: true },
          { key: "timeHeatmap", name: this.$t('apps.widget.timeHeatmaps'), description: this.$t('apps.widget.timeHeatmapsDescription'), color: "white", icon: require('@/assets/custom/icons/time_heatmaps.svg'), route: '/analytics/hourly-heatmaps', show: true },
          { key: "spaceHeatmap", name: this.$t('apps.widget.spaceHeatmaps'), description: this.$t('apps.widget.spaceHeatmapsDescription'), color: "white", icon: require('@/assets/custom/icons/space_heatmaps.svg'), route: '/analytics/heatmaps', show: true },
          { key: "centreActions", name: this.$t('apps.widget.messageCentreActions'), description: this.$t('apps.widget.messageCentreActionsDescription'), color: "white", icon: require('@/assets/custom/icons/alerts.svg'), route: { path: '/message-centre', query: { activeTab: 0 }}, show: this.hasRole('ADMIN') },
          { key: "centreOpportunities", name: this.$t('apps.widget.messageCentreOpportunities'), description: this.$t('apps.widget.messageCentreOpportunitiesDescription'), color: "white", icon: require('@/assets/custom/icons/opportunities.svg'), route: { path: '/message-centre', query: { activeTab: 1 }}, show: this.hasRole('ADMIN') },
          { key: "reports", name: this.$t('apps.widget.reports'), description: this.$t('apps.widget.reportsDescription'), color: "white", icon: require('@/assets/custom/icons/reports.svg'), route: '/reporting', show: (this.isContextOrgAdmin || this.isBuildingAdminInContextOrg) }
        ],
        occupantsFeedback: [
          { key: "feedbacl", name: this.$t('apps.widget.feedbackResponse'), description: this.$t('apps.widget.feedbackResponseDescription'), color: "white", icon: require('@/assets/custom/icons/feedback.svg'), route: { path: '/feedback'}, show: (this.isContextOrgAdmin || this.isBuildingAdminInContextOrg) },
          { key: "forms", name: this.$t('apps.widget.feedbackForm'), description: this.$t('apps.widget.feedbackFormDescription'),color: "white", icon: require('@/assets/custom/icons/forms.svg'), route: { path: '/forms'}, show: (this.isContextOrgAdmin || this.isBuildingAdminInContextOrg)}
        ],
        control: [
          { key: "scheduler", name: this.$t('apps.widget.schedulers'), description: this.$t('apps.widget.schedulersDescription'), color: "white", icon: require('@/assets/custom/icons/schedules.svg'), route: '/schedulers', show: (this.isContextOrgAdmin || this.isBuildingAdminInContextOrg) },
        ],
        management: [
          { key: "buildings", name: this.$t('apps.widget.buildings'), description: this.$t('apps.widget.buildingsDescription'), color: "white", icon: require('@/assets/custom/icons/buildings.svg'), route: '/buildings', show: (this.isContextOrgAdmin || this.isBuildingAdminInContextOrg) },
          { key: "devices", name: this.$t('apps.widget.devices'), description: this.$t('apps.widget.devicesDescription'), color: "white", icon: require('@/assets/custom/icons/devices.svg'), route: '/devices', show: (this.isContextOrgAdmin || this.isBuildingAdminInContextOrg) },
          { key: "profiles", name: this.$t('apps.widget.profiles'), description: this.$t('apps.widget.profilesDescription'), color: "white", icon: require('@/assets/custom/icons/standards.svg'), route: '/profiles', show: (this.isContextOrgAdmin || this.isBuildingAdminInContextOrg) },
          { key: "integrations", name: this.$t('apps.widget.integrations'), description: this.$t('apps.widget.integrationsDescription'), color: "white", icon: require('@/assets/custom/icons/integrations.svg'), route: '/integrations', show: (this.isContextOrgAdmin || this.isBuildingAdminInContextOrg) },
          { key: "share", name: this.$t('apps.widget.shareData'), description: this.$t('apps.widget.shareDataDescription'), color: "white", icon: require('@/assets/custom/icons/publish.svg'), route: '/share-data', show: (this.isContextOrgAdmin || this.isBuildingAdminInContextOrg) }
        ]
      }
    }
  },
  methods: {
    pinApp(appKey) {
      let pinnedAppsArray = (this.pinnedApps) ? [...this.pinnedApps] : []
      if(pinnedAppsArray.includes(appKey)) {
        this.unpinApp(appKey)
        return
      }
      pinnedAppsArray.push(appKey)
      this.$store.dispatch('updateUserSettings', {'pinnedApps': pinnedAppsArray, send: true}, { root: true })
    },
    unpinApp(appKey) {
      let pinnedAppsArray = this.pinnedApps.filter((app) => app !== appKey)
      this.$store.dispatch('updateUserSettings', {'pinnedApps': pinnedAppsArray, send: true}, { root: true })
    },
    isAppPinned(appKey){
      return this.pinnedApps.includes(appKey);
    }
  },
  watch: {
    '$route' (to, from) { // watch it
      console.log('URL changed: ', to, ' | was: ', from, ' | query ', this.$route.query)
      this.$store.dispatch('apps/loadLocationFromRouteQueryParam')
    },
    pinnedApps: function (newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.apps.pinnedApps = newVal;
      }
    },
  }
}
</script>
<style scoped>
.appGroupTitle {
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding-bottom: 10px;
}
a {
  display: inline-block;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
a:visited {
  color: #FFF;
}
a:hover {
  opacity: 0.9;
  transform: scale(1.1);
}
.widget {
  width: 320px; 
  height: 8em;
  padding: 10px;
  margin: 10px;
  display: flex;
  border: none;
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}
.white {
  background-color: #FFFFFF;
  color: #676a6c;
}
.filter-buttons {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 20px;
  transition: 0.3s;
}
.filter-buttons:hover {
  background-color: #61AEB7ff;
  color: white;
  border: 1px solid #61AEB7ff;
}
.selected-filter {
  background-color: #61AEB7ff;
  color: white;
  border-color: #61AEB7ff;
  border-radius: 20px;
}
.app-container {
  position: relative;
  flex-grow: 1; 
  display: flex; 
  flex-direction: column; 
  justify-content: center;
}
.fa-star {
  position: absolute;
  top: 0px;
}
.icon-container {
  flex-shrink: 0; 
  width: 60px; 
  height: 60px; 
  background-color: white; 
  display: flex; 
  justify-content: center; 
  align-items: center;
}
</style>
