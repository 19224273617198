<template>
  <div class="animated fadeInRight">

    <b-tabs ref="tabs" @input="tabSelected" v-if="organisation" v-model="activeTab" content-class="px-5 py-5 white-bg">

      <b-tab :title="$t('orgAdmin.organisation.details.info')">

        <div class="row">

          <div class="col-xxl-8 col-xl-7 col-lg-12">

            <org-view-create-edit
                :organisation="organisation"
                :can-edit="hasRole('ADMIN')"
                initial-type="view"
                :success-callback="editOrgSuccessCallback" />

          </div>

          <div class="col-xxl-4 col-xl-5 col-lg-12">
            <h4 class="d-inline-block">{{ $t('orgAdmin.organisation.details.geolocation') }}</h4>
            <geo-location :markers="buildingCoordinates"></geo-location>
          </div>

        </div>

        <h4 class="mt-5 mb-4">{{ $t('orgAdmin.organisation.details.buildings') }}</h4>

        <div class="row form-group m-b-sm" v-if="buildings && buildings.length != 0">
          <div class="col-lg-6">
            <div class="input-group">
                  <span class="input-group-prepend">
                    <b-button class="btn btn-sm btn-primary" :disabled="buildingList.filter == null || buildingList.filter == ''" @click="clearFilter"> {{
                        $t('common.actions.clear')
                      }}</b-button>
                  </span>
              <b-form-input type="text"
                     v-model="buildingList.filter"
                     debounce="300"
                     max="50"
                     :placeholder="$t('enterForSearch')"
                     class="form-control form-control-sm"/>
            </div>
          </div>
          <div class="col-lg-3 m-t-sm text-right">
            <row-count :size="buildingList.size" :page="buildingList.page" :total="buildingListFiltered.length"></row-count>
          </div>
          <div class="col-lg-3 text-right" v-if="buildingListFiltered.length && buildingListFiltered.length > buildingList.size">
            <b-pagination size="sm" class="m-t-xs" align="right"
                          :total-rows="buildingListFiltered.length"
                          v-model="buildingList.page"
                          :per-page="buildingList.size"></b-pagination>
          </div>
        </div>

        <div class="row" v-if="buildingListFiltered.length != 0">
          <div class="col-xxxl-3 col-xxl-4 col-lg-6 col-sm-12"
               v-for="(item) in buildingListFiltered.slice((buildingList.page - 1) * buildingList.size, (buildingList.page * buildingList.size))"
               :key="item.id">
            <building-card :item="item"></building-card>
          </div>
        </div>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

        <div class="row" v-if="hasOneOfRoles('ADMIN', 'ORGANISATION_MANAGER')">
          <div class="col-lg-12 buttons">
            <button class="btn btn-sm btn-primary pull-left mr-1" @click="addNew">
              <i class="fa fa-plus"></i>&nbsp;{{ $t('orgAdmin.building.actions.new') }}
            </button>
          </div>
        </div>

      </b-tab>

      <b-tab :title="$t('orgAdmin.organisation.details.userResourceRoles')" @click="$store.dispatch('userManagement/fetchOrganisationUsers', $route.params.id)" lazy>
        <users-resources-list :can-add="hasRole('ADMIN') || hasResourceRole('ORGANISATION_MANAGER', $route.params.id)"  route-prefix="/admin"/>
      </b-tab>

      <b-tab :title="$t('orgAdmin.registerUserRequests.title')" @click="$store.dispatch('userManagement/fetchOrganisationUserRegistrationRequests', $route.params.id)" lazy>
        <register-user-requests-list route-prefix="/admin"/>
      </b-tab>

      <b-tab :title="$t('orgAdmin.organisation.details.devices')" @click="$store.dispatch('devices/fetchOrganisationDevices', $route.params.id)" lazy>
        <device-list :items="devices" route-prefix="/admin"/>
      </b-tab>

      <b-tab :title="$t('orgAdmin.organisation.details.beacons')" @click="$store.dispatch('devices/fetchOrganisationBeacons', $route.params.id)" lazy>
        <beacon-list :items="beacons" route-prefix="/admin"/>
      </b-tab>

      <b-tab :title="$t('orgAdmin.organisation.details.metering_points')" @click="$store.dispatch('devices/fetchOrganisationMeteringPoints', $route.params.id)" lazy>
        <device-list :items="meters" route-prefix="/admin"/>
      </b-tab>

      <b-tab :title="$t('orgAdmin.integration.title')" v-if="integrations && hasRole('ADMIN') || hasResourceRole('ORGANISATION_MANAGER', $route.params.id)"
             @click="$store.dispatch('integrations/fetchIntegrations', $route.params.id)" lazy>
        <integration-list :integrations="integrations" route-prefix="/admin"/>
      </b-tab>

      <b-tab :title="$t('orgAdmin.form.title')" @click="$store.dispatch('feedback/fetchOrganisationForms', $route.params.id)" lazy>
        <form-list :items="forms" route-prefix="/admin"/>
      </b-tab>

      <b-tab :title="$t('orgAdmin.organisation.details.locationProfiles')"
             @click="$store.dispatch('profiles/fetchOrganisationLocationProfiles', organisation.id);$store.dispatch('profiles/fetchOrganisationLocationsWithSelectedProfile', organisation.id)"
             lazy>
        <div class="row">
          <div class="col-xxl-6 col-xl-12">
            <location-profile-list :profiles="locationProfiles" :canAdd="true" route-prefix="/admin"/>
            <h3 class="mt-5 mb-4">{{ $t('orgAdmin.locationsWithLocationProfiles.title') }}</h3>
            <locations-with-profile-list :locations-with-selected-profile="locationsWithSelectedLocationProfile" route-prefix="/admin" />
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('orgAdmin.organisation.details.schedulerProfiles')"
             @click="$store.dispatch('schedulers/fetchOrganisationSchedulerProfiles', organisation.id);$store.dispatch('schedulers/fetchOrganisationLocationsWithSelectedProfile', organisation.id)"
             lazy>
        <div class="row">
          <div class="col-xxl-6 col-xl-12">
            <scheduler-profile-list :profiles="schedulerProfiles" :canAdd="true" route-prefix="/admin"/>
            <h3 class="mt-5 mb-4">{{ $t('orgAdmin.locationsWithSchedulerProfiles.title') }}</h3>
            <locations-with-scheduler-profile-list :locations-with-selected-profile="locationsWithSelectedSchedulerProfile" route-prefix="/admin"/>
          </div>
        </div>
      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
import {mapState} from "vuex"
import eventBus from "@/eventBus"
import RowCount from '@/components/common/RowCount'
import BuildingCard from '@/components/views/admin/organisations/BuildingCard'
import GeoLocation from '@/components/common/GeoLocationLeaflet'
import UsersResourcesList from '@/components/views/userManagement/UsersResourcesList.vue'
import RegisterUserRequestsList from '@/components/views/userManagement/RegisterUserRequestsList.vue'
import DeviceList from "@/components/views/devices/DeviceList";
import BeaconList from "@/components/views/devices/BeaconList";
import OrgViewCreateEdit from '@/components/views/admin/organisations/OrgViewCreateEdit'
import LocationProfileList from '@/components/views/profiles/LocationProfileList'
import LocationsWithProfileList from '@/components/views/profiles/LocationsWithProfileList'
import SchedulerProfileList from "@/components/views/schedulers/SchedulerProfileList"
import LocationsWithSchedulerProfileList from '@/components/views/schedulers/LocationsWithSchedulerProfileList'
import IntegrationList from "@/components/views/integrations/IntegrationList"
import FormList from "@/components/views/feedback/FormList.vue";
import _ from 'lodash'

export default {
  components: {
    OrgViewCreateEdit,
    BuildingCard,
    GeoLocation,
    RowCount,
    UsersResourcesList,
    RegisterUserRequestsList,
    DeviceList,
    BeaconList,
    IntegrationList,
    FormList,
    LocationProfileList,
    SchedulerProfileList,
    LocationsWithProfileList,
    LocationsWithSchedulerProfileList
  },
  data() {
    return {
      error: null,
      activeTab: this.$store.state.orgAdmin.organisationDetails.activeTab,
      orgEdit: false,
      buildingList: {
        filter: null,
        page: 1,
        size: 10
      },
      addNewBuildingOpened: false,
      addNewBuildingForm: {
        name: '',
        coordinates: ''
      }
    }
  },
  created() {
    this.$store.dispatch('organisation/fetchOrganisationDetails', this.$route.params.id)
    this.$nextTick(() => {
      if (this.activeTab != 0) {
        this.$refs.tabs.$el.querySelectorAll('.nav-link.active')[0].click()
      }
    })
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      organisation: state => state.organisation.organisationDetails.entity,
      buildings: state => state.organisation.organisationDetails.entity.buildings,
      devices: state => state.devices.devices,
      beacons: state => state.devices.beacons,
      meters: state => state.devices.meters,
      integrations: state => state.integrations.integrations,
      locationProfiles: state => state.profiles.locationProfiles,
      locationsWithSelectedLocationProfile: state => state.profiles.locationsWithSelectedProfile,
      schedulerProfiles: state => state.schedulers.schedulerProfiles,
      locationsWithSelectedSchedulerProfile: state => state.schedulers.locationsWithSelectedSchedulerProfile,
      forms: state => state.feedback.forms
    }),
    buildingCoordinates() {
      if (this.buildings && this.buildings.length > 0) {
        let markers = []
        this.buildings.forEach(building => {
          if(building.coordinates && building.coordinates.latitude && building.coordinates.longitude) {
            let objForList = {}
            objForList.parentObject = building
            objForList.lat = building.coordinates.latitude
            objForList.lng = building.coordinates.longitude
            objForList.label = building.name
            objForList.selectCallback = function () {
              console.log(`selectCallback id ${building.id}`)
              this.$router.push({
                path: `/admin/buildings/${building.id}`,
              })
            }.bind(this)
            markers.push(objForList)
          }
        })
        if(markers.length > 0){
          return markers
        }
      }
      return null
    },
    buildingListFiltered() {
      console.log('buildingListFiltered this.buildingList.filter ' + this.buildingList.filter)
      if (this.buildings && this.buildingList.filter != null && this.buildingList.filter != '') {
        return _.sortBy(this.buildings.filter(item =>
            (item.name && item.name.toLowerCase().indexOf(this.buildingList.filter.toLowerCase()) != -1)
            || (item.city ? item.city.toLowerCase().indexOf(this.allList.filter.toLowerCase()) != -1 : false)
            || (item.address ? item.address.toLowerCase().indexOf(this.allList.filter.toLowerCase()) != -1 : false)
            || (item.country ? item.country.toLowerCase().indexOf(this.allList.filter.toLowerCase()) != -1 : false)
        ), [function(o) { return o.name; }])
      } else {
        return this.buildings ? _.sortBy(this.buildings, [function(o) { return o.name; }]) : []
      }
    }
  },
  methods: {
    tabSelected() {
      console.log('tabSelected ' + this.activeTab)
      this.$store.commit('orgAdmin/SAVE_ORGANISATION_DETAILS_ACTIVE_TAB', this.activeTab)
      if(this.activeTab == 0){
        eventBus.$emit("refresh-geolocation")
      }
    },
    clearFilter() {
      this.buildingList.filter = null
    },
    editOrgSuccessCallback(){
      this.$store.dispatch('organisation/fetchOrganisationDetails', this.$route.params.id)
    },
    addNew() {
      this.$router.push({ path: '/admin/buildings/new'})
    },
    addNewBuildingSuccessCallback(){
      this.addNewBuildingOpened = false
      this.$store.dispatch('organisation/fetchOrganisationDetails', this.$route.params.id)
    },
    addNewBuildingCancelCallback(){
      this.addNewBuildingOpened = false
    },
    refreshRegisterUserRequestCallback(){
      this.$store.dispatch('organisation/fetchOrganisationDetails', this.$route.params.id)
    }
  }
}
</script>

<style scoped>

</style>