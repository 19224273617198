<template>
  <div class="container-fluid">
    <div class="alert alert-danger alert-dismissible" v-show="error">
      {{ error }}
    </div>

    <div class="row equal-height">
      <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="slideInLeft animated" leave-active-class="slideOutLeft animated">
        <div class="col-xxl-8 col-xl-7 col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ $t('indoorClimate.selectedMap.title') }}<template v-if="selectedMap"> - {{ selectedMap.name }} </template></h5>
              <span v-if="selectedMap" class="_label _label-primary float-right">{{ $t('indoorClimate.dateTimeInfo', { 0: selectedBuilding.timeZone }) }} <strong>{{ $store.getters["indoorClimate/mapConfigDateTimeFormatted"] }}</strong></span>
            </div>
            <div class="ibox-content">
              <div class="pl-2 pr-2">
                <map-view :map="selectedMap" />
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="col-xxl-4 col-xl-5 col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5><template v-if="selectedMap">{{ $t('indoorClimate.mapConfig.titleMapSelected') }}</template>
              <template v-else>{{ $t('indoorClimate.mapConfig.titleMapNotSelected') }}</template>
            </h5>
          </div>
          <div class="ibox-content">

            <resource-selector :resourceSelectedCallback="mapSelectedCallback" selectDepth="map" :preselect-resource="selectedMap ? { map: selectedMap} : null"/>

            <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
              <map-config v-if="selectedMap"/>
            </transition>

          </div>
        </div>
      </div>

    </div>

    <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated" appear>
      <div class="row equal-height" v-if="selectedLocation && graphData && graphData.size > 0">
        <div class="col-xxl-9 col-lg-8">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ $t('indoorClimate.graph.title') }}</h5>
            </div>
            <div class="ibox-content">
              <graph-view />
            </div>
          </div>
        </div>

        <div class="col-xxl-3 col-lg-4">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ $t('indoorClimate.graphConfig.title') }}</h5>
            </div>
            <div class="ibox-content">
              <graph-config />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import {mapState} from "vuex"
import ResourceSelector from "@/components/common/ResourceSelector"
import MapView from "@/components/views/indoorClimate/MapView"
import MapConfig from "@/components/views/indoorClimate/MapConfig"
import GraphView from "@/components/views/indoorClimate/GraphView"
import GraphConfig from "@/components/views/indoorClimate/GraphConfig"

export default {
  components:{
    ResourceSelector,
    MapView,
    MapConfig,
    GraphView,
    GraphConfig
  },
  data() {
    return {
      error: null,
      info: null
    }
  },
  created() {
    console.log("IndoorClimate created() selectedMap: " + this.selectedMap)
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedMap: state => state.indoorClimate.selectedMap,
      selectedBuilding: state => state.indoorClimate.selectedMap.building,
      selectedLocation: state => state.indoorClimate.selectedLocation,
      mapConfig: state => state.indoorClimate.mapConfig,
      graphConfig: state => state.indoorClimate.graphConfig,
      graphData: state => state.indoorClimate.graphData
    }),
  },
  methods: {
    mapSelectedCallback(payload){
      console.log("selectedMap " + payload.resource.id + " - " + payload.resource.name)
      this.$store.dispatch('indoorClimate/loadMapFromServer', payload.resource.id)
      // clear route location query part if new map is selected
      if(this.$route.query && Object.keys(this.$route.query).length > 0) {
        this.$router.replace({...this.$router.currentRoute, query: {}})
      }
    }
  },
  watch: {
    '$route' (to, from) { // watch it
      console.log('URL changed: ', to, ' | was: ', from, ' | query ', this.$route.query)
      this.$store.dispatch('indoorClimate/loadLocationFromRouteQueryParam')
    }
  }
}
</script>
