<template>
  <div class="animated fadeInRight">

    <b-tabs @input="tabSelected" v-model="activeTab" content-class="px-5 py-5 white-bg">

      <b-tab :title="$t('orgAdmin.organisations.title')">

        <div class="form-group row m-b-sm m-t-md">
          <div class="col-lg-6">
            <div class="input-group">
              <span class="input-group-prepend">
                <b-button class="btn btn-sm btn-primary" :disabled="allList.filter == null || allList.filter.length == 0" @click="clearFilter"> {{ $t('common.actions.clear') }}</b-button>
              </span>
              <b-form-input type="text"
                     v-model="allList.filter"
                     debounce="300"
                     maxlength="50"
                     :placeholder="$t('enterForSearch')"
                     class="form-control form-control-sm"/>
            </div>
          </div>
          <div class="col-lg-3 m-t-sm text-right">
            <row-count :size="allList.size" :page="allList.page" :total="allListFiltered.length"></row-count>
          </div>
          <div class="col-lg-3 text-right" v-if="allListFiltered.length && allListFiltered.length > allList.size">
            <b-pagination v-if="allListFiltered.length" size="sm" class="m-t-xs" align="right"
                          :total-rows="allListFiltered.length"
                          v-model="allList.page"
                          :per-page="allList.size"></b-pagination>
          </div>
        </div>

        <div class="row" v-if="allListFiltered.length != 0" >
          <div class="col-xxxl-2 col-xxl-3 col-lg-4 col-sm-6"
               v-for="(item) in allListFiltered.slice((allList.page - 1) * allList.size, (allList.page * allList.size))"
               v-bind:key="item.type + item.id">
            <org-card v-if="item.type == 'ORGANISATION'" :item="item"></org-card>
            <building-card v-if="item.type == 'BUILDING'" :item="item"></building-card>
            <location-card v-if="item.type == 'LOCATION'" :item="item"></location-card>
          </div>
        </div>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

        <b-button href="#" v-if="hasRole('ADMIN')" class="btn btn-primary btn-sm mt-3" :to="{ path: '/admin/organisation/new' }"><i class="fa fa-plus"></i> {{ $t('orgAdmin.organisation.actions.new') }}</b-button>

      </b-tab>

      <b-tab :title="$t('orgAdmin.registerOrganisationRequests.title')" v-if="hasRole('ADMIN')" lazy>
        <register-organisation-requests-list route-prefix="/admin"/>
      </b-tab>

      <b-tab :title="$t('orgAdmin.registerUserRequests.title')"
             v-if="hasOneOfRoles('ADMIN')"
             @click="$store.dispatch('userManagement/fetchUserRegistrationRequestsForSuperAdmin', $route.params.id)"
             lazy>
        <register-user-requests-list route-prefix="/admin" />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import {mapState} from "vuex"
import _ from 'lodash'
import RowCount from '@/components/common/RowCount'
import OrgCard from '@/components/views/admin/organisations/OrgCard'
import BuildingCard from '@/components/views/admin/organisations/BuildingCard'
import LocationCard from '@/components/views/admin/organisations/LocationCard'
import RegisterOrganisationRequestsList from '@/components/views/organisation/RegisterOrganisationRequestsList'
import RegisterUserRequestsList from '@/components/views/userManagement/RegisterUserRequestsList.vue'

export default {
  components: {
    RowCount,
    OrgCard,
    BuildingCard,
    LocationCard,
    RegisterOrganisationRequestsList,
    RegisterUserRequestsList
  },
  data() {
    return {
      error: null,
      info: null,
      activeTab: this.$store.state.orgAdmin.orgAdminActiveTab,
      allList: {
        filter: null,
        page: 1,
        size: 10
      }
    }
  },
  created() {
    this.$store.dispatch('orgAdmin/fetchUserResources')
  },
  methods: {
    tabSelected() {
      this.$store.commit('orgAdmin/SAVE_ORG_ADMIN_ACTIVE_TAB', this.activeTab)
    },
    clearFilter() {
      this.allList.filter = null
    }
  },
  computed: {
    ...mapState({
      organisations: state => state.orgAdmin.organisations,
      buildings: state => state.orgAdmin.buildings,
      locations: state => state.orgAdmin.locations,
      allListItems: state => state.orgAdmin.organisations.concat(state.orgAdmin.buildings).concat(state.orgAdmin.locations)
    }),
    allListFiltered() {
      //console.log('allListFiltered this.allList.filter ' + this.allList.filter)
      if (this.allList.filter != null && this.allList.filter != '') {
        return _.sortBy(this.allListItems.filter(item =>
            item.name.toLowerCase().indexOf(this.allList.filter.toLowerCase()) != -1
            || (item.city ? item.city.toLowerCase().indexOf(this.allList.filter.toLowerCase()) != -1 : false)
            || (item.address ? item.address.toLowerCase().indexOf(this.allList.filter.toLowerCase()) != -1 : false)
            || (item.country ? item.country.toLowerCase().indexOf(this.allList.filter.toLowerCase()) != -1 : false)
        ), [function(o) { return o.name; }])
      } else {
        return _.sortBy(this.allListItems, [function(o) { return o.name; }])
      }
    }
  }
}
</script>

