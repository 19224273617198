<template>
  <div>
    <div class="form-group row m-b-md">
      <div class="col-lg-6">
        <div class="input-group">
          <span class="input-group-prepend">
            <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length == 0" @click="clearFilter"> {{
                $t('common.actions.clear')
              }}</b-button>
          </span>
          <b-form-input type="text"
                        v-model="table.filter"
                        debounce="300"
                        max="50"
                        :placeholder="$t('enterForSearch')"
                        class="form-control form-control-sm"/>
        </div>
      </div>
      <div class="col-lg-3 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="items.length"></row-count>
      </div>
      <div class="col-lg-3 text-right" v-if="items.length && items.length > table.size">
        <b-pagination v-if="items.length" size="sm" class="m-t-xs" align="right"
                      :total-rows="items.length"
                      v-model="table.page"
                      :per-page="table.size"></b-pagination>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-table responsive _hover _striped
                 v-if="items.length != 0"
                 _class="text-nowrap"
                 thead-class="text-nowrap"
                 :filter="table.filter"
                 :fields="table.fields"
                 :current-page="table.page"
                 :per-page="table.size"
                 :items="items"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc">
          <template #cell(name)="data">
            <b-link :to="{ path: routePrefix + '/forms/' + data.item.id }">{{ data.value !== '' ? data.value : $t('common.notAvailable')}}</b-link>
          </template>
          <template #cell(status)="data">
            <span class="label" :class="[data.item.status == 'INACTIVE' ? 'label-warning' : 'label-primary']">{{ data.value}}</span>
          </template>
        </b-table>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

        <div class="row">
          <div class="col-lg-12 buttons">
            <router-link :to="routePrefix + '/forms/new'">
              <button class="btn btn-sm btn-primary pull-left mr-1">
                <i class="fa fa-plus"></i>&nbsp;{{ $t('orgAdmin.form.actions.new') }}
              </button>
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import RowCount from '@/components/common/RowCount';

export default {
  components: {
    RowCount
  },
  props: {
    items: {
      type: Array
    },
    routePrefix: {
      default: ''
    }
  },
  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: '',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50},
          {'value': 100}
        ],
        fields: [
          {
            key: 'name',
            label: this.$i18n.t('orgAdmin.form.attributes.name'),
            sortable: true,
            tdClass: 'vertical-align-middle'
          },
          {
            key: 'status',
            label: this.$i18n.t('orgAdmin.form.attributes.status'),
            sortable: true,
            tdClass: 'vertical-align-middle'
          }
        ]
      }
    }
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    }
  }
}
</script>
<style scoped>
.table > tbody > tr > td.vertical-align-middle {
  vertical-align: middle !important;
}
</style>