import Vue from "vue";
import * as commons from '@/commons'

// default state
const getDefaultState = () => {
    return {
        activeTab: 0,
        userResourceRoles: [],
        userDetails: {
            entity: {}
        },
        registerUserRequests: [],
        registerUserRequestDetails: {
            entity: {}
        }
    }

}

// initial state
const state = getDefaultState()

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('userManagement/appInit')
    },

    ['SAVE_ACTIVE_TAB']: (state, data) => {
        state.activeTab = data
    },


    fetchUserRegistrationRequestsForSuperAdmin({commit, state, dispatch, rootState}) {

        console.log('userManagement/fetchUserRegistrationRequestsForSuperAdmin')

        //load user registration request for all orgs
        Vue.axios.get('/admin/register-user-requests').then(response => {
            commit('SAVE_REGISTER_USER_REQUESTS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchOrganisationUsers({commit, state, dispatch, rootState}, id) {

        console.log('userManagement/fetchOrganisationUsers')

        Vue.axios.get('/admin/organisations/' + id + '/user-resource-roles').then(response => {
            commit('SAVE_USERS_RESOURCE_ROLES', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchOrganisationUserRegistrationRequests({commit, state, dispatch, rootState}, id) {

        console.log('userManagement/fetchOrganisationUserRegistrationRequests')

        Vue.axios.get('/admin/organisations/' + id + '/register-user-requests').then(response => {
            commit('SAVE_REGISTER_USER_REQUESTS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchBuildingUsers({commit, state, dispatch, rootState}, id) {

        console.log('userManagement/fetchBuildingUsers')

        // load user resource roles
        Vue.axios.get('/admin/buildings/' + id + '/user-resource-roles').then(response => {
            commit('SAVE_USERS_RESOURCE_ROLES', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchMapUsers({commit, state, dispatch, rootState}, id) {

        console.log('userManagement/fetchBuildingUsers')

        // load user resource roles
        Vue.axios.get('/admin/maps/' + id + '/user-resource-roles').then(response => {
            commit('SAVE_USERS_RESOURCE_ROLES', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchLocationUsers({commit, state, dispatch, rootState}, id) {

        console.log('userManagement/fetchBuildingUsers')

        // load user resource roles
        Vue.axios.get('/admin/location/' + id + '/user-resource-roles').then(response => {
            commit('SAVE_USERS_RESOURCE_ROLES', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchUserDetails({commit, state, dispatch, rootState}, id) {

        console.log('userManagement/fetchUserDetails')

        //load map
        Vue.axios.get('/admin/users/' + id).then(response => {
            commit('SAVE_USER_DETAILS_ENTITY', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchRegisterUserRequestDetails({commit, state, dispatch, rootState}, id) {

        console.log('userManagement/fetchRegisterUserRequestDetails')

        //load map
        Vue.axios.get('/admin/register-user-requests/' + id).then(response => {
            commit('SAVE_REGISTER_USER_REQUEST_DETAILS_ENTITY', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    }
}


// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['SAVE_ACTIVE_TAB']: (state, data) => {
        state.activeTab = data
    },

    ['SAVE_USERS_RESOURCE_ROLES']: (state, data) => {
        state.userResourceRoles = data
    },

    ['SAVE_REGISTER_USER_REQUESTS']: (state, data) => {
        state.registerUserRequests = data
    },

    ['SAVE_USER_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.userDetails, 'entity', data)
    },

    ['SAVE_REGISTER_USER_REQUEST_DETAILS_ENTITY']: (state, data) => {
        Vue.set(state.registerUserRequestDetails, 'entity', data)
    },

}

export default {
    namespaced: true, state, actions, mutations
}