<template>
  <div v-if="selectedMap">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1ab394"></loading>

    <div class="row text-center">
      <div class="col-12">
        <div class="text-center pt-4">
          <div class="row">
            <div class="col-12">
              <div>
                <month-picker
                  :default-month="new Date().getMonth() + 1" 
                  :default-year="new Date().getFullYear()"
                  :min-date="new Date(new Date().setFullYear(new Date().getFullYear() - 5))"
                  :max-date="new Date()"
                  @change="selectedMonthChanged"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="availableLocationsWithSensorValuesSorted.length > 0">
      <div class="row mt-3">
        <div class="col-12">
          <h4>{{ $t('analytics.graphConfig.locations') }}</h4>
          <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
            <b-form-group id="input-group-locations">
              <b-form-checkbox 
                id="select-all-checkbox"
                switch
                size="lg"
                v-model="selectAll"
                @change="toggleSelectAll">
                {{ $t('analytics.graphConfig.allLocations') }}
              </b-form-checkbox>
              <b-form-checkbox-group
                  switches
                  stacked
                  size="lg"
                  id="input-locations"
                  v-model="selectedLocations"
                  :options="availableLocationsWithSensorValuesSorted"
                  value-field="id"
                  text-field="name"
                  name="input-location"
                  @change="selectedLocationsChanged">
              </b-form-checkbox-group>
            </b-form-group>
          </transition>
        </div>
      </div>

      <div class="row mt-3" v-if="availableSensorAttributes && availableSensorAttributes.length > 0">
        <div class="col-12">
          <h4>{{ $t('analytics.graphConfig.showData') }}</h4>
            <b-form-group id="input-group-sensor-attributes">
              <b-form-checkbox-group
                  switches
                  stacked
                  size="lg"
                  id="input-sensor-attributes"
                  v-model="selectedSensorAttributes"
                  :options="availableSensorAttributesOptions"
                  name="input-sensor-attribute"
                  @change="selectedSensorAttributesChanged"
              ></b-form-checkbox-group>
            </b-form-group>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <h4>{{ $t('analytics.graphConfig.graphOptions.titleGraphOptions') }}</h4>
          <b-form-checkbox v-model="graphOptions.fullSize" name="fullSize" switch size="lg" @change="selectedOptionsChanged">
            {{  $t('analytics.graphConfig.graphOptions.fullSize') }}
          </b-form-checkbox>
          <b-form-checkbox v-model="graphOptions.thresholdLegend" name="thresholdLegend" switch size="lg" @change="selectedOptionsChanged">
            {{  $t('analytics.graphConfig.graphOptions.thresholdLegendCO2') }}
          </b-form-checkbox>
        
          <div v-if="graphOptions.thresholdLegend">
            <b-form-group label="Low Threshold (CO2)">
              <b-form-input type="number" v-model="localThresholds.lowCO2"></b-form-input>
            </b-form-group>
            <b-form-group label="High Threshold (CO2)">
              <b-form-input type="number" v-model="localThresholds.highCO2"></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-button variant="primary" @click="confirmAndLoadGraphs">
                {{  $t('analytics.graphConfig.graphOptions.confirmation') }}
              </b-button>
            </b-form-group>
          </div>

          
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import {mapState} from "vuex"
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import _ from "lodash"
import { MonthPicker } from 'vue-month-picker'
import Loading from 'vue-loading-overlay'
import i18n from "@/i18n";

export default {
  components: {
    MonthPicker,
    Loading
  },
  data() {
    return {
      locale: 'en',
      minMonth: new Date(new Date().setFullYear(new Date().getFullYear() - 5)),
      maxMonth: new Date(),
      info: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedLocations: [],
      selectedSensorAttributes: [],
      graphOptions: {
        fullSize: false,
        thresholdLegend: false,
        enableWeekends: false,
        customCO2Thresholds: {
          low: 1000,
          high: 2000,
        },
      },
      localThresholds: {
        lowCO2: 1000,
        highCO2: 2000,
      },
      selectAll: false,
      isLoading: false,
    }
  },
  created(){
    console.log('GraphConfig $route.name: ' + this.$route.name)
    this.selectedLocations = this.graphConfig.selectedLocations
    this.dateRange = this.graphConfig.dateRange
    this.graphOptions.fullSize = this.graphConfig.fullSize
    this.graphOptions.thresholdLegend = this.graphConfig.thresholdLegend,
    this.graphOptions.customCO2Thresholds = this.graphConfig.customCO2Thresholds,
    this.graphOptions.enableWeekends = this.graphConfig.enableWeekends
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedMap: state => state.hourlyHeatmaps.selectedMap,
      selectedBuilding: state => state.hourlyHeatmaps.selectedMap.building,
      availableLocations: state => state.hourlyHeatmaps.selectedMap.locations,
      availableSensorAttributes: state => state.hourlyHeatmaps.availableSensorAttributes,
      graphConfig: state => state.hourlyHeatmaps.graphConfig
    }),
    availableLocationsWithSensorValuesSorted(){
      // check if location has any sensor values
      console.log('availableLocationsWithSensorValuesSorted')
      if(this.availableLocations && this.availableLocations.length > 0){
        return _.sortBy(this.availableLocations.filter(location => { return location.sensorValues && location.sensorValues.length > 0 }), [function(o) { return o.name; }])
      }
      return []
    },
    availableSensorAttributesOptions() {
      const allowedAttributes = ['temperature', 'humidity', 'co2', 'motion'];

      if (this.availableSensorAttributes && this.availableSensorAttributes.length > 0) {
        return this.availableSensorAttributes
          .filter(item => allowedAttributes.includes(item))
          .map(item => {
            return { text: i18n.t('enums.sensorType.' + item + '.name'), value: item }
          })
      }
      return []
    },
  },
  watch: {
    selectedMap: function(newVal, oldVal) {
      console.log('selectedMap changed: ',  newVal ? "(" + newVal.id + ") " + newVal.name: null, ' | was: ', oldVal)
      if(newVal && newVal.id != oldVal.id){
        this.selectedLocations = []
        this.selectAll = false;
      }
    }
  },
  methods: {
    confirmAndLoadGraphs() {
      const updatedGraphOptions = {
        ...this.graphOptions,
        customCO2Thresholds: {
          low: this.localThresholds.lowCO2,
          high: this.localThresholds.highCO2,
        },
      };
      this.isLoading = true;

      setTimeout(() => {
        this.$store.dispatch('hourlyHeatmaps/graphConfigOptionsChanged', updatedGraphOptions)
          .then(() => {
            this.isLoading = false;
            this.selectAll = this.selectedLocations.length === this.availableLocationsWithSensorValuesSorted.length;
          })
          .catch(error => {
            console.error('Error during selectedLocationsChanged:', error);
            this.isLoading = false;
          });
      }, 10);
    },
    selectedLocationsChanged() {
      this.isLoading = true;
      console.log('selectedLocations changed: ' + this.selectedLocations)

      setTimeout(() => {
        this.$store.dispatch('hourlyHeatmaps/graphConfigSelectedLocationsChanged', this.selectedLocations)
          .then(() => {
            this.isLoading = false;
            this.selectAll = this.selectedLocations.length === this.availableLocationsWithSensorValuesSorted.length;
          })
          .catch(error => {
            console.error('Error during selectedLocationsChanged:', error);
            this.isLoading = false;
          });
      }, 10);
    },
    selectedOptionsChanged() {
      this.isLoading = true;
      console.log('graphOptions changed: ' + this.graphOptions)

      setTimeout(() => {
        this.$store.dispatch('hourlyHeatmaps/graphConfigOptionsChanged', this.graphOptions)
          .then(() => {
            this.isLoading = false;
          })
          .catch(error => {
            console.error('Error during selectedOptionsChanged:', error);
            this.isLoading = false;
          });
      }, 10);
    },
    selectedSensorAttributesChanged() {
      this.isLoading = true;
      console.log('selectedSensorAttributes changed: ', JSON.stringify(this.selectedSensorAttributes));

      setTimeout(() => {
        this.$store.dispatch('hourlyHeatmaps/graphConfigSensorAttributesChanged', this.selectedSensorAttributes)
          .then(() => {
            this.isLoading = false;
          })
          .catch(error => {
            console.error('Error during selectedSensorAttributesChanged:', error);
            this.isLoading = false;
          });
      }, 10);
    },
    selectedMonthChanged(month) {
      this.isLoading = true;

      setTimeout(() => {
        this.$store.dispatch('hourlyHeatmaps/graphConfigMonthSelected', month)
          .then(() => {
            this.isLoading = false;
          })
          .catch(error => {
            console.error('Error during selectedMonthChanged:', error);
            this.isLoading = false;
          });
      }, 20);
    },
    toggleSelectAll() {
      this.isLoading = true;

      setTimeout(() => {
        this.selectedLocations = this.selectAll
          ? this.availableLocationsWithSensorValuesSorted.map(loc => loc.id)
          : [];

        this.$store.dispatch('hourlyHeatmaps/graphConfigSelectedLocationsChanged', this.selectedLocations)
          .then(() => {
            this.isLoading = false;
          })
          .catch(error => {
            console.error('Error during toggleSelectAll:', error);
            this.isLoading = false;
          });
      }, 10);
    },
  }
}
</script>

<style>
.custom-switch.b-custom-control-lg .custom-control-label, .input-group-lg .custom-switch .custom-control-label {
  font-size: 1rem;
  padding-top: 3px;
  line-height: 1.5;
}
.month-picker__month.selected {
  background-color: #61AEB7;
}
.month-picker__container{
  width: 100%;
  box-shadow: none;
}
.month-picker__year {
  box-shadow: none;
  background-color: #ffffff;
}
.month-picker__year button {
  background-color: #61AEB7;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.month-picker__year button:hover {
  background-color: #61AEB7;
  color: white;
}
</style>