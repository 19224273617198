<template>
  <div>

    <div class="row equal-height">
      <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="slideInLeft animated" leave-active-class="slideOutLeft animated">
        <div class="col-xxl-9 col-xl-8 col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ $t('graphs.locationComparison.graphView.title') }}<template v-if="selectedMap"> - {{ selectedMap.name }} </template></h5>
              <span v-if="selectedMap" class="_label _label-primary float-right">{{ $t('graphs.locationComparison.dateTimeRangeInfo', { 0: selectedBuilding.timeZone }) }} <strong>{{ $store.getters["graphLocationComparison/graphConfigDateTimeRangeFormatted"] }}</strong></span>
            </div>
            <div class="ibox-content">
              <div class="pl-2 pr-2">
                <graph-view />
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="col-xxl-3 col-xl-4 col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5><template v-if="selectedMap">{{ $t('graphs.locationComparison.graphConfig.titleMapSelected') }}</template>
              <template v-else>{{ $t('graphs.locationComparison.graphConfig.titleMapNotSelected') }}</template>
            </h5>
          </div>
          <div class="ibox-content">

            <resource-selector :resourceSelectedCallback="mapSelectedCallback" selectDepth="map" :preselect-resource="selectedMap ? { map: selectedMap} : null" />

            <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
              <graph-config v-if="selectedMap"/>
            </transition>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>


<script>
import {mapState} from "vuex"
import ResourceSelector from "@/components/common/ResourceSelector"
import GraphView from "@/components/views/graphs/locationComparison/GraphView"
import GraphConfig from "@/components/views/graphs/locationComparison/GraphConfig"

export default {
  components:{
    ResourceSelector,
    GraphView,
    GraphConfig
  },
  data() {
    return {
      info: null
    }
  },
  created() {
    console.log("GraphLocationComparison created() selectedMap: " + this.selectedMap)
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedMap: state => state.graphLocationComparison.selectedMap,
      selectedBuilding: state => state.graphLocationComparison.selectedMap.building,
      graphConfig: state => state.graphLocationComparison.graphConfig,
      graphData: state => state.graphLocationComparison.graphData
    }),
  },
  methods: {
    mapSelectedCallback(payload){
      console.log("mapSelectedCallback " + payload.resource.id + " - " + payload.resource.name)
      if(this.$router.currentRoute.query.mapId == null || this.$router.currentRoute.query.mapId != payload.resource.id) {
        this.$router.replace({...this.$router.currentRoute, query: {mapId: payload.resource.id}})
      }
      this.$store.dispatch('graphLocationComparison/loadMapFromRouteQueryParam')
    }
  },
  watch: {
    '$route' (to, from) { // watch it
      console.log('URL changed: ', to, ' | was: ', from, ' | query ', this.$route.query)
    }
  }
}
</script>
