<template>
  <div class="animated fadeInRight">

    <div class="ibox">
      <div class="ibox-content p-5">

        <validation-observer ref="form" v-slot="{ handleSubmit }">

          <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(save)">

            <div class="row">

              <div class="col-6">
                <b-form-group v-if="form.status" id="input-group-register-organisation-request-status" :label="$t('orgAdmin.registerOrganisationRequest.attributes.status')" label-for="input-register-organisation-request-status" label-cols-sm="2">
                  <span class="label" :class="{'label-danger':form.status == 'DECLINED','label-primary':form.status == 'APPROVED','label-warning':form.status == 'PENDING'}">{{ $i18n.t('enums.RegisterOrgRequestStatus.' + form.status) }}</span>
                </b-form-group>
              </div>
            </div>

            <div class="row mt-4">

              <div class="col-xl-6">

                <h3 class="m-t-none m-b">{{ $t('orgAdmin.registerOrganisationRequest.tableHeaderGroupOrganisation') }}</h3>

                <validation-provider name="orgAdmin.registerOrganisationRequest.attributes.organisationName" :rules="{ required: true, min: 3, max: 50 }" v-slot="validationContext">
                  <b-form-group id="input-group-register-organisation-request-org-name" :label="$t('orgAdmin.registerOrganisationRequest.attributes.organisationName')" label-for="input-register-organisation-request-org-name" label-cols-sm="4">
                    <b-form-input
                        :plaintext="type=='view'"
                        id="input-register-organisation-request-org-name"
                        name="input-register-organisation-request-org-name"
                        v-model="form.organisationName"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-register-organisation-request-feedback-org-name"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-register-organisation-request-feedback-org-name">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider name="orgAdmin.registerOrganisationRequest.attributes.organisationAddress" :rules="{ required: true, max: 50 }" v-slot="validationContext">
                  <b-form-group id="input-group-register-organisation-request-org-name" :label="$t('orgAdmin.registerOrganisationRequest.attributes.organisationAddress')" label-for="input-register-organisation-request-org-address" label-cols-sm="4">
                    <b-form-input
                        :plaintext="type=='view'"
                        id="input-register-organisation-request-org-address"
                        name="input-register-organisation-request-org-address"
                        v-model="form.organisationAddress"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-register-organisation-request-feedback-org-address"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-register-organisation-request-feedback-org-address">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider name="orgAdmin.registerOrganisationRequest.attributes.organisationAddress2" :rules="{ max: 100 }" v-slot="validationContext">
                  <b-form-group id="input-group-register-organisation-request-org-name" :label="$t('orgAdmin.registerOrganisationRequest.attributes.organisationAddress2')" label-for="input-register-organisation-request-org-address2" label-cols-sm="4">
                    <b-form-input
                        :plaintext="type=='view'"
                        id="input-register-organisation-request-org-address2"
                        name="input-register-organisation-request-org-address2"
                        v-model="form.organisationAddress2"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-register-organisation-request-feedback-org-address2"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-register-organisation-request-feedback-org-address2">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider name="orgAdmin.registerOrganisationRequest.attributes.organisationCity" :rules="{ required: true, max: 100 }" v-slot="validationContext">
                  <b-form-group id="input-group-register-organisation-request-org-name" :label="$t('orgAdmin.registerOrganisationRequest.attributes.organisationCity')" label-for="input-register-organisation-request-org-city" label-cols-sm="4">
                    <b-form-input
                        :plaintext="type=='view'"
                        id="input-register-organisation-request-org-city"
                        name="input-register-organisation-request-org-city"
                        v-model="form.organisationCity"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-register-organisation-request-feedback-org-city"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-register-organisation-request-feedback-org-city">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider name="orgAdmin.registerOrganisationRequest.attributes.organisationZipCode" :rules="{ required: true, max: 10 }" v-slot="validationContext">
                  <b-form-group id="input-group-register-organisation-request-org-name" :label="$t('orgAdmin.registerOrganisationRequest.attributes.organisationZipCode')" label-for="input-register-organisation-request-org-zipCode" label-cols-sm="4">
                    <b-form-input
                        :plaintext="type=='view'"
                        id="input-register-organisation-request-org-zipCode"
                        name="input-register-organisation-request-org-zipCode"
                        v-model="form.organisationZipCode"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-register-organisation-request-feedback-org-zipCode"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-register-organisation-request-feedback-org-zipCode">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider name="orgAdmin.registerOrganisationRequest.attributes.organisationCountry" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group id="input-group-register-organisation-request-org-name" :label="$t('orgAdmin.registerOrganisationRequest.attributes.organisationCountry')" label-for="input-register-organisation-request-org-country" label-cols-sm="4">
                    <b-form-input :plaintext="true" v-if="type=='view'" :value="countryValueLabel" />
                    <v-select
                        v-if="type!='view'"
                        id="input-register-organisation-request-org-country"
                        name="input-register-organisation-request-org-country"
                        v-model="form.country"
                        :options="$t('enums.countries')"
                        :reduce="type => type.code"
                        label="name"
                        :clearable="true"
                        :class="{'is-invalid': validationContext.errors.length > 0}"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>


              </div>

              <div class="col-xl-6">

                <h3 class="m-t-none m-b">{{ $t('orgAdmin.registerOrganisationRequest.tableHeaderGroupUser') }}</h3>

                <validation-provider vid="username" name="orgAdmin.registerOrganisationRequest.attributes.userUsername" :rules="{ required: true, alpha_dash: true, min: 3, max: 50 }" v-slot="validationContext">
                  <b-form-group id="input-group-register-organisation-request-user-username" :label="$t('orgAdmin.registerOrganisationRequest.attributes.userUsername')" label-for="input-register-organisation-request-user-username" label-cols-sm="2">
                    <b-form-input
                        :plaintext="true"
                        id="input-register-organisation-request-user-username"
                        name="input-register-organisation-request-user-username"
                        v-model="form.userUsername"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-feedback-register-organisation-request-user-username"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-feedback-register-organisation-request-user-username">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider vid="firstName" name="orgAdmin.registerOrganisationRequest.attributes.userFirstName" :rules="{ required: true, max: 50 }" v-slot="validationContext">
                  <b-form-group id="input-group-register-organisation-request-user-firstName" :label="$t('orgAdmin.registerOrganisationRequest.attributes.userFirstName')" label-for="input-register-organisation-request-user-firstName" label-cols-sm="2">
                    <b-form-input
                        :plaintext="type=='view'"
                        id="input-register-organisation-request-user-firstName"
                        name="input-register-organisation-request-user-firstName"
                        v-model="form.userFirstName"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-feedback-register-organisation-request-user-firstName"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-feedback-register-organisation-request-user-firstName">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider vid="lastName" name="orgAdmin.registerOrganisationRequest.attributes.userLastName" :rules="{ required: true, max: 50 }" v-slot="validationContext">
                  <b-form-group id="input-group-register-organisation-request-user-lastName" :label="$t('orgAdmin.registerOrganisationRequest.attributes.userLastName')" label-for="input-register-organisation-request-user-lastName" label-cols-sm="2">
                    <b-form-input
                        :plaintext="type=='view'"
                        id="input-register-organisation-request-user-lastName"
                        name="input-register-organisation-request-user-lastName"
                        v-model="form.userLastName"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-feedback-register-organisation-request-user-lastName"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-feedback-register-organisation-request-user-lastName">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider vid="email" name="orgAdmin.registerOrganisationRequest.attributes.userEmail" :rules="{ required: true, email: true, max: 100 }" v-slot="validationContext">
                  <b-form-group id="input-group-register-organisation-request-user-email" :label="$t('orgAdmin.registerOrganisationRequest.attributes.userEmail')" label-for="input-register-organisation-request-user-email" label-cols-sm="2">
                    <b-form-input
                        :plaintext="true"
                        id="input-register-organisation-request-user-email"
                        name="input-register-organisation-request-user-email"
                        v-model="form.userEmail"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-feedback-register-organisation-request-user-email"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-feedback-register-organisation-request-user-email">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider vid="phone" name="orgAdmin.registerOrganisationRequest.attributes.userPhone" :rules="{ max: 20 }" v-slot="validationContext">
                  <b-form-group id="input-group-register-organisation-request-user-phone" :label="$t('orgAdmin.registerOrganisationRequest.attributes.userPhone')" label-for="input-register-organisation-request-user-phone" label-cols-sm="2">
                    <b-form-input
                        :plaintext="type=='view'"
                        id="input-register-organisation-request-user-phone"
                        name="input-register-organisation-request-user-phone"
                        v-model="form.userPhone"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-feedback-register-organisation-request-user-phone"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-feedback-register-organisation-request-user-phone">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-form-group id="input-group-register-organisation-request-user-language" :label="$t('orgAdmin.registerOrganisationRequest.attributes.userLanguage')" label-for="input-register-organisation-request-user-language" label-cols-sm="2">
                  <span class="badge badge-primary text-uppercase" v-if="type!='create'">{{ form.userLanguage }}</span>
                  <div class="pt-1" v-if="type=='create'">
                    <button type="button" class="btn btn-primary btn-outline btn-xs m-r-xs"
                            :class="{active:form.userLanguage=='en'}" @click.prevent="form.userLanguage='en'">EN
                    </button>
                    <button type="button" class="btn btn-primary btn-outline btn-xs m-r-xs"
                            :class="{active:form.userLanguage=='da'}" @click.prevent="form.userLanguage='da'">DA
                    </button>
                  </div>
                </b-form-group>

              </div>

            </div>

            <div class="row">
              <div class="col-12">
                <!--button  type="button" class="btn btn-sm btn-primary m-r-sm" v-if="type=='view' && form.status == 'PENDING'" @click.prevent="type='edit'">
                  <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.registerOrganisationRequest.actions.edit') }}
                </button>
                <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary m-r-sm">{{ $t('common.actions.save') }}</button>
                <button type="button" v-if="type=='edit' && form.status == 'PENDING'" class="btn btn-sm btn-secondary m-r-sm" @click="reset">{{ $t('common.actions.reset') }}</button-->
                <button type="button" class="btn btn-sm btn-white m-r-sm" @click="cancel">{{ $t('common.actions.back') }}</button>
                <button type="button" v-if="type=='view' && form.status == 'PENDING'" class="btn btn-sm btn-danger float-right m-l-sm" @click="decline">{{ $t('orgAdmin.registerOrganisationRequest.actions.decline') }}</button>
                <button type="button" v-if="type=='view' && form.status == 'PENDING'" class="btn btn-sm btn-primary float-right" @click="approve">{{ $t('orgAdmin.registerOrganisationRequest.actions.approve') }}</button>
              </div>
            </div>

          </b-form>
        </validation-observer>

      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue from "vue"
import * as commons from "@/commons"
import i18n from "@/i18n"


export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      error: null,
      type: 'view',
      form: {
        id: null,
        userFirstName: '',
        userLastName: '',
        userEmail: '',
        userUsername: '',
        userPhone: '',
        userLanguage: 'en',
        organisationName: '',
        organisationAddress: '',
        organisationAddress2: '',
        organisationZipCode: '',
        organisationCity: '',
        organisationCountry: ''
      }
    }
  },
  created(){
    console.log('RegisterOrganisationRequestDetails $route.name: ' + this.$route.name)
    this.$store.dispatch('organisation/fetchRegisterOrganisationRequestDetails', this.$route.params.id)
  },
  computed: {
    ...mapState({
      registerOrganisationRequest: state => state.organisation.registerOrganisationRequestDetails.entity
    }),
    countryValueLabel(){
      let value = i18n.t('enums.countries').filter(country => { return country.code == this.form.country })
      return value[0] ? value[0].name : ''
    }
  },
  watch: {
    registerOrganisationRequest: function(newVal, oldVal) { // watch it
      console.log('Prop registerOrganisationRequest changed: ', newVal, ' | was: ', oldVal)
      this.reset()
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    reset() {
      this.error = ""
      this.info = ""
      this.form = Object.assign({}, this.registerOrganisationRequest)
      this.type = 'view'

      if(this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    cancel(){
      this.goBack()
    },
    save() {

      this.error = ""
      this.info = ""

      let vm = this

      Vue.axios.put('/admin/register-organisation-requests/' + this.registerOrganisationRequest.id, JSON.stringify(this.form),
          {
            headers: {'Content-Type': 'application/json'}
          }).then(response => {
        this.$toastr.i(i18n.t('orgAdmin.registerOrganisationRequest.info.saved'))
        self.$store.dispatch('organisation/fetchRegisterOrganisationRequestDetails', self.$route.params.id)
      }).catch(error => {
        if (error.response.status === 422) {
          vm.$refs.form.setErrors(error.response.data)
        } else {
          commons.processRestError(error)
        }
      })
    },
    decline(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.registerOrganisationRequest.confirm.decline'),
        callbackYes: function(){
          Vue.axios.put('/admin/register-organisation-requests/' + self.form.id + '/decline')
              .then(response => {
                self.$toastr.i(i18n.t('orgAdmin.registerOrganisationRequest.info.declined'))
                self.$store.dispatch('organisation/fetchRegisterOrganisationRequestDetails', self.$route.params.id)
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
    approve(){

      let self = this
      this.confirmDialog({
        text: this.$t('orgAdmin.registerOrganisationRequest.confirm.approve'),
        callbackYes: function(){
          Vue.axios.put('/admin/register-organisation-requests/' + self.form.id + '/approve', JSON.stringify(self.form),
              {
                headers: {'Content-Type': 'application/json'}
              }).then(response => {
                self.$toastr.i(i18n.t('orgAdmin.registerOrganisationRequest.info.approved'))
                self.$store.dispatch('organisation/fetchRegisterOrganisationRequestDetails', self.$route.params.id)
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    }
  }
}
</script>
