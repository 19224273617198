<template>
  <div>
    <div class="col equal-height">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ $t('orgAdmin.building.name') + " " + $t('common.actions.select')  }}</h5>
          </div>
          <div class="ibox-content">
            <resource-selector
              :resourceSelectedCallback="resourceSelectedCallback"
              selectDepth="building"
            />
          </div>
        </div>
      </div>
      <transition :duration="{ enter: 0, leave: 0 }" enter-active-class="slideInLeft animated" leave-active-class="slideOutLeft animated">
        <div class="col-lg-12" v-if="building">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ $t('energySavings.completed') }}</h5>
            </div>
            <div class="ibox-content">
              <div class="pl-2 pr-2">
                <energy-savings-analysis-list :building="building"/>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ResourceSelector from "@/components/common/ResourceSelector";
import EnergySavingsAnalysisList from "@/components/views/energySavings/EnergySavingsAnalysisList.vue";
import _ from "lodash";

export default {
  components: {
    ResourceSelector,
    EnergySavingsAnalysisList
  },
  data() {
    return {
      analysisList: {
        filter: null,
        page: 1,
        size: 10
      },
      table: {
        filter: '',
        sortBy: '',
        sortDesc: false,
        fields: [
          {
            key: 'generatedTimestamp',
            label: this.$t('energySavings.generated'),
            sortable: true,
          },
          {
            key: 'periodStart',
            label: this.$t('energySavings.period'),
            sortable: true,
          },
          {
            key: 'status',
            label: this.$t('energySavings.status'),
            sortable: true,
          },
          {
            key: 'reportId',
            label: '',
            sortable: false,
          },
        ],
      },
      building: null,
      analyses: [],
    }
  },
  computed: {
    ...mapState({
      contextOrg: state => state.contextOrg,
    }),
    analysisListFiltered() {
      if (this.analysisList.filter) {
        return _.sortBy(this.analyses.filter(item =>
            item.name.toLowerCase().includes(this.analysisList.filter.toLowerCase())
        ), ['name']);
      } else {
        return _.sortBy(this.analyses, ['name']);
      }
    }
  },
  methods: {
    resourceSelectedCallback(payload) {
      this.building = payload.buildingSelected;
      this.$store.dispatch('buildings/fetchBuildingDetails', this.building.id);
      this.fetchAnalyses();
    },
    fetchAnalyses() {
      this.$store.dispatch('energySavings/loadAnalysesForBuilding', this.building.id);
    },
    clearFilter() {
      this.analysisList.filter = null;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}, ${hours}:${minutes}`;
    },
  }
}
</script>

<style scoped>
.download-link {
  cursor: pointer;
  color: #61AEB7;
}

.delete-link {
  cursor: pointer;
  color: #E94F37;
}

.icon-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
</style>
