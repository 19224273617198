import Vue from "vue"
import * as commons from '@/commons'
import router from '@/router'
import moment from 'moment-timezone'
import i18n from "@/i18n";

// default state
const getDefaultState = () => {

  return {
    allActiveTab: 0,
    selectedBuilding: null,
    selectedBuildingDevices: [],
    listsConf: {},
    listsFilter: {},
    messageDetails: {
      entity: null
    }
  }
}

// initial state
const state = getDefaultState()

const actions = {

  appInit({ commit, state, dispatch, rootState }) {
    console.log('messageCentre/appInit')
  },

  loadBuildingFromRouteQueryParam({ commit, state, dispatch }){
    if (router.currentRoute.query.buildingId) {
      //load org from server
      Vue.axios.get('/buildings/' + router.currentRoute.query.buildingId).then(response => {
        commit('UPDATE_SELECTED_BUILDING', response.data)
      }).catch(error => {
        commons.processRestError(error)
      })

      Vue.axios.get('/admin/buildings/' + router.currentRoute.query.buildingId + '/devices').then(response => {
        commit('UPDATE_SELECTED_BUILDING_DEVICES', response.data)
      }).catch(error => {
        commons.processRestError(error)
      })
    } else if (state.selectedBuilding){
      if(router.currentRoute.query.buildingId == null) {
        router.replace({ ...router.currentRoute, query: {buildingId: state.selectedBuilding.id} })
      }
    }
  },

  fetchMessageDetails({commit, state, dispatch, rootState}, id) {
    Vue.axios.get('/messages/' + id).then(response => {
      commit('UPDATE_MESSAGE_DETAILS_ENTITY', response.data)
    }).catch(error => {
      this.error = commons.processRestError(error)
    })
  }
}


// mutations
const mutations = {

  ['GLOBAL_RESET']: (state, data) => {
    Object.assign(state, getDefaultState())
  },

  ['SAVE_ALL_ACTIVE_TAB']: (state, data) => {
    state.allActiveTab = data
  },

  ['UPDATE_SELECTED_BUILDING']: (state, data) => {
    state.selectedBuilding = data
    //reset lists conf and filter
    state.listsConf = {}
    state.filter = {}
  },

  ['UPDATE_SELECTED_BUILDING_DEVICES']: (state, data) => {
    state.selectedBuildingDevices = data
  },

  ['UPDATE_MESSAGE_DETAILS_ENTITY']: (state, data) => {
    Vue.set(state.messageDetails, 'entity', data)
  },

  ['UPDATE_LIST_CONF']: (state, data) => {
    if(data.id) {
      if(!state.listsConf.hasOwnProperty(data.id)){
        Vue.set(state.listsConf, data.id, {})
      }
      Vue.set(state.listsConf, data.id, {...data})
    }
  },

  ['UPDATE_LISTS_FILTER']: (state, data) => {
    state.listsFilter = {...data}
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}