<template>
  <div>
    <div class="alert alert-info" v-if="info">{{ info }}</div>

    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(submit)">
        <div class="row">
          <div class="col-xxl-8 col-xl-7 col-12">
            <b-form-group v-if="building.status" id="input-group-user-status" :label="$t('orgAdmin.user.attributes.status')" label-for="input-resource-status" label-cols-sm="3">
              <span class="label" :class="[(building.status == 'BLOCKED' || building.status == 'PARENT_BLOCKED') ? 'label-danger' : 'label-primary']">{{ $i18n.t('enums.ResourceStatus.' + building.status) }}</span>
            </b-form-group>

            <validation-provider name="orgAdmin.building.attributes.name" :rules="{ required: true, min: 3, max: 50 }" v-slot="validationContext">
              <b-form-group id="input-group-building-name" :label="$t('orgAdmin.building.attributes.name')" label-for="input-building-name" label-cols-sm="3">
                <b-form-input
                  :plaintext="type=='view'"
                  id="input-building-name"
                  name="input-building-name"
                  v-model="form.name"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-feedback-building-name"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-building-name">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.building.attributes.address" :rules="{ required: true, max: 50 }" v-slot="validationContext">
              <b-form-group id="input-group-building-address" :label="$t('orgAdmin.building.attributes.address')" label-for="input-building-address" label-cols-sm="3">
                <b-form-input
                  :plaintext="type=='view'"
                  id="input-building-address"
                  name="input-building-address"
                  v-model="form.address"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-feedback-building-address"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-building-address">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.building.attributes.city" :rules="{ required: true, max: 100 }" v-slot="validationContext">
              <b-form-group id="input-group-building-city" :label="$t('orgAdmin.building.attributes.city')" label-for="input-building-city" label-cols-sm="3">
                <b-form-input
                  :plaintext="type=='view'"
                  id="input-building-city"
                  name="input-building-city"
                  v-model="form.city"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-feedback-building-city"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-building-city">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.building.attributes.zipCode" :rules="{ required: true, max: 10 }" v-slot="validationContext">
              <b-form-group id="input-group-building-zipCode" :label="$t('orgAdmin.building.attributes.zipCode')" label-for="input-building-zipCode" label-cols-sm="3">
                <b-form-input
                  :plaintext="type=='view'"
                  id="input-building-zipCode"
                  name="input-building-zipCode"
                  v-model="form.zipCode"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-feedback-building-zipCode"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-building-zipCode">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.building.attributes.country" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group id="input-group-building-country"
                :label="$t('orgAdmin.building.attributes.country')"
                label-for="input-building-country"
                label-cols-sm="3"
                :state="getValidationState(validationContext)">
                <b-form-input :plaintext="true" v-if="type=='view'" :value="countryValueLabel" />
                <v-select
                  v-if="type!='view'"
                  id="input-building-country"
                  name="input-building-country"
                  v-model="form.country"
                  :options="$t('enums.countries')"
                  :reduce="type => type.code"
                  label="name"
                  :clearable="true"
                  :class="{'is-invalid': validationContext.errors.length > 0}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.building.attributes.timeZone" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group id="input-group-building-timeZone"
                :label="$t('orgAdmin.building.attributes.timeZone')"
                label-for="input-building-timeZone" label-cols-sm="3"
                :state="getValidationState(validationContext)">
                <b-form-input :plaintext="true" v-if="type=='view'" :value="form.timeZone" />
                <v-select
                  v-if="type!='view'"
                  id="input-building-timeZone"
                  name="input-building-timeZone"
                  v-model="form.timeZone"
                  :options="Intl.supportedValuesOf('timeZone')"
                  :clearable="true"
                  :class="{'is-invalid': validationContext.errors.length > 0}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.building.attributes.openingHours" v-slot="{ errors }">
              <b-form-group
                id="input-group-building-openingHours"
                :label="$t('orgAdmin.building.attributes.openingHours')"
                label-for="input-building-openingHours" label-cols-sm="3"
                :state="errors.length === 0">
                <div v-for="times in openingTimes" :key="times.id" class="time-picker-wrapper">
                  <label :for="`input-building-${times.dayOfWeek}-opening`" class="mb-0">
                    {{ $t(`orgAdmin.building.attributes.dayOfWeek.${times.dayOfWeek}`) }}
                  </label>
                  <b-dropdown v-if="type !== 'view'" :variant="times.active ? 'primary' : 'secondary'" class="" @change="setOpeningState(times.id, $event)">
                    <b-dropdown-item @click="setOpeningState(times.id, 'open')">Open</b-dropdown-item>
                    <b-dropdown-item @click="setOpeningState(times.id, 'closed')">Closed</b-dropdown-item>
                    <b-dropdown-item @click="setOpeningState(times.id, 'openAllDay')">Open All Day</b-dropdown-item>
                  </b-dropdown>
                  <b-form-timepicker
                    v-if="type !== 'view'"
                    :id="`input-building-${times.id}-opening`"
                    v-model="times.openTime"
                    :disabled="!times.active"
                    placeholder="Opening time"
                    :hour12="false"
                    :class="{'is-invalid': errors.length > 0}"
                    :state="validateTimes(times.id) ? null : false"
                    @input="updateOpeningTime($event, times.dayOfWeek, 'open')"
                  ></b-form-timepicker>
                  <b-form-timepicker
                    v-if="type !== 'view'"
                    :id="`input-building-${times.id}-closing`"
                    v-model="times.closeTime"
                    :disabled="!times.active"
                    placeholder="Closing time"
                    :hour12="false"
                    :class="{'is-invalid': errors.length > 0}"
                    :state="validateTimes(times.id) ? null : false"
                    @input="updateOpeningTime($event, times.dayOfWeek, 'close')"
                  ></b-form-timepicker>
                  <b-form-input
                    :plaintext="true"
                    v-if="type === 'view' && times.dayOfWeek !== null && times.active === true"
                    :value="`${times.openTime} - ${times.closeTime}`"
                    :class="{ 'full-width-time': type !== 'edit' }"
                  />
                  <div v-if="type === 'view' && times.active === false" class="full-width-closed">
                    {{ $t(`orgAdmin.building.attributes.closed`) }}
                  </div>
                </div>
                <div v-if="type === 'view' && openingTimes.length === 0">
                  {{ $t(`orgAdmin.building.attributes.notSet`) }}
                </div>
                <div v-if="openingTimes.length === 0 && type !== 'view'" class="no-opening-hours">
                  <button class="btn btn-primary" @click="addDefaultOpeningHours">
                    {{ $t(`orgAdmin.building.attributes.addHours`) }}
                  </button>
                </div>
                <b-form-invalid-feedback :state="errors.length === 0">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group :label="$t('orgAdmin.building.openingHourExceptions.openingHourExceptions')" label-cols-sm="3" v-if="type === 'view'">
              <b-button @click="showExceptionPopover = !showExceptionPopover" variant="info" >
                {{$t('orgAdmin.building.openingHourExceptions.manageExceptions')}}
              </b-button>

              <opening-hour-exception-popup
                v-if="showExceptionPopover"
                :initialData="form"
                :building="building"
                :closeCallback="() => {showExceptionPopover = false;}"
                :openCallback="() => {showExceptionPopover = true;}"
                initialType="create"
              ></opening-hour-exception-popup>
            </b-form-group>
          </div>

          <div class="col-xxl-4 col-xl-5 col-lg-12">
            <geo-location-select-location
              v-if="type=='create' || type=='edit'"
              :selectCallback="geoLocationSelected"
              :initial-coordinates="form.coordinates ? { lat: form.coordinates.latitude, lng: form.coordinates.longitude} : null"
              :initial-address="{ address: form.address, zipCode: form.zipCode, city: form.city, countryCode: form.country }"
            />

            <geo-location
              v-show="type=='view'"
              :markers="buildingCoordinates"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-xxl-8 col-xl-7 col-lg-12">
            <button type="button" class="btn btn-sm btn-primary" v-if="canEdit && type=='view' && building.status=='ACTIVE'" @click.prevent="type='edit'">
              <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.building.actions.edit') }}
            </button>
            <button type="submit" v-if="type=='create'" class="btn btn-sm btn-primary">{{ $t('common.actions.create') }}</button>
            <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary">{{ $t('common.actions.save') }}</button>
            <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-secondary m-l-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
            <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-white m-l-sm" @click="cancel">{{ $t('common.actions.cancel') }}</button>
            <button type="button" v-if="type=='edit' && building.status=='ACTIVE'" class="btn btn-sm btn-danger float-right" @click="blockBuilding">{{ $t('common.actions.block') }}</button>
            <button type="button" v-if="type!='create' && building.status=='BLOCKED'" class="btn btn-sm btn-danger float-right" @click="unblockBuilding">{{ $t('common.actions.unblock') }}</button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue from "vue"
import * as commons from "@/commons"
import GeoLocation from '@/components/common/GeoLocationLeaflet'
import GeoLocationSelectLocation from '@/components/common/GeoLocationLeafletSelectLocation'
import OpeningHourExceptionPopup from '@/components/views/admin/organisations/OpeningHourExceptionPopup'
import i18n from "@/i18n";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    GeoLocation,
    GeoLocationSelectLocation,
    OpeningHourExceptionPopup
  },
  props: {
    building: {
      type: Object
    },
    canEdit: {
      type: Boolean
    },
    initialType: {
      validator: function (value) {
        return ['view', 'edit', 'create'].includes(value)
      }
    },
    successCallback: {
      type: Function
    },
    cancelCallback: {
      type: Function
    }
  },
  data() {
    return {
      error: null,
      info: null,
      type: this.initialType,
      showExceptionPopover: false,
      form: {
        name: '',
        address: '',
        zipCode: '',
        city: '',
        country: '',
        coordinates: null,
        timeZone: '',
        buildingOperatingHours: []
      }
    }
  },
  watch: {
    building: function (newVal, oldVal) {
      console.log('Prop building changed: ', newVal, ' | was: ', oldVal)
      this.reset()
    }
  },
  computed: {
    buildingCoordinates() {
      if (this.building && this.building.coordinates && this.building.coordinates.latitude && this.building.coordinates.longitude) {
        return [{
          lat: this.building.coordinates.latitude,
          lng: this.building.coordinates.longitude,
          label: this.building.name
        }]
      }
      return null
    },
    countryValueLabel() {
      let value = i18n.t('enums.countries').filter(country => { return country.code == this.form.country });
      return value[0] ? value[0].name : '';
    },
    openingTimes() {
      return this.form.buildingOperatingHours.map((hour) => {
        return {
          ...hour,
          active: hour.active,
          openTime: hour.openTime,
          closeTime: hour.closeTime,
        };
      }).sort((a, b) => a.dayOfWeek - b.dayOfWeek); // Ensure days in order
    }
  },
  methods: {
    geoLocationSelected(callbackValue) {
      console.log('callbackValue.position.lat: ' + callbackValue.position.lat + ', address: ' + JSON.stringify(callbackValue.address))
      this.form.coordinates = { x: callbackValue.position.lat, y: callbackValue.position.lng }
      if (callbackValue.address) {
        this.form.address = callbackValue.address.address
        this.form.city = callbackValue.address.city
        this.form.zipCode = callbackValue.address.zipCode
        this.form.country = callbackValue.address.countryCode ? callbackValue.address.countryCode.toUpperCase() : ''
      }
      if (callbackValue.position) {
        this.form.coordinates.latitude = callbackValue.position.lat
        this.form.coordinates.longitude = callbackValue.position.lng
      }
      if (callbackValue.timeZone) {
        this.form.timeZone = callbackValue.timeZone
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    reset() {
      if (this.building) {
        this.form = JSON.parse(JSON.stringify(this.building));
      }
      this.$refs.form.reset();
    },
    cancel() {
      this.reset()
      this.type = this.initialType
      if (this.cancelCallback) {
        this.cancelCallback()
      }
    },
    blockBuilding() {
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.building.confirm.block', { '0': self.form.name }),
        callbackYes: function () {
          Vue.axios.put('/admin/buildings/' + self.form.id + '/block')
            .then(response => {
              self.reset()
              self.type = self.initialType
              self.$toastr.i(i18n.t('orgAdmin.building.info.blocked', self.form.name))
              if (self.successCallback) {
                self.successCallback()
              }
            })
            .catch(error => {
              commons.processRestError(error)
            });
        }
      })
    },
    unblockBuilding() {
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.building.confirm.unblock', { '0': self.form.name }),
        callbackYes: function () {
          Vue.axios.put('/admin/buildings/' + self.form.id + '/unblock')
            .then(response => {
              self.reset()
              self.type = self.initialType
              self.$toastr.i(i18n.t('orgAdmin.building.info.unblocked', self.form.name))
              if (self.successCallback) {
                self.successCallback()
              }
            })
            .catch(error => {
              commons.processRestError(error)
            });
        }
      })
    },
    submit() {
      this.error = "";
      this.info = "";
      let vm = this;

      if (this.type == "create") {
        this.form.organisation = this.building.organisation

        Vue.axios.post('/admin/buildings', JSON.stringify(this.form),
          {
            headers: { 'Content-Type': 'application/json' }
          }).then(response => {
            vm.reset()
            vm.type = vm.initialType
            vm.$toastr.i(i18n.t('orgAdmin.building.info.created'))

            if (vm.successCallback) {
              vm.successCallback()
            }
          }).catch(error => {
            if (error.response.status === 422) {
              vm.$refs.form.setErrors(error.response.data)
            } else {
              commons.processRestError(error)
            }
          })
      } else if (this.type == "edit") {
        Vue.axios.put('/admin/buildings/' + this.form.id, JSON.stringify(this.form),
          {
            headers: { 'Content-Type': 'application/json' }
          }).then(response => {
            vm.reset()
            vm.type = vm.initialType
            vm.$toastr.i(i18n.t('orgAdmin.building.info.saved'))
            console.log(response)
            if (vm.successCallback) {
              vm.successCallback()
            }
          }).catch(error => {
            if (error.response.status === 422) {
              vm.$refs.form.setErrors(error.response.data)
            } else {
              commons.processRestError(error)
            }
          })
      }
    },
    updateOpeningTime(time, dayOfWeek, type) {
      const hourEntry = this.form.buildingOperatingHours.find(oh => oh.dayOfWeek === dayOfWeek);
      if (hourEntry) {
        if (type === 'open') {
          hourEntry.openTime = time.slice(0, 5);
        } else {
          hourEntry.closeTime = time.slice(0, 5);
        }
      }
    },
    validateTimes(dayId) {
      const day = this.form.buildingOperatingHours.find(day => day.id === dayId);
      if (day) {
        const [openHour, openMinute] = day.openTime.split(':').map(Number);
        const [closeHour, closeMinute] = day.closeTime.split(':').map(Number);
        return openHour * 60 + openMinute < closeHour * 60 + closeMinute;
      }
      return false;
    },
    setOpeningState(dayId, state) {
      const day = this.form.buildingOperatingHours.find(d => d.id === dayId);
      if (day) {
        switch (state) {
          case 'open':
            day.active = true;
            break;
          case 'closed':
            day.active = false;
            break;
          case 'openAllDay':
            day.active = true;
            day.openTime = '00:00';
            day.closeTime = '23:59';
            break;
        }
      }
    },
    addDefaultOpeningHours() {
      const defaultHours = [
        { id: 1, dayOfWeek: 1, openTime: '09:00', closeTime: '17:00', active: true },
        { id: 2, dayOfWeek: 2, openTime: '09:00', closeTime: '17:00', active: true },
        { id: 3, dayOfWeek: 3, openTime: '09:00', closeTime: '17:00', active: true },
        { id: 4, dayOfWeek: 4, openTime: '09:00', closeTime: '17:00', active: true },
        { id: 5, dayOfWeek: 5, openTime: '09:00', closeTime: '17:00', active: true },
        { id: 6, dayOfWeek: 6, openTime: '09:00', closeTime: '17:00', active: false },
        { id: 7, dayOfWeek: 7, openTime: '09:00', closeTime: '17:00', active: false }
      ];
      this.form.buildingOperatingHours = defaultHours;
    }
  }
}
</script>

<style scoped>
.time-picker-wrapper {
  display: grid;
  grid-template-columns: 3fr 1fr 6fr 6fr;
  align-items: center;
}

.full-width-time {
  grid-column: 3 / span 2;
}

.full-width-closed {
  grid-column: 3 / span 2;
  margin-left: 1.2rem;
  padding: 0.375rem 0;
}

.time-picker-wrapper > * {
  font-size: small;
  padding: 1px;
}
</style>
