<template>
  <div>

    <div class="row equal-height">
      <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="slideInLeft animated" leave-active-class="slideOutLeft animated">
        <div class="col-xxl-9 col-xl-8 col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ $t('graphs.meteringPoints.graphView.title') }}<template v-if="selectedDevice"> - {{ selectedDevice.alias }} </template></h5>
              <span v-if="selectedDevice" class="_label _label-primary float-right">{{ $t('graphs.meteringPoints.dateTimeRangeInfo', { 0: selectedBuilding.timeZone }) }} <strong>{{ $store.getters["graphMeteringPoints/graphConfigDateTimeRangeFormatted"] }}</strong></span>
            </div>
            <div class="ibox-content">
              <div class="pl-2 pr-2">
                <graph-view />
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="col-xxl-3 col-xl-4 col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>
              <template v-if="selectedDevice">{{ $t('graphs.meteringPoints.graphConfig.titleMeteringPointSelected') }}</template>
              <template v-else>{{ $t('graphs.meteringPoints.graphConfig.titleMeteringPointNotSelected') }}</template>
            </h5>
          </div>
          <div class="ibox-content">

            <metering-point-selector :selected-callback="deviceSelectedCallback"
                                     select-depth="device" :preselect-resource="selectedDevice ? { device: selectedDevice} : null" />

            <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
              <graph-config v-if="selectedDevice"/>
            </transition>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import {mapState} from "vuex"
import MeteringPointSelector from "@/components/views/graphs/meteringPoints/MeteringPointSelector"
import GraphView from "@/components/views/graphs/meteringPoints/GraphView"
import GraphConfig from "@/components/views/graphs/meteringPoints/GraphConfig"

export default {
  components:{
    MeteringPointSelector,
    GraphView,
    GraphConfig
  },
  data() {
    return {
      info: null
    }
  },
  created() {
    console.log("GraphLocation created() selectedLocation: " + this.selectedLocation)
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedDevice: state => state.graphMeteringPoints.selectedDevice,
      selectedBuilding: state => state.graphMeteringPoints.selectedDevice.building,
      graphConfig: state => state.graphMeteringPoints.graphConfig,
    }),
  },
  methods: {
    deviceSelectedCallback(payload){
      if(this.$router.currentRoute.query.deviceId == null || this.$router.currentRoute.query.deviceId != payload.device.id) {
        this.$router.replace({...this.$router.currentRoute, query: {deviceId: payload.device.id}})
      }
      this.$store.dispatch('graphMeteringPoints/loadDeviceFromRouteQueryParam')
    }
  },
  watch: {
    '$route' (to, from) { // watch it
        console.log('URL changed: ', to, ' | was: ', from, ' | query ', this.$route.query)
    }
  }
}
</script>
