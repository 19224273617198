import Vue from "vue";
import * as commons from '@/commons'
import router from "@/router";
import store from "@/store";

// default state
const getDefaultState = () => {
    return {
        activeTab: 0,
        devices: [],
        beacons: [],
        meters:[],
        deviceDetails: {
            entity: {}
        },
        beaconDetails: {
            entity: {}
        },
        meterDetails: {
            entity: {}
        }
    }
}

// initial state
const state = getDefaultState()

// action
const actions = {

    appInit({commit, state, dispatch, rootState}) {
        console.log('profiles/appInit')
    },

    fetchOrganisationDevices({commit, state, dispatch, rootState}, id) {

        console.log('devices/fetchOrganisationDevices')

        Vue.axios.get('/admin/organisations/' + id + '/devices', {params: {type: "SENSOR"}}).then(response => {
            commit('SAVE_DEVICES', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchOrganisationBeacons({commit, state, dispatch, rootState}, id) {

        console.log('devices/fetchOrganisationBeacons')

        Vue.axios.get('/admin/organisations/' + id + '/beacons').then(response => {
            commit('SAVE_BEACONS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchOrganisationMeteringPoints({commit, state, dispatch, rootState}, id) {

        console.log('devices/fetchOrganisationMeteringPoints')

        Vue.axios.get('/admin/organisations/' + id + '/devices', { params: { type: "METERING_POINT" }}).then(response => {
            commit('SAVE_METERS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchBuildingDevices({commit, state, dispatch, rootState}, id) {

        console.log('devices/fetchBuildingDevices')

        //load devices for building
        Vue.axios.get('/admin/buildings/' + id + '/devices', { params: { type: "SENSOR" }}).then(response => {
            commit('SAVE_DEVICES', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchBuildingBeacons({commit, state, dispatch, rootState}, id) {

        console.log('devices/fetchBuildingBeacons')

        //load beacons for building
        Vue.axios.get('/admin/buildings/' + id + '/beacons').then(response => {
            commit('SAVE_BEACONS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    fetchBuildingMeteringPoints({commit, state, dispatch, rootState}, id) {

        console.log('devices/fetchBuildingMeteringPoints')

        //load meters for building
        Vue.axios.get('/admin/buildings/' + id + '/devices', { params: { type: "METERING_POINT" }}).then(response => {
            commit('SAVE_METERS', response.data)
        }).catch(error => {
            commons.processRestError(error)
        })
    },

}


// mutations
const mutations = {

    ['GLOBAL_RESET']: (state, data) => {
        Object.assign(state, getDefaultState())
    },

    ['SAVE_DEVICES_ACTIVE_TAB']: (state, data) => {
        Vue.set(state, 'activeTab', data)
    },

    ['SAVE_DEVICES']: (state, data) => {
        Vue.set(state, 'devices', data)
    },

    ['SAVE_BEACONS']: (state, data) => {
        Vue.set(state, 'beacons', data)
    },

    ['SAVE_METERS']: (state, data) => {
        Vue.set(state, 'meters', data)
    },
}

export default {
    namespaced: true, state, actions, mutations
}