import BootstrapDialog from '@/assets/js/plugins/dialog/bootstrap-dialog.min.js'
import store from '@/store'
import i18n from '@/i18n'

export function confirmDialog(options) {
	console.log('confirmDialog options: ' + JSON.stringify(options))
	BootstrapDialog.show({
		title: options.title ? options.title : i18n.t('common.popupTitle.confirmation'),
		message: options.text,
		closable: false,
		cssClass: 'inmodal',
		type: options.type ? options.type : BootstrapDialog.TYPE_WARNING,
		buttons: [{
			label: options.yesLabel ? options.yesLabel : i18n.t('common.actions.confirm'),
			action: function (dialogItself) { dialogItself.close(); if (options.callbackYes) { options.callbackYes(); } },
			cssClass: 'btn-warning'
		},
		{
			label: options.noLabel ? options.noLabel : i18n.t('common.actions.cancel'),
			action: function (dialogItself) { dialogItself.close(); if (options.callbackNo) { options.callbackNo(); } },
			cssClass: 'btn-default'
		}]
	});
}

export function warningDialog(options) {
	console.log('warningDialog options: ' + JSON.stringify(options))
	BootstrapDialog.show({
		title: options.title ? options.title : i18n.t('common.popupTitle.warning'),
		message: options.text,
		cssClass: 'inmodal',
		type: BootstrapDialog.TYPE_WARNING,
		buttons: [{
			label: options.okLabel ? options.okLabel : i18n.t('common.actions.close'),
			action: function (dialogItself) { if (options.callback) { options.callback(); } dialogItself.close(); },
			cssClass: 'btn-default'
		}]
	});
}

export function infoDialog(options) {
	console.log('warningDialog options: ' + JSON.stringify(options))
	BootstrapDialog.show({
		title: options.title ? options.title : i18n.t('common.popupTitle.info'),
		message: options.text,
		cssClass: 'inmodal',
		type: options.type ? options.type : BootstrapDialog.TYPE_INFO,
		buttons: [{
			label: options.okLabel ? options.okLabel : i18n.t('common.actions.close'),
			action: function (dialogItself) { if (options.callback) { options.callback(); } dialogItself.close(); },
			cssClass: 'btn-default'
		}]
	});
}

export function getConfigParamValue(key, defaultValue) {
	if(store.state.systemParams && store.state.systemParams[key]){
		return store.state.systemParams[key]
	}
	return defaultValue
}

export function hasRole(testedRole) {
	let result = false
	let currentUser = store.state.userInfo
	if (currentUser && currentUser.authorities) {
		result = currentUser.authorities.some(authority => authority.role == testedRole)
	}
	//console.log('hasRole(' + testedRole + ') result ' + result)
	return result
}

export function hasOneOfRoles(...testedRoles) {
	let result = false
	let currentUser = store.state.userInfo
	if (currentUser && currentUser.authorities) {
		result = testedRoles.some(testedRole => currentUser.authorities.some(authority => authority.role === testedRole))
	}
	//console.log('hasOneOfRoles(' + testedRoles + ') result ' + result)
	return result
}

export function hasResourceRole(testedRole, targetResourceId) {
	let result = false
	let currentUser = store.state.userInfo
	if (currentUser && currentUser.authorities) {
		result = currentUser.authorities.some(authority => authority.role === testedRole && authority.authorizationTarget.id == targetResourceId)
	}
	console.log('hasResourceRole(' + testedRole + ', ' + targetResourceId + ') result ' + result)
	return result
}

export function getUserAuthorities() {
	let result = []
	let currentUser = store.state.userInfo
	if (currentUser && currentUser.authorities) {
		// return deepCopy
		result = JSON.parse(JSON.stringify(currentUser.authorities))
	}
	//console.log('getUserAuthorities() result ' + result)
	return result
}

export function getUserOrganisationAuthorities() {
	let result = []
	let currentUser = store.state.userInfo
	if (currentUser && currentUser.authorities) {
		let authorities = currentUser.authorities.filter(authority => authority.role === 'ORGANISATION_MANAGER')
		authorities.forEach(authority => {
			result.push(authority)
		})
	}
	//console.log('getUserOrganisationsAuthorities() result ' + result)
	return result
}

export function getUserBuildingAuthorities() {
	let result = []
	let currentUser = store.state.userInfo
	if (currentUser && currentUser.authorities) {
		let authorities = currentUser.authorities.filter(authority => authority.role === 'BUILDING_MANAGER')
		authorities.forEach(authority => {
			result.push(authority)
		})
	}
	//console.log('getUserBuildingAuthorities() result ' + result)
	return result
}

export function getUserLocationAuthorities() {
	let result = []
	let currentUser = store.state.userInfo
	if (currentUser && currentUser.authorities) {
		let authorities = currentUser.authorities.filter(authority => authority.role == 'ROOM_MANAGER')
		authorities.forEach(authority => {
			result.push(authority)
		})
	}
	//console.log('getUserBuildingAuthorities() result ' + result)
	return result
}

export function processRestError(error, payload) {

	let resultErrorMessage = null

	if (error instanceof Error) {
		// e.g vuex will pass Error: "Network Error" as error object in catch handlers
		if (error.response) {
			resultErrorMessage = error.config.url + '  -->  ' + error.response.status + ' (' + error.response.statusText + ')'
		} else {
			resultErrorMessage = i18n.t('common.errors.connectionError')
		}
	}

	// somtimes we are passing a Vue error object and sometimes a data object from response
	var responseData = (error instanceof Error && error.response && error.response.data) ? error.response.data : error;

	if (responseData) {
		if (responseData.message) {
			resultErrorMessage = responseData.message;
		}

		if (responseData.exception) {
			resultErrorMessage = responseData.exception;
		}

		if (responseData.error) {
			resultErrorMessage = responseData.error;
		}

		if (responseData.messageKey) {
			resultErrorMessage = i18n.t(responseData.messageKey);
		}

		if (responseData.error == 'user.noUser' || responseData.error == 'user.badCredentials') {
			resultErrorMessage = i18n.t('login.errors.badCredentials')
		}

		if (responseData.error == 'user.noPermission') {
			resultErrorMessage = i18n.t('login.errors.noPermission')
		}

		if (responseData.error == 'user.userAlreadyLogged') {
			var errObject = responseData.userAlreadyLoggedErr
			if (errObject) {
				resultErrorMessage = i18n.t('login.errors.userAlreadyLogged', { '0': errObject.username, '1': errObject.host })
				if (errObject.lastUserActivityTime) {
					resultErrorMessage += ". " + i18n.t('login.errors.lastRegisteredActivityTime', { '0': errObject.lastUserActivityTime })
				}
				if (errObject.currentHost) {
					resultErrorMessage += ". " + i18n.t('login.errors.loginAttemptOnServer', { '0': errObject.currentHost })
				}
			} else {
				resultErrorMessage = i18n.t('login.errors.userAlreadyLogged', { '0': '???' })
			}
		}
	}

	if (resultErrorMessage) {
		console.error('!!! RESOLVED EXCEPTION: ' + resultErrorMessage)
		if (payload && payload.hasOwnProperty('error')) {
			payload.error = resultErrorMessage
		} else {
			if (resultErrorMessage.toLowerCase().indexOf('timeout of ') == -1) {  // connection error already registered in global axios handler ...
				store.commit('SET_ROOT_ERROR', resultErrorMessage)
			}
		}
	} else {
		console.error('!!! UNRESOLVED EXCEPTION: ' + JSON.stringify(error));
	}

	store.dispatch('showGlobalLoader', false)
	store.dispatch('showInPageLoader', false)

	return resultErrorMessage
}

export function getUniqueListBy(arr, key) {
	return [...new Map(arr.map(item => [item[key], item])).values()]
}

export function groupBy(objectArray, property) {
	return objectArray.reduce((acc, obj) => {
		const key = obj[property]
		const curGroup = acc[key] ?? []
		return { ...acc, [key]: [...curGroup, obj] }
	}, {})
}

export function getDatePickerOptions() {
	return {
		locale: i18n.t('dateTimePicker.locale'),
		extraFormats: ['YYYY-MM-DD', 'DD.MM.YYYY'],
		format: 'DD.MM.YYYY',
		showClose: true,
		showClear: true,
		allowInputToggle: true,
		tooltips: {
			today: i18n.t('dateTimePicker.tooltips.today'),
			clear: i18n.t('dateTimePicker.tooltips.clear'),
			close: i18n.t('dateTimePicker.tooltips.close'),
			selectMonth: i18n.t('dateTimePicker.tooltips.selectMonth'),
			prevMonth: i18n.t('dateTimePicker.tooltips.prevMonth'),
			nextMonth: i18n.t('dateTimePicker.tooltips.nextMonth'),
			selectYear: i18n.t('dateTimePicker.tooltips.selectYear'),
			prevYear: i18n.t('dateTimePicker.tooltips.prevYear'),
			nextYear: i18n.t('dateTimePicker.tooltips.nextYear'),
		}
	}
}

export function getTimePickerOptions() {
	return {
		locale: i18n.t('dateTimePicker.locale'),
		extraFormats: ['DD.MM.YYYY HH:mm', 'YYYY-MM-DDTHH:mm:ssZ'],
		format: 'LT',
		showClose: true,
		showClear: true,
		allowInputToggle: true,
		tooltips: {
			today: i18n.t('dateTimePicker.tooltips.today'),
			clear: i18n.t('dateTimePicker.tooltips.clear'),
			close: i18n.t('dateTimePicker.tooltips.close'),
			selectMonth: i18n.t('dateTimePicker.tooltips.selectMonth'),
			prevMonth: i18n.t('dateTimePicker.tooltips.prevMonth'),
			nextMonth: i18n.t('dateTimePicker.tooltips.nextMonth'),
			selectYear: i18n.t('dateTimePicker.tooltips.selectYear'),
			prevYear: i18n.t('dateTimePicker.tooltips.prevYear'),
			nextYear: i18n.t('dateTimePicker.tooltips.nextYear'),
			selectTime: i18n.t('dateTimePicker.tooltips.selectTime'),
			incrementHour: i18n.t('dateTimePicker.tooltips.incrementHour'),
			decrementHour: i18n.t('dateTimePicker.tooltips.decrementHour'),
			pickHour: i18n.t('dateTimePicker.tooltips.pickHour'),
			incrementMinute: i18n.t('dateTimePicker.tooltips.incrementMinute'),
			decrementMinute: i18n.t('dateTimePicker.tooltips.decrementMinute'),
			pickMinute: i18n.t('dateTimePicker.tooltips.pickMinute')
		}
	}
}

export function getDateTimePickerOptions() {
	return {
		locale: i18n.t('dateTimePicker.locale'),
		extraFormats: ['DD.MM.YYYY HH:mm', 'YYYY-MM-DDTHH:mm:ssZ'],
		showTodayButton: true,
		showClose: true,
		showClear: true,
		allowInputToggle: true,
		focusOnShow: true,
		tooltips: {
			today: i18n.t('dateTimePicker.tooltips.today'),
			clear: i18n.t('dateTimePicker.tooltips.clear'),
			close: i18n.t('dateTimePicker.tooltips.close'),
			selectMonth: i18n.t('dateTimePicker.tooltips.selectMonth'),
			prevMonth: i18n.t('dateTimePicker.tooltips.prevMonth'),
			nextMonth: i18n.t('dateTimePicker.tooltips.nextMonth'),
			selectYear: i18n.t('dateTimePicker.tooltips.selectYear'),
			prevYear: i18n.t('dateTimePicker.tooltips.prevYear'),
			nextYear: i18n.t('dateTimePicker.tooltips.nextYear'),
			selectTime: i18n.t('dateTimePicker.tooltips.selectTime'),
			incrementHour: i18n.t('dateTimePicker.tooltips.incrementHour'),
			decrementHour: i18n.t('dateTimePicker.tooltips.decrementHour'),
			pickHour: i18n.t('dateTimePicker.tooltips.pickHour'),
			incrementMinute: i18n.t('dateTimePicker.tooltips.incrementMinute'),
			decrementMinute: i18n.t('dateTimePicker.tooltips.decrementMinute'),
			pickMinute: i18n.t('dateTimePicker.tooltips.pickMinute')
		}
	}
}

export function onlyNumberFld(event) {
	let keyCode = (event.keyCode ? event.keyCode : event.which);
	if ((keyCode < 48 || keyCode > 57) && keyCode != 13) {
		event.preventDefault();
	}
}

export function formatDuration(durationInSeconds) {
	formatDuration_hoursHide(durationInSeconds, false);
}
// eg. input: formatDuration(3601) , output: 01:00:01
export function formatDuration_hoursHide(durationInSeconds, hideHoursIfNotExist) {
	var str = "", hoursStr = "", temp;

	let hours = Math.floor(durationInSeconds / 3600);
	durationInSeconds %= 3600;
	let minutes = Math.floor(durationInSeconds / 60);
	durationInSeconds %= 60;

	minutes = String(minutes).padStart(2, "0");
	hoursStr = String(hours).padStart(2, "0");
	let seconds = String(durationInSeconds).padStart(2, "0");

	var duration = ""
	if (hours > 0) {
		duration += hoursStr + ":"
	} else if (!hideHoursIfNotExist) {
		duration += "00:"
	}

	duration += minutes + ":" + seconds;
	return duration;
}

export function validEmail(email) {
	var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

export function validPhone(phone) {
	var re = /^\+?[0-9]{6,}$/
	return re.test(phone);
}

export function desktopNotificationsSupported() {
	if (!("Notification" in window)) {
		console.log("This browser does not support desktop notification");
		return false;
	} else if (Notification.permission === "granted") {
		return true;
	} else if (Notification.permission !== 'denied' || Notification.permission === "default") {
		Notification.requestPermission(function (permission) {
			if (permission === "granted") {
				return true;
			}
		})
	}
}

export const tinyMCEConfigReadOnly = function () {
	return {
		mode: "specific_textareas",
		editor_selector: "mceEditor",
		height: 600,
		readonly: 1, // iz nekog razloga ovo ne funkcionira, treba koristiti <editor :disabled=true 
		toolbar: false,
		statusbar: true,
		menu: false,
		menubar: false,
		branding: false,
		browser_spellcheck: true,
		content_css: "",
	}
}

export const tinyMCEConfigReadOnlyShortHeight = function () {
	return {
		mode: "specific_textareas",
		editor_selector: "mceEditor",
		height: 300,
		readonly: 1, // iz nekog razloga ovo ne funkcionira, treba koristiti <editor :disabled=true 
		toolbar: false,
		statusbar: true,
		menu: false,
		menubar: false,
		branding: false,
		browser_spellcheck: true,
		content_css: "",
	}
}

export const tinyMCEConfigPreview = function () {
	return {
		mode: "specific_textareas",
		editor_selector: "mceEditor",
		height: "100%",
		readonly: 1, // iz nekog razloga ovo ne funkcionira, treba koristiti <editor :disabled=true 
		toolbar: false,
		statusbar: false,
		menu: false,
		menubar: false,
		branding: false,
		browser_spellcheck: true,
		content_css: ""
	}
}

export const tinyMCEConfigDefault = function () {
	return {
		mode: "specific_textareas",
		editor_selector: "mceEditor",
		height: 500,
		menu: false,
		menubar: false,
		browser_spellcheck: true,
		fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
		font_formats: "Andale Mono=andale mono,times;" +
			"Arial=arial,helvetica,sans-serif;" +
			"Arial Black=arial black,avant garde;" +
			"Book Antiqua=book antiqua,palatino;" +
			"Comic Sans MS=comic sans ms,sans-serif;" +
			"Courier New=courier new,courier;" +
			"Georgia=georgia,palatino;" +
			"Helvetica=helvetica;" +
			"Impact=impact,chicago;" +
			"Symbol=symbol;" +
			"Tahoma=tahoma,arial,helvetica,sans-serif;" +
			"Terminal=terminal,monaco;" +
			"Times New Roman=times new roman,times;" +
			"Trebuchet MS=trebuchet ms,geneva;" +
			"Verdana=verdana,geneva;" +
			"Webdings=webdings;" +
			"Wingdings=wingdings,zapf dingbats",
		forced_root_block_attrs: { "style": "margin: 0 0; font-family:Arial, Helvetica, sans-serif; font-size:10pt;" },

		plugins: "charmap media print template code link paste autolink lists preview table wordcount",
		toolbar: ["undo redo | copy cut paste | preview code print | bold italic underline | forecolor backcolor | fontselect fontsizeselect styleselect | table charmap link | wordcount"],
		// uploading images on paste to images_upload_url is automatic by tinyMce
		// <img> src and data-mce-src atributes are auto-updated with the location returned by images_upload_url
		paste_data_images: true,
		paste_retain_style_properties: "font-size, font-family",
		content_css: ""
	}
}


export function getAvailableSensorAttributesFromSensorData(sensorValues, preferredSensorOrder, includeThermostat) {

	//TODO: try to simplify
	// determine available sensor attributes
	let result = new Set()
	if (sensorValues) {
		sensorValues.forEach(sensorValue => {
			if (sensorValue.field === "devEUI") {
				//skip
			} else if (sensorValue.field === "target_temperature") {
				if(includeThermostat) {
					result.add(sensorValue.field)
				}
			} else {
				result.add(sensorValue.field)
			}
		})
	}

	if(preferredSensorOrder == null){
		preferredSensorOrder = store.state.systemParams.preferredSensorOrder
	}

	if(result && result.size > 0) {
		return [...result].sort(function (a, b) {
			return preferredSensorOrder.indexOf(a) - preferredSensorOrder.indexOf(b)
		})
	} else {
		return []
	}
}

export function processSensorData(sensorValues){

	let sensorValuesMap = new Map()
	sensorValues.forEach(sensorValue => {
		if (sensorValuesMap.has(sensorValue.field)) {
			sensorValuesMap.get(sensorValue.field).push(sensorValue)
		} else {
			sensorValuesMap.set(sensorValue.field, [sensorValue])
		}
	})

	return sensorValuesMap
}

export function getAvailableSensorAttributesFromPivotedSensorData(sensorValues, preferredSensorOrder) {

	// determine available sensor attributes
	let result = new Set()
	if (sensorValues) {
		sensorValues.forEach(sensorValue => {
			for (const property in sensorValue.values) {
				if (property !== "target_temperature" && property !== "devEUI") {
					result.add(property)
				}
			}
		})
	}

	if(preferredSensorOrder == null){
		preferredSensorOrder = store.state.systemParams.preferredSensorOrder
	}

	if(result && result.size > 0) {
		return [...result].sort(function (a, b) {
			return preferredSensorOrder.indexOf(a) - preferredSensorOrder.indexOf(b)
		})
	} else {
		return []
	}
}

export function processPivotedSensorData(sensorValues) {

	let sensorValuesMap = new Map()
	sensorValues.forEach(sensorValue => {
		for (const property in sensorValue.values) {
			sensorValue.value = sensorValue.values[property]
			if (sensorValuesMap.has(property)) {
				sensorValuesMap.get(property).push({...sensorValue})
			} else {
				sensorValuesMap.set(property, [{...sensorValue}])
			}
		}
	})

	return sensorValuesMap
}

