<template>
  <div>
    <div class="row">
      <div :class="graphConfig.fullSize ? 'col-xxxl-12' : 'col-xxxl-6'" class="col-xxl-12 p-xl-2 p-md-0" v-for="chart in state_graphData" :key="chart.sensorDef.type">
        <highcharts :options="chart" :ref="chart.sensorDef.type"></highcharts>
      </div>
    </div>
  </div>
</template>


<script>
import {mapState} from "vuex"
import moment from 'moment-timezone'
import Highcharts from 'highcharts';

let chartOptionsBase = {
  credits: false,
  title: {
    useHTML: true,
    style: {
      fontSize: '14px',
      fontStyle: 'bold'
    }
  },
  chart: {
    type: 'spline',
    height: '350px',
    spacingTop: 40,
    style: {
      fontFamily: 'open sans, Helvetica Neue, Helvetica, Arial, sans-serif',
    }
  },
  xAxis: {
    type: 'datetime',
    labels: {
      autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
      formatter: function () {
        return Highcharts.dateFormat('%H:%M', this.value);
      }
    },
    minPadding: 0,
    maxPadding: 0,
    tickInterval: 3600 * 1000
  },
  plotOptions: {
    area: {
      fillOpacity: 0.3,
    },
    spline: {
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3
        }
      },
      marker: {
        enabled: false
      }
    }
  },
  yAxis: {
    maxPadding: 0.1,
    allowDecimals: false,
    title: {
      text: ''
    }
  },
  legend: {
    enabled: false
  },
  series: []
}

export default {
  data() {
    return {
    }
  },
  watch: {
    graphConfig(newVal, oldVal) {
      console.log('graphConfig changed: ',  JSON.stringify(newVal), ' | was: ', oldVal)
      if(newVal && oldVal && newVal.fullSize !== oldVal.fullSize)
        if (this.graphData && this.graphData.size > 0) {
          let vm = this
          this.graphData.forEach((sensorData, key) => {
            this.$nextTick(() => {
              let comp = vm.$refs[key]
              if (comp) {
                setTimeout(function() {
                  //comp[0].chart.reflow()
                  window.dispatchEvent(new Event('resize'));
                }, 1000)
              }
            })
          })
        }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      selectedMap: state => state.indoorClimate.selectedMap,
      selectedBuilding: state => state.indoorClimate.selectedMap.building,
      selectedLocation: state => state.indoorClimate.selectedLocation,
      mapConfig: state => state.indoorClimate.mapConfig,
      graphConfig: state => state.indoorClimate.graphConfig,
      graphData: state => state.indoorClimate.graphData
    }),

    state_graphData() {

      let charts = []

      console.log('state_graphData size ' + this.graphData.size + ', selectedSensorAttributes ' + this.mapConfig.selectedSensorAttributes)

      let vm = this

      if (this.graphData && this.graphData.size > 0) {

        let profileParameters = this.selectedLocation.locationProfile ? this.selectedLocation.locationProfile.parameters : this.$store.state.systemParams.defaultLocationProfileParams

        this.graphData.forEach((sensorData, key) => {

          if(sensorData.length > 1 && this.mapConfig.selectedSensorAttributes && this.mapConfig.selectedSensorAttributes.includes(key)) {

            let chartDef = JSON.parse(JSON.stringify(chartOptionsBase))

            chartDef.sensorDef = vm.$i18n.t('enums.sensorType.' + key)
            chartDef.sensorDef.type = key
            chartDef.title = {
              text: '<i class="' + chartDef.sensorDef.icon + '  mr-2"></i>' + chartDef.sensorDef.name + ' (' + chartDef.sensorDef.unit + ')'
            }
            chartDef.time = {}
            chartDef.time.getTimezoneOffset = function (timestamp) {
              return -moment.tz(timestamp, vm.selectedBuilding.timeZone).utcOffset();
            }

            //chartDef.yAxis.title.text = chartDef.sensorDef.name + ' (' + chartDef.sensorDef.unit + ')'
            chartDef.series[0] = {}
            chartDef.series[0].unit = chartDef.sensorDef.unit
            chartDef.series[0].name = chartDef.sensorDef.name
            chartDef.series[0].data = []
            chartDef.series[0].tooltip = { valueSuffix: ' ' + chartDef.sensorDef.unit }

            if(this.graphConfig.disableThresholds !== true) {

              // zones by location profile
              if (key == 'temperature' || key == 'humidity') {

                chartDef.series[0].zones = [
                  {value: profileParameters[key][0], className: 'zone-red'},
                  {value: profileParameters[key][1], className: 'zone-yellow'},
                  {value: profileParameters[key][2], className: 'zone-green'},
                  {value: profileParameters[key][3], className: 'zone-yellow'},
                  {className: 'zone-red'}
                ]

                chartDef.yAxis.plotBands = [
                  {from: 0, to: profileParameters[key][0], className: 'zone-red'},
                  {from: profileParameters[key][0], to: profileParameters[key][1], className: 'zone-yellow'},
                  {from: profileParameters[key][1], to: profileParameters[key][2], className: 'zone-green'},
                  {from: profileParameters[key][2], to: profileParameters[key][3], className: 'zone-yellow'},
                  {from: profileParameters[key][3], to: 10000, className: 'zone-red'}
                ]

              } else if (key == 'co2' || key == 'noise') {

                chartDef.series[0].zones = [
                  {value: profileParameters[key][0], className: 'zone-green'},
                  {value: profileParameters[key][1], className: 'zone-yellow'},
                  {className: 'zone-red'},
                ]

                chartDef.yAxis.plotBands = [
                  {from: 0, to: profileParameters[key][0], className: 'zone-green'},
                  {from: profileParameters[key][0], to: profileParameters[key][1], className: 'zone-yellow'},
                  {from: profileParameters[key][1], to: 10000,  className: 'zone-red'}
                ]
              }
            } else {

              this.$nextTick(() => {
                let comp = vm.$refs[key]
                if (comp) {
                  $('g.highcharts-plot-bands-0').remove()
                }
              })
            }

            //customizing yAxis according to attribute type
            if(chartDef.sensorDef.type == 'temperature'){
              chartDef.yAxis.softMin = 15
              chartDef.yAxis.softMax = 30
            } else if(chartDef.sensorDef.type == 'humidity'){
              chartDef.yAxis.min = 0
              chartDef.yAxis.max = 100
            }

            for (let i = 0; i < sensorData.length; i++) {
              //console.log("sensorData[" + i + "]: " + JSON.stringify(sensorData[i]))
              if (i > 0 && Date.parse(sensorData[i].time) < Date.parse(sensorData[i - 1].time)) {
                console.warn("!!!! Mismatch sensorData[" + i + "]: " + JSON.stringify(sensorData[i]) + ", sensorData[" + (i-1) + "]: " + JSON.stringify(sensorData[i - 1]))
              }
              chartDef.series[0].data.push({
                x: Date.parse(sensorData[i].time),
                y: sensorData[i].value ? Math.round(+(sensorData[i].value) * 10) / 10 : null
              })
            }
            chartDef.series[0].data.sort((a,b)=>{return a.x - b.x})
            charts.push(chartDef)

            /*
            this.$nextTick(() => {
              let comp = vm.$refs[key]
              if (comp) {
                comp[0].chart.yAxis[0].update(chartDef.yAxis[0])
              }
            })
             */

          }
        })

        let preferredOrder = this.$store.state.systemParams.preferredSensorOrder
        charts.sort(function (a, b) {
          return preferredOrder.indexOf(a.sensorDef.type) - preferredOrder.indexOf(b.sensorDef.type)
        });

      }

      return charts
    }
  },
}
</script>

<style>
  .highcharts-area.zone-green, .highcharts-point.zone-green, .highcharts-plot-band.zone-green {
    fill: #66C28F;
    opacity: 0.25;
  }
  .highcharts-graph._zone-green {
    stroke: #66C28F;
  }
  .highcharts-area.zone-yellow, .highcharts-point.zone-yellow, .highcharts-plot-band.zone-yellow {
    fill: #FFD966;
    opacity: 0.25;
  }
  .highcharts-graph._zone-yellow {
    stroke: #FFD966;
  }
  .highcharts-area.zone-red, .highcharts-point.zone-red, .highcharts-plot-band.zone-red {
    fill: #E94F37;
    opacity: 0.25;
  }
  .highcharts-graph._zone-red {
    stroke: #E94F37;
  }

</style>