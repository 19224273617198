<template>
  <div>

    <h4 class="mb-4">{{ $t('orgAdmin.map.details.locations') }}</h4>

    <div class="form-group row m-b-sm" v-if="locations && locations.length > 0">
      <div class="col-lg-6">
        <div class="input-group">
          <span class="input-group-prepend">
            <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length == 0" @click="clearFilter"> {{
                $t('common.actions.clear')
              }}</b-button>
          </span>
          <b-form-input type="text"
                        v-model="table.filter"
                        debounce="300"
                        max="50"
                        :placeholder="$t('enterForSearch')"
                        class="form-control form-control-sm"/>
        </div>
      </div>
      <div class="col-lg-2 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="locations.length"></row-count>
      </div>
      <div class="col-lg-4 text-right" v-if="locations.length && locations.length > table.size">
        <b-pagination v-if="locations.length" size="sm" class="m-t-xs" align="right"
                      :total-rows="locations.length"
                      v-model="table.page"
                      :per-page="table.size"></b-pagination>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-table responsive hover
                 v-if="locations.length != 0"
                 class="text-nowrap"
                 :filter="table.filter"
                 :fields="table.fields"
                 :current-page="table.page"
                 :per-page="table.size"
                 :items="locations"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc">
          <template #cell(name)="data">
            <b-link @click="select(data.item.id)">{{ data.value }}</b-link>
          </template>
          <template #cell(status)="data">
            <span class="label" :class="[data.item.status == 'BLOCKED' || data.item.status == 'PARENT_BLOCKED'? 'label-danger' : 'label-primary']">{{ data.value}}</span>
          </template>
        </b-table>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import RowCount from '@/components/common/RowCount';
import Vue from "vue";
import * as commons from "@/commons";
import i18n from "@/i18n";

export default {
  components: {
    RowCount
  },
  props: {
    selectedCallback: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: 'name',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50}
        ],
        fields: [
          {
            key: 'name',
            label: this.$i18n.t('orgAdmin.location.attributes.name'),
            sortable: true
          },
          {
            key: 'status',
            label: this.$i18n.t('orgAdmin.location.attributes.status'),
            sortable: true,
            class: ["text-center"],
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return i18n.t('enums.ResourceStatus.' + item.status)
            }
          },
          /*
          {
            key: 'locationProfile.name',
            label: this.$i18n.t('orgAdmin.location.attributes.locationProfile'),
            sortable: true
          },
          {
            key: 'schedulerProfile.name',
            label: this.$i18n.t('orgAdmin.location.attributes.schedulerProfile'),
            sortable: true
          }
          */
        ]
      }
    }
  },
  mounted() {
    console.log('MapLocationsList created()')
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    },
    select(locationId){
      if(this.selectedCallback){
        this.selectedCallback(locationId)
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      locations: state => state.buildings.mapDetails.entity.locations
    })
  }
}
</script>
