<template>
  <div>
    <building-log-list :logs="logs" :backRoute="backRoute" @refreshLogs="loadLogs"></building-log-list>
    <b-button @click="createNewLog" class="mb-2 new-log-btn">{{ $t('orgAdmin.building.logs.newLog') }} + </b-button>
    <b-button v-if="logs.length" @click="downloadLogs" class="mb-2 ml-2 download-logs-btn">{{ $t('common.actions.download') }} <i class="fa-solid fa-download ml-1" ></i></b-button>
  </div>
</template>

<script>
import BuildingLogList from './BuildingLogList.vue';
import axios from 'axios';

export default {
  components: {
    BuildingLogList,
  },
  props: {
    orgId: {
      type: Number,
      required: true,
    },
    buildingId: {
      type: Number,
      required: false,
    },
    backRoute: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectedLog: null,
      logMode: 'view', // Can be 'view' or 'edit'
    };
  },
  computed: {
    logs() {
      return this.$store.state.buildingLogs.logs;
    }
  },
  created() {
    this.loadLogs();
    // this.$store.dispatch('buildingLogs/loadOrgDetails', this.orgId);
    if (this.buildingId) {
      this.$store.dispatch('buildingLogs/loadBuildingDetails', this.buildingId);
    }
  },
  methods: {
    loadLogs() {
      if (this.buildingId) {
        this.$store.dispatch('buildingLogs/loadLogsForBuilding', this.buildingId);
      } else {
        this.$store.dispatch('buildingLogs/loadLogsForOrg', this.orgId);
      }
    },
    createNewLog() {
      this.$store.commit("buildingLogs/SET_BACK_ROUTE", this.backRoute)
      this.$router.push(`/buildings/${this.buildingId}/logs/new`);
    },
    async downloadLogs() {
      try {
        const endpoint = this.buildingId 
          ? `/building/${this.buildingId}/logs/export`
          : `/organisation/${this.orgId}/logs/export`;

        const response = await axios.get(endpoint, {
          responseType: 'blob', // Important for handling binary data
        });

        const filename = this.buildingId ? `building-${this.buildingId}-logs.xlsx` : `org-${this.orgId}-logs.xlsx`;

        // Create a link element and trigger a download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } 
      catch (error) {
        console.error('Error downloading logs:', error);
        this.$bvToast.toast(this.$t('orgAdmin.building.logs.downloadFailed'), {
          title: this.$t('orgAdmin.building.parameters.error'),
          variant: 'danger',
          solid: true
        });
      }
    },
  },
};

</script>

<style scoped>

.new-log-btn {
  background-color: #61AEB7;
  color: white;
  border: none;
}

.download-logs-btn {
  border: 1px solid #61AEB7;
  background-color: white;
  color: #61AEB7;
}

</style>
