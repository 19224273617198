import * as commons from '@/commons'
import {
    countryNameFromCode,
    formatDateByLocale,
    formatDateToDateTimeByLocale,
    formatDateToDateTimeShortByLocale
} from "@/filters"
import store from "@/store";
import {hasRole} from "@/commons";

export default {
    methods: {
        confirmDialog: commons.confirmDialog,
        warningDialog: commons.warningDialog,
        infoDialog: commons.infoDialog,
        getConfigParamValue: commons.getConfigParamValue,
        hasRole: commons.hasRole,
        hasOneOfRoles: commons.hasOneOfRoles,
        hasResourceRole: commons.hasResourceRole,
        getUserAuthorities: commons.getUserAuthorities,
        getUserOrganisationAuthorities: commons.getUserOrganisationAuthorities,
        getUserBuildingAuthorities: commons.getUserBuildingAuthorities,
        getUserLocationAuthorities: commons.getUserLocationAuthorities,
        processRestError: commons.processRestError,
        goBack() {
            this.$router.go(-1)
        },
        countryNameFromCode: countryNameFromCode,
        formatDateByLocale: formatDateByLocale,
        formatDateToDateTimeByLocale: formatDateToDateTimeByLocale,
        formatDateToDateTimeShortByLocale: formatDateToDateTimeShortByLocale,
        getUniqueListBy: commons.getUniqueListBy,
        groupBy: commons.groupBy
    },
    computed: {
        isContextOrgAdmin() {
            let result = false
            let currentUser = this.$store.state.userInfo
            if(hasRole('ADMIN')){
                return true;
            }
            let currentOrgId = this.$store.state.contextOrg?.id
            if (currentUser && currentUser.authorities && currentOrgId) {
                result = currentUser.authorities.some(authority => authority.role === 'ORGANISATION_MANAGER' && authority.authorizationTarget.id == currentOrgId)
            }
            console.log('isContextOrgAdmin currentOrgId ' + currentOrgId + ' ' + result)
            return result
        },
        isBuildingAdminInContextOrg() {
            let result = false
            let currentUser = this.$store.state.userInfo
            let currentOrgId = this.$store.state.contextOrg?.id
            if (currentUser && currentUser.authorities && currentOrgId) {
                result = currentUser.authorities.some(authority => authority.role === 'BUILDING_MANAGER' && authority.authorizationTargetPath.organisation.id == currentOrgId)
            }
            console.log('isBuildingAdminInContextOrg currentOrgId ' + currentOrgId + ' ' + result)
            return result
        }
    }
}